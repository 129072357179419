import React from 'react';
import { ShepherdScreen, GoogleCalendarIcon, GoogleCalendarScreen } from '../images/index';
import { CALENDAR_SCREEN_HIGHLIGHT } from '../utils/colours';
import OnboardingWelcomeScreensModel from '../utils/onboarding-welcome-screens-model';
import { Container, ImageContainer, ImagesContainer } from '../utils/styles';
import WelcomeScreenTitleAndSubtitle from '../components/welcome-screens-title-and-subtitle-organism';

const CalendarScreen = () => {
  const { title, highlightedTitle, subtitle } = OnboardingWelcomeScreensModel.value.prepareNotes;

  return (
    <Container>
      <ImagesContainer>
        <ImageContainer left="0px" top="160px" animation="fadeInRight" order={1}>
          <GoogleCalendarScreen />
        </ImageContainer>
        <ImageContainer left="50px" top="280px" animation="fadeIn" delay="0.2s" order={3}>
          <GoogleCalendarIcon />
        </ImageContainer>
        <ImageContainer left="179px" top="60px" animation="fadeInLeft" order={2}>
          <ShepherdScreen />
        </ImageContainer>
      </ImagesContainer>
      <WelcomeScreenTitleAndSubtitle
        title={title}
        highlightedSection={highlightedTitle}
        highlightColor={CALENDAR_SCREEN_HIGHLIGHT}
        subtitle={subtitle}
      />
    </Container>
  );
};

export default CalendarScreen;
