import React from 'react';
import styled from 'styled-components';
import { blue6 } from '../../../colours';
import { uiTextMedium } from '../../../typography';

const MarkAllReadText = styled.div`
  ${uiTextMedium}
  font-size: 12px;
  color: ${blue6};
  padding-left: 16px;
  cursor: pointer;
  width: fit-content;
`;

interface Props {
  tabIndex: number,
  unreadCount: number,
  onClick: ()=> void;
}

function MarkAllReadButton({ tabIndex, onClick, unreadCount }:Props) {
  if (tabIndex !== 2 && unreadCount !== 0) {
    return (
      <MarkAllReadText onClick={onClick}>Mark all as read</MarkAllReadText>
    );
  }
  return <></>;
}

export default MarkAllReadButton;
