import React, {
  Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../colours';
import { PrivateNoteData } from '../../../types/types';
import PrivateSpaceButtonMolecule from '../unused/delete-private-note-modal/components/private-space-button-molecule';
import ExtraChatMolecule from '../../../../pages/meeting/tab/secret-chat-tab-buttons/ExtraChatAtom';

const Container = styled.div`
  width: 266px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 8px;
  margin-top: 40px;
  background: ${gray1};
  box-shadow: 0px 8px 16px rgba(27, 33, 36, 0.16), 0px 4px 6px -2px rgba(27, 33, 36, 0.06);
  border-radius: 8px;
  z-index: 20;
`;

export const ItemContainer = styled.div<ItemContainerProps>`
  width: 100%;
  margin-bottom: ${(props) => (props.isLastChatItem ? 'opx' : '8px')};
`;

export interface ItemContainerProps {
  isLastChatItem: boolean,
}

interface Props {
  privateNotes: PrivateNoteData[],
  isOpen: boolean,
  refOutsideClick: React.MutableRefObject<null>,
  // eslint-disable-next-line no-unused-vars
  handleSecretNotesSelected: (chat: PrivateNoteData) => void,
  selectedChat: number,
  setSelectedChat: Dispatch<SetStateAction<number>>,
  handleOpenCreateSecretChat: () => void,
  setChatToEdit: Dispatch<SetStateAction<PrivateNoteData>>,
  setEditModalOpen: Dispatch<SetStateAction<boolean>>,
}

const PrivateNoteDropdownOption = ({
  privateNotes,
  isOpen,
  refOutsideClick,
  handleSecretNotesSelected,
  selectedChat,
  setSelectedChat,
  handleOpenCreateSecretChat,
  setChatToEdit,
  setEditModalOpen,
}: Props) => {
  if (!isOpen) return null;

  const handleChatSelected = (secretChat: PrivateNoteData, index: number) => {
    handleSecretNotesSelected(secretChat);
    setSelectedChat(index);
  };

  return (
    <Container ref={refOutsideClick}>
      {
        privateNotes.map((privateNote, index) => (
          <ItemContainer
            isLastChatItem={index === privateNotes.length - 1}
            key={privateNote.chatPathInRealtimeDb}
            onClick={() => handleChatSelected(privateNote, index)}
          >
            <ExtraChatMolecule
              isSelected={selectedChat === index}
              privateNote={privateNote}
              key={privateNote.chatPathInRealtimeDb}
              setChatToEdit={setChatToEdit}
              setEditModalOpen={setEditModalOpen}
            />
          </ItemContainer>
        ))
      }
      <PrivateSpaceButtonMolecule onClick={handleOpenCreateSecretChat} />
    </Container>
  );
};

export default PrivateNoteDropdownOption;
