import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { ScrollStatus, Scrollbar as ScrollbarType } from 'smooth-scrollbar/interfaces';
import { Scrollbar as SmoothScrollbar } from 'smooth-scrollbar-react';
import { gray3, gray5, gray1 } from '../../colours';

const ScrollbarStyleModifier = styled.div`
  background-color:${gray1};
  & > div > .scrollbar-track-y {
    width: 6px;
    background-color: ${gray3};

    & > .scrollbar-thumb-y {
      width: 6px;
      background-color: ${gray5};
    }
  }
`;

const HeightLimiter = styled.div`
  max-height: ${(props: Props) => props.maxHeight};
  overflow-y: scroll;
  overflow-x: hidden;
`;

interface Props {
  maxHeight: string,
  children: ReactNode
  // eslint-disable-next-line no-unused-vars
  onScroll?: ((status: ScrollStatus, scrollbar: ScrollbarType | null) => void) | undefined,
}

const Scrollbar = ({ maxHeight, children, onScroll }: Props) => (
  <ScrollbarStyleModifier>
    <SmoothScrollbar
      plugins={{ overscroll: { maxOverscroll: 30, damping: 0.15 } }}
      onScroll={onScroll}
      alwaysShowTracks
      renderByPixels
    >
      <HeightLimiter maxHeight={maxHeight}>
        {children}
      </HeightLimiter>
    </SmoothScrollbar>
  </ScrollbarStyleModifier>
);

Scrollbar.defaultProps = {
  onScroll: undefined,
};

export default Scrollbar;
