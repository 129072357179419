import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../../../../shared/colours';
import OnboardingWelcomeScreensModel from '../../../utils/onboarding-welcome-screens-model';
import SingleIntegration from './SingleIntegration';

interface AllIntegrationsProps {
  setIsConfettiActive: Dispatch<SetStateAction<boolean>>;
}

const AllIntegrations = ({
  setIsConfettiActive,
}: AllIntegrationsProps) => {
  const { apps } = OnboardingWelcomeScreensModel.integration;

  return (
    <IntegrationsContainer>
      {apps.map((app) => (
        <SingleIntegration
          key={app.id}
          data={app}
          functions={{ setActivateConfetti: setIsConfettiActive }}
        />
      ))}
    </IntegrationsContainer>
  );
};

export default AllIntegrations;

const IntegrationsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  row-gap: 16px;

  width: 100%;
  background-color: ${gray1};
  border-radius: 10px;
  padding: 40px 24px;
`;
