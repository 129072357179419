import { UserSettings } from '../../shared/types/types';

export const DEFAULT_USER_SETTINGS: UserSettings = {
  fanOfCoding: false,
  fanOfPPT: false,
  receiveNewsletter: false,
  defaultUserTab: 'shared',
  receiveTaskEmail: false,
  isDesktopNotificationEnabled: false,
  desktopNotification: {
    endpoint: '',
    expirationTime: '',
    keys: {
      p256dh: '',
      auth: '',
    },
  },
};

export const mapDatabaseUserSettingsToUserSettings = (databaseUserSettings: any): UserSettings => {
  const userSettings: UserSettings = {
    fanOfCoding: databaseUserSettings?.fanOfCoding ?? false,
    fanOfPPT: databaseUserSettings?.fanOfPPT ?? false,
    receiveNewsletter: databaseUserSettings?.receiveNewsletter ?? false,
    defaultUserTab: databaseUserSettings?.defaultUserTab ?? 'shared',
    receiveTaskEmail: databaseUserSettings?.receiveTaskEmail ?? false,
    isDesktopNotificationEnabled: databaseUserSettings?.isDesktopNotificationEnabled ?? false,
    desktopNotification: {
      endpoint: databaseUserSettings?.desktopNotification?.endpoint ?? '',
      expirationTime: databaseUserSettings?.desktopNotification?.expirationTime ?? '',
      keys: {
        p256dh: databaseUserSettings?.desktopNotification?.keys?.p256dh ?? '',
        auth: databaseUserSettings?.desktopNotification?.keys?.auth ?? '',
      },
    },
  };
  return userSettings;
};
