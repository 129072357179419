import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  gray2, darkBlue4, surface,
} from '../../colours';
import { uiText } from '../../typography';
import Scrollbar from '../scrollbar';

interface StyleProps {
  height: number
}

const TextArea = styled.textarea<StyleProps>`
  ${uiText};
  width: 100%;
  height: ${({ height }:StyleProps) => (`${height}px`)};
  background-color: ${gray2};
  color: ${surface};
  border: unset;
  border-radius: 10px;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  flex-grow: 0;
  align-self: stretch;
  resize: none;

  &:focus-visible, &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: ${darkBlue4};
  }

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface TextBoxProps {
  height?: number
  placeholder?: string,
  onChange?: any | undefined,
  defaultValue?: string,
}

const TextBox = ({
  placeholder, onChange, defaultValue, height = 36,
}: TextBoxProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e?.target?.value) onChange(e?.target?.value);
    if (textareaRef.current === null) return;
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  // Set the scrollbar at the very first render
  useEffect(() => {
    if (textareaRef.current === null) return;
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [defaultValue]);

  return (
    <Scrollbar maxHeight="160px">
      <TextArea
        height={height}
        placeholder={placeholder}
        onChange={handleChange}
        defaultValue={defaultValue}
        ref={textareaRef}
      />
    </Scrollbar>
  );
};

TextBox.defaultProps = {
  height: 36,
  placeholder: '',
  onChange: undefined,
  defaultValue: '',
};

export default TextBox;
