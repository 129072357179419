/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import {
  blue6,
  darkBlue1,
  darkBlue2,
  darkBlue3, darkBlue4, gray1, green6, green7, red7,
} from '../../colours';
import { small, uiText } from '../../typography';
import fBytes from '../../../utils/formatBytes';
import CrossIcon from '../../icons/cross';

const ChipParent = styled.div`
  margin-top: 16px;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  background-color: ${gray1};
  overflow: hidden;
  position: relative;
`;

const ChipContent = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FileName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 128px;
  display: inline-block;
  margin-right: 8px;
  ${uiText}
`;

interface ColoredComponent {
  color: string;
}

const Info = styled.span<ColoredComponent>`
  ${small}
  color: ${(props) => props.color};
`;

const LoadingBarContainer = styled.div`
  background-color: ${darkBlue1};
  height: 6px;
  width: 100%;
  position: absolute;
  bottom: 0;
  transition: all cubic-bezier(.24,.44,.82,1) 0.5s;
`;

interface LoadingBarProps extends ColoredComponent {
  width: string;
}

const LoadingBar = styled.div<LoadingBarProps>`
  height: 100%;
  background-color: ${(props) => props.color};
  width: ${(props) => props.width};
  transition: all cubic-bezier(.24,.44,.82,1) 0.5s;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const CrossButton = styled.div`
  position: relative;
  width: 10px;
  height: 10px;

  &:hover {
    cursor: pointer;
  }

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); 
  }

  &:hover path {
    fill: ${darkBlue4};
  }
`;

export interface FileUploadChipProps {
  filename?: string;
  currentFileSize?: number;
  fullFileSize?: number;
  error?: boolean;
  pause?: () => void;
  cancel?: () => void;
}

const FileUploadChip = ({
  filename,
  currentFileSize,
  fullFileSize,
  error,
  pause,
  cancel,
}: FileUploadChipProps) => {
  const getInfoData = useCallback(
    () => {
      if (error) {
        return ['Upload failed', red7, '100%', red7];
      }
      if (currentFileSize! >= fullFileSize!) {
        return ['Upload complete', green7, '100%', green6];
      }
      return [
        `Uploading... ${fBytes(currentFileSize!)} of ${fBytes(fullFileSize!)}`,
        darkBlue3,
        `${((currentFileSize! / fullFileSize!) * 100).toFixed(0)}%`,
        blue6,
      ];
    },
    [error, currentFileSize, fullFileSize],
  );

  return (
    <ChipParent>
      <ChipContent>
        <FileName>
          { filename }
        </FileName>
        <Info
          color={getInfoData()[1]}
        >
          {getInfoData()[0]}
        </Info>
        <Spacer />
        <CrossButton
          onClick={cancel!}
        >
          <CrossIcon
            fill={darkBlue2}
            width={26}
            height={26}
          />
        </CrossButton>
      </ChipContent>
      <LoadingBarContainer>
        <LoadingBar
          width={getInfoData()[2]}
          color={getInfoData()[3]}
        />
      </LoadingBarContainer>
    </ChipParent>
  );
};

FileUploadChip.defaultProps = {
  filename: 'Photo-1.jpeg',
  currentFileSize: 50_000,
  fullFileSize: 100_000,
  error: false,
  pause: () => null,
  cancel: () => null,
};

export default FileUploadChip;
