import { Dispatch, SetStateAction } from 'react';
import { DropdownItem } from '../../../../../shared/components/dropdown/utils';
import {
  Note, PrivateNoteHtml, SharePlatforms, TaskItem,
} from '../../../../../shared/types/types';
import { EmailRecipient } from '../../ShareNotesModal';

export const APPS_VIEW = 'appsView';
export const SHARE_VIEW = 'shareView';
export const NOTION_SHARE_VIEW = 'notionShareView';
export const SLACK_SHARE_VIEW = 'slackShareView';
export const TRELLO_SHARE_VIEW = 'trelloShareView';
export const EMAIL_SHARE_VIEW = 'emailShareView';
export const CALENDAR_SHARE_VIEW = 'calendarShareView';

export type ShareModalMode = typeof APPS_VIEW | typeof SHARE_VIEW | typeof NOTION_SHARE_VIEW |
  typeof SLACK_SHARE_VIEW | typeof TRELLO_SHARE_VIEW | typeof EMAIL_SHARE_VIEW
  | typeof CALENDAR_SHARE_VIEW

export interface PlatformData {
  platform: SharePlatforms,
  isActiveIntegration: boolean,
  isEnabled: boolean,
  onClick: () => void,
}

export interface NotesData {
  emailHeadTemplate: string,
  agendaHtml: Note,
  sharedNotesHtml: Note,
  personalNotesHtml: Note,
  privateNotesHtml: PrivateNoteHtml[],
  setEmailHeadTemplate: Dispatch<SetStateAction<string>>
  setAgendaHtml: Dispatch<SetStateAction<Note>>
  setSharedNotesHtml: Dispatch<SetStateAction<Note>>
  setPersonalNotesHtml: Dispatch<SetStateAction<Note>>
}

export interface NotesSwitchData {
  isAgendaNotesEnabled: boolean,
  isAgendaNotesChecked: boolean,
  isAgendaNotesDisabled: boolean,
  isSharedNotesChecked: boolean,
  isSharedNotesDisabled: boolean,
  isPersonalNotesChecked: boolean,
  isPersonalNotesDisabled: boolean,
  isTasksChecked: boolean,
  isTasksDisabled: boolean,
  isPrivateSpaceNotesChecked: string[]
}

export interface PlatformsMultiInputsData {
  emailRecipients: EmailRecipient[],
  setEmailRecipients: Dispatch<SetStateAction<EmailRecipient[]>>,
  slackChannelsToReceive: DropdownItem[],
  setSlackChannelsToReceive: Dispatch<SetStateAction<DropdownItem[]>>,
  taskItems: TaskItem[],
  taskToSend: TaskItem[],
  setTaskToSend: Dispatch<SetStateAction<TaskItem[]>>
}
