import React, { useEffect, useState } from 'react';
import { PRIVATE_NOTES } from '../../../../../../utils/enums';
import { darkBlue4, darkBlue6, gray1 } from '../../../../../colours';
import SmallArrowDown from '../../../../../icons/text-editor/SmallArrowDown';
import { MeetingSection } from '../../../../../types/types';
import DropdownArrowContainer from './utils/styles';

interface Props {
  meetingTab: MeetingSection,
  isDropdownOpen: boolean,
  /* eslint-disable-next-line */
  onClickToggleDropdown: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
}

const DropdownArrow = ({
  meetingTab,
  isDropdownOpen,
  onClickToggleDropdown,
}: Props) => {
  const [isPrivateNotesActive, setIsPrivateNotesActive] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    setIsPrivateNotesActive(meetingTab === PRIVATE_NOTES);
  }, [meetingTab]);

  const setSmallArrowDownColor = () => {
    if (isPrivateNotesActive) return gray1;
    if (!isPrivateNotesActive && isMouseOver) return darkBlue6;
    return darkBlue4;
  };

  return (
    <DropdownArrowContainer
      meetingTab={meetingTab}
      isDropdownOpen={isDropdownOpen}
      onClick={(event) => onClickToggleDropdown(event)}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <SmallArrowDown fill={setSmallArrowDownColor()} />
    </DropdownArrowContainer>
  );
};

export default DropdownArrow;
