// We separate the Slack API in two files
// 1. SlackAPI
// 2. SlackCoreAPI

// In 1. we handle toasts and setting of loading values
// In 2. we handle the actual sending of the message
//    and parsing of the result

import { Dispatch, SetStateAction } from 'react';
import { AuthState, SlackChannel, TaskItem } from '../../shared/types/types';
import { toastSuccess, toastDanger } from '../notifications';
import { isValidAccessToken } from './SlackAPIUtils';
import { slackCoreAPISendNotificationForTaskUpdate, slackCoreSendMessages } from './SlackCoreAPI';

const slackAPISendMessages = (
  authState: AuthState,
  channels: SlackChannel[],
  accessToken: string,
  notes: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  closeModal: () => void,
) => {
  if (!isValidAccessToken(accessToken)) {
    toastDanger('Error', 'Invalid access token');
    return;
  }

  slackCoreSendMessages(authState, channels, accessToken, notes)
    .then(() => {
      toastSuccess('Success', 'Notes shared to Slack');
      closeModal();
    })
    .catch(() => {
      toastDanger('Ops!', 'Something went wrong while sharing notes to Slack');
    })
    .finally(() => {
      setLoading(false);
    });
};

export const slackAPINotificationForTaskUpdate = (
  task: TaskItem,
  context: AuthState,
  updatedSection: string,
  newDueDate?: string,
  newTitle?: string,
) => {
  slackCoreAPISendNotificationForTaskUpdate(task, context, updatedSection, newDueDate, newTitle);
};

export default slackAPISendMessages;
