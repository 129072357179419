import * as Sentry from '@sentry/browser';
import { firestore } from '../utils/firebase';
import { UserFeedback } from '../shared/types/types';

const dbSaveUserFeedback = (userFeedbackData: UserFeedback) => {
  const userFeedback = firestore()
    .collection('feedback')
    .add(userFeedbackData)
    .then((docRef) => {
      console.log('new feedback added', userFeedbackData);
      return docRef.id;
    })
    .catch((error) => {
      console.log('Something went wrong while adding the user feedback');
      console.log(userFeedbackData);
      Sentry.captureException(error);
      return '';
    });
  return userFeedback;
};

export default dbSaveUserFeedback;
