import React from 'react';
import { MeetingSection, MeetingVersion } from '../../../shared/types/types';
import { AGENDA } from '../../../utils/enums';
import TabDivButton from './TabDivButton';

interface AgendaTabButtonAtomProps {
  meetingDataVersion: MeetingVersion,
  meetingTab: MeetingSection,
  onClick: () => void
}

const AgendaTabButtonAtom = ({
  meetingDataVersion,
  meetingTab,
  onClick,
}: AgendaTabButtonAtomProps) => {
  if (meetingDataVersion >= 4) return null;

  return (
    <TabDivButton
      active={meetingTab === AGENDA}
      disabled={meetingTab === AGENDA}
      onClick={onClick}
      tooltipText=""
      meetingTab={AGENDA}
    >
      Agenda
    </TabDivButton>
  );
};

export default AgendaTabButtonAtom;
