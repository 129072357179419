import * as Sentry from '@sentry/browser';
import { Dispatch, SetStateAction } from 'react';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../external/publicUserData/PublicUserDataAPI';
import ConsoleImproved from '../shared/classes/ConsoleImproved';
import {
  AttendeeV2,
  ConferenceData,
  DatabaseMeetingData,
  GapiMeetingData,
  GoogleMeetingIds,
  MeetingData,
  SetMeetingDataType,
  ShepherdMeetingId,
  SimpleUserData,
} from '../shared/types/types';
import { CONFERENCE_DATA_PATH } from '../utils/constants';
import firebase, { firestore, functions, performance } from '../utils/firebase';
import { gapiGetMeeting, gapiInsertInstantMeeting } from '../utils/google/GoogleCalendarAPI';
import { gapiCoreGetMeeting } from '../utils/google/GoogleCalendarCore';
import log from '../utils/logging';
import {
  getClosestMeeting, makeGoogleCalendarUrl, makeMeetingUrl,
  sortDatabaseMeetingDataByStartDate,
} from '../utils/meetings/meetingsUtils';
import {
  handleDocDoesNotExist,
  handleOnSnapshotError,
} from './firebaseHandleError';
import { dbGetSimpleUserDataByUserId } from './firebaseUsersAPI';
import { gapiAPIGetMeetingByMeetId } from './gapiCalendarAPI';
import { rejectedGapiMeetingData } from './utils/gapiMeetingDataUtils';
import mapDatabaseMeetingDataToMeetingData from './utils/mapMeetingData';
import { mapGoogleMeetingToDatabaseMeetingData, rejectedMeetingData } from './utils/templateMeetingData';

type DocType = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;

const dbListenToMeetingData = (
  meetingId: string,
  userId: string,
  setMeetingData: SetMeetingDataType,
  setGapiMeetingData: Dispatch<SetStateAction<GapiMeetingData>>,
) => {
  ConsoleImproved.log(`Starting to listen to meeting with id: ${meetingId}`);
  return meetingRef(meetingId).onSnapshot(
    async (doc: DocType) => {
      if (!doc.exists) {
        setMeetingData(rejectedMeetingData);
        setGapiMeetingData(rejectedGapiMeetingData);
        return handleDocDoesNotExist(
          'Meeting does not exist',
          { meetingId, userId },
          () => { },
        );
      }

      const meetingData = mapDatabaseMeetingDataToMeetingData(meetingId, doc.data(), userId);
      setMeetingData(meetingData);
      console.log('Got new meeting data from Firebase');
      console.log(meetingData);

      const attendees = await cfGetAttendeesFromGoogleAttendees(meetingData.data.attendees);

      const updatedMeetingData = combineMeetingDataWithAttendees(meetingData, attendees);
      setMeetingData(updatedMeetingData);
      return null;
    }, (error) => {
      Sentry.captureException(error);
      handleOnSnapshotError('Something went wrong while listening to meeting', { meetingId, userId }, () => { });
      setMeetingData(rejectedMeetingData);
      setGapiMeetingData(rejectedGapiMeetingData);
    },
  );
};

export const dbGetMeetingsByGoogleMeetId = async (googleMeetId: string, userId: string) => firestore().collection('meetings')
  .where('googleData.ids.meetId', '==', googleMeetId)
  .get()
  .then((docs) => {
    const meetings = docs.docs.map(
      (doc) => mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), userId),
    );
    console.log(meetings);
    return meetings;
  })
  .catch((error) => {
    console.error('Something went wrong inside dbGetMeetingsByGoogleMeetId ');
    console.error(error);
  });

export const dbFindAndNavigateToMeeting = async (
  googleMeetId: string, userId: string, history: any, setError: Dispatch<SetStateAction<boolean>>,
) => {
  const trace = performance().trace('dbFindAndNavigateToMeeting');
  trace.start();
  const meetings = await functions()
    .httpsCallable('searchMeetingsByGoogleMeetId')({ googleMeetId })
    .then((newMeetings) => newMeetings.data)
    .catch((error) => {
      console.log('Error searching meeting by google meet id', error);
      Sentry.captureException(error);
      return [] as MeetingData[];
    });
  if (meetings.length === 0) {
    // Try to find meeting using GAPI
    // If they have not already created the meeting in Shepherd, it might still be
    // in their calendar.
    const meeting: GapiMeetingData = await gapiAPIGetMeetingByMeetId(googleMeetId);
    if (meeting.resolvedState === 'resolved') {
      ConsoleImproved.log('Actually found a meeting using GAPI');
      history.push(makeGoogleCalendarUrl(meeting.id, meeting.organizer.email));
      trace.stop();
      return;
    }
    ConsoleImproved.log('Did not find a meeting using GAPI');
    log(`Could not find any meetings in the database with meetId: ${googleMeetId}`);
    try {
      // create a google calendar event for the meeting

      /*
      1. First, we’re using the gapiInsertInstantMeeting function to create a new meeting.
      2. Next, we’re using the gapiCoreGetMeeting function to get the newly created meeting.
      3. Then, we’re using the dbGetSimpleUserDataByUserId function to get the user’s data.
      4. Next, we’re using the dbAddMeeting function to add the meeting to the database.
      5. Finally, we’re using the makeMeetingUrl function to create a meeting URL.
      */
      const { eventId, calendarId }: GoogleMeetingIds = await gapiInsertInstantMeeting();
      const newlyCreatedMeeting = await gapiCoreGetMeeting(eventId);
      const user: SimpleUserData = await dbGetSimpleUserDataByUserId(userId, 'admin');
      newlyCreatedMeeting.conferenceData.conferenceId = googleMeetId;
      const addMeetingResponse = await dbAddMeeting(
        mapGoogleMeetingToDatabaseMeetingData(newlyCreatedMeeting, calendarId, user),
      );
      if (addMeetingResponse.resolvedState === 'resolved') {
        const meetingUrl = makeMeetingUrl(addMeetingResponse.meetingId);
        log(`Created new meeting in database with meetId: ${addMeetingResponse.meetingId} as an Instant Meeting`);
        history.push(meetingUrl);
        trace.stop();
        return;
      }
    } catch (error) {
      trace.stop();
      log(`Could not create new Instant Meeting in the database with meetId: ${googleMeetId}`);
      setError(true);
      return;
    }
  }
  log('Meet meetings', meetings);
  const closesMeeting = getClosestMeeting(meetings);
  history.push(makeMeetingUrl(closesMeeting.meetingId));
  trace.stop();
};

const meetingRef = (meetingId: string) => firestore().collection('meetings').doc(meetingId);

export default dbListenToMeetingData;

/**
 * This is just using searching in the database by the EventId.
 * Since depending on who reads the event, different calendarIds might be used
 */
export const dbGetMeetingByEventAndCalendarId = async (
  eventId: string, calendarId: string,
) => {
  const trace = performance().trace('dbGetMeetingByEventAndCalendarId');
  trace.start();
  const meetings = await functions()
    .httpsCallable('searchMeetingsByEventAndCalendarId')({ eventId, calendarId })
    .then((newMeetings) => newMeetings.data)
    .catch((error) => {
      console.log('Error searching meeting by google meet id', error);
      Sentry.captureException(error);
      return rejectedMeetingData;
    });
  if (meetings.length === 0) return rejectedMeetingData;
  const closestMeeting = getClosestMeeting(meetings);
  trace.stop();
  return closestMeeting;
};

export const dbAddMeeting = (meetingData: DatabaseMeetingData) => firestore()
  .collection('meetings')
  .add(meetingData)
  .then((docRef) => {
    console.log('Added new meeting', meetingData);
    return { resolvedState: 'resolved', meetingId: docRef.id } as ShepherdMeetingId;
  })
  .catch((error) => {
    console.log('Something went wrong while adding meeting');
    console.log(meetingData);
    Sentry.captureException(error);
    return { resolvedState: 'rejected', meetingId: '' } as ShepherdMeetingId;
  });

export const dbUpdateMeetingData = (newValue: any, meetingId: string, DATA_PATH: string) => {
  firestore().collection('meetings').doc(meetingId).update({
    [DATA_PATH]: newValue,
  })
    .then(() => {
      log(`Updated path: ${DATA_PATH} successfully, with value:`);
      console.log(newValue);
    })
    .catch((error) => {
      log(`Unsuccessfully updated path ${DATA_PATH}, for meetingId: ${meetingId}, with value:`);
      console.log(newValue);
      console.log(error);
      Sentry.captureException(error);
    });
};

export const dbGetPreviousMeetingsByRecurringEventId = async (
  recurringEventId: string,
  currentStartTime: number,
  userEmail: string,
) => functions()
  .httpsCallable('searchPreviousMeetingsByRecurringEventId')({ recurringEventId, currentStartTime })
  .then((results) => {
    if (results.data.length === 0) {
      throw new Error('no meetings found');
    }
    const sortedMeetings = results.data.sort(sortDatabaseMeetingDataByStartDate);
    return sortedMeetings.slice(1).slice(-10);
  })
  .then(async (previousMeetings: MeetingData[]) => {
    console.log('Prev Meeting Log:', { currentStartTime, previousMeetingsFromCf: previousMeetings });
    const validatedPreviousMeetings = await Promise.all(previousMeetings.map(async (
      meeting: MeetingData,
    ) => {
      ConsoleImproved.log("Validating meeting's start time");
      const userIsAttendee = await validateUserIsAttendeeInMeeting(
        meeting.googleData.ids.eventId,
        meeting.googleData.ids.calendarId,
        userEmail,
      );

      if (userIsAttendee) return meeting;

      return null;
    }));

    const filteredPreviousMeetings: MeetingData[] = validatedPreviousMeetings.filter((
      meeting,
    ) => meeting !== null) as MeetingData[] ?? [];

    console.log('Prev Meeting Log:', { filteredPreviousMeetings });

    return filteredPreviousMeetings.reverse().slice(0, 5);
  })
  .catch((error) => {
    console.log('Error searching meeting by recurring event id', error);
    Sentry.captureException(error);
    return [];
  });

export const validateUserIsAttendeeInMeeting = async (
  eventId: string,
  calendarId: string,
  userEmail: string,
) => {
  const meetingGapiData: GapiMeetingData = await gapiGetMeeting(
    eventId,
    calendarId,
    userEmail,
  );

  if (meetingGapiData.resolvedState === 'resolved') return true;

  return false;
};

export const cfGetAttendeesFromGoogleAttendees = async (
  googleAttendees: any[],
): Promise<AttendeeV2[]> => {
  ConsoleImproved.log('cfGetAttendeesFromGoogleAttendees', googleAttendees);
  const emails = googleAttendees.map((attendee) => (attendee?.email as string) ?? '');
  const publicUsers = await cfSearchPublicUserDataV2ByEmailsV2(emails);
  const attendees = publicUsers.map((publicUser) => ({
    ...publicUser,
    responseStatus: googleAttendees.filter((googleAttendee) => googleAttendee?.email === publicUser.data.email)[0]?.responseStatus ?? 'needsAction',
  } as AttendeeV2));
  return attendees;
};

const combineMeetingDataWithAttendees = (
  meetingData: MeetingData, attendees: AttendeeV2[],
): MeetingData => ({
  ...meetingData,
  attendees: {
    attendees,
    resolvedState: 'resolved',
  },
} as MeetingData);

export const dbUpdateConferenceData = async (meetingId: string, conferenceData: ConferenceData) => {
  dbUpdateMeetingData(conferenceData, meetingId, CONFERENCE_DATA_PATH);
};

/**
 * Takes in userId and list of google eventIds to return meetingData
 * if they are present in our DB and also have some notes written in them.
 */
export const dbGetMeetingsThatHaveNotes = async (userId: string, eventIds: string[]) => functions().httpsCallable('filterMeetingHavingNotes')({
  userId,
  eventIds,
})
  .then((data) => {
    const meetingData: MeetingData[] = data.data.map(
      (meeting: any) => mapDatabaseMeetingDataToMeetingData(meeting.id, meeting, userId),
    );
    return meetingData;
  })
  .catch((error) => {
    console.error(error);
    return [] as MeetingData[];
  });
