/**
 * This component has been deprecated and no more used in actual product
 * Pls refer to src\pages\meeting\modal\ShareModal\index.tsx
 */

/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useContext, Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import Scrollbar from '../../../shared/components/scrollbar';
import ButtonSmall from '../../../shared/components/button-small';
import TextBox from '../../../shared/components/textbox';
import SendIcon from '../../../shared/icons/send';
import Switch from '../../../shared/components/switch';
import ModalLabel from '../../../shared/components/Modal/ModalLabel';
import ModalLine from '../../../shared/components/Modal/ModalLine';
import ModalBodyWrapper from '../../../shared/components/Modal/ModalBodyWrapper';
import { AuthContext, UserDataContext } from '../../../App';
import {
  NOTION_PROCESSING_VIEW, OVERVIEW_VIEW, SEND_HEADLESS_NOTES_TEMPLATE_ID, SLACK_NOTIFICATIONS_VIEW,
  SLACK_PROCESSING_VIEW, SLACK_SELECT_DEFAULT_CHANNEL_VIEW, TRELLO_PROCESSING_VIEW,
} from '../../../utils/constants';
import { assembleEmailHTML } from '../../../utils/email/emailUtils';
import {
  AGENDA, SHARED_NOTES, PERSONAL_NOTES, RESOLVED, PENDING, REJECTED,
} from '../../../utils/enums';
import MeetingDataContext from '../context/MeetingDataContext';
import {
  SlackChannel, Note, TaskItem, NotionPageData,
  TrelloData, ShareModalTab, IntegrationsTabView,
  PrivateNoteData, PrivateNoteHtml, SharePlatforms, MeetingSection, ResolvedState,
} from '../../../shared/types/types';

import { DateToTemplateDateFormat, getUTCString } from '../../../utils/dateUtils/date';
import { mapAttendeesToEmailRecipients, setNoteContent } from '../../../utils/meetings/meetingsUtils';
import { darkBlue1 } from '../../../shared/colours';
import ShareModalContainer from '../../../shared/components/Modal/ShareModalContainer';
import { dbUpdateSlackData } from '../../../database/firebaseSlackAPI';
import IntegrationsTab from './integrations/IntegrationsTab';
import EmailMultiInput from './EmailMultiInput';
import { header200 } from '../../../shared/typography';
import PreviewButton from './PreviewButton';
import ModalFooterWrapper, { ModalFooterRightButtonsWrapper } from '../../../shared/components/Modal/ModalFooterWrapper';
import ShareSlackCheckboxAndDropdown from './SlackModalComponents/ShareSlackCheckboxAndDropdown';
import ShareNotionCheckboxAndDropdown from './NotionModalComponents/ShareNotionCheckboxAndDropdown';
import { toastDanger } from '../../../utils/notifications';
import { isValidAccessToken } from '../../../utils/slack/SlackAPIUtils';
import { defaultTrelloData } from '../../../utils/trello/trelloUtils';
import { dbSaveTrelloData, removeTrelloIntegration } from '../../../database/firebaseTrelloAPI';
import ShareTrelloCheckboxAndDropdown from './TrelloModalComponents/ShareTrelloCheckboxAndDropdown';
import {
  generateLog,
  generateNotionPage,
  generateSlackMessage,
  sendEmails,
  sendToTrello,
  ShareNotesDataType,
} from '../../../utils/meetings/ShareNotesUtils';
import { isEmptyHTML, makeSubstringsBold } from '../../../utils/strings';
import defaultSlackNotifications from '../../../database/utils/templateSlackNotifications';
import { slackCoreGetChannelNames, slackCoreGetDirectMessages } from '../../../utils/slack/SlackCoreAPI';
import FireworkConfetti from '../../../shared/confetti/FireworkConfetti';
import { mapDefaultChannelToSlackData } from '../../../utils/slack/slackUtils';
import { getCandidatesFromUserData } from '../../../utils/user/UserDataUtils';
import SecretNotesSwitchMolecule from './SecretNotesSwitchMolecule';
import { determinePrivateSpaceNotesToShare } from '../../../shared/components/private-notes/utils/functions';
import { logIntegrationsEvent } from '../../../utils/analytics/eventLogger';
import { OPEN_EVENT, SHARE_MODAL_LOCATION } from '../../../utils/analytics/enums';
import { DropdownItem } from '../../../shared/components/dropdown/utils';

const MultiInputWrapper = styled.div`
  margin-bottom: 24px;
`;

const TextBoxWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ShareOptionsWrapper = styled.div`
  margin-top: 24px;
`;

export const SwitchGroup = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 16px;
  }
  margin-bottom: 0px;
`;

export const ModalLineLocal = styled.div`
  background: ${darkBlue1};
  height: 2px;
  width: 100%;
`;

const RecipientsText = styled.h2`
  ${header200};
`;

const ModalLineContainer = styled.div`
  margin-top: 24px;
`;

const RecipientsToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export type EmailRecipient = {
  index: number,
  displayValue: string,
}

export interface ShareNotesModalProps {
  isModalOpen: boolean,
  closeModal: () => void,
  handleCancel: any,
  handleEmailSent: any,
  onPreviewClick: () => void,
  setEmailHeadTemplate: Dispatch<SetStateAction<string>>,
  emailHeadTemplate: string
  agendaChecked: boolean,
  sharedNotesChecked: boolean,
  personalNotesChecked: boolean,
  taskChecked: boolean
  setAgendaChecked: Dispatch<SetStateAction<boolean>>,
  setSharedNotesChecked: Dispatch<SetStateAction<boolean>>,
  setPersonalNotesChecked: Dispatch<SetStateAction<boolean>>,
  setTaskChecked: Dispatch<SetStateAction<boolean>>,
  taskItems: TaskItem[],
  agendaHtml: Note,
  sharedNotesHtml: Note,
  personalNotesHtml: Note,
  setAgendaHtml: Dispatch<SetStateAction<Note>>
  setSharedNotesHtml: Dispatch<SetStateAction<Note>>
  setPersonalNotesHtml: Dispatch<SetStateAction<Note>>
  channelsToReceive: SlackChannel[],
  setChannelsToReceive: Dispatch<SetStateAction<SlackChannel[]>>,
  privateNotes: PrivateNoteData[],
  privateSpaceNotesChecked: string[],
  handlePrivateSpaceChecked: (noteId: string) => void,
  privateNotesHtml: PrivateNoteHtml[],
  setPrivateNotesHtml: Dispatch<SetStateAction<PrivateNoteHtml[]>>,
}

const ShareNotesModal = ({
  isModalOpen,
  closeModal,
  handleCancel,
  handleEmailSent,
  onPreviewClick,
  setEmailHeadTemplate,
  emailHeadTemplate,
  agendaChecked,
  sharedNotesChecked,
  personalNotesChecked,
  setAgendaChecked,
  taskChecked,
  setSharedNotesChecked,
  setPersonalNotesChecked,
  setTaskChecked,
  taskItems,
  agendaHtml,
  sharedNotesHtml,
  personalNotesHtml,
  setAgendaHtml,
  setSharedNotesHtml,
  setPersonalNotesHtml,
  channelsToReceive,
  setChannelsToReceive,
  privateNotes,
  privateSpaceNotesChecked,
  handlePrivateSpaceChecked,
  privateNotesHtml,
  setPrivateNotesHtml,
}: ShareNotesModalProps) => {
  const [shareModalTab, setShareModalTab] = useState<ShareModalTab>('integrations');

  const [integrationsTabView, setIntegrationsTabView] = useState<IntegrationsTabView>(
    OVERVIEW_VIEW,
  );
  const [slackChannels, setSlackChannels] = useState<DropdownItem[]>([]);
  const [slackDms, setSlackDms] = useState<DropdownItem[]>([]);
  const [newDefaultChannels, setNewDefaultChannels] = useState<DropdownItem[]>([]);
  // TODO: Use userData object to get trello data
  const [trelloData, setTrelloData] = useState<TrelloData>(defaultTrelloData);
  const [taskToSend, setTaskToSend] = useState<TaskItem[]>([]);

  // TODO: Merge all checked and html states into one state
  const [emailsChecked, setEmailsChecked] = useState<boolean>(true);
  const [slackChecked, setSlackChecked] = useState<boolean>(false);
  const [notionChecked, setNotionChecked] = useState<boolean>(false);
  const [trelloChecked, setTrelloChecked] = useState<boolean>(false);
  const [isResolved, setIsResolved] = useState<ResolvedState>(PENDING);

  const [showAgenda, setShowAgenda] = useState<boolean>(true);

  const [isIntegratingSlack, setIsIntegratingSlack] = useState<boolean>(false);
  const [activateConfetti, setActivateConffetti] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const authState = useContext(AuthContext);
  const [emailRecipients, setEmailRecipients] = useState<EmailRecipient[]>([]);
  const {
    meetingId, data: { title, attendees }, date: { start, end }, version,
  } = useContext(MeetingDataContext);
  const { userId } = authState;
  const { trackEvent } = useIntercom();

  const userData = useContext(UserDataContext);
  const assigneeCandidates = getCandidatesFromUserData(userData);
  const slackUserAccessToken = userData?.integrations?.slack[0]?.userAccessToken ?? '';
  const trelloUserData = userData?.integrations?.trello ?? defaultTrelloData;
  const notionUserData = userData?.integrations?.notion ?? [];
  const isSlackEnabled = slackUserAccessToken.length > 10;
  const isNotionEnabled = notionUserData.length > 0;
  const { isTrelloEnabled } = trelloUserData.settings;

  useEffect(() => {
    if (shareModalTab === 'integrations') logIntegrationsEvent(userId, OPEN_EVENT, '', SHARE_MODAL_LOCATION);
  }, [shareModalTab]);

  useEffect(() => {
    if (version <= 3) setShowAgenda(true);
    if (version >= 4) setShowAgenda(false);
  }, [version]);

  useEffect(() => {
    if (userData?.integrations?.trello?.accessToken.length > 10) {
      setTrelloData(userData.integrations.trello);
    }
  }, [userData]);

  useEffect(() => {
    if (!isSlackEnabled) return;
    slackCoreGetChannelNames(authState, slackUserAccessToken, setSlackChannels, setIsResolved);
    slackCoreGetDirectMessages(authState, slackUserAccessToken, setSlackDms, setIsResolved);
  }, [slackUserAccessToken]);

  useEffect(() => {
    if (!attendees.length) return;
    const newEmailList = mapAttendeesToEmailRecipients(attendees);
    setEmailRecipients(newEmailList);
  }, [attendees]);

  useEffect(() => {
    if (activateConfetti) {
      setTimeout(() => {
        setActivateConffetti(false);
      }, 4000);
    }
  }, [activateConfetti]);

  useEffect(() => {
    if (!meetingId) return;

    if (version <= 2) {
      setNoteContent(setAgendaHtml, AGENDA, meetingId, userId);
      setNoteContent(setSharedNotesHtml, SHARED_NOTES, meetingId, userId);
      setNoteContent(setPersonalNotesHtml, PERSONAL_NOTES, meetingId, userId);
    }

    if (version >= 3) {
      // Filler comment
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (emailRecipients.length) setEmailsChecked(true);
  }, [emailRecipients]);

  const handleSendClick = () => {
    setLoading(true);
    const platformsToShare:SharePlatforms[] = [];
    const meetingSectionToShare = getMeetingSectionToShare(
      agendaChecked, sharedNotesChecked,
      personalNotesChecked, taskChecked,
    );
    const promises: Promise<any>[] = [];
    const emails = emailRecipients.map(({ displayValue }) => ({ email: displayValue }));
    const emailHeadHtml = makeSubstringsBold(emailHeadTemplate, [title, getUTCString(start.date)]);
    const checkedPrivateNotesHtml = determinePrivateSpaceNotesToShare(
      privateSpaceNotesChecked,
      privateNotesHtml,
    );
    const notesAndTasksHtml = assembleEmailHTML(
      agendaChecked && showAgenda, sharedNotesChecked, personalNotesChecked, taskChecked,
      emailHeadHtml, agendaHtml, sharedNotesHtml, personalNotesHtml,
      taskItems, privateSpaceNotesChecked, checkedPrivateNotesHtml,
    );
    const shareNotesData: ShareNotesDataType = {
      authState,
      sendHeadlessNotesTemplateId: SEND_HEADLESS_NOTES_TEMPLATE_ID,
      emails,
      html: notesAndTasksHtml,
      title,
      startTime: getUTCString(start.date),
      startDate: DateToTemplateDateFormat(start.date),
      agendaChecked: agendaChecked && showAgenda,
      sharedNotesChecked,
      personalNotesChecked,
      taskChecked,
      emailHeadHtml,
      emailHeadTemplate,
      agendaHtml,
      sharedNotesHtml,
      personalNotesHtml,
      taskItems,
      checkedPrivateNotesHtml,
      privateSpaceNotesChecked,
    };

    if (slackChecked && channelsToReceive.length !== 0) {
      if (!isValidAccessToken(slackUserAccessToken)) {
        toastDanger('Error', 'Invalid slack access token');
      } else {
        platformsToShare.push('slack');
        generateSlackMessage(
          shareNotesData,
          channelsToReceive,
          slackUserAccessToken,
          promises,
        );
      }
    }

    if (notionChecked) {
      platformsToShare.push('notion');
      generateNotionPage(shareNotesData, notionUserData, promises);
    }

    if (emailsChecked && emailRecipients.length) {
      platformsToShare.push('email');
      sendEmails(userData, shareNotesData, trackEvent, promises);
    }

    if (trelloChecked && taskToSend.length) {
      platformsToShare.push('trello');
      sendToTrello(trelloChecked, taskToSend, trelloUserData, promises);
    }

    Promise.all(promises).then(() => {
      generateLog(userId, platformsToShare, meetingSectionToShare, start, end, RESOLVED);
      setChannelsToReceive([]);
      setEmailRecipients([]);
      setTaskToSend([]);
      setPrivateNotesHtml([]);
      handleEmailSent(true);
    }).catch((error: any) => {
      console.error('error in sending');
      console.error(error);
      toastDanger('Error', 'Something went wrong while sharing notes. If this persists, please contact customer support');
      handleEmailSent(false);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleSlackSaveDefaultChannel = () => {
    if (isIntegratingSlack) {
      setIntegrationsTabView(SLACK_NOTIFICATIONS_VIEW);
    } else {
      setIntegrationsTabView(OVERVIEW_VIEW);
    }
    const slackData = mapDefaultChannelToSlackData(
      newDefaultChannels,
      userData?.integrations?.slack,
    );
    dbUpdateSlackData(authState.userId, slackData);
  };

  const handleTrelloConfirmClick = () => {
    // TODO: Find more elegant way to do this
    trelloData.settings.isTrelloEnabled = true;
    dbSaveTrelloData(userId, trelloData, () => { });
    setTrelloData(defaultTrelloData);
    setActivateConffetti(true);
    setIntegrationsTabView(OVERVIEW_VIEW);
  };

  const handleAddEmails = () => {
    const newemailRecipients = mapAttendeesToEmailRecipients(attendees);
    setEmailRecipients(newemailRecipients);
  };

  const handleRemoveEmails = () => {
    setEmailRecipients([]);
    setEmailsChecked(false);
  };

  const handleCancelClick = () => {
    if (integrationsTabView === TRELLO_PROCESSING_VIEW) {
      removeTrelloIntegration(userId);
    }
    if (integrationsTabView !== OVERVIEW_VIEW) {
      setIntegrationsTabView(OVERVIEW_VIEW);
      return;
    }
    if (shareModalTab === 'integrations') {
      setShareModalTab('sendNotes');
    }
    handleCancel();
  };

  const handleCloseModal = () => {
    closeModal();
    setShareModalTab('sendNotes');
  };

  const handleClickDoneInIntegrationsTab = () => {
    if (integrationsTabView === NOTION_PROCESSING_VIEW) {
      setIntegrationsTabView(OVERVIEW_VIEW);
      return;
    }

    setIntegrationsTabView(OVERVIEW_VIEW);
    setShareModalTab('sendNotes');
  };

  const selectModalFooterForIntegrations = () => {
    if (shareModalTab !== 'integrations') return null;

    switch (integrationsTabView) {
      case OVERVIEW_VIEW:
        return <ButtonSmall onClick={handleClickDoneInIntegrationsTab} text="Done" />;
      case SLACK_PROCESSING_VIEW:
        if (!isSlackEnabled) return null;
        return <ButtonSmall text="Next" onClick={() => setIntegrationsTabView(SLACK_SELECT_DEFAULT_CHANNEL_VIEW)} />;
      case SLACK_SELECT_DEFAULT_CHANNEL_VIEW:
        return <ButtonSmall text="Confirm" onClick={handleSlackSaveDefaultChannel} />;
      case TRELLO_PROCESSING_VIEW:
        return <ButtonSmall text="Confirm" onClick={handleTrelloConfirmClick} />;
      case NOTION_PROCESSING_VIEW:
        if (!isNotionEnabled) return null;
        return <ButtonSmall text="Done" onClick={handleClickDoneInIntegrationsTab} />;
      default:
        return null;
    }
  };

  const renderCloseButtonForFooter = () => {
    if (isNotionEnabled && integrationsTabView === NOTION_PROCESSING_VIEW && shareModalTab !== 'sendNotes') return null;

    return <ButtonSmall onClick={handleCancelClick} text="Cancel" isOutline />;
  };

  const agendaDisabled = isEmptyHTML(agendaHtml);
  const sharedNotesDisabled = isEmptyHTML(sharedNotesHtml);
  const personalNotesDisabled = isEmptyHTML(personalNotesHtml);
  const taskDisabled = taskItems.length === 0;
  const isSendBtnEnabled = (
    atLeastOneNoteOrTaskEnabled(
      agendaChecked, sharedNotesChecked, personalNotesChecked, taskChecked,
    )
    && (
      slackEnabledAndHaveChannels(slackChecked, channelsToReceive)
      || emailEnabledAndHaveRecipients(emailsChecked, emailRecipients)
      || notionEnabledAndHavePages(notionChecked, notionUserData)
      || trelloEnabledAndHaveTasks(trelloChecked, taskToSend)
    )
  );

  return (
    <ShareModalContainer
      isOpen={isModalOpen}
      setModalClosed={handleCloseModal}
      modalTab={shareModalTab}
      setModalTab={setShareModalTab}
    >
      <Scrollbar maxHeight="71vh">
        <ModalBodyWrapper>
          {shareModalTab === 'sendNotes' ? (
            <>
              <MultiInputWrapper>
                <SwitchGroup>
                  <RecipientsToggleContainer>
                    <RecipientsText>Enter email recipients</RecipientsText>
                    <Switch
                      checked={emailsChecked}
                      setChecked={setEmailsChecked}
                    />
                  </RecipientsToggleContainer>
                </SwitchGroup>
                <EmailMultiInput
                  recipients={emailRecipients}
                  handleAddEmails={handleAddEmails}
                  handleRemoveEmails={handleRemoveEmails}
                  setEmailRecipients={setEmailRecipients}
                  isChecked={emailsChecked}
                  candidates={assigneeCandidates}
                />
                <ShareSlackCheckboxAndDropdown
                  isSlackEnabled={isSlackEnabled}
                  isChecked={slackChecked}
                  setIsChecked={setSlackChecked}
                  slackChannels={slackChannels}
                  // defaultChannels={userDefaultSlackChannels}
                  slackDms={slackDms}
                  setChannelsToReceive={setChannelsToReceive}
                  channelsToReceive={channelsToReceive}
                />
                <ShareNotionCheckboxAndDropdown
                  isNotionEnabled={isNotionEnabled}
                  notionWorkspace={notionUserData}
                  isChecked={notionChecked}
                  setIsChecked={setNotionChecked}
                />
                <ShareTrelloCheckboxAndDropdown
                  isTrelloEnabled={isTrelloEnabled}
                  isChecked={trelloChecked}
                  setIsChecked={setTrelloChecked}
                  taskItems={taskItems}
                  taskToSend={taskToSend}
                  setTaskToSend={setTaskToSend}
                />
              </MultiInputWrapper>
              <ModalLine />
              <ShareOptionsWrapper>
                <ModalLabel text="Select to share" />
                <SwitchGroup>
                  {showAgenda && (
                    <Switch
                      label="Agenda"
                      checked={agendaChecked}
                      setChecked={setAgendaChecked}
                      disabled={agendaDisabled}
                      disabledTooltipText="No agenda"
                    />
                  )}
                  <Switch
                    label="Shared notes"
                    checked={sharedNotesChecked}
                    setChecked={setSharedNotesChecked}
                    disabled={sharedNotesDisabled}
                    disabledTooltipText="No shared notes"
                  />
                  <Switch
                    label="My notes"
                    checked={personalNotesChecked}
                    setChecked={setPersonalNotesChecked}
                    disabled={personalNotesDisabled}
                    disabledTooltipText="No personal notes"
                  />
                  <Switch
                    label="Tasks"
                    checked={taskChecked}
                    setChecked={setTaskChecked}
                    disabled={taskDisabled}
                    disabledTooltipText="No tasks"
                  />
                </SwitchGroup>
              </ShareOptionsWrapper>
              <ModalLineContainer>
                <ModalLine />
              </ModalLineContainer>
              <TextBoxWrapper>
                <ModalLabel text="Message to recipients" />
                <TextBox
                  placeholder="Write here to add additional notes"
                  onChange={setEmailHeadTemplate}
                  defaultValue={emailHeadTemplate}
                />
              </TextBoxWrapper>
            </>
          ) : (
            <IntegrationsTab
              isSlackError={isResolved === REJECTED}
              isSlackLoading={isResolved === PENDING}
              isSlackEnabled={isSlackEnabled}
              isNotionEnabled={isNotionEnabled}
              isTrelloEnabled={isTrelloEnabled}
              view={integrationsTabView}
              setView={setIntegrationsTabView}
              slackChannels={slackChannels}
              setNewDefaultChannels={setNewDefaultChannels}
              trelloData={trelloData}
              setTrelloData={setTrelloData}
              setActiveConfetti={setActivateConffetti}
              setIsIntegratingSlack={setIsIntegratingSlack}
              isIntegratingSlack={isIntegratingSlack}
            />
          )}
        </ModalBodyWrapper>
      </Scrollbar>
      <ModalLineLocal />
      <ModalFooterWrapper>
        {shareModalTab === 'sendNotes' ? (
          <PreviewButton onClick={onPreviewClick} />
        ) : (<div />)}
        {/* TODO Matt: Double check Harry's merging went fine */}
        <ModalFooterRightButtonsWrapper>
          {renderCloseButtonForFooter()}
          {selectModalFooterForIntegrations()}
          {shareModalTab === 'sendNotes' && (
            <ButtonSmall type="submit" onClick={handleSendClick} text="Send" hasLeftIcon Icon={SendIcon} loading={loading} isDisabled={!isSendBtnEnabled} />
          )}
        </ModalFooterRightButtonsWrapper>
      </ModalFooterWrapper>
      <FireworkConfetti shouldTrigger={activateConfetti} />
    </ShareModalContainer>
  );
};

export default ShareNotesModal;

const slackEnabledAndHaveChannels = (
  enabled: boolean, channels: any[],
) => enabled && channels.length > 0;
const emailEnabledAndHaveRecipients = (
  enabled: boolean, recipients: any[],
) => enabled && recipients.length > 0;
const notionEnabledAndHavePages = (
  enabled: boolean, pages: any[],
) => enabled && pages.length > 0;
const trelloEnabledAndHaveTasks = (
  enabled: boolean, tasks: any[],
) => enabled && tasks.length > 0;

const atLeastOneNoteOrTaskEnabled = (
  agendaChecked: boolean,
  sharedNotesChecked: boolean,
  personalNotesChecked: boolean,
  taskChecked: boolean,
) => agendaChecked || sharedNotesChecked || personalNotesChecked || taskChecked;

const getMeetingSectionToShare = (agendaChecked:boolean, sharedNotesChecked:boolean,
  personalNotesChecked:boolean, taskChecked:boolean) => {
  const meetingSections: MeetingSection[] = [];
  if (agendaChecked) meetingSections.push('agenda');
  if (sharedNotesChecked) meetingSections.push('shared');
  if (personalNotesChecked) meetingSections.push('private');
  if (taskChecked) meetingSections.push('task');
  return meetingSections;
};
