import React from 'react';
import styled from 'styled-components';
import { darkBlue1, darkBlue4, red6 } from '../../../../../colours';
import { uiTextMedium } from '../../../../../typography';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding-left: 16px;
  border-left: 1.5px solid ${darkBlue1};
`;

const UndoButton = styled.div`
  color: ${darkBlue4};
  ${uiTextMedium};
  cursor: pointer;
  &:hover {
    opacity: 0.8
  }
`;

const DeleteButton = styled.div`
  ${uiTextMedium};
  color: ${red6};
  cursor: pointer;
  &:hover {
    opacity: 0.8
  }
`;

interface Props {
  onClickCancelDelete: () => void;
  onClickDelete: () => void;
}

const DeleteConfirmationButtons = ({
  onClickCancelDelete,
  onClickDelete,
}: Props) => (
  <ButtonsContainer>
    <UndoButton onClick={onClickCancelDelete}>
      Cancel
    </UndoButton>
    <DeleteButton onClick={onClickDelete}>
      Delete
    </DeleteButton>
  </ButtonsContainer>
);

export default DeleteConfirmationButtons;
