import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { gray10 } from '../../../../shared/colours';
import { TemplateData } from '../../../../shared/types/types';
import TemplateCard from './TemplateCard';
import EmptyTemplateView from './EmptyTemplateView';
import CssSpinner from '../../../../shared/components/css-spinner';

const SpinnerContainer = styled.div`
  margin: 50px 0px;
`;

const TemplateCardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 8px;
`;

interface Props {
  section: string,
  loading: boolean,
  templates: TemplateData[]
  // eslint-disable-next-line no-unused-vars
  handleInsert: (templatesHtml: string) => void
  // eslint-disable-next-line no-unused-vars
  handleEdit: (template:TemplateData)=> void
  // eslint-disable-next-line no-unused-vars
  handleDelete: (templateId: string) => void
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>
}

const TemplateCardsContent = ({
  section,
  loading,
  templates,
  handleInsert,
  handleEdit,
  handleDelete,
  setIsTemplatesOpen,
}: Props) => {
  if (loading) {
    return (
      <TemplateCardsContainer>
        <SpinnerContainer>
          <CssSpinner size={60} color={gray10} />
        </SpinnerContainer>
      </TemplateCardsContainer>
    );
  }

  if (!loading && templates.length === 0) {
    return (
      <TemplateCardsContainer>
        <EmptyTemplateView setIsTemplatesOpen={setIsTemplatesOpen} />
      </TemplateCardsContainer>
    );
  }

  if (!loading) {
    return (
      <TemplateCardsContainer>
        {templates.map((template) => (
          <TemplateCard
            section={section}
            key={template.templateId}
            template={template}
            handleEdit={handleEdit}
            handleInsert={handleInsert}
            handleDelete={handleDelete}
          />
        ))}
      </TemplateCardsContainer>
    );
  }

  return null;
};

export default TemplateCardsContent;
