import React from 'react';
import styled from 'styled-components';
import { warning2 } from '../../../shared/colours';
import DangerIcon from '../../../shared/icons/DangerIcon';

const Container = styled.div`
  padding: 8px;
  background: ${warning2};
  border-radius: 10px;
`;

const DangerIconAtom = () => (
  <Container>
    <DangerIcon />
  </Container>
);

export default DangerIconAtom;
