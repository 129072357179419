import React, {
  Dispatch,
  SetStateAction,
  useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../App';
import { dbUpdateSlackData } from '../../../../database/firebaseSlackAPI';
import { dbSaveTrelloData } from '../../../../database/firebaseTrelloAPI';
import IntegrationsTab from '../../../../pages/meeting/modal/integrations/IntegrationsTab';
import { ModalLineLocal } from '../../../../pages/meeting/modal/ShareNotesModal';
import { slackCoreGetChannelNames } from '../../../../utils/slack/SlackCoreAPI';
import {
  NOTION_PROCESSING_VIEW, OVERVIEW_VIEW, SLACK_PROCESSING_VIEW, TRELLO_PROCESSING_VIEW,
  SLACK_SELECT_DEFAULT_CHANNEL_VIEW, SLACK_NOTIFICATIONS_VIEW,
} from '../../../../utils/constants';

import { mapDefaultChannelToSlackData } from '../../../../utils/slack/slackUtils';
import { defaultTrelloData } from '../../../../utils/trello/trelloUtils';
import FireworkConfetti from '../../../confetti/FireworkConfetti';
import {
  IntegrationsTabView, ResolvedState, TrelloData,
} from '../../../types/types';
import ButtonSmall from '../../button-small';
import Modal from '../../Modal';
import ModalBodyWrapper from '../../Modal/ModalBodyWrapper';
import { toastSuccess } from '../../../../utils/notifications';
import { SETTINGS_MODAL_LOCATION } from '../../../../utils/analytics/enums';
import { DropdownItem } from '../../dropdown/utils';
import { PENDING, REJECTED } from '../../../../utils/enums';

export const ModalFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 24px;

  button:last-child {
    margin-left: 8px;
  }
`;

interface Props {
  isOpen: boolean,
  setClosed: () => void,
  setActivateConffetti: Dispatch<SetStateAction<boolean>>,
  activateConfetti: boolean,
}

const IntegrationsModal = ({
  isOpen,
  setClosed,
  setActivateConffetti,
  activateConfetti,
}: Props) => {
  const [integrationsTabView, setIntegrationsTabView] = useState<IntegrationsTabView>(
    OVERVIEW_VIEW,
  );
  const [slackChannels, setSlackChannels] = useState<DropdownItem[]>([]);
  const [newDefaultChannels, setNewDefaultChannels] = useState<DropdownItem[]>([]);
  const [newTrelloData, setNewTrelloData] = useState<TrelloData>(defaultTrelloData);
  const [isIntegratingSlack, setIsIntegratingSlack] = useState<boolean>(false);
  const [isResolved, setIsResolved] = useState<ResolvedState>(PENDING);

  const authState = useContext(AuthContext);
  const { userId } = authState;

  const userData = useContext(UserDataContext);
  const accessToken = userData?.integrations?.slack[0]?.userAccessToken ?? '';
  const isSlackEnabled = accessToken.length > 10;
  const isNotionEnabled = userData?.integrations.notion.length > 0;
  const { isTrelloEnabled } = userData?.integrations.trello.settings;

  useEffect(() => {
    if (!isSlackEnabled) return;
    slackCoreGetChannelNames(authState, accessToken, setSlackChannels, setIsResolved);
  }, [accessToken, isSlackEnabled]);

  useEffect(() => {
    if (userData?.integrations?.trello?.accessToken.length > 10) {
      setNewTrelloData(userData.integrations.trello);
    }
  }, [userData]);

  const handleConfirmClick = () => {
    if (integrationsTabView === SLACK_SELECT_DEFAULT_CHANNEL_VIEW) handleDefaultChannelSelection();

    if (integrationsTabView === SLACK_PROCESSING_VIEW) {
      setIntegrationsTabView(SLACK_SELECT_DEFAULT_CHANNEL_VIEW);
    }
  };

  const handleDefaultChannelSelection = async () => {
    if (isIntegratingSlack) {
      setIntegrationsTabView(SLACK_NOTIFICATIONS_VIEW);
    } else {
      setIntegrationsTabView(OVERVIEW_VIEW);
    }
    const slackData = mapDefaultChannelToSlackData(
      newDefaultChannels,
      userData?.integrations?.slack,
    );
    const status = await dbUpdateSlackData(authState.userId, slackData);
    if (status === 'resolved') {
      toastSuccess('Updated', 'Updated default Slack channel');
    }
  };

  const handleTrelloConfirmClick = () => {
    newTrelloData.settings.isTrelloEnabled = true;
    dbSaveTrelloData(userId, newTrelloData, () => { });
    setNewTrelloData(defaultTrelloData);
    setActivateConffetti(true);
    setIntegrationsTabView(OVERVIEW_VIEW);
  };

  const handleCloseClick = () => {
    setIntegrationsTabView(OVERVIEW_VIEW);
    setClosed();
  };

  const handleDoneClick = () => {
    setIntegrationsTabView(OVERVIEW_VIEW);
  };

  const selectModalFooter = () => {
    switch (integrationsTabView) {
      case SLACK_PROCESSING_VIEW:
        if (!isSlackEnabled) return null;
        return <ButtonSmall text="Next" onClick={handleConfirmClick} />;
      case SLACK_SELECT_DEFAULT_CHANNEL_VIEW:
        return <ButtonSmall text="Confirm" onClick={handleConfirmClick} />;
      case TRELLO_PROCESSING_VIEW:
        return <ButtonSmall text="Confirm" onClick={handleTrelloConfirmClick} />;
      case NOTION_PROCESSING_VIEW:
        if (!isNotionEnabled) return null;
        return <ButtonSmall text="Done" onClick={handleDoneClick} />;
      default:
        return null;
    }
  };

  const renderCloseButtonForFooter = () => {
    if (isNotionEnabled && integrationsTabView === NOTION_PROCESSING_VIEW) return null;

    return <ButtonSmall text="Close" onClick={handleCloseClick} isOutline />;
  };

  return (
    <Modal title="Apps" isOpen={isOpen} setModalClosed={handleCloseClick}>
      <ModalBodyWrapper>
        <IntegrationsTab
          isSlackError={isResolved === REJECTED}
          isSlackLoading={isResolved === PENDING}
          isSlackEnabled={isSlackEnabled}
          isNotionEnabled={isNotionEnabled}
          isTrelloEnabled={isTrelloEnabled}
          view={integrationsTabView}
          setView={setIntegrationsTabView}
          slackChannels={slackChannels}
          setNewDefaultChannels={setNewDefaultChannels}
          trelloData={newTrelloData}
          setTrelloData={setNewTrelloData}
          setActiveConfetti={setActivateConffetti}
          setIsIntegratingSlack={setIsIntegratingSlack}
          isIntegratingSlack={isIntegratingSlack}
          analyticsFrom={SETTINGS_MODAL_LOCATION}
        />
      </ModalBodyWrapper>
      <ModalLineLocal />
      <ModalFooterWrapper>
        {renderCloseButtonForFooter()}
        {selectModalFooter()}
      </ModalFooterWrapper>
      <FireworkConfetti shouldTrigger={activateConfetti} />
    </Modal>
  );
};

export default IntegrationsModal;
