import React from 'react';
import { gray1 } from '../colours';

interface Props {
  fill?: string,
}
const LeftArrowOutlineIcon = ({ fill }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M22 7.666L22 16.335C22 19.7386 19.5707 22 16.084 22L7.916 22C4.43296 22 2 19.7319 2 16.335L2 7.666C2 4.26222 4.4295 2 7.916 2L16.084 2C19.5705 2 22 4.26222 22 7.666ZM20.5 16.335L20.5 7.666C20.5 5.1155 18.765 3.5 16.084 3.5L7.916 3.5C5.23496 3.5 3.5 5.1155 3.5 7.666L3.5 16.335C3.5 18.879 5.23878 20.5 7.916 20.5L16.084 20.5C18.7653 20.5 20.5 18.8852 20.5 16.335ZM16.836 12C16.836 12.3797 16.5538 12.6935 16.1878 12.7432L16.086 12.75H9.73081L12.2069 15.2165C12.4737 15.4822 12.4988 15.8989 12.2816 16.1929L12.2092 16.2772C11.9435 16.544 11.5268 16.5691 11.2328 16.3519L11.1485 16.2795L7.3845 12.5315C7.11686 12.265 7.09253 11.8468 7.31151 11.5528L7.31421 11.5497C7.33516 11.5218 7.358 11.4954 7.38255 11.4708L7.3845 11.4685L11.1485 7.72054C11.442 7.42827 11.9169 7.42928 12.2092 7.7228C12.4749 7.98963 12.4982 8.40635 12.2797 8.69949L12.2069 8.78346L9.72942 11.25H16.086C16.5002 11.25 16.836 11.5858 16.836 12Z" fill={fill} />
  </svg>
);

LeftArrowOutlineIcon.defaultProps = {
  fill: gray1,
};

export default LeftArrowOutlineIcon;
