import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/browser';
import { AuthContext } from '../../App';
import {
  dbUpdateSlackData,
} from '../../database/firebaseSlackAPI';
import { ResolveState } from '../../shared/types/types';
import { header400 } from '../../shared/typography';
import { mapUserIdAccessTokenToSlackData } from '../../utils/slack/slackUtils';
import { logIntegrationsEvent, logPageEvent } from '../../utils/analytics/eventLogger';
import {
  ENABLE_EVENT, REDIRECT_LOCATION, SLACK_INTEGRATION, SLACK_REDIRECT_PAGE,
} from '../../utils/analytics/enums';
import { SLACK } from '../../utils/enums';

const TextContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  ${header400}

  margin-top: 50px;
`;

const SlackRedirectPage = () => {
  const [successfullyIntegratedSlack, setSuccessfullyIntegratedSlack] = useState<ResolveState>('pending');
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');
  const authState = useContext(AuthContext);
  const { trackEvent } = useIntercom();

  const isValidCode = code && code.length > 10;

  useEffect(() => {
    if (authState.userState !== 'loggedIn') return;
    logPageEvent(authState.userId, trackEvent, SLACK_REDIRECT_PAGE);
  }, [authState.userId]);

  useEffect(() => {
    if (!isValidCode) return;
    // TODO: Modulize
    axios.post('https://slack.com/api/oauth.v2.access', null, {
      params: {
        client_id: '1490440008293.2369381747799',
        client_secret: '3d40f0cf466acc2a9fb961b53104cab2',
        code,
      },
    })
      .then(async (result: any) => {
        const userAccessToken = (result?.data?.authed_user?.access_token) as string ?? '';
        const botAccessToken = (result?.data?.access_token) as string ?? '';
        const slackUserId = (result?.data?.authed_user?.id) as string ?? '';
        const isValidAccessToken = userAccessToken.length > 10;
        if (!isValidAccessToken) {
          setSuccessfullyIntegratedSlack('rejected');
          Sentry.captureException({ integration: SLACK, error: 'invalid access token code detected' });
          return;
        }

        const newSlackData = mapUserIdAccessTokenToSlackData(
          slackUserId,
          userAccessToken,
          botAccessToken,
        );
        const status = await dbUpdateSlackData(authState.userId, [newSlackData]);
        setSuccessfullyIntegratedSlack(status);
        if (status === 'resolved') logIntegrationsEvent(authState.userId, ENABLE_EVENT, SLACK_INTEGRATION, REDIRECT_LOCATION);
      })
      .catch((error) => {
        setSuccessfullyIntegratedSlack('rejected');
        Sentry.captureException({ integration: SLACK, error });
        console.log(error);
      });
  }, [code]);

  if (!isValidCode) {
    return (
      <TextContainer>
        The code provided is not valid
      </TextContainer>
    );
  }

  if (successfullyIntegratedSlack === 'pending') {
    return (
      <TextContainer>
        Loading...
      </TextContainer>
    );
  }

  if (successfullyIntegratedSlack === 'resolved') {
    return (
      <TextContainer>
        Slack has been successfully integrated! You can close this page :)
      </TextContainer>
    );
  }

  return (
    <TextContainer>
      Something went wrong
    </TextContainer>
  );
};

export default SlackRedirectPage;
