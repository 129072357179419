import { cfSendTaskAssignNotificationEmail, cfSendTaskUpdateNotificationEmail } from '../../../database/cloudFunctionEmailAPI';
import TaskAPI from '../../../external/TaskAPI/TaskAPI';
import {
  EDIT_EVENT, TASKS_ASSIGNEE_FIELD, TASKS_CARD_LOCATION,
  TASKS_DUE_DATE_FIELD, TASKS_STATUS_FIELD, TASKS_TITLE_FIELD,
} from '../../../utils/analytics/enums';
import {
  logTasksUserAction,
} from '../../../utils/analytics/eventLogger';
import {
  TASK_UPDATE_FIELD_DUE_DATE, TASK_UPDATE_FIELD_STATUS, TASK_UPDATE_FIELD_TITLE,
} from '../../../utils/enums';
import { toastDanger, toastInfo } from '../../../utils/notifications';
import { slackAPINotificationForTaskUpdate } from '../../../utils/slack/SlackAPI';
import {
  getStatusName, getTaskWithNewAssignee, getTaskWithNewDueDate,
  getTaskWithNewStatus, getTaskWithNewTitle,
} from '../../../utils/tasks/tasksUtils';
import { updateTrelloCard } from '../../../utils/trello/trelloUtils';
import { mapPublicUserDataV2ToAssignee } from '../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import {
  User, TaskItem, TaskStatus, PublicUserDataV2, AuthState,
} from '../../types/types';

export const getDbUpdateDueDateHandler = (
  userData: User, oldTask: TaskItem, trackEvent: any, authState: AuthState,
) => async (newDueDate: Date) => {
  const result = await TaskAPI.updateDueDate(oldTask.taskId, newDueDate);
  if (result !== 'resolved') return;

  updateTrelloCard(userData.integrations.trello, oldTask, 'single', 'due', newDueDate.toISOString());
  toastInfo('Updated', 'The due date was updated');
  slackAPINotificationForTaskUpdate(
    oldTask,
    authState,
    'due date',
    newDueDate.toDateString(),
  );
  logTasksUserAction(
    authState.userId, EDIT_EVENT,
    TASKS_CARD_LOCATION, TASKS_DUE_DATE_FIELD, trackEvent,
  );
  cfSendTaskUpdateNotificationEmail(
    getTaskWithNewDueDate(oldTask, newDueDate),
    userData,
    [TASK_UPDATE_FIELD_DUE_DATE],
  ).catch((error) => toastDanger('Failed to send notification', error));
};

export const getDbUpdateAssigneeHandler = (
  userData: User, oldTask: TaskItem, trackEvent: any, authState: AuthState,
) => async (assignee: PublicUserDataV2) => {
  const result = await TaskAPI.updateAssignee(oldTask.taskId, assignee);
  if (result !== 'resolved') return;

  const newAssignee = mapPublicUserDataV2ToAssignee(assignee);
  slackAPINotificationForTaskUpdate(
    oldTask,
    authState,
    'assignee',
  );
  if (newAssignee.userId.length !== 0) {
    toastInfo('Updated', `The assignee was updated to '${newAssignee.name} (${newAssignee.email})'`);
  } else {
    toastInfo('Updated', 'The assignee has been removed');
  }
  updateTrelloCard(userData.integrations.trello, oldTask, 'single', 'idMembers', newAssignee.name);
  logTasksUserAction(
    authState.userId, EDIT_EVENT,
    TASKS_CARD_LOCATION, TASKS_ASSIGNEE_FIELD, trackEvent,
  );
  cfSendTaskAssignNotificationEmail(getTaskWithNewAssignee(oldTask, newAssignee),
    userData.data.name,
    userData.data.email)
    .catch((error) => toastDanger('Failed to send notification', error));
};

export const getDbUpdateTitleHandler = (
  userData: User, oldTask: TaskItem, trackEvent: any, authState: AuthState,
) => async (newTitle: string) => {
  if (newTitle === oldTask.data.title) return;

  const result = await TaskAPI.updateTitle(oldTask.taskId, newTitle);
  if (result !== 'resolved') return;

  slackAPINotificationForTaskUpdate(
    oldTask,
    authState,
    'title',
    '',
    newTitle,
  );

  updateTrelloCard(userData.integrations.trello, oldTask, 'single', 'name', newTitle);
  toastInfo('Updated', `The title was updated to '${newTitle}'`);
  logTasksUserAction(
    authState.userId, EDIT_EVENT,
    TASKS_CARD_LOCATION, TASKS_TITLE_FIELD, trackEvent,
  );
  cfSendTaskUpdateNotificationEmail(
    getTaskWithNewTitle(oldTask, newTitle),
    userData,
    [TASK_UPDATE_FIELD_TITLE],
  ).catch((error) => toastDanger('Failed to send notification', error));
};

export const getDbUpdateStatusHandler = (
  userData: User, oldTask: TaskItem, trackEvent: any, authState: AuthState,
) => async (newStatus: TaskStatus) => {
  const result = await TaskAPI.updateStatus(oldTask.taskId, newStatus);
  if (result !== 'resolved') return;

  slackAPINotificationForTaskUpdate(
    oldTask,
    authState,
    'status',
  );
  updateTrelloCard(userData.integrations.trello, oldTask, 'single', 'idList', newStatus);
  toastInfo('Updated', `The status was updated to '${getStatusName(newStatus)}'`);
  logTasksUserAction(
    authState.userId, EDIT_EVENT,
    TASKS_CARD_LOCATION, TASKS_STATUS_FIELD, trackEvent,
  );
  cfSendTaskUpdateNotificationEmail(
    getTaskWithNewStatus(oldTask, newStatus),
    userData,
    [TASK_UPDATE_FIELD_STATUS],
  ).catch((error) => toastDanger('Failed to send notification', error));
};
