import firebase from 'firebase';
import * as Sentry from '@sentry/browser';
import { Dispatch, SetStateAction } from 'react';
import { MeetingAnalyticsData } from '../shared/types/types';
import INIT_MEETING_ANALYTICS_DATA from '../utils/analytics/analyticsDataObjects';
import { firestore } from '../utils/firebase';

const meetingCollectionRef = firestore()
  .collection('analytics')
  .doc('meetingsAnalytics')
  .collection('meetingsAnalytics');

export const dbListenForMeetingAnalyticsData = (
  meetingId: string, stateSetter: Dispatch<SetStateAction<MeetingAnalyticsData>>,
) => meetingCollectionRef
  .doc(meetingId)
  .onSnapshot((doc) => {
    if (!doc.exists) {
      console.log('Initialize new meeting analytics doc');
      // TODO: Add eventId info to analyticsData so we can easier track which meetings have
      // notes in them or not when we show them in the all notes dashboard
      meetingCollectionRef.doc(meetingId).set(INIT_MEETING_ANALYTICS_DATA);
    } else {
      stateSetter(mapDatabaseMeetingAnalyticsDataToMeetingAnalyticsData(doc.data()));
      console.log('Got new Analytics data');
      console.log(doc.data());
    }
  }, (error) => {
    console.error('Something went wrong while listening to analytics data');
    console.error(error);
  });

const mapDatabaseMeetingAnalyticsDataToMeetingAnalyticsData = (
  databaseData: any,
): MeetingAnalyticsData => {
  const data: MeetingAnalyticsData = {
    hasUsedShepherd: databaseData?.hasUsedShepherd ?? false,
    hasAgenda: databaseData?.hasAgenda ?? false,
    hasSharedNotes: databaseData?.hasSharedNotes ?? false,
    hasPrivateNotes: databaseData?.hasPrivateNotes ?? false,
    users: databaseData?.users ?? [],
  };
  return data;
};

export const dbUpdateMeetingAnalyticsData = (
  meetingId: string,
  onSuccess: any,
  fieldTestFunction: AnalyticsDataFieldTestFunc,
  fieldUpdateFunc: AnalyticsDataFieldUpdateFunc,
) => {
  const docRef = meetingCollectionRef.doc(meetingId);
  return firestore()
    .runTransaction(
      (transaction: firebase.firestore.Transaction) => transaction.get(
        docRef,
      ).then((doc) => {
        if (fieldTestFunction(doc)) {
          // test if field exists/is true, if not exists/false means no log,
          // therefore okay to log
          transaction.update(
            docRef,
            fieldUpdateFunc(doc),
          );
          return '';
        }
        throw new Error('transaction failed, event already logged');
      }),
    ).then(() => {
      console.log('Transaction successfully committed!');
      console.log(fieldTestFunction);
      onSuccess();
    }).catch((error) => {
      console.error('Transaction failed: ', error);
      Sentry.captureException(error);
      console.error(fieldTestFunction);
    });
};

export interface AnalyticsDataFieldTestFunc {
  // (docRef: DocumentSnapshot<DocumentData>): boolean
  // eslint-disable-next-line no-unused-vars
  (docRef: any): boolean
}

export interface AnalyticsDataFieldUpdateFunc {
  // eslint-disable-next-line no-unused-vars
  (docRef: any): any
}
