import React, { ReactNode } from 'react';
import Tooltip from 'react-tooltip';
import styled from 'styled-components';
import { uniqueId } from 'lodash';
import { darkBlue4, gray1, gray10 } from '../../colours';
import { defaultSmall } from '../../typography';
import { REACT_TOOLTIP_CLASS_NAME, REACT_TOOLTIP_ID_PREFIX } from '../../../utils/constants';
import { isMobileOrTablet } from '../../../utils/analytics/functions';

interface Offset {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

const StyleWrapper = styled.div<WrapperProps>`
  .${REACT_TOOLTIP_CLASS_NAME} {
    ${defaultSmall}
    color: ${({ textEditor }) => (textEditor ? gray10 : gray1)};
    background-color: ${({ textEditor }) => (textEditor ? gray1 : darkBlue4)};
    padding: 2px 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    opacity: 1 !important;
    z-index: 100;
    max-width: 400px;
    // white-space: nowrap;
  }
`;

interface WrapperProps {
  textEditor: boolean,
}

interface Props {
  tip: string | undefined | ReactNode,
  place?: 'top' | 'bottom' | 'left' | 'right',
  children: ReactNode,
  offset?: Offset,
  disabled?: boolean,
  enableOnMobile?: boolean,
  delay?: boolean,
  multiline?: boolean,
  textEditor?: boolean,
  html?: boolean
}

const ReactTooltip = ({
  tip, children, place, offset, disabled, enableOnMobile = false, delay = false,
  multiline = false, textEditor = false, html = false,
}: Props) => {
  const id = `${REACT_TOOLTIP_ID_PREFIX}${uniqueId()}`;
  const delayShowMilliseconds = delay ? 1000 : 200;
  const isDisabled = disabled || tip?.toString()?.length === 0;
  const isMobile = isMobileOrTablet(navigator.userAgent) && !enableOnMobile;

  return (
    <>
      <StyleWrapper textEditor={textEditor}>
        <Tooltip
          id={id}
          place={place}
          effect="solid"
          offset={offset}
          arrowColor="transparent"
          className={REACT_TOOLTIP_CLASS_NAME}
          disable={isDisabled || isMobile}
          delayShow={delayShowMilliseconds}
          multiline={multiline}
          html={html}
        >
          {tip}
        </Tooltip>
        <div data-for={id} data-tip style={{ display: 'flex', alignItems: 'center' }}>
          {children}
        </div>
      </StyleWrapper>
    </>
  );
};

ReactTooltip.defaultProps = {
  place: 'top',
  disabled: false,
  enableOnMobile: false,
  offset: {
    top: 0,
    left: 0,
  },
  delay: false,
  multiline: false,
  textEditor: false,
  html: false,
};

export default ReactTooltip;
