import React, {
  createContext, ReactNode, useCallback, useContext, useMemo, useReducer,
} from 'react';
import { UserCenterPage } from '../../../shared/types/types';

export interface UIState {
  isInviteMemberModalOpen: boolean;
  isUpdatesHubModalOpen: boolean;
  isInviteMemberModalBackButtonEnable: boolean,
  page: UserCenterPage,
  // eslint-disable-next-line no-unused-vars
  setPage: (page: UserCenterPage) => void
  handleBackButtonClickInviteMemberModal: ()=> void
}

export interface UIStateController {
  openInviteMemberModal: () => void;
  closeInviteMemberModal: () => void;
  openUpdatesHubModal: ()=> void;
  closeUpdatesHubModal: ()=> void;
  // eslint-disable-next-line no-unused-vars
  enableBackButtonInviteMemberModal:(isEnable: boolean)=> void,
  // eslint-disable-next-line no-unused-vars
  onBackButtonClickInviteMemberModal: (data: any)=> void
  // eslint-disable-next-line no-unused-vars
  setPage: (page: UserCenterPage) => void

}

const initialState: UIState = {
  isInviteMemberModalOpen: false,
  isInviteMemberModalBackButtonEnable: false,
  page: 'currentMeeting',
  // eslint-disable-next-line no-unused-vars
  setPage: (page: UserCenterPage) => {},
  handleBackButtonClickInviteMemberModal: () => {},
  isUpdatesHubModalOpen: false,
};

export const UIContext = createContext<UIStateController & UIState | UIState>(initialState);

UIContext.displayName = 'UIContext';

type UIAction =
  | {
    type: 'OPEN_INVITE_MEMBER_MODAL';
  } | {
    type: 'CLOSE_INVITE_MEMBER_MODAL';
  }| {
    type: 'ENABLE_BACK_BUTTON_INVITE_MEMBER_MODAL';
    data: boolean
  }|{
    type: 'ON_BACK_BUTTON_CLICK_INVITE_MEMBER_MODAL';
    data: ()=> {}
  }|{
    type: 'OPEN_UPDATES_HUB_MODAL';
  } | {
    type: 'CLOSE_UPDATES_HUB_MODAL';
  }|{
    type: 'SET_PAGE';
    data: UserCenterPage
  };

const uiReducer = (state: UIState, action: UIAction): UIState => {
  if (action.type === 'OPEN_INVITE_MEMBER_MODAL') {
    return {
      ...state,
      isInviteMemberModalOpen: true,
    };
  }
  if (action.type === 'CLOSE_INVITE_MEMBER_MODAL') {
    return {
      ...state,
      isInviteMemberModalOpen: false,
    };
  }
  if (action.type === 'ENABLE_BACK_BUTTON_INVITE_MEMBER_MODAL') {
    return {
      ...state,
      isInviteMemberModalBackButtonEnable: action.data,
    };
  }
  if (action.type === 'ON_BACK_BUTTON_CLICK_INVITE_MEMBER_MODAL') {
    return {
      ...state,
      handleBackButtonClickInviteMemberModal: action.data,
    };
  }
  if (action.type === 'OPEN_UPDATES_HUB_MODAL') {
    return {
      ...state,
      isUpdatesHubModalOpen: true,
    };
  }
  if (action.type === 'CLOSE_UPDATES_HUB_MODAL') {
    return {
      ...state,
      isUpdatesHubModalOpen: false,
    };
  }

  if (action.type === 'SET_PAGE') {
    return {
      ...state,
      page: action.data,
    };
  }

  return state;
};

interface Props {
  children: ReactNode;
}

export const UIProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(uiReducer, initialState);

  const openInviteMemberModal = useCallback(
    () => dispatch({ type: 'OPEN_INVITE_MEMBER_MODAL' }),
    [dispatch],
  );
  const closeInviteMemberModal = useCallback(
    () => dispatch({ type: 'CLOSE_INVITE_MEMBER_MODAL' }),
    [dispatch],
  );

  const enableBackButtonInviteMemberModal = useCallback(
    (isEnable: boolean) => dispatch({ type: 'ENABLE_BACK_BUTTON_INVITE_MEMBER_MODAL', data: isEnable }),
    [dispatch],
  );

  const onBackButtonClickInviteMemberModal = useCallback(
    (data: any) => dispatch({ type: 'ON_BACK_BUTTON_CLICK_INVITE_MEMBER_MODAL', data }),
    [dispatch],
  );

  const openUpdatesHubModal = useCallback(
    () => dispatch({ type: 'OPEN_UPDATES_HUB_MODAL' }),
    [dispatch],
  );

  const closeUpdatesHubModal = useCallback(
    () => dispatch({ type: 'CLOSE_UPDATES_HUB_MODAL' }),
    [dispatch],
  );

  const setPage = useCallback(
    (page: UserCenterPage) => dispatch({ type: 'SET_PAGE', data: page }),
    [dispatch],
  );

  const value: UIStateController & UIState = useMemo(
    () => ({
      ...state,
      openInviteMemberModal,
      closeInviteMemberModal,
      enableBackButtonInviteMemberModal,
      onBackButtonClickInviteMemberModal,
      openUpdatesHubModal,
      closeUpdatesHubModal,
      setPage,
    }),
    [state],
  );

  return (
    <UIContext.Provider value={value}>
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => {
  const context = useContext(UIContext) as UIStateController & UIState;
  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider');
  }
  return context;
};
