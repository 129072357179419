import React from 'react';
import styled from 'styled-components';
import { header200 } from '../../typography';
import { gray9 } from '../../colours';

const Label = styled.div`
  ${header200};
  color: ${gray9};
  margin-bottom: 8px;
`;

interface Props {
  text?: string
}

const ModalLabel = ({ text }: Props) => (
  <Label>{text}</Label>
);

ModalLabel.defaultProps = {
  text: '',
};

export default ModalLabel;
