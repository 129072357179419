import React from 'react';
import styled from 'styled-components';
import { header600 } from '../../../../shared/typography';
import { surface } from '../../../../shared/colours';

const Title = styled.div`
  ${header600};
  color: ${surface};
`;

interface Props {
  title: string,
}

const TitleAtom = ({ title }: Props) => (
  <Title>
    {title}
  </Title>
);

export default TitleAtom;
