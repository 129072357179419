import React from 'react';
import styled, { css } from 'styled-components';
import { COLORS } from '../../../shared/colours';

interface ContainerProps {
  isLoading: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  margin-right: 14px;

  background-color: ${COLORS.blue1};
  border-radius: 8px;
  height: 70px;

  ${({ isLoading }) => !isLoading && css`
    cursor: pointer;

    &:hover {
      background-color: ${COLORS.blue2};
    }
  `}

`;

const Text = styled.span`
  color: ${COLORS.blue6};
`;

interface Props {
  isLoading: boolean;
  onClick: () => void;
}

const AllNotesBottomButtonToFetchMoreMeetings = ({ isLoading, onClick }: Props) => (
  <Container isLoading={isLoading} onClick={onClick}>
    {isLoading ? (
      <Text>Loading...</Text>
    ) : (
      <Text>
        Fetch more meetings
      </Text>
    )}
  </Container>
);

export default AllNotesBottomButtonToFetchMoreMeetings;
