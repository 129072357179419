import React, {
  Dispatch, SetStateAction, useContext, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../App';
import { surface } from '../../../shared/colours';
import { UserCenterPage } from '../../../shared/types/types';
import { OPEN_EVENT } from '../../../utils/analytics/enums';
import { logMainTabMenuEvent } from '../../../utils/analytics/eventLogger';
import { USER_CENTER_ALL_NOTES, USER_CENTER_ALL_TASKS } from '../../../utils/enums';
import PageTabButtonAtom from './PageTabButtonAtom';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${surface};
`;

const ButtonsContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
`;

interface Props {
  section: UserCenterPage,
  setSection: Dispatch<SetStateAction<UserCenterPage>>
}

const Navbar = ({ section, setSection }: Props) => {
  const authState = useContext(AuthContext);
  const tabButtonsContainerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line no-unused-vars
  const [isHover, setIsHover] = useState<boolean>(false);
  const handleAllTasksClicked = () => {
    setSection(USER_CENTER_ALL_TASKS);
    logMainTabMenuEvent(authState.userId, OPEN_EVENT, USER_CENTER_ALL_TASKS);
  };

  const handleCurrentMeetingClicked = () => {
    setSection('currentMeeting');
    logMainTabMenuEvent(authState.userId, OPEN_EVENT, 'currentMeeting');
  };

  // eslint-disable-next-line no-unused-vars
  const handleAllNotesClicked = () => {
    setSection(USER_CENTER_ALL_NOTES);
    logMainTabMenuEvent(authState.userId, OPEN_EVENT, USER_CENTER_ALL_NOTES);
  };

  return (
    <Container>
      <ButtonsContainer ref={tabButtonsContainerRef}>
        <PageTabButtonAtom
          id="all-notes"
          text="All notes"
          onClick={handleAllNotesClicked}
          isSelected={section === USER_CENTER_ALL_NOTES}
          tooltipText="Dashboard of all your notes"
          setIsHover={setIsHover}
        />
        <PageTabButtonAtom
          text="All tasks"
          onClick={handleAllTasksClicked}
          isSelected={section === USER_CENTER_ALL_TASKS}
          tooltipText="Dashboard of all your tasks"
          setIsHover={setIsHover}
        />
        <PageTabButtonAtom
          id="current-meeting"
          text="Current meeting"
          onClick={handleCurrentMeetingClicked}
          isSelected={section === 'currentMeeting'}
          tooltipText="Current meeting notes and tasks"
          setIsHover={setIsHover}
        />
      </ButtonsContainer>
    </Container>
  );
};

export default Navbar;
