import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface StrikethroughProps {
  fill?: string;
  width?: number;
  height?: number;
}

const StrikethroughIcon = ({
  fill,
  width,
  height,
}: StrikethroughProps) => (
  <svg width={width} height={height} viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 5.9472656 0 C 5.0172656 0 4.2225 0.27421875 3.5625 0.82421875 C 2.9025 1.3642188 2.5722656 2.0992969 2.5722656 3.0292969 C 2.5722656 3.4992969 2.6561719 3.9097656 2.8261719 4.2597656 C 2.9019019 4.4156856 2.9960087 4.5612225 3.1054688 4.6953125 L 6.640625 4.6953125 C 6.568895 4.6715325 6.4927894 4.6453606 6.4121094 4.6191406 C 5.6121094 4.3691406 5.0580469 4.1340625 4.7480469 3.9140625 C 4.4480469 3.6840625 4.296875 3.374375 4.296875 2.984375 C 4.296875 2.584375 4.4480469 2.2690625 4.7480469 2.0390625 C 5.0480469 1.7990625 5.4472656 1.6796875 5.9472656 1.6796875 C 6.6586356 1.6796875 7.2282031 1.9133994 7.6582031 2.3808594 C 7.9904031 2.7420694 8.5222656 2.9367863 8.9472656 2.6914062 C 9.3206656 2.4758563 9.4728094 2.0028906 9.2246094 1.6503906 C 8.9323094 1.2352606 8.5754438 0.89056438 8.1523438 0.61523438 C 7.5223437 0.20523438 6.7872656 0 5.9472656 0 z M 0.75 5.4453125 C 0.33579 5.4453125 0 5.7810925 0 6.1953125 C 0 6.6094825 0.33579 6.9453125 0.75 6.9453125 L 7.7832031 6.9453125 C 8.0491031 7.1606125 8.1816406 7.4553781 8.1816406 7.8300781 C 8.1816406 8.2400781 8.0175 8.5759375 7.6875 8.8359375 C 7.3675 9.0859375 6.8824219 9.2109375 6.2324219 9.2109375 C 5.3029819 9.2109375 4.5965112 8.9170313 4.1132812 8.3320312 C 3.7748712 7.9223312 3.1967912 7.6883313 2.7382812 7.9570312 C 2.3887012 8.1618312 2.2244181 8.5926125 2.4238281 8.9453125 C 2.7099581 9.4515125 3.1044219 9.8687187 3.6074219 10.199219 C 4.3174219 10.659219 5.1775 10.890625 6.1875 10.890625 C 7.29751 10.890625 8.1930469 10.614453 8.8730469 10.064453 C 9.5630469 9.5044531 9.90625 8.7507812 9.90625 7.8007812 C 9.90625 7.4857813 9.8732875 7.2003125 9.8046875 6.9453125 L 11.25 6.9453125 C 11.6642 6.9453125 12 6.6094825 12 6.1953125 C 12 5.7810925 11.6642 5.4453125 11.25 5.4453125 L 0.75 5.4453125 z"
      fill={fill}
    />
  </svg>
);

StrikethroughIcon.defaultProps = {
  fill: darkBlue4,
  width: 12,
  height: 11,
};

export default StrikethroughIcon;
