import React from 'react';
import styled from 'styled-components';
import { gray8 } from '../../colours';
import { uiTextMedium } from '../../typography';

const Label = styled.span`
  ${uiTextMedium};
  color: ${gray8};
`;

interface Props {
  text: string
}

const LabelAtom = ({ text }: Props) => (
  <Label>
    {text}
  </Label>
);

export default LabelAtom;
