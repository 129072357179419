import React, { useContext } from 'react';
import MeetingDataContext from '../../context/MeetingDataContext';
import { getDayAndMonth, getHourAndMinutes, getWeekday } from '../../../../utils/dateUtils/date';
import SpotSeparator from '../../../../shared/icons/SpotSeparator';
import MeetingRecurrence from '../meeting-recurrence';
import MeetingDateAndTimeContainer from './utils/styles';

interface Props {
  isRecurringMeeting: boolean,
}

const MeetingDateAndTime = ({ isRecurringMeeting }: Props) => {
  // Example output: Friday  4 June  09:30 - 10:30
  const meetingData = useContext(MeetingDataContext);
  if (meetingData.date.start.date.length === 0) return null;

  const weekday = getWeekday(meetingData.date.start.date);
  const dayAndMonth = getDayAndMonth(meetingData.date.start.date);
  const startHourAndMinutes = getHourAndMinutes(meetingData.date.start.date);
  const endHourAndMinutes = getHourAndMinutes(meetingData.date.end.date);

  return (
    <MeetingDateAndTimeContainer>
      <MeetingRecurrence isRecurringMeeting={isRecurringMeeting} />
      {weekday}
      <SpotSeparator />
      {dayAndMonth}
      <SpotSeparator />
      {`${startHourAndMinutes} - ${endHourAndMinutes}`}
    </MeetingDateAndTimeContainer>
  );
};

export default React.memo(MeetingDateAndTime);
