/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { AuthContext, UserDataContext } from '../../../App';
import RealisticConfetti from '../../../shared/confetti/RealisticConfetti';
import SendIcon from '../../../shared/icons/send';
import {
  Note, PrivateNoteData, PrivateNoteHtml, SlackChannel, TaskItem,
} from '../../../shared/types/types';
import { CLOSE_EVENT, OPEN_EVENT } from '../../../utils/analytics/enums';
import {
  logEventButtonClickSendEmail, logEventButtonClickSendEmailCancel,
  logPreviewButtonClicked, logShareNotesEvents,
} from '../../../utils/analytics/eventLogger';
import { getUTCString } from '../../../utils/dateUtils/date';
import { defaultEmailHeadTemplate, populateEmailHeadTemplate } from '../../../utils/email/emailUtils';
import { AGENDA, PERSONAL_NOTES, SHARED_NOTES } from '../../../utils/enums';
import { mapTimestampForLog, setNoteContent } from '../../../utils/meetings/meetingsUtils';
import { toastSuccess } from '../../../utils/notifications';
import SquareButtonMolecule from '../../all-pages/bottom-bar/SquareButtonMolecule';
import MeetingDataContext from '../context/MeetingDataContext';
import FetchProseMirrorTextEditors from '../modal/FetchProseMirrorTextEditors';
// import ShareNotesModal from '../modal/ShareNotesModal';
import ShareNotesModal from '../modal/ShareModal/index';
import { NotesData } from '../modal/ShareModal/utils/types';

type Props = {
  privateNotes: PrivateNoteData[],
  taskItems: TaskItem[];
  closeDropdown: () => void;
}

const ShareMeetingNotes = ({ taskItems, privateNotes, closeDropdown }: Props) => {
  const [isShareNotesModalOpen, setShareNotesModalOpen] = useState(false);
  const [isPreviewEmailModalOpen, setPreviewEmailModalOpen] = useState(false);
  const [triggerConfetti, setTriggerConfetti] = useState(false);
  const [agendaChecked, setAgendaChecked] = useState<boolean>(false);
  const [sharedNotesChecked, setSharedNotesChecked] = useState<boolean>(true);
  const [personalNotesChecked, setPersonalNotesChecked] = useState<boolean>(false);
  const [taskChecked, setTaskChecked] = useState<boolean>(false);
  const [emailHeadTemplate, setEmailHeadTemplate] = useState<string>('');
  const [channelsToReceive, setChannelsToReceive] = useState<SlackChannel[]>([]);
  const [privateSpaceNotesChecked, setPrivateSpaceNotesChecked] = useState<string[]>([]);

  const [agendaHtml, setAgendaHtml] = useState<Note>('');
  const [sharedNotesHtml, setSharedNotesHtml] = useState<Note>('');
  const [personalNotesHtml, setPersonalNotesHtml] = useState<Note>('');
  const [privateNotesHtml, setPrivateNotesHtml] = useState<PrivateNoteHtml[]>([]);

  const { trackEvent } = useIntercom();
  const {
    data: { title }, date: { start, end }, version, meetingId,
  } = useContext(MeetingDataContext);

  const { userId } = useContext(UserDataContext);
  const authData = useContext(AuthContext);

  const initialEmailHeadTemplate = populateEmailHeadTemplate(
    defaultEmailHeadTemplate, title, getUTCString(start.date),
  );

  const notesData: NotesData = {
    emailHeadTemplate,
    agendaHtml,
    sharedNotesHtml,
    personalNotesHtml,
    privateNotesHtml,
    setEmailHeadTemplate,
    setAgendaHtml,
    setSharedNotesHtml,
    setPersonalNotesHtml,
  };

  useEffect(() => {
    setEmailHeadTemplate(initialEmailHeadTemplate);
  }, [initialEmailHeadTemplate]);

  const closePreviewEmailModal = () => setPreviewEmailModalOpen(false);

  const handleShareButtonClick = () => {
    closeDropdown();
    handleShareNotesModalOpen(true);
    logEventButtonClickSendEmail(authData.userId, trackEvent);
  };

  const handleCancel = () => {
    handleShareNotesModalOpen(false);
    logEventButtonClickSendEmailCancel(authData.userId, trackEvent);
  };

  const handleEmailSent = (success: boolean) => {
    setTriggerConfetti(success);
    if (success) toastSuccess('Successfully shared!', '');
    handleShareNotesModalOpen(false);
  };

  const onBackClick = () => {
    closePreviewEmailModal();
    handleShareNotesModalOpen(true);
  };

  const onPreviewClick = () => {
    setPreviewEmailModalOpen(true);
    logPreviewButtonClicked(authData.userId, trackEvent);
  };

  const handlePrivateSpaceChecked = (noteId: string) => {
    setPrivateSpaceNotesChecked((prev) => {
      if (prev.filter((ids) => ids === noteId).length === 0) return [...prev, noteId];
      return prev.filter((ids) => ids !== noteId);
    });
  };

  const handleShareNotesModalOpen = (value: boolean) => {
    logShareNotesEvents(authData.userId, value ? OPEN_EVENT : CLOSE_EVENT,
      [], [], mapTimestampForLog(start, end), 'resolved');
    setShareNotesModalOpen(value);
  };

  useEffect(() => {
    // After the an email is sent this const is used to triger the confetti fireworks,
    // after 5 seconds it resets to false
    if (triggerConfetti) {
      setTimeout(() => {
        setTriggerConfetti(false);
      }, 5000);
    }
  }, [triggerConfetti]);

  useEffect(() => {
    if (!meetingId) return;

    if (version <= 2) {
      setNoteContent(setAgendaHtml, AGENDA, meetingId, userId);
      setNoteContent(setSharedNotesHtml, SHARED_NOTES, meetingId, userId);
      setNoteContent(setPersonalNotesHtml, PERSONAL_NOTES, meetingId, userId);
    }

    if (version >= 3) {
      // Filler comment
    }
  }, [isShareNotesModalOpen]);

  return (
    <>
      <SquareButtonMolecule icon={<SendIcon />} onClick={handleShareButtonClick} tooltipText="Share a summary of this meeting" />
      <ShareNotesModal
        isOpen={isShareNotesModalOpen}
        handleCloseClick={() => { handleShareNotesModalOpen(false); }}
        taskItems={taskItems}
        notesData={notesData}
        privateSpaceNotesChecked={privateSpaceNotesChecked}
        setTriggerConfetti={setTriggerConfetti}
      />
      <RealisticConfetti triggerConfetti={triggerConfetti} />
      {isShareNotesModalOpen && (
        <FetchProseMirrorTextEditors
          setAgendaHtml={setAgendaHtml}
          setSharedNotesHtml={setSharedNotesHtml}
          setPersonalNotesHtml={setPersonalNotesHtml}
          setPrivateNotesHtml={setPrivateNotesHtml}
          privateNotes={privateNotes}
        />
      )}
    </>
  );
};

export default ShareMeetingNotes;
