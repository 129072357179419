import React, { ReactNode } from 'react';
import styled from 'styled-components';
import BREAKPOINTS from '../../../shared/CSS/CSS_Constants';

const Container = styled.div`
  pointer-events: none; // Make clickthrough
  position: absolute;
  bottom: 0;
  display: flex;
  height: 60px;
  border-radius: 0px 24px 0px 0px;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,1) 100%);
  padding: 10px 12.5px;

  & > {
    div:first-child{
      margin-right: 8px;
    }
  }

  @media only screen and (min-width: ${BREAKPOINTS.xl}){
    width: calc(100% - 415px);
    background-color: transparent;
    padding-left: 20%;
    padding-right: 20%;
    max-width: 1097px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.xl}){
    width: 100%;
  }
`;

interface Props {
  children: ReactNode,
}

const ContainerAtom = ({ children }: Props) => (
  <Container>
    {children}
  </Container>
);

export default ContainerAtom;
