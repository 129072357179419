import React from 'react';
import styled from 'styled-components';
import { gray8 } from '../../../../shared/colours';
import { defaultSmall } from '../../../../shared/typography';

const Explanation = styled.div`
  ${defaultSmall};
  color: ${gray8};
  text-align: center;
`;

interface Props {
  explanation: string,
}

const ExplanationAtom = ({ explanation }: Props) => (
  <Explanation>
    {explanation}
  </Explanation>
);

export default ExplanationAtom;
