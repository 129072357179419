import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../shared/colours';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import IconAtom from './IconAtom';

const Container = styled.div`
  display: flex;
  cursor: pointer;

  background-color: ${COLORS.blue6};
  width: 36px;
  height: 36px;
  border: 1px solid ${COLORS.blue6};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  margin: 4px;

  &:hover{
    border: 1px solid ${COLORS.blue5};
    background-color: ${COLORS.blue5};
    box-shadow: 0px 8px 16px rgb(27 33 36 / 16%), 0px 4px 6px -2px rgb(27 33 36 / 6%);
  }
`;

interface Props {
  icon: any,
  onClick: () => void,
  tooltipText?: string,
}

const SquareButtonMolecule = ({
  icon, onClick, tooltipText = '',
}: Props) => (
  <ReactTooltip tip={tooltipText} delay disabled={tooltipText?.length === 0 ?? true}>
    <Container onClick={onClick}>
      <IconAtom icon={icon} />
    </Container>
  </ReactTooltip>
);

SquareButtonMolecule.defaultProps = {
  tooltipText: '',
};

export default SquareButtonMolecule;
