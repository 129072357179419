import { DatabaseUsers, MeetingData } from '../../shared/types/types';
import { mapDatabaseUsersToUsers } from '../../utils/user/UserDataUtils';
import calculateUserPermissions from './meetingData_DataManipulation';

const mapDatabaseMeetingDataToMeetingData = (
  meetingId: string,
  databaseData: any,
  userId: string,
): MeetingData => {
  const newData: MeetingData = {
    resolvedState: 'resolved',
    attendees: {
      attendees: [],
      resolvedState: 'pending',
    },
    userRole: calculateUserPermissions(userId, databaseData?.permissions),
    version: databaseData.version ?? '1',
    meetingId,
    data: {
      agenda: databaseData.data?.agenda ?? [],
      attachments: databaseData.data?.attachments ?? [],
      attendees: databaseData.data?.attendees ?? [],
      description: databaseData.data?.description ?? '',
      title: databaseData.data?.title ?? '',
      postMeetingTasks: databaseData.data?.post_meeting_tasks
        ?? databaseData.data?.postMeetingTasks ?? [],
      preMeetingTasks: databaseData.data?.pre_meeting_tasks
        ?? databaseData.data?.preMeetingTasks ?? [],
    },
    tags: {
      tags: databaseData?.tags?.tags ?? [],
      meetingSeries: databaseData?.tags?.meeting_series ?? databaseData?.tags?.meetingSeries ?? {
        name: '',
        id: '',
      },
    },
    date: {
      created: databaseData.date?.created,
      start: databaseData.date?.start,
      end: databaseData.date?.end,
      lastUpdated: databaseData.date?.lastUpdated,
    },
    googleData: {
      ids: {
        eventId: databaseData?.googleData?.ids?.eventId ?? '',
        recurringEventId: databaseData?.googleData?.ids?.recurringEventId ?? '',
        dataEventId: databaseData?.googleEventId ?? databaseData?.googleData?.ids?.dataEventId ?? '',
        meetId: databaseData.googleMeetId ?? databaseData?.googleData?.ids?.meetId ?? '',
        calendarId: databaseData?.googleData?.ids?.calendarId ?? '',
      },
      content: {
        summary: databaseData?.googleData?.content?.summary ?? '',
      },
      conferenceData: {
        type: databaseData?.googleData?.conferenceData?.type ?? 'undefined',
        link: databaseData?.googleData?.conferenceData?.link ?? '',
      },
    },
    permissions: {
      users: mapDatabaseUsersToUsers(
        databaseData.permissions?.users as DatabaseUsers,
      ),
      userGroups: databaseData.permissions?.user_groups ?? [],
      linkPermissions: databaseData.permissions.linkPermissions ?? 'private',
    },
  };
  return newData;
};

export default mapDatabaseMeetingDataToMeetingData;
