import React from 'react';
import styled from 'styled-components';
import {
  gray1, gray9, darkBlue1, darkBlue2,
} from '../colours';
import { uiTextMedium } from '../typography';

const OutsideRing = styled.span`
  border-radius: 100px;
  width: 24px;
  height: 24px;

  background: ${gray1};
  cursor: pointer;
`;

const InsideRing = styled.span`
  border-radius: 64px;

  background: ${darkBlue1};
  border: 2px dashed ${darkBlue2};
  box-sizing: border-box;
  
  color: ${gray9};
  ${uiTextMedium}

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  // letter-spacing: 0.6px;
`;

interface Props {
  character: string
}

const EmptyProfileIcon = ({ character }: Props) => (
  <OutsideRing>
    <InsideRing>
      {character[0]}
    </InsideRing>
  </OutsideRing>
);

export default EmptyProfileIcon;
