import { useEffect, useState } from 'react';

import { WindowMessage } from '../../shared/types/types';
import { checkOrigin } from '../chromeExtension/chromeExtension';

function useWindowMessageReceiver<T>(defaultValue: T, type: string): { data: T } {
  const [data, setData] = useState<T>(defaultValue);

  useEffect(() => {
    window.addEventListener('message', (event: MessageEvent) => {
      const receivedMessage: WindowMessage = event.data;
      if (!isEventValid(event, type)) return;
      setData(receivedMessage.data);

      const ackMessage: WindowMessage = {
        id: receivedMessage.id,
        type: receivedMessage.type,
        sequence: receivedMessage.sequence,
        ack: true,
        data: undefined,
      };
      console.log(event.data);
      // TODO restrict origin
      window.parent.postMessage(ackMessage, '*');
    });
    console.log('%cbind listener', 'color:red;');
  }, []);

  return { data };
}

export default useWindowMessageReceiver;

const isEventValid = (event: MessageEvent, type: string) => event.data.type === type
  && event.data.id?.length > 0
  && event.data.sequence !== undefined
  && checkOrigin(event.origin);
