import React from 'react';
import { ScreensNavigationData, ScreensNavigateFunctions } from '../../utils/types';
import WelcomeScreensNavigationButtons from '../welcome-screens-navigation-buttons-atom';
import WelcomeScreensPageIndicator from '../welcome-screens-page-indicator-atom';
import WelcomeScreensNavigationContainer from './utils/styles';

interface WelcomeScreensStepProps {
  data: ScreensNavigationData,
  functions: ScreensNavigateFunctions
}

const WelcomeScreensNavigation = ({ data, functions }: WelcomeScreensStepProps) => (
  <WelcomeScreensNavigationContainer>
    <WelcomeScreensPageIndicator data={data} />
    <WelcomeScreensNavigationButtons data={data} functions={functions} />
  </WelcomeScreensNavigationContainer>
);

export default WelcomeScreensNavigation;
