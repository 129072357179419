import ConsoleImproved from '../shared/classes/ConsoleImproved';
import { red7 } from '../shared/colours';
import {
  DatabaseTaskItem, User, SendGridEmailRecipient,
  TaskItem, TaskNotificationType, TaskUpdateField, ResolveState,
} from '../shared/types/types';
import { DateToTemplateDateFormat } from '../utils/dateUtils/date';
import { getStatusIconUrl, getTaskOperationDescription } from '../utils/email/emailUtils';
import {
  TASK_NOTIFICATION_ASSIGN, TASK_NOTIFICATION_DELETE, TASK_NOTIFICATION_UPDATE,
} from '../utils/enums';
import { isTaskOverdue } from '../utils/tasks/tasksUtils';
import {
  cfGetAssigneeDataByEmailsCore,
  cfSendInviteMembersEmailsCore,
  cfSendMeetingNotesEmailCore,
  cfSendQuickFeedbackEmailCore,
  cfSendTaskChangeNotificationEmailCore,
  cfSendWelcomeEmailCore,
} from './cloudFunctionEmailCore';
import { cfSearchUserSettingByEmail } from './firebaseUsersAPI';

export const cfSendMeetingNotesEmail = (
  emails: SendGridEmailRecipient[], meetingTitle: string, html: string, templateId: string,
) => cfSendMeetingNotesEmailCore(emails, meetingTitle, html, templateId);

export const cfSendQuickFeedbackEmail = (
  email: string, feedback: string,
) => cfSendQuickFeedbackEmailCore(email, feedback);

export const cfGetAssigneeDataByEmails = ( // TODO: Shaokun: Should return Promise<AssigneeData[]>
  emails: string[],
) => cfGetAssigneeDataByEmailsCore(emails);

/**
 * @param reporterName - Name of the person which changed the task
 * @param reporterEmail - Email of the person which changed the task
 */
const cfSendTaskChangeNotificationEmail = (
  disabled: boolean, task: TaskItem | DatabaseTaskItem, reporterName: string, reporterEmail: string,
  notificationType: TaskNotificationType, updateFields?: TaskUpdateField[],
) => {
  if (disabled
    || task.data.assignee.email.length === 0
    || task.data.assignee.email === reporterEmail) {
    ConsoleImproved.log("Not sending task change notification email because it's disabled or the assignee's email is the same as the reporter's email", {
      task, reporterName, reporterEmail, notificationType, updateFields,
    });
    return;
  }
  console.log('sending task updated notification email');
  cfSendTaskChangeNotificationEmailCore(
    task.data.assignee.email,
    reporterName,
    getTaskOperationDescription(notificationType, updateFields),
    task.data.assignee.name,
    task.data.title,
    task.date.dueDate.type === 'noDueDate' ? 'none' : 'unset',
    DateToTemplateDateFormat(task.date.dueDate.date.date),
    isTaskOverdue(task) ? red7 : 'unset',
    getStatusIconUrl(task.data.status),
    task.data.description,
  ).then(() => console.log('task updated notification email sent'));
};

export const cfSendTaskUpdateNotificationEmail = async (
  task: TaskItem | DatabaseTaskItem, userData: User,
  updateFields?: TaskUpdateField[] | undefined,
) => {
  cfSearchUserSettingByEmail(task.data.assignee.email)
    .then((settings) => {
      if (!settings) throw new Error('No assignee settings found');
      cfSendTaskChangeNotificationEmail(
        !settings.receiveTaskEmail,
        task,
        userData.data.name,
        userData.data.email,
        TASK_NOTIFICATION_UPDATE,
        updateFields,
      );
    })
    .catch((error) => {
      console.log('user setting not found', error);
    });
};

export const cfSendTaskAssignNotificationEmail = async (
  task: TaskItem | DatabaseTaskItem, name: string, email: string,
) => {
  if (!task.data.assignee.email.length) return;
  console.log('Maybe sending task assign notification email');
  const settings = await cfSearchUserSettingByEmail(task.data.assignee.email);

  // TODO: Have settings not be void;
  // TODO: Fix that all places
  if (!settings) throw new Error('No assignee settings found');
  cfSendTaskChangeNotificationEmail(
    !settings.receiveTaskEmail,
    task,
    name,
    email,
    TASK_NOTIFICATION_ASSIGN,
  );
};

export const cfSendTaskDeleteNotificationEmail = async (
  task: TaskItem | DatabaseTaskItem, userData: User,
) => {
  console.log('sending task deleted notification email');
  cfSearchUserSettingByEmail(task.data.assignee.email).then((settings) => {
    if (!settings) throw new Error('No assignee settings found');
    cfSendTaskChangeNotificationEmail(
      !settings.receiveTaskEmail,
      task,
      userData.data.name,
      userData.data.email,
      TASK_NOTIFICATION_DELETE,
    );
  }).catch((error) => {
    console.log(error);
  });
};

export const cfSendWelcomeEmailAPI = async (
  firstName: string,
): Promise<ResolveState> => cfSendWelcomeEmailCore(firstName)
  .then((resolveState: ResolveState) => {
    if (resolveState === 'rejected') {
      console.log('error sending welcome email');
    }

    return resolveState;
  });

export const cfSendInviteMembersEmails = async (
  emails: string[],
): Promise<ResolveState> => cfSendInviteMembersEmailsCore(emails)
  .then((resolveState: ResolveState) => {
    if (resolveState === 'rejected') {
      console.log('error sending invite members emails');
    }
    return resolveState;
  });
