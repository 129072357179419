import React from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../colours';
import ColorDropdownColorCirclesGridMol from './ColorDropdown/ColorDropdownColorCirclesGridMol';
import ColorDropdownResetButtonAtom from './ColorDropdown/ColorDropdownResetButtonAtom';
import { ColorDropdownColors } from './ColorDropdown/ColorDropdownUtils';

const Container = styled.div`
  padding: 8px;
  border-radius: 10px;
  background: ${gray1};
  width: fit-content;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  & > div:first-child {
    margin-bottom: 8px;
  }
`;

interface Props {
  selectedColor: ColorDropdownColors,
  // eslint-disable-next-line no-unused-vars
  setSelectedColor: (color: ColorDropdownColors) => void;
  handleResetClick?: CallableFunction;
}

const ColorsDropdownOrg = ({ selectedColor, setSelectedColor, handleResetClick }: Props) => (
  <Container>
    <ColorDropdownResetButtonAtom
      onMouseDown={() => {
        handleResetClick!();
      }}
    />
    <ColorDropdownColorCirclesGridMol
      selectedColor={selectedColor}
      setSelectedColor={setSelectedColor}
    />
  </Container>
);

ColorsDropdownOrg.defaultProps = {
  handleResetClick: () => null,
};

export default ColorsDropdownOrg;
