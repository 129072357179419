import React from 'react';
import styled from 'styled-components';
import { gray8 } from '../../../colours';
import { small } from '../../../typography';

const Text = styled.span`
  color: ${gray8};
  ${small};
`;

const OhNoTextAtom = () => (
  <Text>
    Oh no! These friends are not on Shepherd
  </Text>
);

export default OhNoTextAtom;
