import React from 'react';
import styled from 'styled-components';
import { blue6 } from '../../../colours';
import { small } from '../../../typography';

const Text = styled.div`
  color: ${blue6};
  ${small};
  cursor: pointer;
`;

const InviteTextAtom = () => (
  <Text>
    Invite contacts
  </Text>
);

export default InviteTextAtom;
