import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface DeleteTableProps {
  fill?: string;
  width?: number;
  height?: number;
}

const DeleteTableIcon = ({
  fill,
  width,
  height,
}: DeleteTableProps) => (
  <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 3.75,0 C 1.67893,0 0,1.67893 0,3.75 v 7.5 C 0,13.3211 1.67893,15 3.75,15 h 7.5 C 13.3211,15 15,13.3211 15,11.25 V 3.75 C 15,1.67893 13.3211,0 11.25,0 Z m 0,1.5 h 7.5 c 1.2426,0 2.25,1.00736 2.25,2.25 v 7.5 c 0,1.2426 -1.0074,2.25 -2.25,2.25 H 3.75 C 2.50736,13.5 1.5,12.4926 1.5,11.25 V 3.75 C 1.5,2.50736 2.50736,1.5 3.75,1.5 Z M 5.5,4.75 c -0.1919413,0 -0.3828469,0.072305 -0.5292969,0.21875 -0.29289,0.29289 -0.29289,0.76961 0,1.0625 L 6.4375,7.5 4.96875,8.96875 c -0.29289,0.2928 -0.29289,0.767647 0,1.060547 0.29289,0.2929 0.76961,0.2929 1.0625,0 L 7.5,8.560547 8.970703,10.03125 c 0.2929,0.2929 0.767647,0.2929 1.060547,0 0.2929,-0.2929 0.2929,-0.7696 0,-1.0625 L 8.5625,7.5 10.03125,6.0292969 c 0.2929,-0.2929 0.2929,-0.7676569 0,-1.0605469 -0.2929,-0.2929 -0.7696,-0.2929 -1.0625,0 L 7.5,6.4375 6.03125,4.96875 C 5.884805,4.822305 5.6919413,4.75 5.5,4.75 Z"
      fill={fill}
    />
  </svg>
);

DeleteTableIcon.defaultProps = {
  fill: darkBlue4,
  width: 15,
  height: 15,
};

export default DeleteTableIcon;
