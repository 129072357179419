import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface DeleteColumnProps {
  fill?: string;
  width?: number;
  height?: number;
}

const DeleteColumnIcon = ({
  fill,
  width,
  height,
}: DeleteColumnProps) => (
  <svg width={width} height={height} viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 4.1649875,0 c -1.51878,0 -2.75,1.23122 -2.75,2.75 v 0.5 c 0,0.41421 0.33579,0.75 0.75,0.75 0.41421,0 0.75,-0.33579 0.75,-0.75 v -0.5 c 0,-0.69036 0.55964,-1.25 1.25,-1.25 h 4 c 0.6904,0 1.25,0.55964 1.25,1.25 v 10 c 0,0.6904 -0.5596,1.25 -1.25,1.25 h -4 c -0.69036,0 -1.25,-0.5596 -1.25,-1.25 v -0.4375 c 0,-0.4142 -0.33579,-0.75 -0.75,-0.75 -0.41421,0 -0.75,0.3358 -0.75,0.75 V 12.75 c 0,1.5188 1.23122,2.75 2.75,2.75 h 4 c 1.5188,0 2.7500005,-1.2312 2.7500005,-2.75 v -10 C 10.914988,1.23122 9.6837875,0 8.1649875,0 Z M 0.750925,5.5859375 c -0.1919413,-1.3e-6 -0.3848,0.072305 -0.53125,0.21875 -0.29289,0.2929 -0.29289,0.7676469 0,1.0605469 L 1.1044406,7.75 0.219675,8.6347656 c -0.2929,0.2929004 -0.2929,0.7676474 0,1.0605464 0.29289,0.292901 0.7676469,0.292901 1.0605469,0 L 2.1649875,8.810547 3.0478,9.695312 c 0.29289,0.2929 0.7676569,0.2929 1.0605469,0 0.29289,-0.292899 0.29289,-0.769629 0,-1.0624995 L 3.2255344,7.75 4.1103,6.8652344 c 0.2929,-0.2929 0.2929,-0.7676569 0,-1.0605469 -0.29289,-0.2929 -0.7696,-0.2929 -1.0625,0 L 2.1649875,6.6894531 1.2802219,5.8046875 C 1.1337769,5.6582375 0.9428663,5.5859388 0.750925,5.5859375 Z"
      fill={fill}
    />
  </svg>
);

DeleteColumnIcon.defaultProps = {
  fill: darkBlue4,
  width: 11,
  height: 16,
};

export default DeleteColumnIcon;
