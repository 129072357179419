import React from 'react';
import styled from 'styled-components';
import { darkBlue4 } from '../../colours';
import { uiText } from '../../typography';

const Container = styled.div`
  span {
    ${uiText};
    /* color: ${darkBlue4}; */
    margin: 0px;
  }
`;

interface Props {
  children: React.ReactNode
}

const ParagraphAtom = ({ children }: Props) => (
  <Container>
    { children }
  </Container>
);

export default ParagraphAtom;
