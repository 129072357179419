import React, {
  Dispatch, SetStateAction,
} from 'react';
import {
  MeetingSection, Shortcut, TaskItems, TaskTab, TemplateData, UserCenterPage,
} from '../../../shared/types/types';
import { MEETING_PAGE, TASK } from '../../../utils/enums';
import Tasks from '../../../shared/components/tasks';
import Notes from '../notes';
import TemplatesView from '../templates';

interface Props {
  tab: MeetingSection,
  setSection: Dispatch<SetStateAction<UserCenterPage>>,
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
  userId: string,
  meetingId: string,
  setNotesContent: Dispatch<SetStateAction<string>>,
  setIsSynchronized: Dispatch<SetStateAction<boolean>>,
  taskItems: TaskItems,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  isTemplatesOpen: boolean,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
  // eslint-disable-next-line no-unused-vars
  onShortcutTrigger: (shortcut: Shortcut) => void,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>
  secretChatId: string,
}

const TabBody = ({
  tab,
  setSection,
  taskTab,
  setTaskTab,
  userId,
  meetingId,
  setNotesContent,
  setIsSynchronized,
  taskItems,
  setCreateTaskModalOpen,
  isTemplatesOpen,
  setIsTemplatesOpen,
  setSelectedTemplateToEdit,
  onShortcutTrigger,
  setProseMirrorEditorView,
  secretChatId,
}: Props) => {
  if (tab === TASK) {
    return (
      <Tasks
        setSection={setSection}
        taskTab={taskTab}
        setTaskTab={setTaskTab}
        taskItems={taskItems}
        page={MEETING_PAGE}
        setCreateTaskModalOpen={setCreateTaskModalOpen}
        disableGoToMeeting
        height="calc(100vh - 335px)"
      />
    );
  }

  return (
    <>
      {isTemplatesOpen && (
      <TemplatesView
        tab={tab}
        setIsTemplatesOpen={setIsTemplatesOpen}
        setSelectedTemplateToEdit={setSelectedTemplateToEdit}
      />
      )}
      <Notes
        page={tab}
        userId={userId}
        meetingId={meetingId}
        setNotesContent={setNotesContent}
        setIsSynchronized={setIsSynchronized}
        setProseMirrorEditorView={setProseMirrorEditorView}
        onShortcutTrigger={onShortcutTrigger}
        displayNotes={!isTemplatesOpen}
        secretChatId={secretChatId}
      />
    </>
  );
};

export default TabBody;
