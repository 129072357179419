import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { NewFeature } from '../../types/types';
import FeatureBodyMolecule from './FeatureBodyMolecule';
import ModalHeaderExpandableMol from './NidakGeaderExpandableMol';
import LineAtom from './LineAtom';

interface HeaderContainerProps {
  isExpanded: boolean,
  isLast: boolean;
}

const HeaderContainer = styled.div<HeaderContainerProps>`
  ${(props) => (props.isExpanded ? css`
      margin-bottom: 8px;
    ` : !props.isLast && css`
      margin-bottom: 18px;
    `)}
`;

const LineContainer = styled.div`
  margin-bottom: 24px;
`;

interface Props {
  feature: NewFeature,
  isExpandable: boolean,
  isLast: boolean,
}

const NewFeatureModalFeatureMol = ({ feature, isExpandable, isLast }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleHeaderClick = () => isExpandable && setIsExpanded((prev) => !prev);

  useEffect(() => {
    if (!isExpandable) setIsExpanded(true);
  }, [isExpandable]);

  return (
    <>
      <HeaderContainer isExpanded={isExpanded} isLast={isLast}>
        <ModalHeaderExpandableMol
          isOpen={isExpanded}
          onClick={handleHeaderClick}
          isExpandable={isExpandable}
        >
          {feature.title}
        </ModalHeaderExpandableMol>
      </HeaderContainer>
      <FeatureBodyMolecule
        feature={feature}
        isExpanded={isExpanded}
        isLast={isLast}
      />
      {!isLast
        && (
          <LineContainer>
            <LineAtom />
          </LineContainer>
        )}
    </>
  );
};

export default NewFeatureModalFeatureMol;
