import styled from 'styled-components';
import { darkBlue4 } from '../../../../../shared/colours';
import { small } from '../../../../../shared/typography';

const MeetingDateAndTimeContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  column-gap: 6px;

  margin: 0;
  ${small};
  color: ${darkBlue4};
`;

export default MeetingDateAndTimeContainer;
