import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface UnlinkProps {
  fill?: string;
  width?: number;
  height?: number;
}

const UnlinkIcon = ({
  fill,
  width,
  height,
}: UnlinkProps) => (
  <svg width={width} height={height} viewBox="0 0 16.5 11.5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m 3.8572889,0.29665644 c -0.2929,-0.29289 -0.7677,-0.29289 -1.0606,0 -0.2929,0.29289 -0.2929,0.76776996 0,1.06065996 l 0.4817,0.48176 c -1.8312,0.40314 -3.20139996,2.03548 -3.20139996,3.98785 0,2.25516 1.82819996,4.08336 4.08329996,4.08336 h 2.5 c 0.4142,0 0.75,-0.3358 0.75,-0.75 0,-0.4142 -0.3358,-0.75 -0.75,-0.75 h -2.5 c -1.4267,0 -2.5833,-1.1566 -2.5833,-2.58336 0,-1.42674 1.1566,-2.58334 2.5833,-2.58334 h 0.5226 l 1.8334,1.83335 h -1.5226 c -0.4142,0 -0.75,0.33578 -0.75,0.75 0,0.41425 0.3358,0.74995 0.75,0.74995 h 3.0226 l 1.8449999,1.845 c -0.3511,0.0625 -0.6177,0.3693 -0.6177,0.7384 0,0.4142 0.3358,0.75 0.75,0.75 h 1.3560002 l 1.447,1.4470006 c 0.2929,0.292899 0.7678,0.292899 1.0607,0 0.2929,-0.2929 0.2929,-0.767801 0,-1.060701 z M 12.410389,5.8269364 c 0,0.27715 -0.1503,0.51915 -0.3738,0.64895 l -1.399,-1.39895 h 1.0228 c 0.4142,0 0.75,0.33578 0.75,0.75 z m 2.3644,3.38725 c 1.0872,-0.7337 1.8022,-1.977 1.8022,-3.38726 0,-2.25516 -1.8282,-4.08334 -4.0834,-4.08334 H 9.9935888 c -0.4142,0 -0.75,0.33579 -0.75,0.75 0,0.41422 0.3358,0.75 0.75,0.75 h 2.5000002 c 1.4268,0 2.5834,1.1566 2.5834,2.58334 0,0.99826 -0.5663,1.86426 -1.395,2.29436 z"
      fill={fill}
    />
  </svg>
);

UnlinkIcon.defaultProps = {
  fill: darkBlue4,
  width: 16.5,
  height: 11.5,
};

export default UnlinkIcon;
