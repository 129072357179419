/* eslint-disable no-unused-vars */
import {
  useRef, useEffect, useState, useMemo,
} from 'react';
import { FirebaseEditor } from '@meetshepherd/prosemirror-firebase';
import {
  DOMParser, MarkSpec, NodeSpec, Schema,
} from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { addListNodes } from 'prosemirror-schema-list';
import { schema, marks } from 'prosemirror-schema-basic';
import { menuBar } from 'prosemirror-menu';
import OrderedMap from 'orderedmap';
import { Mutex } from 'async-mutex';
import { keymap } from 'prosemirror-keymap';
import { MeetingSection } from '../../../types/types';
import setup from '../logic';
import { buildColorMarks, defaultColors } from '../logic/marks/color-marks';
import { buildHighlightMarks, defaultHighlights } from '../logic/marks/highlight-marks';
import { tableNodes } from '../logic/menu/helpers/table-utils';
import suggestions from '../logic/suggestions';
import MentionSpec from '../logic/nodes/mention';
import underline from '../logic/marks/underline';
import { todoItemSpec, todoListSpec } from '../logic/nodes/checkbox';
import fontSize from '../logic/marks/font-size';
import strikethrough from '../logic/marks/strikethrough';
import fonts from '../logic/marks/fonts';
import TimestampSpec from '../logic/nodes/timestamp';
// TODO: Uncomment this on release
import { getDbRef } from '../../../../utils/firebase';
// TODO: Comment this on release (or delete it)
// import { getDbRef } from '../../../../utils/inventiff-firebase';
import SpanSpec from '../logic/nodes/span';
import linkPreviewDecoration from '../logic/plugins/link-creation-preview';
import { TaskSpec, TaskView } from '../logic/nodes/task';
import TimestampDividerSpec from '../logic/nodes/timestamp-divider';
import lazyTransactionAdapter from '../logic/adapters/lazy-transaction-adapter';
import linkPreviewAdapter from '../logic/adapters/link-preview-adapter';
import tableControlsAdapter from '../logic/adapters/table-controls-adapter';

export interface HookProps {
  path: string;
  canEdit?: boolean;
  page: MeetingSection;
  toggleImageUpload: () => void;
}

export default function useProseMirrorFirebase(props: HookProps) {
  const proseMirrorRef = useRef<HTMLDivElement>(null);

  const [editorView, setEditorView] = useState<EditorView | null>(null);

  const [editorSchema, setEditorSchema] = useState<Schema | null>(null);

  /**
   * This state is not used only for readonly.
   * Currently, it can also be used for hiding, masking, deleting, etc. -
   * on a lost connection.
   */
  const [editorReadonly, setEditorReadonly] = useState<boolean>(true);

  const readonlyRef = useRef<boolean>();

  readonlyRef.current = editorReadonly;

  function stringToColor(s: string, alpha = 1) {
    const hue = s.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) % 360;
    return `hsla(${hue}, 100%, 50%, ${alpha})`;
  }

  const mutex = useMemo(() => new Mutex(), []);

  useEffect(() => {
    let firebaseEditor: FirebaseEditor;

    const baseMarks = {} as Record<string, MarkSpec>;

    const firebaseEditorSchema: Schema = new Schema({
      nodes: addListNodes(
        (schema.spec.nodes as OrderedMap<NodeSpec>)
          .append(
            tableNodes({
              tableGroup: 'block',
              cellContent: 'block+',
              cellAttributes: {
                background: {
                  default: null,
                  getFromDOM(dom: any) {
                    return dom.style.backgroundColor || null;
                  },
                  setDOMAttr(value: any, attrs: any) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      attrs.style = `${(attrs.style || '')};background-color: ${value};`;
                    }
                  },
                },
                'text-align': {
                  default: null,
                  getFromDOM(dom: any) {
                    return dom.style.textAlign || null;
                  },
                  setDOMAttr(value: any, attrs: any) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      attrs.style = `${(attrs.style || '')};text-align: ${value};`;
                    }
                  },
                },
              },
            }) as any,
          ).append({
            textInlineNode: SpanSpec,
            resolvedTimestamp: TimestampSpec,
            resolvedMention: MentionSpec,
            todo_list: todoListSpec,
            todo_item: todoItemSpec,
            task: TaskSpec,
            timestampDivider: TimestampDividerSpec,
          }),
        'paragraph block*',
        'block',
      ),
      marks: Object.assign(
        baseMarks,
        marks,
        {
          underline,
          fontSize,
          strikethrough,
          ...fonts,
        },
        buildColorMarks(...defaultColors),
        buildHighlightMarks(...defaultHighlights),
      ),
    });

    setEditorSchema(firebaseEditorSchema);

    /**
     * This mutex is required here as cleanup can
     * only happens once the text editor has been
     * instantiated. Since there is no mechanism
     * to synchronise the cleanup with regards to
     * the text editor constructor, they had to be
     * placed within the same mutex.
     */
    mutex.runExclusive(async () => {
      firebaseEditor = await new FirebaseEditor({
        firebaseRef: getDbRef(props.path),
        stateConfig: {
          schema: firebaseEditorSchema,
          plugins: [
            ...setup({ schema: firebaseEditorSchema, legacy: true }),
            suggestions,
          ],
        },
        view({ stateConfig, updateCollab, selections }) {
          const view = new EditorView(proseMirrorRef.current!, {
            state: EditorState.create(
              {
                ...stateConfig,
              },
            ),
            nodeViews: {
              // @ts-ignore
              task(node: Node, v: EditorView, getPos: () => number) {
                // @ts-ignore
                return new TaskView(node, v, getPos);
              },
            },
            dispatchTransaction(transaction) {
              const newState = view.state.apply(transaction);
              view.updateState(newState);
              updateCollab(transaction, newState);
            },
            decorations({ doc }) {
              return DecorationSet.create(doc, Object.entries(selections!).map(
                ([clientID, { from, to }]) => {
                  if (from === to) {
                    const elem = document.createElement('span');
                    elem.style.borderLeft = `1px solid ${stringToColor(clientID)}`;
                    return Decoration.widget(from, elem);
                  }
                  return Decoration.inline(from, to, {
                    style: `background-color: ${stringToColor(clientID, 0.2)};`,
                  });
                },
              ));
            },
            editable() {
              return true;
              // This has proved to not work as expected.
              // However, you might want to re-enable this in the future.
              // return !readonlyRef.current || false;
            },
          });
          setEditorView(view);
          lazyTransactionAdapter.view = view;
          return view;
        },
      });

      getDbRef('.info/connected').on('value', (snapshot) => {
        if (snapshot.val()) {
          setEditorReadonly(false);
        } else {
          setEditorReadonly(true);
          if ('activeElement' in document && document.activeElement) {
            (document.activeElement as HTMLElement).blur();
          }
        }
      });
    });
    return () => {
      /**
       * See explanation at the first use of
       * mutex.runExclusive in this useEffect().
       */
      mutex.runExclusive(async () => {
        setEditorReadonly(true);
        getDbRef('.info/connected').off();
        if (firebaseEditor) {
          linkPreviewAdapter.closePreviewDropdown();
          tableControlsAdapter.closePreviewControls();
          await firebaseEditor.destroy();
        }
      });
    };
  }, [props.path, props.canEdit, props.page, mutex]);

  return [proseMirrorRef, editorView, editorSchema, getDbRef, editorReadonly] as const;
}
