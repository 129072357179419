import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import PreviousMeetingDataContext from '../context/PreviousMeetingDataContext';
import { darkBlue4 } from '../../../colours';
import { header300 } from '../../../typography';
import { getDayAndMonth, getHourAndMinutes, getWeekday } from '../../../../utils/dateUtils/date';

const Container = styled.p`
  ${header300};
  color: ${darkBlue4};

  width: 100%;

  margin: 0;

  & > span:not(:last-of-type) {
    margin-right: 12px;
  }
`;

/**
 * Example output: Friday  4 June  09:30 - 10:30
 */
const StartAndEndTimeAtom = () => {
  const PreviousMeetingData = useContext(PreviousMeetingDataContext);

  const [weekDay, setWeekDay] = useState('');
  const [dayAndMonth, setDayAndMonth] = useState('');
  const [startHourAndMinutes, setStartHourAndMinutes] = useState('');
  const [endHourAndMinutes, setEndHourAndMinutes] = useState('');

  useEffect(() => {
    const formatWeekDay = getWeekday(PreviousMeetingData.date?.start?.date ?? '');
    const formatDayAndMonth = getDayAndMonth(PreviousMeetingData.date?.start?.date ?? '');
    const formatStartTime = getHourAndMinutes(PreviousMeetingData.date?.start?.date ?? '');
    const formatEndTime = getHourAndMinutes(PreviousMeetingData.date?.end?.date ?? '');

    setWeekDay(formatWeekDay);
    setDayAndMonth(formatDayAndMonth);
    setStartHourAndMinutes(formatStartTime);
    setEndHourAndMinutes(formatEndTime);
  }, [PreviousMeetingData]);

  return (
    <Container>
      <span>{weekDay}</span>
      <span>{dayAndMonth}</span>
      <span>
        {startHourAndMinutes}
        {' '}
        -
        {' '}
        {endHourAndMinutes}
      </span>
    </Container>
  );
};

export default StartAndEndTimeAtom;
