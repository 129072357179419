import styled from 'styled-components';

const TitleContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
`;

export default TitleContainer;
