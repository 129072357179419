import * as Sentry from '@sentry/browser';
import { AuthState } from '../../shared/types/types';

const setSentryUser = (authState: AuthState) => {
  if (authState.userState !== 'loggedIn') return;
  Sentry.setUser({
    id: authState.userId,
    email: authState.email,
    username: `${authState.firstName} ${authState.lastName}`,
  });
};

export default setSentryUser;
