/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { UserDataContext } from '../../../../../App';
import { dbUpdateSlackData } from '../../../../../database/firebaseSlackAPI';
import { dbGetUserByUserId } from '../../../../../database/firebaseUsersAPI';
import defaultSlackNotifications from '../../../../../database/utils/templateSlackNotifications';
import { dateISOObject } from '../../../../../utils/dateUtils/date';
import { mapDatabaseNotificationsToSlackNotifications, mapNotificationsToSlackData } from '../../../../../utils/slack/slackUtils';
import useFirstUserLoadEffect from '../../../../../utils/user/useFirstUserLoadEffect';
import { ResolveState, ResolveStateExtended, SlackNotificationsSettings } from '../../../../types/types';
import SlackNotificationsModal from './SlackNotificationsModal';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  isIntegratingSlack?: boolean,
}

const SlackNotificationsModalCtrl = ({
  isOpen,
  onClose,
  isIntegratingSlack,
}: Props) => {
  const userData = useContext(UserDataContext);
  const [isResolved, setIsResolved] = useState<ResolveStateExtended>('null');
  const [updatedNotifications, setUpdatedNotifications] = useState(defaultSlackNotifications);
  const [isLocked, setIsLocked] = useState<boolean>(false);

  const notifications = mapDatabaseNotificationsToSlackNotifications(
    userData.integrations.slack,
  );

  useEffect(() => {
    // Logic to handle the update text in the bottom bar
    // whenver a new update is initiated, we clear the state
    // of the previous setTimout, and starts a new one
    if (isResolved === 'null') return () => { };
    const timeoutId = setTimeout(() => {
      setIsResolved('null');
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [isResolved]);

  useFirstUserLoadEffect(() => {
    if (userData.integrations.slack.length === 0) return;
    setUpdatedNotifications(userData.integrations.slack[0].notifications);
  }, [userData]);

  useEffect(() => {
    // We want to make sure the notifications we just updated, is the same as the notifications
    // we get from the user data before we let the user interact with the UI
    if (isResolved !== 'resolved') return;
    if (_.isEqual(updatedNotifications, userData.integrations.slack[0]?.notifications)) {
      setIsLocked(false);
    } else {
      setIsLocked(true);
    }
  }, [userData.integrations.slack, updatedNotifications, isResolved]);

  const onUpdateSlackNotifications = async (newNotifications: SlackNotificationsSettings) => {
    setIsResolved('pending');
    setUpdatedNotifications(newNotifications);
    setIsLocked(true);
    const mappedSlackData = mapNotificationsToSlackData(
      newNotifications,
      userData.integrations.slack,
    );
    const status = await dbUpdateSlackData(userData.userId, mappedSlackData);
    setIsResolved(status);
  };

  const isResolvedLocal = isLocked ? 'pending' : isResolved;

  return (
    <SlackNotificationsModal
      isOpen={isOpen}
      setClosed={onClose}
      notifications={notifications}
      updateSlackNotifications={onUpdateSlackNotifications}
      isResolved={isResolvedLocal}
      isIntegratingSlack={isIntegratingSlack}
    />
  );
};

SlackNotificationsModalCtrl.defaultProps = {
  isIntegratingSlack: false,
};

export default SlackNotificationsModalCtrl;
