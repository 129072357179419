import React from 'react';
import styled from 'styled-components';
import { surface } from '../../../shared/colours';
import DeleteIcon24x24 from '../../../shared/icons/DeleteIcon24x24';

const IconContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  border-radius: 4px;
  height: 24px;

  &:hover {
    background: ${surface};
  }
`;

interface Props {
  onClick: () => void
}

const WelcomeBannerCloseIcon = ({ onClick }: Props) => (
  <IconContainer onClick={onClick}>
    <DeleteIcon24x24 />
  </IconContainer>
);

export default WelcomeBannerCloseIcon;
