import styled from 'styled-components';
import { gray7 } from '../../../../../../colours';
import { defaultSmall } from '../../../../../../typography';

export const ModalContent = styled.div`
  padding: 30px 24px;
`;

export const ModalContentTitle = styled.p`
  ${defaultSmall};
  color: ${gray7};
  margin: 0 0 16px;
`;
