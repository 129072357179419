import React from 'react';
import { surface } from '../colours';

interface Props {
  fill?: string,
  width?: number,
}

const DownArrow = ({
  fill,
  width,
}: Props) => (
  <svg width={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.29201 10.293C8.1052 10.4819 8.00043 10.7368 8.00043 11.0025C8.00043 11.2682 8.1052 11.5231 8.29201 11.712L11.231 14.677C11.449 14.892 11.731 14.999 12.01 14.999C12.289 14.999 12.566 14.892 12.779 14.677L15.709 11.722C15.8956 11.533 16.0002 11.2781 16.0002 11.0125C16.0002 10.7469 15.8956 10.492 15.709 10.303C15.6172 10.2098 15.5077 10.1358 15.387 10.0853C15.2664 10.0348 15.1368 10.0088 15.006 10.0088C14.8752 10.0088 14.7457 10.0348 14.625 10.0853C14.5043 10.1358 14.3948 10.2098 14.303 10.303L12.005 12.62L9.69801 10.293C9.60597 10.2002 9.49646 10.1265 9.3758 10.0762C9.25514 10.0259 9.12572 10.0001 8.99501 10.0001C8.86429 10.0001 8.73487 10.0259 8.61421 10.0762C8.49355 10.1265 8.38404 10.2002 8.29201 10.293Z" fill={fill} />
  </svg>
);

DownArrow.defaultProps = {
  fill: surface,
  width: 24,
};

export default DownArrow;
