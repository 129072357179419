import React from 'react';
import styled from 'styled-components';
import NotionIcon from '../../../../../shared/icons/integration-icons/components/Notion';
import SlackIcon from '../../../../../shared/icons/integration-icons/components/Slack';
import TrelloIcon from '../../../../../shared/icons/integration-icons/components/Trello';
import AsanaIcon from '../../../../../shared/img/AsanaIcon.png';
import { JiraIcon } from '../../../../../shared/icons/integration-icons';
import { SharePlatforms } from '../../../../../shared/types/types';
import EmailIcon from '../../../../../shared/icons/integration-icons/components/Email';
import GoogleDocsIcon from '../../../../../shared/icons/integration-icons/components/GoogleDocs';
import {
  ASANA, EMAIL, GOOGLE_DOCS, JIRA, NOTION, SLACK, TRELLO, GOOGLE_CALENDAR,
} from '../../../../../utils/enums';
import GoogleCalendarLogo from '../../../../../shared/icons/GoogleCalendarLogo';

const Logo = styled.img`
  height: 40px;
  width: 40px;
`;

interface Props {
  platform: SharePlatforms,
  size?: string
}
export default function PlatformIcon({ platform, size }: Props) {
  return (
    <>
      {platform === SLACK && <SlackIcon width={size} />}
      {platform === NOTION && <NotionIcon width={size} />}
      {platform === TRELLO && <TrelloIcon width={size} />}
      {platform === EMAIL && <EmailIcon width={size} />}
      {platform === ASANA && <Logo src={AsanaIcon} />}
      {platform === GOOGLE_DOCS && <GoogleDocsIcon width={size} />}
      {platform === JIRA && <JiraIcon width={size} />}
      {platform === GOOGLE_CALENDAR && <GoogleCalendarLogo width={size} />}
    </>
  );
}

PlatformIcon.defaultProps = {
  size: '40',
};
