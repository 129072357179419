import React from 'react';
import { gray7 } from '../colours';

interface Props {
  fill?: string,
  width?: string,
  height?: string
}
const RecurringMeetingIcon = ({ fill, width, height }: Props) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M19 12.25C19.4142 12.25 19.75 12.5858 19.75 13L19.75 14C19.75 16.6234 17.6233 18.75 15 18.75L5.81065 18.75L8.53032 21.4697C8.82321 21.7626 8.82321 22.2374 8.53032 22.5303C8.23743 22.8232 7.76255 22.8232 7.46966 22.5303L2.93933 18L7.46966 13.4697C7.76256 13.1768 8.23743 13.1768 8.53032 13.4697C8.82321 13.7626 8.82321 14.2374 8.53032 14.5303L5.81065 17.25L15 17.25C16.7949 17.25 18.25 15.7949 18.25 14L18.25 13C18.25 12.5858 18.5858 12.25 19 12.25Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.00001 11.75C4.5858 11.75 4.25001 11.4142 4.25001 11L4.25001 10C4.25001 7.37665 6.37666 5.25 9.00001 5.25L18.1893 5.25L15.4697 2.53033C15.1768 2.23744 15.1768 1.76256 15.4697 1.46967C15.7626 1.17678 16.2374 1.17678 16.5303 1.46967L21.0607 6L16.5303 10.5303C16.2374 10.8232 15.7626 10.8232 15.4697 10.5303C15.1768 10.2374 15.1768 9.76256 15.4697 9.46967L18.1893 6.75L9.00001 6.75C7.20508 6.75 5.75001 8.20507 5.75001 10L5.75001 11C5.75001 11.4142 5.41422 11.75 5.00001 11.75Z" fill={fill} />
  </svg>
);

RecurringMeetingIcon.defaultProps = {
  fill: gray7,
  width: '24px',
  height: '24px',
};

export default RecurringMeetingIcon;
