import React, {
  Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import MeetingDateAndTime from '../../../pages/meeting/components/meeting-date-and-time';
import WarningIcon from '../../icons/warning';
import { MeetingData } from '../../types/types';
import { titleSemibold } from '../../typography';
import MeetingAttendeesList from '../meeting-attendees-list';
import ReactTooltip from '../tooltip/ReactTooltip';
import ConferenceIcon from './ConferenceIcon';

const MeetingDetailContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  padding: 20px 20px 16px 20px;
  border-radius: 24px 24px 0 0;
`;

const TitleAndIconsContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  margin-bottom: 8px;

  & > div:first-child {
    width: 88%;
  }
`;

const TitleSemibold = styled.span`
  ${titleSemibold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
`;

const DateAndAttendeesContainer = styled.div`
  height: 28px; // Need to set to fixed height because of meeting section tabs
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

interface Props {
  meetingData: MeetingData,
  isRecurringMeeting: boolean,
  calendarError: boolean
  attendeesLoading: boolean,
  setMembersInvited: Dispatch<SetStateAction<boolean>>
}

const MeetingDetailsHeaderOrg = ({
  meetingData, isRecurringMeeting, calendarError, attendeesLoading,
  setMembersInvited,
}: Props) => {
  const titleRef = useRef<HTMLSpanElement>(document.createElement('span'));
  const [isTextOverflow, setTextOverflow] = useState<boolean>(false);

  useEffect(() => {
    setTextOverflow(titleRef.current.scrollWidth > titleRef.current.offsetWidth);
  }, [titleRef, meetingData.data.title]);

  return (
    <MeetingDetailContainer>
      <TitleAndIconsContainer>
        {/* TODO: Have this title as a separate component */}
        {isTextOverflow ? (
          <ReactTooltip tip={meetingData.data.title} place="bottom">
            <TitleSemibold ref={titleRef}>{meetingData.data.title}</TitleSemibold>
          </ReactTooltip>
        ) : (
          <TitleSemibold ref={titleRef}>{meetingData.data.title}</TitleSemibold>
        )}
        <IconsContainer>
          <WarningIcon error={calendarError} />
          <ConferenceIcon conferenceData={meetingData.googleData.conferenceData} />
        </IconsContainer>
      </TitleAndIconsContainer>
      <DateAndAttendeesContainer>
        <FlexSpaceBetween>
          <MeetingDateAndTime isRecurringMeeting={isRecurringMeeting} />
        </FlexSpaceBetween>
        <MeetingAttendeesList
          attendees={meetingData.attendees.attendees}
          loading={attendeesLoading}
          setMembersInvited={setMembersInvited}
        />
      </DateAndAttendeesContainer>
    </MeetingDetailContainer>
  );
};

export default MeetingDetailsHeaderOrg;
