import React from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../colours';
import Scrollbar from '../../scrollbar';
import MenuItemsMolecule from './MenuItemsMolecule';
import { DropdownItem } from '../utils';

interface Props {
  inputText: string,
  isActive: boolean,
  isLoading: boolean,
  isError: boolean,
  selectedItems: DropdownItem[],
  dropdownItems: DropdownItem[],
  // eslint-disable-next-line no-unused-vars
  onItemClick: (item: DropdownItem)=> void
}

const DropdownMenuContainer = styled.div`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
`;

interface MenuProps {
  'data-active': boolean
}

const Menu = styled.nav`
  background: ${gray1};
  position: ${(props: MenuProps) => (props['data-active'] ? 'relative' : 'absolute')};
  top: 2px;
  left: 0;
  width: 100%;
  transition: opacity 0.4s ease, transform 1s ease, visibility 0.4s;
  padding-top: 12px;
  padding-bottom: 12px;
  opacity: ${(props: MenuProps) => (props['data-active'] ? 1 : 0)};
  visibility: ${(props: MenuProps) => (props['data-active'] ? 'visible' : 'hidden')};
  transform: ${(props: MenuProps) => (props['data-active'] ? 'translateY(0)' : 'translateY(130px)')};
`;

function DropdownOptionMolecule({
  inputText, isActive, dropdownItems, selectedItems, onItemClick, isLoading, isError,
}:Props) {
  const menuJSX = (
    <Menu data-active={isActive}>
      <MenuItemsMolecule
        isError={isError}
        isLoading={isLoading}
        inputText={inputText}
        selectedItems={selectedItems}
        dropdownItems={dropdownItems}
        onItemClick={onItemClick}
      />
    </Menu>
  );
  return (
    <DropdownMenuContainer>
      {dropdownItems.length > 7 ? (
        <Scrollbar maxHeight="29vh">
          {menuJSX}
        </Scrollbar>
      ) : menuJSX }
    </DropdownMenuContainer>
  );
}

export default DropdownOptionMolecule;
