import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../../App';
import { dbFindAndNavigateToMeeting } from '../../database/firebaseMeetingAPI';
import SpinnerLoadingPage from '../../shared/components/loading/spinner-loading-page';
import { logEventPageViewGoogleMeet } from '../../utils/analytics/eventLogger';
import GoogleMeetErrorPage from './GoogleMeetErrorPage';

type Props = {
  match: any;
};

const GoogleMeet = ({ match }: Props) => {
  const [error, setError] = useState<boolean>(false);
  const authState = useContext(AuthContext);
  const { update } = useIntercom();
  const history = useHistory();

  const googleMeetId: string = match.params?.googleMeetId ?? '';

  useEffect(() => {
    logEventPageViewGoogleMeet(authState, update);
  }, []);

  useEffect(() => {
    if (googleMeetId.length > 9) {
      dbFindAndNavigateToMeeting(googleMeetId, authState.userId, history, setError);
    } else {
      setError(true);
    }
  }, [googleMeetId]);

  const handleRetry = () => {
    setError(false);
    dbFindAndNavigateToMeeting(googleMeetId, authState.userId, history, setError);
  };

  if (error) {
    return <GoogleMeetErrorPage googleMeetId={googleMeetId} retry={handleRetry} />;
  }

  return (
    <SpinnerLoadingPage />
  );
};

export default GoogleMeet;
