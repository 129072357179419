import React from 'react';
import styled from 'styled-components';
import CssSpinner from '../css-spinner';
import { gray7 } from '../../colours';

const Container = styled.div`
  min-width: 300px;
  text-align: center;
`;

const SearchableAssignLoading = () => (
  <Container>
    <CssSpinner color={gray7} size={16} padding={1} />
  </Container>
);

export default SearchableAssignLoading;
