/* eslint-disable no-unused-vars */
import create from 'zustand';
import { cfGetAttendeesFromGoogleAttendees } from '../database/firebaseMeetingAPI';
import GoogleAPI from '../external/GoogleAPI/GoogleAPI';
import ConsoleImproved from '../shared/classes/ConsoleImproved';
import GapiMeetings from '../shared/classes/GapiMeetings';
import PublicUsers from '../shared/classes/PublicUsers';
import {
  GapiMeetingData,
  GoogleAttendee, PublicUserDataV2, ResolveState,
} from '../shared/types/types';
import { PENDING, RESOLVED } from '../utils/enums';

interface AllMeetingsStore {
  resolveState: ResolveState;
  gapiMeetings: GapiMeetings;
  meetings: GapiMeetingData[],
  fetchMeetingsDaysAgo: number;
  users: PublicUsers;
  // eslint-disable-next-line no-unused-vars
  fetchMeetings: (daysAgo: number, userId: string, alwaysFetch?: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  fetchMeetingsLastXDays: (daysAgo: number, userId: string) => void;
  // eslint-disable-next-line no-unused-vars
  fetchMeetingsAgain: (daysAgo: number, userId: string) => void;
  fetchUsers: (googleAttendees: GoogleAttendee[]) => Promise<PublicUserDataV2[]>;
  // getUserByEmail: (googleAttendees: GoogleAttendee[]) => Promise<void>;
}

const useMeetingsAndUsersStore = create<AllMeetingsStore>((set, get) => ({
  resolveState: PENDING,
  gapiMeetings: new GapiMeetings(),
  meetings: [],
  users: new PublicUsers(),
  fetchMeetingsDaysAgo: 0,
  fetchMeetings: async (daysAgo, userId, alwaysFetch = false) => {
    const { resolveState, fetchMeetingsDaysAgo } = get();
    if (resolveState === RESOLVED && fetchMeetingsDaysAgo === daysAgo && !alwaysFetch) {
      ConsoleImproved.log('Meetings already fetched, so not fetching');
      return;
    }

    // const gapiMeetings = await GoogleAPI.getMeetingsLastSevenDays(daysAgo);
    const gapiMeetings = await GapiMeetings.getMeetingsLastSevenDays();
    set(() => ({
      gapiMeetings,
      meetings: gapiMeetings.meetingsWithNotesOrIsToday,
      fetchMeetingsDaysAgo: daysAgo,
    }));

    await gapiMeetings.checkWhichMeetingsHaveNotes(userId);

    // // const meetingsWithNotes2 = gapiMeetings.getMeetingsLastXDays( userId, daysAgo);
    // // const meetingsWithNotes = await filterMeetingsByThoseThatHaveNotesExceptForToday(
    // //   userId, gapiMeetings.item,
    // // );
    // if (meetingsWithNotes.resolvedState !== RESOLVED) {
    //   ConsoleImproved.log('Failed to fetch meetings',
    //  { gapiMeetings, filteredMeetings: meetingsWithNotes });
    //   set(() => ({
    //     resolveState: meetingsWithNotes.resolvedState,
    //   }));
    //   return;
    // }

    // const googleAttendees = GoogleAPI.mapMeetingsToAttendees(meetingsWithNotes.item);
    await get().fetchUsers(gapiMeetings.googleAttendees);
    set(() => {
      console.log('All Notes fetching data ended at', new Date());
      return {
        gapiMeetings,
        meetings: gapiMeetings.meetingsWithNotesOrIsToday,
        resolveState: RESOLVED,
        fetchMeetingsDaysAgo: daysAgo,
      };
    });
  },
  fetchMeetingsLastXDays: async (daysAgo, userId) => {
    set(() => ({
      resolveState: PENDING,
    }));
    const { gapiMeetings } = get();
    await gapiMeetings.getMeetingsLastXDays(daysAgo);
    // set(() => ({
    //   gapiMeetings,
    //   resolveState: RESOLVED,
    //   fetchMeetingsDaysAgo: daysAgo,
    // }));
    await gapiMeetings.checkWhichMeetingsHaveNotes(userId);
    set(() => ({
      gapiMeetings,
      meetings: gapiMeetings.meetingsWithNotesOrIsToday,
      resolveState: RESOLVED,
      fetchMeetingsDaysAgo: daysAgo,
    }));
  },
  fetchMeetingsAgain: async (daysAgo, userId) => {
    console.log('fetchMeetingsAgain');
    set(() => ({
      resolveState: PENDING,
    }));
    const gapiMeetings = await GapiMeetings.getMeetingsLastSevenDays();
    let i = daysAgo;
    while (i > 6) {
      // eslint-disable-next-line no-await-in-loop
      await gapiMeetings.getMeetingsLastXDays(i);
      i -= 7;
    }
    await gapiMeetings.checkWhichMeetingsHaveNotes(userId);
    set(() => ({
      gapiMeetings,
      meetings: gapiMeetings.meetingsWithNotesOrIsToday,
      resolveState: RESOLVED,
      fetchMeetingsDaysAgo: daysAgo,
    }));
  },
  fetchUsers: async (googleAttendees: GoogleAttendee[]) => {
    const { users } = get();
    const usersNotAlreadyInList = users
      .getGoogleAttendeesNotAlreadyInList(googleAttendees);
    if (usersNotAlreadyInList.length === 0) {
      ConsoleImproved.log('Users already fetched');
      return users.getUsersAlsoInGoogleAttendees(googleAttendees);
    }

    const newUsers = await cfGetAttendeesFromGoogleAttendees(usersNotAlreadyInList);
    users.add(newUsers);
    set(() => ({
      users,
    }));
    return users.getUsersAlsoInGoogleAttendees(googleAttendees);
  },
}));

export default useMeetingsAndUsersStore;
