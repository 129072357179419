import React, { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { darkBlue1 } from '../../colours';
import { header700, uiText } from '../../typography';
import ImageSource from '../../img/yoga.png';
import { logEventButtonClickEmptyTaskTab } from '../../../utils/analytics/eventLogger';
import { AuthContext } from '../../../App';

const NoTasksContainer = styled.div`
  width: 100%;
  height: 278px;
  border: 2px dashed ${darkBlue1};
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const NoTasksHeader = styled.h1`
  ${header700};
  margin-bottom: 0px;
`;

const CreateTasksText = styled.h3`
  ${uiText};
  margin-top: 8px;
`;

const Image = styled.img`
  height: 150px;
`;

interface Props {
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>;
}

const EmptyTaskTab = ({ setCreateTaskModalOpen }: Props) => {
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  const handleClick = () => {
    console.log('Clicked in EmptyTaskTab');
    logEventButtonClickEmptyTaskTab(authState.userId, trackEvent);
    setCreateTaskModalOpen(true);
  };

  return (
    <NoTasksContainer onClick={handleClick}>
      <Image src={ImageSource} />
      <NoTasksHeader>No tasks here yet!</NoTasksHeader>
      <CreateTasksText>Create a new task using the button below</CreateTasksText>
    </NoTasksContainer>
  );
};

export default EmptyTaskTab;
