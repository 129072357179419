import React, { useContext, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router';
import { pendingMeetingData } from '../../database/utils/templateMeetingData';
import Header from '../../shared/components/header';
import {
  MeetingData, MeetingSection, PrivateNoteData, TaskItems, TaskTab,
  UserCenterPage, TemplateData, ProseMirrorView,
} from '../../shared/types/types';
import Meeting from '../meeting';
import AllTasksOrganism from '../userCenter/AllTasksOrganism';
import MeetingDashboard from '../userCenter/meetingDashboard';
import Navbar from '../userCenter/navbar';
import BottomBar from './bottom-bar';
import MeetingDataContext from '../meeting/context/MeetingDataContext';
import ProseMirrorEditorViewContext from '../meeting/context/ProseMirrorEditorViewContext';
import { mapDatabaseTemplateDataToTemplateData } from '../../shared/components/templates-modal/utils';
import NewFeaturesModalOrgCtrl from '../../shared/components/new-feaures-modal/NewFeaturesModalOrgCtrl';
import { NewFeaturesV3 } from '../../shared/components/new-feaures-modal/newFeaturesConstant';
import ConnectionLostPopupAndModal from './connection-lost/ConnectionLostPopupAndModal';
import commandAdapter from '../../shared/components/prosemirror/logic/suggestions/command-adapter';
import { isValidTaskTab, isValidUserCenterTab, pendingTaskItems } from '../../utils/tasks/tasksUtils';
import { dbListenToPrivateNotesData } from '../../database/firebasePrivateNotesAPI';
import { AuthContext } from '../../App';
import { DEFAULT_SECTION, PRIVATE_NOTES, TODO } from '../../utils/enums';
import useGetQueryParam from '../../utils/hook/useGetParams';
import PrivateNotesModals from '../../shared/components/private-notes/create-private-notes-modal-or-show-invite-notif';
import AllNotesPageCtrl from '../all-notes/AllNotesPageCtrl';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../shared/components/header/utils/constants';
import TaskItemsContext from '../meeting/context/TaskDataContext';
import { logNotesTabEvent, logTasksUserAction } from '../../utils/analytics/eventLogger';
import { OPEN_EVENT, VIEW_EVENT } from '../../utils/analytics/enums';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { useUI } from './ui-context/uiContext';

interface Props {
  match: any,
}

const AllPages = ({ match }: Props) => {
  const authState = useContext(AuthContext);
  const location = useLocation();
  const [taskItems, setTaskItems] = useState<TaskItems>(pendingTaskItems); // For a specific meeting
  // const [page, setPage] = useState<UserCenterPage>('currentMeeting');
  const { page, setPage } = useUI();
  const setSection = setPage as React.Dispatch<React.SetStateAction<UserCenterPage>>;
  const [taskTab, setTaskTab] = useState<TaskTab>(TODO);
  const [isTemplatesOpen, setIsTemplatesOpen] = useState<boolean>(false);

  /**
   * This is so that the "/agenda" command can open the templates.
   */
  commandAdapter.openTemplatesMethod = () => {
    setIsTemplatesOpen(true);
  };

  const [meetingData, setMeetingData] = useState<MeetingData>(pendingMeetingData);
  const [isEditTemplateEnabled, setIsEditTemplateEnabled] = useState<boolean>(false);
  const [selectedTemplateToEdit, setSelectedTemplateToEdit] = useState<TemplateData>(
    mapDatabaseTemplateDataToTemplateData('', ''),
  );
  const [meetingSection, setMeetingSection] = useState<MeetingSection>(DEFAULT_SECTION);
  const [isRecurringMeeting, setIsRecurringMeeting] = useState<boolean>(false);
  const [proseMirrorEditorView, setProseMirrorEditorView] = useState<ProseMirrorView>({ page: '', editorView: '' });

  const [isPrivateNoteModalOpen, setIsPrivateNoteModalOpen] = useState<boolean>(false);
  const [isPrivateNoteInviteNotifOpen, setIsPrivateNoteInviteNotifOpen] = useState(false);
  const [activePrivateNoteId, setActivePrivateNoteId] = useState<string>('');
  const [privateNotesData, setPrivateNotesData] = useState<PrivateNoteData[]>([]);

  const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState<boolean>(false);
  useHotkeys('alt+t', () => setCreateTaskModalOpen(true), {}, [meetingSection]);

  const onClickSelectPrivateNote = (chatId: string) => {
    if (chatId.length === 0) return;
    setActivePrivateNoteId(chatId);
    setMeetingSection(PRIVATE_NOTES);
  };

  const onClickClosePrivateNoteModal = () => setIsPrivateNoteModalOpen(false);
  const onClickClosePrivateNoteInviteNotif = () => setIsPrivateNoteInviteNotifOpen(false);

  useEffect(() => {
    if (meetingData.meetingId.length === 0) return () => {};
    return dbListenToPrivateNotesData(
      meetingData.meetingId,
      authState.userId,
      authState.email,
      setPrivateNotesData,
    );
  }, [meetingData, activePrivateNoteId]);

  useEffect(() => {
    if (privateNotesData.length > 0) setIsPrivateNoteInviteNotifOpen(true);
  }, [privateNotesData]);

  useEffect(() => {
    logNotesTabEvent(authState.userId, OPEN_EVENT, meetingSection);
    if (meetingSection !== PRIVATE_NOTES) setActivePrivateNoteId('');
  }, [meetingSection]);

  useEffect(() => {
    if (selectedTemplateToEdit.templateId.length === 0) {
      setIsEditTemplateEnabled(false);
    } else {
      setIsEditTemplateEnabled(true);
    }
  }, [selectedTemplateToEdit]);

  useEffect(() => {
    const taskSectionQueryParam: string = useGetQueryParam('taskSection'); // TODO Asish: Add type for these query params
    if (isValidTaskTab(taskSectionQueryParam)) {
      handleSetTaskTab(taskSectionQueryParam as TaskTab);
    }
  }, [location]);

  useEffect(() => {
    const topTab: string = useGetQueryParam('tab');
    ConsoleImproved.log('TopTab', topTab);
    if (isValidUserCenterTab(topTab)) {
      const newMeetingSection = topTab as UserCenterPage;
      ConsoleImproved.log('Actually is a top tab', newMeetingSection);
      setPage(newMeetingSection);
    }
  }, [location]);

  const handleSetTaskTab = (tab:TaskTab) => {
    logTasksUserAction(VIEW_EVENT, '', taskTab);
    setTaskTab(tab);
  };

  return (
    <MeetingDataContext.Provider value={meetingData}>
      <TaskItemsContext.Provider value={taskItems}>
        <ProseMirrorEditorViewContext.Provider value={proseMirrorEditorView}>
          <Header view={DEFAULT_HEADER_MEETING_VIEW} />
          <Navbar section={page} setSection={setSection} />
          <AllNotesPageCtrl setSection={setSection} section={page} />
          <AllTasksOrganism
            section={page}
            setSection={setSection}
            taskTab={taskTab}
            setTaskTab={handleSetTaskTab as React.Dispatch<React.SetStateAction<TaskTab>>}
          />
          <MeetingDashboard section={page} />
          <Meeting
            section={page}
            setSection={setSection}
            taskTab={taskTab}
            setTaskTab={handleSetTaskTab as React.Dispatch<React.SetStateAction<TaskTab>>}
            meetingData={meetingData}
            setMeetingData={setMeetingData}
            taskItems={taskItems}
            setTaskItems={setTaskItems}
            match={match}
            meetingTab={meetingSection}
            setMeetingTab={setMeetingSection}
            isTemplatesOpen={isTemplatesOpen}
            setIsTemplatesOpen={setIsTemplatesOpen}
            isEditTemplateEnabled={isEditTemplateEnabled}
            setSelectedTemplateToEdit={setSelectedTemplateToEdit}
            selectedTemplateToEdit={selectedTemplateToEdit}
            setCreateTaskModalOpen={setCreateTaskModalOpen}
            isRecurringMeeting={isRecurringMeeting}
            setProseMirrorEditorView={setProseMirrorEditorView}
            setPrivateNoteOpen={setIsPrivateNoteModalOpen}
            activePrivateNoteId={activePrivateNoteId}
            onClickSelectPrivateNote={onClickSelectPrivateNote}
            privateNotes={privateNotesData}
            setPrivateNotes={setPrivateNotesData}
          />
          <BottomBar
            isEditTemplateBottomBarEnabled={isEditTemplateEnabled}
            meetingData={meetingData}
            setIsTemplatesOpen={setIsTemplatesOpen}
            meetingTab={meetingSection}
            taskItems={taskItems}
            section={page}
            setCreateTaskModalOpen={setCreateTaskModalOpen}
            isCreateTaskModalOpen={isCreateTaskModalOpen}
            setIsRecurringMeeting={setIsRecurringMeeting}
            privateNotes={privateNotesData}
            setSelectedTemplateToEdit={setSelectedTemplateToEdit}
            selectedTemplateToEdit={selectedTemplateToEdit}
          />
          <PrivateNotesModals
            isPrivateNotesModalOpen={isPrivateNoteModalOpen}
            onClickClosePrivateNotesModal={onClickClosePrivateNoteModal}
            isPrivateNoteInviteNotifOpen={isPrivateNoteInviteNotifOpen}
            onClickClosePrivateNoteInviteNotif={onClickClosePrivateNoteInviteNotif}
            privateNotes={privateNotesData}
            onClickSelectPrivateNote={onClickSelectPrivateNote}
          />
          <NewFeaturesModalOrgCtrl newFeature={NewFeaturesV3} />
          <ConnectionLostPopupAndModal />
        </ProseMirrorEditorViewContext.Provider>
      </TaskItemsContext.Provider>
    </MeetingDataContext.Provider>
  );
};

export default AllPages;
