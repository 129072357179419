import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { darkBlue1 } from '../../../../../../shared/colours';
import { DropdownItem, mapTrelloBoardsToDropdownItem } from '../../../../../../shared/components/dropdown/utils';
import { ResolvedStateItem, TrelloData } from '../../../../../../shared/types/types';
import { getTrelloBoards } from '../../../../../../utils/trello/trelloAPIs';
import { updateBoard } from '../../../../../../utils/trello/trelloSettingsUpdateUtil';
import { getTrelloBoardsAPIResponse } from '../../../../../../utils/trello/trelloUtils';
import SettingTab from '../SettingTab';

interface Props {
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
  trelloData: TrelloData
}

export const Line = styled.div`
  background:  ${darkBlue1};
  height: 1px;
  width: 100%;
  margin: 0px 0px 12px 0px;
`;

const WorkspaceAndBoardSetting = ({ setTrelloData, trelloData }:Props) => {
  const [accessToken, setAccessToken] = useState('');

  const [boardsLoading, setBoardsLoading] = useState(false);

  const [boardPlaceholder, setBoardPlaceholder] = useState('');

  const [boardsOptions, setBoardsOption] = useState<DropdownItem[]>([]);
  const [boardsResponse, setBoardsResponse] = useState<getTrelloBoardsAPIResponse[]>([]);

  useEffect(() => {
    setAccessToken(trelloData.accessToken);
    setBoardPlaceholder(trelloData.board.boardName || 'Select a Board');
  }, [trelloData]);

  const onBoardChange = (selectedOption: DropdownItem) => {
    setBoardPlaceholder(selectedOption.name);
    if (selectedOption.name !== boardPlaceholder) {
      updateBoard(selectedOption, boardsResponse, setTrelloData);
    }
  };

  const onBoardDropdownClick = () => {
    if (accessToken.length > 10 && boardsResponse.length === 0) {
      setBoardsLoading(true);
      getTrelloBoards(accessToken, trelloData.workspaceId)
        .then((response: ResolvedStateItem<getTrelloBoardsAPIResponse[]>) => {
          setBoardsResponse(response.item);
          const boardColumns: DropdownItem[] = mapTrelloBoardsToDropdownItem(
            response.item,
          );
          setBoardsOption(boardColumns);
        }).catch((error:any) => {
          console.log('Trello CF Failed', error);
        })
        .finally(() => {
          setBoardsLoading(false);
        });
    }
  };

  return (
    <>
      <SettingTab
        label="Trello Board"
        onClick={onBoardDropdownClick}
        onChange={onBoardChange}
        loading={boardsLoading}
        options={boardsOptions}
        placeholder={boardPlaceholder}
      />
      <Line />
    </>
  );
};

export default WorkspaceAndBoardSetting;
