import React from 'react';
import styled from 'styled-components';
import { gray3 } from '../../../colours';

export interface LoaderProps {
  color?: string,
}

const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 32px;
  margin: auto;

  & div {
    position: absolute;
    top: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${(props: LoaderProps) => props.color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & div:nth-child(1){
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  & div:nth-child(2){
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  & div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  & div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export default function HorizontalLoader({ color }:LoaderProps) {
  return (
    <Loader className="lds-ellipsis" color={color}>
      <div />
      <div />
      <div />
      <div />
    </Loader>
  );
}

Loader.defaultProps = {
  color: gray3,
};
