import React from 'react';
import { blue6 } from '../colours';

interface Props {
  fill?: string,
}

const BlueDotIcon = ({ fill }: Props) => (
  <svg width="16" height="16" viewBox="-4 -4 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="4" fill={fill} />
  </svg>

);

BlueDotIcon.defaultProps = {
  fill: blue6,
};

export default BlueDotIcon;
