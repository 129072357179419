import React from 'react';
import styled from 'styled-components';
import { layoutWhite, surface } from '../../../colours';
import CrossIcon from '../../../icons/cross';
import { PublicUserDataV2, ResolvedState } from '../../../types/types';
import CssSpinner from '../../css-spinner';
import ProfileIcon from '../../profile-icon';
import EmailAtom from './EmailAtom';
import SentTextAtom from './SentTextAtom';
import TickIconAtom from './TickIconAtom';

const Container = styled.div`
  width: 409px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  background-color: ${layoutWhite};
  border-radius: 8px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span:first-of-type {
    margin: 0px 8px 0px 8px;
  }
`;

interface Props {
  user: PublicUserDataV2,
  resolvedState: ResolvedState;
}

const EmailTagMolecule = ({ user, resolvedState }: Props) => (
  <Container>
    <LeftContainer>
      <ProfileIcon user={user} size={20} />
      <EmailAtom email={user.data.email} />
      {resolvedState === 'resolved' && <SentTextAtom text="Invite sent" />}
    </LeftContainer>

    {resolvedState === 'resolved' && <TickIconAtom />}
    {resolvedState === 'pending' && <CssSpinner color={surface} />}
    {resolvedState === 'rejected' && <CrossIcon />}
  </Container>
);

export default EmailTagMolecule;
