import { keyframes } from 'styled-components';

export const fadeInRight = keyframes`
  from {
    opacity: 0.25;
    left: -235px;
  }
  to {
    opacity: 1;
    left: 0px;
  }
`;

export const fadeInRightTask = keyframes`
  from {
    opacity: 0;
    left: 200px;
  }
  to {
    opacity: 1;
    left: 75px;
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeInLeft = (left:string) => keyframes`
  from {
    opacity: 0.25;
    left: 349px;
  }
  to {
    opacity: 1;
    left: ${left};
  }
`;

export const fadeInLeftTask = keyframes`
  from {
    opacity: 0;
    left: -75px;
  }
  to {
    opacity: 1;
    left: 50px;
  }
`;
