export const currentDateNicelyFormatted = (): string => {
  const today = new Date();
  const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
  const dateTime = `${date} ${time}`;
  return dateTime;
};

export function currentTimestamp() {
  return Date.now();
}

export const currentISOString = () => {
  const currentISODateString = new Date().toISOString();
  return currentISODateString;
};
