import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../App';
import { mapMeetingSectionToText } from '../../../utils/meetings/meetingsUtils';
import { gray3, surface } from '../../colours';
import { MeetingSection } from '../../types/types';
import { link } from '../../typography';

interface ButtonProps {
  isSelected: boolean;
}

const Button = styled.button<ButtonProps>`
  ${link};
  border: none;
  background: none;
  padding: 6px 16px;
  color: ${surface};
  cursor: pointer;
  width: 100%;
  text-align: left;
  ${(props) => props.isSelected && 'font-weight: bold'};
  
  &:hover {
    background: ${gray3};
  }
`;

interface Props {
  value: MeetingSection,
  // eslint-disable-next-line no-unused-vars
  onClick: (value: MeetingSection) => void,
}

export default function MeetingTabDropdownOption({ value, onClick }:Props) {
  const { settings: { defaultUserTab } } = useContext(UserDataContext);

  return (
    <li>
      <Button isSelected={value === defaultUserTab} type="button" onClick={() => onClick(value)}>{mapMeetingSectionToText(value)}</Button>
    </li>
  );
}
