import {
  blue3, blue6, blue7, cyan3, cyan6, cyan7, gray2,
  gray4, gray6, gray7, gray8, green3, green6, green7,
  purple3, purple6, purple7, red3, red6, red7, surface,
  yellow3, yellow6, yellow7,
} from '../../../../colours';

const ColorDropdownColorObjects: ColorObj[] = [
  { isSelected: true, color: surface },
  { isSelected: false, color: gray8 },
  { isSelected: false, color: gray7 },
  { isSelected: false, color: gray6 },
  { isSelected: false, color: gray4 },
  { isSelected: false, color: gray2 },
  { isSelected: false, color: blue6 },
  { isSelected: false, color: yellow6 },
  { isSelected: false, color: cyan6 },
  { isSelected: false, color: purple6 },
  { isSelected: false, color: green6 },
  { isSelected: false, color: red6 },
  { isSelected: false, color: blue3 },
  { isSelected: false, color: yellow3 },
  { isSelected: false, color: cyan3 },
  { isSelected: false, color: purple3 },
  { isSelected: false, color: green3 },
  { isSelected: false, color: red3 },
  { isSelected: false, color: blue7 },
  { isSelected: false, color: yellow7 },
  { isSelected: false, color: cyan7 },
  { isSelected: false, color: purple7 },
  { isSelected: false, color: green7 },
  { isSelected: false, color: red7 },
];

export default ColorDropdownColorObjects;

export type ColorDropdownColors =
typeof surface | typeof gray8 | typeof gray7 |
typeof gray6 | typeof gray4 | typeof gray2 |
typeof blue6 | typeof yellow6 | typeof cyan6 |
typeof purple6 | typeof green6 | typeof red6 |
typeof blue3 | typeof yellow3 | typeof cyan3 |
typeof purple3 | typeof green3 | typeof red3 |
typeof blue7 | typeof yellow7 | typeof cyan7 |
typeof purple7 | typeof green7 | typeof red7;

export type ColorObj = {
  isSelected: boolean;
  color: ColorDropdownColors;
}
