import React from 'react';
import styled from 'styled-components';
import { green6 } from '../../../../../../shared/colours';
import { uiTextMedium } from '../../../../../../shared/typography';
import { ConnectedMark } from '../../../images';

const IntegrationConnected = () => (
  <Container>
    <ConnectedMark />
    <TextContainer>Connected</TextContainer>
  </Container>
);

export default IntegrationConnected;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  min-width: 100px;
`;

const TextContainer = styled.div`
  ${uiTextMedium};
  color: ${green6};
`;
