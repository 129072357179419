import {
  ConferenceData, GapiMeetingData, MeetingData, User,
} from '../../shared/types/types';
import {
  ATTENDEES_PATH,
  CONFERENCEID_PATH,
  END_DATE_PATH,
  START_DATE_PATH,
  TITLE_DATA_PATH,
  RECURRING_EVENT_ID_PATH,
  DESCRIPTION_PATH,
} from '../../utils/constants';
import { isDateStringsTheSame } from '../../utils/dateUtils/date';
import { dbUpdateConferenceData, dbUpdateMeetingData } from '../firebaseMeetingAPI';
import { getDateObjectFromGoogleDate, mapGoogleMeetingToConferenceData } from './templateMeetingData';

const updateMeetingDataFromGapiMeetingData = (
  calendarData: GapiMeetingData, meetingData: MeetingData, user: User,
) => {
  if (calendarData.resolvedState !== 'resolved') return;
  if (meetingData.resolvedState !== 'resolved') return;
  updateTitle(calendarData, meetingData);
  updateStartTime(calendarData, meetingData);
  updateEndTime(calendarData, meetingData);
  if (!isAuthUserTheCreatorOfTheEvent(calendarData, user)) return;
  updateAttendees(calendarData, meetingData);
  updateConferenceId(calendarData, meetingData);
  updateRecurringEventId(calendarData, meetingData);
  updateConferenceData(calendarData, meetingData);
  updateDescription(calendarData, meetingData);
};

export default updateMeetingDataFromGapiMeetingData;

/**
 * We experienced "flickering" of some meeting data since some users got
 * different GAPI meeting response than others. This is probably because
 * users who has access to the calendar, can in some cases get different responses
 * than those that are only guests. I.e. the attendees list can sometimes be
 * hidden for guests. Therefore different clients tried to update the meetingData with
 * different attendees, making it "flicker" in the UI.
 * This assumes that at least one Shepherd user that access an event is the creator of the
 * event
 */
const isAuthUserTheCreatorOfTheEvent = (calendarData: GapiMeetingData, user: User) => {
  if (user.resolvedState !== 'resolved') return false;
  const creatorEmail = calendarData?.creator?.email ?? '';
  const userEmail = user?.data?.email ?? '';
  if (creatorEmail.length === 0) return false;
  if (userEmail.length === 0) return false;
  if (creatorEmail !== userEmail) return false;
  return true;
};

const updateTitle = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  if (isTitleDifferent(calendarData, meetingData)) {
    dbUpdateMeetingData(calendarData?.summary, meetingData.meetingId, TITLE_DATA_PATH);
  }
};

const updateStartTime = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  if (isStartTimeDifferent(calendarData, meetingData)) {
    dbUpdateMeetingData(
      getDateObjectFromGoogleDate(calendarData?.start?.dateTime),
      meetingData.meetingId,
      START_DATE_PATH,
    );
  }
};
const updateEndTime = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  if (isEndTimeDifferent(calendarData, meetingData)) {
    dbUpdateMeetingData(
      getDateObjectFromGoogleDate(calendarData?.end?.dateTime),
      meetingData.meetingId,
      END_DATE_PATH,
    );
  }
};

const updateAttendees = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  if (isAttendeesDifferent(calendarData, meetingData)) {
    const attendees = calendarData?.attendees ?? [];
    dbUpdateMeetingData(attendees, meetingData.meetingId, ATTENDEES_PATH);
  }
};

const updateConferenceId = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  if (isConferenceIdDifferent(calendarData, meetingData)) {
    dbUpdateMeetingData(calendarData?.conferenceData?.conferenceId,
      meetingData.meetingId,
      CONFERENCEID_PATH);
  }
};

const updateRecurringEventId = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  if (isRecurringEventIdDifferent(calendarData, meetingData)) {
    dbUpdateMeetingData(calendarData?.recurringEventId ?? '',
      meetingData.meetingId,
      RECURRING_EVENT_ID_PATH);
  }
};

export const isTitleDifferent = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  const calendarTitle = getCalendarTitle(calendarData);
  const meetingTitle = meetingData.data.title;
  if (calendarTitle.length > 0 && calendarTitle !== meetingTitle) return true;
  return false;
};

const isStartTimeDifferent = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  const calendarStartDate = calendarData?.start?.dateTime ?? '';
  const meetingStartDate = meetingData.date.start.date;
  if (isDateStringsTheSame(calendarStartDate, meetingStartDate)) return false;
  return true;
};

const isEndTimeDifferent = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  const calendarEndDate = calendarData?.end?.dateTime ?? '';
  const meetingEndDate = meetingData.date.end.date;
  if (isDateStringsTheSame(calendarEndDate, meetingEndDate)) {
    return false;
  }
  return true;
};

const sortAttendees = (a: any, b: any) => {
  if (a.email > b.email) return 1;
  if (a.email < b.email) return -1;
  return 0;
};

export const isAttendeesDifferent = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  const calendarAttendeesEmails = calendarData.attendees?.sort(sortAttendees)?.map((attendees) => attendees.email).join(',') ?? '';
  const meetingAttendeesEmails = meetingData.data.attendees?.sort(sortAttendees)?.map((attendees) => attendees.email).join(',') ?? '';
  const calendarAttendeesResponseStatus = calendarData.attendees?.sort(sortAttendees)?.map((attendees) => attendees.responseStatus).join(',') ?? '';
  const meetingAttendeesResponseStatus = meetingData.data.attendees?.sort(sortAttendees)?.map((attendees) => attendees?.responseStatus).join(',') ?? '';
  if ((calendarAttendeesEmails !== meetingAttendeesEmails)
    || (calendarAttendeesResponseStatus !== meetingAttendeesResponseStatus)) {
    return true;
  }
  return false;
};

export const isConferenceIdDifferent = (
  calendarData: GapiMeetingData,
  meetingData: MeetingData,
) => {
  const calendarConferenceId = calendarData?.conferenceData?.conferenceId ?? '';
  const meetingConferenceId = meetingData?.googleData?.ids?.meetId ?? '';
  if (calendarConferenceId.length > 0 && calendarConferenceId !== meetingConferenceId) {
    return true;
  }
  return false;
};

const getCalendarTitle = (calendarData: any) => calendarData?.summary ?? '';

export const isRecurringEventIdDifferent = (
  calendarData: GapiMeetingData,
  meetingData: MeetingData,
) => {
  const calendarRecurringEventId = calendarData?.recurringEventId ?? '';
  const meetingRecurringEventId = meetingData?.googleData?.ids?.recurringEventId ?? '';

  if (calendarRecurringEventId !== meetingRecurringEventId) {
    return true;
  }

  return false;
};

const updateConferenceData = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  const gapiConferenceData = mapGoogleMeetingToConferenceData(calendarData);
  if (isConferenceDataDifferent(gapiConferenceData, meetingData.googleData.conferenceData)) {
    dbUpdateConferenceData(meetingData.meetingId, gapiConferenceData);
  }
};

const isConferenceDataDifferent = (
  gapiConferenceData: ConferenceData, meetingConferenceData: ConferenceData,
) => {
  if (gapiConferenceData.type !== meetingConferenceData.type) return true;
  if (gapiConferenceData.link !== meetingConferenceData.link) return true;
  return false;
};

const updateDescription = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  if (isDescriptionDifferent(calendarData, meetingData)) {
    dbUpdateMeetingData(calendarData?.description ?? '', meetingData.meetingId, DESCRIPTION_PATH);
  }
};

const isDescriptionDifferent = (calendarData: GapiMeetingData, meetingData: MeetingData) => {
  const calendarDescription = calendarData?.description ?? '';
  const meetingDescription = meetingData.data.description;
  if (calendarDescription !== meetingDescription) return true;
  return false;
};
