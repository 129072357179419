import { Dispatch, SetStateAction } from 'react';
import { dateISOObject, dateToSDateObject } from '../../../utils/dateUtils/date';
import { emptyPublicUserDataV2, mapAssigneeV2ToBasicUser } from '../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import {
  AssigneeV2, MeetingData, ReporterV2, TaskItem,
} from '../../types/types';

export const resetAssignee = (setTaskItem: Dispatch<SetStateAction<TaskItem>>) => {
  setTaskItem((prev) => ({
    ...prev,
    date: {
      ...prev.date,
      updated: dateToSDateObject(new Date()),
    },
    data: {
      ...prev.data,
      assignee: {
        userId: '',
        email: '',
        name: '',
        photoUrl: '',
      },
    },
    assignee: emptyPublicUserDataV2,
  }));
};

export const setAssignee = (
  setTaskItem: Dispatch<SetStateAction<TaskItem>>,
  newAssignee: AssigneeV2,
) => {
  setTaskItem((prev) => ({
    ...prev,
    date: {
      ...prev.date,
      updated: dateToSDateObject(new Date()),
    },
    data: {
      ...prev.data,
      assignee: mapAssigneeV2ToBasicUser(newAssignee),
    },
    assignee: newAssignee,
  }));
};

export const setReporter = (
  setTaskItem: Dispatch<SetStateAction<TaskItem>>,
  newReporter: ReporterV2,
) => {
  setTaskItem((prev) => ({
    ...prev,
    date: {
      ...prev.date,
      updated: dateToSDateObject(new Date()),
    },
    data: {
      ...prev.data,
      reporter: mapAssigneeV2ToBasicUser(newReporter),
    },
    reporter: newReporter,
  }));
};

export const setTaskDueDate = (
  setTaskItem: Dispatch<SetStateAction<TaskItem>>,
  newDueDate: Date,
) => {
  setTaskItem((prev) => ({
    ...prev,
    date: {
      ...prev.date,
      updated: dateToSDateObject(new Date()),
      dueDate: {
        type: 'date',
        date: dateToSDateObject(newDueDate as Date),
        meeting: {
          meetingId: '',
          startDate: dateISOObject(),
          name: '',
        },
      },
    },
  }));
};

export const resetTaskDueDate = (setTaskItem: Dispatch<SetStateAction<TaskItem>>) => {
  setTaskItem((prev) => ({
    ...prev,
    date: {
      ...prev.date,
      updated: dateToSDateObject(new Date()),
      dueDate: {
        type: 'noDueDate',
        date: {
          date: '',
          timestamp: 0,
        },
        meeting: {
          meetingId: '',
          startDate: {
            date: '',
            timestamp: 0,
          },
          name: '',
        },
      },
    },
  }));
};

export const newTitleChange = (
  setTaskItem: Dispatch<SetStateAction<TaskItem>>,
  newTitle: string,
) => {
  setTaskItem((prev) => ({
    ...prev,
    date: {
      ...prev.date,
      updated: dateToSDateObject(new Date()),
    },
    data: {
      ...prev.data,
      title: newTitle,
    },
  }));
};

export const setIsPrivate = (
  setTaskItem: Dispatch<SetStateAction<TaskItem>>,
  isPrivate: boolean,
) => {
  setTaskItem((prev) => ({
    ...prev,
    date: {
      ...prev.date,
      updated: dateToSDateObject(new Date()),
    },
    data: {
      ...prev.data,
      isPrivate,
    },
  }));
};

export const newDescriptionChange = (
  setTaskItem: Dispatch<SetStateAction<TaskItem>>,
  newDescription: string,
) => {
  setTaskItem((prev) => ({
    ...prev,
    date: {
      ...prev.date,
      updated: dateToSDateObject(new Date()),
    },
    data: {
      ...prev.data,
      description: newDescription,
    },
  }));
};

export const setTaskMeeting = (
  setTaskItem: Dispatch<SetStateAction<TaskItem>>,
  meetingData: MeetingData,
) => {
  if (meetingData.resolvedState !== 'resolved') return;
  setTaskItem((prev) => ({
    ...prev,
    date: {
      ...prev.date,
      updated: dateToSDateObject(new Date()),
    },
    meeting: {
      meetingId: meetingData.meetingId,
      name: meetingData.data.title,
      startDate: meetingData.date.start,
      tags: meetingData.tags.tags.map((tag) => tag.name),
    },
  }));
};
