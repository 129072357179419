import { USER_CENTER_CURRENT_MEETING, USER_CENTER_PREV_MEETING } from '../enums';

export const ROOT_DOMAIN = 'https://app.meetshepherd.com';

// ============ Sidebar State Analytics ===========

export const APP_EVENT = 'app';

export const APP_FIELD = 'loaded_state';

// ============ Sidebar State Analytics ===========

export const SIDEBAR_EVENT = 'sidebar';

export const SIDEBAR_FIELD = 'visible_state';

// ============ Page Analytics ===========

export const PAGE_EVENT = 'page';
export const PAGE_FIELD = 'visible_state';
export const MEETING_PAGE = 'meeting';
export const ALL_NOTES_PAGE = 'all_notes_page';
export const ALL_TASKS_PAGE = 'all_tasks_page';
export const LOGIN_PAGE = 'login';
export const SEND_FEEDBACK = 'send_feedback';
export const ONBOARDING_FORM_PAGE = 'onboarding_form';
export const ONBOARDING_FORM_PERSONAL_INFO_PAGE = 'onboarding_personal_info_form';
export const ONBOARDING_FORM_COMPANY_INFO_PAGE = 'onboarding_company_info_form';
export const ONBOARDING_MEET_PAGE = 'onboarding_meet';
export const ONBOARDING_CALENDAR_PAGE = 'onboarding_calendar';
export const ONBOARDING_TASKS_PAGE = 'onboarding_tasks';
export const ONBOARDING_INTEGRATIONS_PAGE = 'onboarding_connect_integrations';
export const ONBOARDING_VIDEO_PAGE = 'onboarding_video_intro';
export const ONBOARDING_FIRST_MEETING_PAGE = 'onboarding_first_meeting';
export const INVITE_NON_SHEPHERD_USERS_MODAL = 'invite_non_shepherd_users_modal';
export const GOOGLE_CALENDAR_ERROR_PAGE = 'google_calendar_error_page';
export const GOOGLE_MEET_ERROR_PAGE = 'google_meet_error_page';
export const INTERCOM_REDIRECT_PAGE = 'intercom_redirect_page';
export const SLACK_REDIRECT_PAGE = 'slack_redirect_page';

// ============ Onboarding Analytics ===========

export const ONBOARDING_EVENT = 'onboarding';
export const CREATE_MEETING_ERROR_EVENT = 'create_meeting_error';

// ============ Update Database Analytics ===========

export const DATABASE_EVENT = 'database';
export const DATABASE_PHOTO_URL_FIELD = 'photoUrl';
export const DATABASE_USER_DOCUMENT = 'user_document';

// ============ Consolidated Tasks Analytics ===========

export const CONSOLIDATED_TASKS_EVENT = 'consolidated_tasks';
export const CONSOLIDATED_TASKS_BACK_BUTTON_FIELD = 'back_button';

// ============ Page Analytics ===========

export const AUTHORIZATION_EVENT = 'authorization';
export const INVITED_SIGN_UP = 'invited';
export const ORGANIC_SIGN_UP = 'organic';

// ============ Invite Analytics ===========

export const INVITE_EVENT = 'invite';
export const SENT_INVITE_ACTION = 'sent';
export const INVITE_EMAIL = 'inviteEmail';
export const SLACK = 'slack';
export const UNKNOWN = 'unknown';
export const SHARE_NOTES_EMAIL = 'shareNotesEmail';
export type InviteSource = typeof INVITE_EMAIL | typeof SHARE_NOTES_EMAIL
  | typeof SLACK | typeof UNKNOWN

// ============ Templates Analytics ===========

export const TEMPLATES_EVENT = 'templates';
export const TEMPLATES_NOTE_TEMPLATE_FIELD = 'note_template';
export const TEMPLATES_BROWSE_MY_TEMPLATES_BUTTON_FIELD = 'browse_my_templates_in_dropdown';
export const TEMPLATES_SAVE_AS_TEMPLATE_BUTTON_FIELD = 'save_as_template_in_dropdown';
export const TEMPLATES_SAVE_TEMPLATES_MODAL_FIELD = 'save_templates_modal';
export const TEMPLATES_TEMPLATES_PLUS_BUTTON_FIELD = 'templates_plus_button';
export const TEMPLATES_BROWSE_TEMPLATES_VIEW_FIELD = 'browse_templates_view';

// ============ Notion Analytics ===========

export const NOTION_PAGE_FIELD = 'page';
export const NOTION_WORKSPACE_PAGE_FIELD = 'workspace_page';
export const NOTION_SUB_PAGE_FIELD = 'sub_page';

// ============ Slack Analytics ===========

export const SLACK_CHANNEL = 'channel';
export const SLACK_DM = 'direct_message';
export const SLACK_USER = 'userInfo';
export const SLACK_NOTIFICATION = 'notification';

// ============ Tasks Analytics ===========

export const TASKS_EVENT = 'tasks';
export const TASKS_STATUS_FIELD = 'status';
export const TASKS_TITLE_FIELD = 'title';
export const TASKS_DUE_DATE_FIELD = 'due_date';
export const TASKS_ASSIGNEE_FIELD = 'assignee';
export const TASKS_MULTIPLE_FIELD = 'multiple';
export const TASKS_CREATE_TASK_BUTTON_FIELD = 'create_task_button';
export const TASKS_DELETE_TASK_BUTTON_FIELD = 'delete_task_button';
export const DUPLICATE_TASK = 'duplicate_task';
export const GO_TO_MEETING_FROM_TASK = 'go_to_meeting';

// ============ GAPI Analytics ===========

export const GAPI_EVENT = 'gapi';
export const GAPI_MEETING_FIELD = 'meeting';

// ============ DESKTOP NOTIFICATION Analytics ===========

export const DESKTOP_NOTIFICATION = 'desktop_notification';
export type DesktopNotificationActionOn = 'feature' | 'login_modal' | 'reminder_modal';

// ============  Tab  Analytics ============

export const MAIN_TAB_MENU_EVENT = 'main_tab_menu';
export const NOTES_TAB_EVENT = 'notes_tab';

// ============  SECRET CHAT Analytics ============

export const SECRET_CHAT_EVENT = 'secret_chat';

// ============ Integration Analytics ============

export const INTEGRATION_EVENT = 'integration';

export const SLACK_INTEGRATION = 'slack';
export const TRELLO_INTEGRATION = 'trello';
export const NOTION_INTEGRATION = 'notion';

// ============ Share Notes Analytics ============

export const SHARE_NOTES_EVENT = 'share_notes_modal';
export const PREVIEW_VIEW_EVENT = 'preview_event';

// ============ App Error Notes Analytics ============

export const APP_ERROR_EVENT = 'error_event';
export const APP_RELOAD_EVENT = 'app_reload_event';

// ============ All Notes Dashboard ============

export const ALL_NOTES_EVENT = 'all_notes_event';
export const MEETING_CARD_FIELD = 'meeting_card_field';

// ============ Quick settings and settings Analytics ============

export const QUICK_SETTINGS_CLICK_EVENT = 'quick_settings_click_event';
export const SETTING_ITEM_CLICK_EVENT = 'settings_click_event';

export const TOGGLE_INTERCOM = 'toggle_intercom';
export const MENU_ITEM_CLICK_EVENT = 'menu_item_event';
export const GENERAL_SETTINGS = 'general_settings';
export const SLACK_NOTIFICATION_SETTINGS = 'slack_notifications';

// ============ Event Types Analytics ===========

export const EDIT_EVENT = 'edit';
export const GET_EVENT = 'get';
export const CREATE_EVENT = 'create';
export const SEND_EVENT = 'send';
export const DELETE_EVENT = 'delete';
export const OPEN_EVENT = 'open';
export const CLOSE_EVENT = 'close';
export const INSERT_EVENT = 'insert';
export const UPDATE_EVENT = 'update';
export const LOGIN_EVENT = 'login';
export const SIGNUP_EVENT = 'signup';
export const LOADED_EVENT = 'loaded';
export const VIEW_EVENT = 'view';
export const SUBMIT_EVENT = 'submit';
export const ERROR_EVENT = 'error';
export const ENABLE_EVENT = 'enable';
export const DISABLE_EVENT = 'disable';
export const NON_SHEPHERD_USER = 'non_shepherd_user';
export const CLICK_EVENT = 'click_event';

// ============ Location Types Analytics ===========

export const SHARE_MODAL_LOCATION = 'share_modal';
export const SETTINGS_MODAL_LOCATION = 'settings_modal';
export const REDIRECT_LOCATION = 'redirect_page';
export const TASKS_MODAL_LOCATION = 'task_modal';
export const TASKS_SETTINGS_LOCATION = 'task_settings_menu';
export const TASKS_CARD_LOCATION = 'task_card';

//  ============ CF Related Types ===========

export type AnalyticsDataPayload = {
  userId: string,
  eventType: string,
  eventData: Object,
}

export type MixPanelUserPayload = {
  userId: string,
  userEmail: string,
  firstName: string,
  lastName: string,
}

//  ============ Text Editor Types   ===========

export const TEXT_EDITOR_TOOLBAR = 'toolbar';
export const TEXT_EDITOR_SLASH_MENU = 'slash_menu';
export const TEXT_EDITOR_EVENT = 'text_editor_event';
export type NotesFor = typeof USER_CENTER_CURRENT_MEETING | typeof USER_CENTER_PREV_MEETING;
export type TextEditorOptionSource = typeof TEXT_EDITOR_TOOLBAR | typeof TEXT_EDITOR_SLASH_MENU
export type TextEditorOptions =
  'font_style' | 'font_size' | 'font_color' |
  'font_highlight' | 'insert_table' | 'mention' |
  'insert_link' | 'insert_emoji' | 'three_dot_menu' |
  'clear_formatting' | 'strike_through' | 'change_indent' |
  'insert_media' | 'insert_gif' | 'insert_task' |
  'insert_bullet_list' | 'insert_number_list' | 'insert_checkbox_list' |
  'insert_quote' | 'insert_code' | 'insert_time_stamp' |
  'insert_divider' | 'insert_time_stamp_divider' | 'insert_template' |
  'save_as_template' | 'show_shortcut' | '';

// ============ Updates Hub Types   ===========

export const UPDATE_HUB_EVENT = 'update_hub_event';
export const NOTIFICATION_CLICK_EVENT = 'notification_click_event';
export const MARK_AS_READ_ICON_CLICK_EVENT = 'mark_as_read_click_event';
export const MARK_AS_UNREAD_ICON_CLICK_EVENT = 'mark_as_unread_click_event';
export const MARK_ALL_READ_CLICK_EVENT = 'mark_all_read_click_event';
export const ALL_TAB_CLICK_EVENT = 'all_tab_click_event';
export const UNREAD_TAB_CLICK_EVENT = 'unread_tab_click_event';
export const READ_TAB_CLICK_EVENT = 'read_tab_click_event';

// =========== Platform Usage Event =============

export const PLATFORM_USAGE_EVENT = 'platform_usage_event';
