import styled from 'styled-components';
import { darkBlue1 } from '../../../../../colours';

const ModalLineAtom = styled.div`
  background:  ${darkBlue1};
  height: 1px;
  width: 100%;
`;

export default ModalLineAtom;
