import { PreviousMeetingWindowMessage } from '../../shared/types/types';

interface Props {
  meetingId: string
  previousMeetingId: string
}

const SendPreviousMeetingWindowMessage = ({ meetingId, previousMeetingId }:Props) => {
  const type = 'TOGGLE_PREVIOUS_MEETING_WINDOW';

  const message:PreviousMeetingWindowMessage = {
    type,
    meetingId,
    previousMeetingId,
  };

  window.parent.postMessage(message, '*');
};

export default SendPreviousMeetingWindowMessage;
