import { dbGetMeetingsThatHaveNotes } from '../../database/firebaseMeetingAPI';
import { isGapiMeetingToday } from '../../database/utils/gapiMeetingDataUtils';
import GoogleAPI from '../../external/GoogleAPI/GoogleAPI';
import { PENDING, REJECTED, RESOLVED } from '../../utils/enums';
import { GapiMeetingData, GapiMeetingDataExtended, GoogleAttendee } from '../types/types';

interface IGapiMeetings {
  meetings: Map<string, GapiMeetingDataExtended>;
  // eslint-disable-next-line no-unused-vars
  add: (meetings: GapiMeetingDataExtended[]) => void;
}

/**
 * GapiMeetingData[] info
 */
class GapiMeetings implements IGapiMeetings {
  meetings: Map<string, GapiMeetingDataExtended>;

  constructor() {
    this.meetings = new Map();
  }

  /** Get the list of all meetings `<GapiMeetingData[]>` */
  public get value(): GapiMeetingDataExtended[] {
    return Array.from(this.meetings.values());
  }

  public get meetingsWithNotesOrIsToday(): GapiMeetingData[] {
    const meetings = this.value;
    const meetingsWithNotesOrIsToday = meetings
      .filter((gapiMeeting) => isGapiMeetingToday(gapiMeeting)
   || this.eventIdsThatHaveNotes.includes(gapiMeeting.id));
    return meetingsWithNotesOrIsToday;
  }

  /** EventIds of all meetings */
  public get eventIds(): string[] {
    return this.value.map((meeting) => meeting.id);
  }

  public get eventIdsThatHaveNotes(): string[] {
    return this.value
      .filter((meeting) => meeting.hasNotes !== 'rejected')
      .map((meeting) => meeting.id);
  }

  /** All attendees across all meetings without duplications */
  public get googleAttendees(): GoogleAttendee[] {
    const allAttendees = this.value.reduce((acc, meeting) => {
      const { attendees } = meeting;
      attendees.forEach((attendeeCandidate) => {
        if (!acc.find((attendee) => attendee.email === attendeeCandidate?.email)) {
          acc.push(attendeeCandidate);
        }
      });
      return acc;
    }, [] as GoogleAttendee[]);
    return allAttendees;
  }

  add(meetings: GapiMeetingData[]): GapiMeetingDataExtended[] {
    // Should probably add value to eventIdsWithNotes
    meetings.forEach((meeting) => {
      this.meetings.set(meeting.id, { ...meeting, hasNotes: PENDING });
    });
    return this.value;
  }

  static async getMeetingsLastSevenDays(): Promise<GapiMeetings> {
    const gapiMeetingList = await GoogleAPI.getMeetingsLastSevenDays(6);
    if (gapiMeetingList.resolvedState !== RESOLVED) return new GapiMeetings();
    const gapiMeeting = new GapiMeetings();
    gapiMeeting.add(gapiMeetingList.item);
    return gapiMeeting;
  }

  async getMeetingsLastXDays(daysAgo: number) {
    const gapiMeetings = await GoogleAPI.getMeetingsLastSevenDays(daysAgo);
    if (gapiMeetings.resolvedState !== RESOLVED) return;
    this.add(gapiMeetings.item);
  }

  /** For those meetings we have not already checked, we check if they have notes or not */
  async checkWhichMeetingsHaveNotes(userId: string) {
    // Step 1: Get all meetings that hasNotes = PENDING
    const meetingsToCheck = this.value
      .filter((meetingToCheck) => meetingToCheck.hasNotes === PENDING);
    const eventIdsWeNeedToCheck = meetingsToCheck.map((meeting) => meeting.id);

    // Step 2: Check whether those meetings have notes or not
    const meetingsHavingNotes = await dbGetMeetingsThatHaveNotes(userId, eventIdsWeNeedToCheck);
    const eventIdsHavingNotes = meetingsHavingNotes.map(
      (meeting) => meeting.googleData.ids.eventId,
    );

    // Step 3: Set those meetings hasNotes state to the correct value
    meetingsToCheck.forEach((meetingToCheck) => {
      const hasNotes = eventIdsHavingNotes.includes(meetingToCheck.id) ? RESOLVED : REJECTED;
      this.meetings.set(meetingToCheck.id, { ...meetingToCheck, hasNotes });
    });
  }

  // async getMeetingsWithNotesExceptForToday(userId: string) {
  //   if (this.eventIds.length === 0) return [];

  //   const meetingsThatHaveNotes = await dbGetMeetingsThatHaveNotes(userId, this.eventIds);
  //   const eventIdsThatHaveNotes = meetingsThatHaveNotes
  //     .map((meeting) => meeting.googleData.ids.eventId);
  //   this.addEventIdsThatHaveNotes(eventIdsThatHaveNotes);
  // }

  // filterMeetingsByThoseThatHaveNotesExceptForToday(
  //   userId: string, allGapiMeetings: GapiMeetingDataExtended[],
  // ): Promise<ResolvedStateItem<GapiMeetingDataExtended[]>> {
  //   const eventIds = allGapiMeetings.map((meeting) => meeting.id);

  //   if (eventIds.length === 0) {
  //     const response: ResolvedStateItem<GapiMeetingDataExtended[]> = {
  //       item: [] as GapiMeetingDataExtended[],
  //       resolvedState: REJECTED,
  //     };
  //     return response;
  //   }

  //   const meetingsThatHaveNotes = await dbGetMeetingsThatHaveNotes(userId, eventIds);
  //   const filteredGAPIMeetings = allGapiMeetings
  //     .filter((gapiMeeting) => isGapiMeetingToday(gapiMeeting)
  //  || meetingsThatHaveNotes.find(
  //    (databaseMeeting) => gapiMeeting.id === databaseMeeting.googleData.ids.eventId,
  //  ));
  //   const response: ResolvedStateItem<GapiMeetingDataExtended[]> = {
  //     item: filteredGAPIMeetings,
  //     resolvedState: 'resolved',
  //   };
  //   return response;
  // }
}

export default GapiMeetings;
