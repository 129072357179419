import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ButtonSmall from '../../shared/components/button-small';
import Header from '../../shared/components/header';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../shared/components/header/utils/constants';
import LeftArrowOutlineIcon from '../../shared/icons/leftArrowOutline';
import OnboardingWelcomeScreensModel from '../onboarding/welcome/utils/onboarding-welcome-screens-model';

const IframeVideoWrapper = styled.div`
  & > iframe {
    width: 100%;
    height: 281.25px;
    object-fit: contain;
  }
  margin-top: 150px;
  padding: 10px;
`;

const BackButtonContainer = styled.div`
  margin: 10px;
`;

const TutorialVideo = () => {
  const history = useHistory();
  const onGoBackNavButtonClick = () => history.goBack();
  const { videoSrc } = OnboardingWelcomeScreensModel.video;

  return (
    <>
      <Header view={DEFAULT_HEADER_MEETING_VIEW} />
      <BackButtonContainer>
        <ButtonSmall text="" Icon={LeftArrowOutlineIcon} onClick={onGoBackNavButtonClick} />
      </BackButtonContainer>
      <IframeVideoWrapper>
        <iframe
          src={videoSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </IframeVideoWrapper>
    </>
  );
};

export default TutorialVideo;
