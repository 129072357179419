import { AttendeeV2, MeetingData } from '../../../shared/types/types';
import { emptyPublicUserDataV2 } from '../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';

const attendeeV2WithEmail = (email: string): AttendeeV2 => ({
  ...emptyPublicUserDataV2,
  responseStatus: 'needsAction',
  data: {
    ...emptyPublicUserDataV2.data,
    email,
  },
} as AttendeeV2);

const attendeeV2WithEmailNameAndUserId = (
  email: string, name: string, userId: string,
): AttendeeV2 => ({
  ...emptyPublicUserDataV2,
  responseStatus: 'needsAction',
  userId,
  data: {
    ...emptyPublicUserDataV2.data,
    email,
    name,
  },
});

const dummyMeetingData: MeetingData[] = [
  {
    resolvedState: 'resolved',
    attendees: {
      attendees: [
        attendeeV2WithEmailNameAndUserId('ivanmarinchev00@gmail.com', 'Ivan Marinchev', 'ydXu1byTKCW3SsU4TwtiqmBLHQC3'),
        attendeeV2WithEmailNameAndUserId('harald@meetshepherd.com', 'Harald the Git man', 'ydXu1byCW3SsU4TwtiqmBLHQC3'),
        attendeeV2WithEmailNameAndUserId('matthew@meetshepherd.com', 'Matthew the cowboy', 'dXu1byTKCW3SsU4TwtiqmBLHQC3'),
        attendeeV2WithEmailNameAndUserId('shaokun@meetshepherd.com', 'Shaokun the CSS-king', 'ydXu1byTKCW3SsU4TwtiqmBLC3'),
        attendeeV2WithEmailNameAndUserId('shiva@meetshepherd.com', 'Shiva the architect', 'dXu1byTKCW3SsU4TwtiqmBLHQC3'),
        attendeeV2WithEmailNameAndUserId('jamie@meetshepherd.com', 'Jamie the tester', 'ydXu1byTKCW3Ss4TwtiqmBLHQC3'),
        attendeeV2WithEmailNameAndUserId('tom@meetshepherd.com', 'Tom the product hunt master', 'ydXu1yTKCW3SsU4TwtiqmBLHQC3'),
        attendeeV2WithEmailNameAndUserId('pam@meetshepherd.com', 'Pam the designer', 'ydXu1byTKCW3SsU4wtiqmBLHQC3'),
        attendeeV2WithEmailNameAndUserId('ana@meetshepherd.com', 'Ana the product girl', 'ydXu1byTKCW3Ss4TwtiqmBLHQC3'),
      ],
      resolvedState: 'resolved',
    },
    data: {
      attachments: [],
      agenda: [],
      attendees: [
        {
          email: 'ivanko56@abv.bg', responseStatus: 'needsAction',
        },
        {
          responseStatus: 'accepted', email: 'ivanmarinchev00@gmail.com', self: true, organizer: true,
        },
      ],
      description: '',
      postMeetingTasks: [],
      preMeetingTasks: [],
      title: 'Test meeting',
    },
    date: {
      created: {
        timestamp: 1625577021506,
        date: '2021-7-6 15:10:21',
      },
      end: {
        timestamp: 1625583600000,
        date: '2021-07-06T18:00:00+03:00',
      },
      lastUpdated: {
        date: '2021-7-6 15:10:21',
        timestamp: 1625577021509,
      },
      start: {
        date: '2021-07-06T17:00:00+03:00',
        timestamp: 1625580000000,
      },
    },
    googleData: {
      content: {
        summary: 'Test meeting',
      },
      ids: {
        eventId: '2q19sedlmqnc633orhub4g3k4d',
        dataEventId: '',
        meetId: 'umq-gxqv-oqg',
        calendarId: 'ivanmarinchev00@gmail.com',
        recurringEventId: '',
      },
      conferenceData: {
        type: 'undefined',
        link: '',
      },
    },
    meetingId: 'K2iVXQkjCp4dPQdN6Hcd',
    permissions: {
      users: [],
      userGroups: [],
      linkPermissions: 'public_edit',
    },
    tags: {
      meetingSeries: {
        id: '',
        name: '',
      },
      tags: [],
    },
    userRole: {
      canAddAdmin: true,
      canAddCommenter: true,
      canAddEditor: true,
      canAddGroup: true,
      canAddModerator: true,
      canAddUser: true,
      canAddViewer: true,
      canComment: true,
      canDeleteMeeting: true,
      canEdit: true,
      canEditLinkSettings: true,
      canEditMeetingSeries: true,
      canEditTitle: true,
      canRemoveUser: true,
      canView: true,
      isLastAdmin: true,
      role: 'admin',
    },
    version: 2,
  },
  {
    resolvedState: 'resolved',
    attendees: {
      attendees: [
        attendeeV2WithEmail('ivanko57@abv.bg'),
        attendeeV2WithEmailNameAndUserId('ivanmarinchev00@gmail.com', 'Ivan Marinchev', 'ydXu1byTKCW3SsU4TwtiqmBLHQC3'),
      ],
      resolvedState: 'resolved',
    },
    data: {
      attachments: [],
      agenda: [],
      attendees: [
        {
          email: 'ivanko56@abv.bg', responseStatus: 'needsAction',
        },
        {
          responseStatus: 'accepted', email: 'ivanmarinchev00@gmail.com', self: true, organizer: true,
        },
      ],
      description: '',
      postMeetingTasks: [],
      preMeetingTasks: [],
      title: 'Test meeting',
    },
    date: {
      created: {
        timestamp: 1625577021506,
        date: '2021-7-6 15:10:21',
      },
      end: {
        timestamp: 1625583600000,
        date: '2021-07-06T18:00:00+03:00',
      },
      lastUpdated: {
        date: '2021-7-6 15:10:21',
        timestamp: 1625577021509,
      },
      start: {
        date: '2021-07-06T17:00:00+03:00',
        timestamp: 1625580000000,
      },
    },
    googleData: {
      content: {
        summary: 'Test meeting',
      },
      ids: {
        eventId: '2q19sedlmqnc633orhub4g3k4d',
        dataEventId: '',
        meetId: 'umq-gxqv-oqg',
        calendarId: 'ivanmarinchev00@gmail.com',
        recurringEventId: '',
      },
      conferenceData: {
        type: 'undefined',
        link: '',
      },
    },
    meetingId: 'K2iVXQkjCp4dPQdN6Hcd',
    permissions: {
      users: [],
      userGroups: [],
      linkPermissions: 'public_edit',
    },
    tags: {
      meetingSeries: {
        id: '',
        name: '',
      },
      tags: [],
    },
    userRole: {
      canAddAdmin: true,
      canAddCommenter: true,
      canAddEditor: true,
      canAddGroup: true,
      canAddModerator: true,
      canAddUser: true,
      canAddViewer: true,
      canComment: true,
      canDeleteMeeting: true,
      canEdit: true,
      canEditLinkSettings: true,
      canEditMeetingSeries: true,
      canEditTitle: true,
      canRemoveUser: true,
      canView: true,
      isLastAdmin: true,
      role: 'admin',
    },
    version: 2,
  },
  {
    resolvedState: 'resolved',
    attendees: {
      attendees: [
        attendeeV2WithEmail('ivanko57@abv.bg'),
        attendeeV2WithEmailNameAndUserId('ivanmarinchev00@gmail.com', 'Ivan Marinchev', 'ydXu1byTKCW3SsU4TwtiqmBLHQC3'),
      ],
      resolvedState: 'resolved',
    },
    data: {
      attachments: [],
      agenda: [],
      attendees: [
        {
          email: 'ivanko56@abv.bg', responseStatus: 'needsAction',
        },
        {
          responseStatus: 'accepted', email: 'ivanmarinchev00@gmail.com', self: true, organizer: true,
        },
      ],
      description: '',
      postMeetingTasks: [],
      preMeetingTasks: [],
      title: 'Test meeting',
    },
    date: {
      created: {
        timestamp: 1625577021506,
        date: '2021-7-6 15:10:21',
      },
      end: {
        timestamp: 1625583600000,
        date: '2021-07-06T18:00:00+03:00',
      },
      lastUpdated: {
        date: '2021-7-6 15:10:21',
        timestamp: 1625577021509,
      },
      start: {
        date: '2021-07-06T17:00:00+03:00',
        timestamp: 1625580000000,
      },
    },
    googleData: {
      content: {
        summary: 'Test meeting',
      },
      ids: {
        eventId: '2q19sedlmqnc633orhub4g3k4d',
        dataEventId: '',
        meetId: 'umq-gxqv-oqg',
        calendarId: 'ivanmarinchev00@gmail.com',
        recurringEventId: '',
      },
      conferenceData: {
        type: 'undefined',
        link: '',
      },
    },
    meetingId: 'K2iVXQkjCp4dPQdN6Hcd',
    permissions: {
      users: [],
      userGroups: [],
      linkPermissions: 'public_edit',
    },
    tags: {
      meetingSeries: {
        id: '',
        name: '',
      },
      tags: [],
    },
    userRole: {
      canAddAdmin: true,
      canAddCommenter: true,
      canAddEditor: true,
      canAddGroup: true,
      canAddModerator: true,
      canAddUser: true,
      canAddViewer: true,
      canComment: true,
      canDeleteMeeting: true,
      canEdit: true,
      canEditLinkSettings: true,
      canEditMeetingSeries: true,
      canEditTitle: true,
      canRemoveUser: true,
      canView: true,
      isLastAdmin: true,
      role: 'admin',
    },
    version: 2,
  },
  {
    resolvedState: 'resolved',
    attendees: {
      attendees: [
        attendeeV2WithEmail('ivanko57@abv.bg'),
        attendeeV2WithEmailNameAndUserId('ivanmarinchev00@gmail.com', 'Ivan Marinchev', 'ydXu1byTKCW3SsU4TwtiqmBLHQC3'),
      ],
      resolvedState: 'resolved',
    },
    data: {
      attachments: [],
      agenda: [],
      attendees: [
        {
          email: 'ivanko56@abv.bg', responseStatus: 'needsAction',
        },
        {
          responseStatus: 'accepted', email: 'ivanmarinchev00@gmail.com', self: true, organizer: true,
        },
      ],
      description: '',
      postMeetingTasks: [],
      preMeetingTasks: [],
      title: 'Test meeting',
    },
    date: {
      created: {
        timestamp: 1625577021506,
        date: '2021-7-6 15:10:21',
      },
      end: {
        timestamp: 1625583600000,
        date: '2021-07-06T18:00:00+03:00',
      },
      lastUpdated: {
        date: '2021-7-6 15:10:21',
        timestamp: 1625577021509,
      },
      start: {
        date: '2021-07-06T17:00:00+03:00',
        timestamp: 1625580000000,
      },
    },
    googleData: {
      content: {
        summary: 'Test meeting',
      },
      ids: {
        eventId: '2q19sedlmqnc633orhub4g3k4d',
        dataEventId: '',
        meetId: 'umq-gxqv-oqg',
        calendarId: 'ivanmarinchev00@gmail.com',
        recurringEventId: '',
      },
      conferenceData: {
        type: 'undefined',
        link: '',
      },
    },
    meetingId: 'K2iVXQkjCp4dPQdN6Hcd',
    permissions: {
      users: [],
      userGroups: [],
      linkPermissions: 'public_edit',
    },
    tags: {
      meetingSeries: {
        id: '',
        name: '',
      },
      tags: [],
    },
    userRole: {
      canAddAdmin: true,
      canAddCommenter: true,
      canAddEditor: true,
      canAddGroup: true,
      canAddModerator: true,
      canAddUser: true,
      canAddViewer: true,
      canComment: true,
      canDeleteMeeting: true,
      canEdit: true,
      canEditLinkSettings: true,
      canEditMeetingSeries: true,
      canEditTitle: true,
      canRemoveUser: true,
      canView: true,
      isLastAdmin: true,
      role: 'admin',
    },
    version: 2,
  },
];

export default dummyMeetingData;
