import React from 'react';
import { surface } from '../colours';

interface Props {
  fill?: string,
}

const TrashBinIcon = ({ fill }: Props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 15.75C9.58579 15.75 9.25 15.4142 9.25 15L9.25 12C9.25 11.5858 9.58579 11.25 10 11.25C10.4142 11.25 10.75 11.5858 10.75 12L10.75 15C10.75 15.4142 10.4142 15.75 10 15.75Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M14 15.75C13.5858 15.75 13.25 15.4142 13.25 15L13.25 12C13.25 11.5858 13.5858 11.25 14 11.25C14.4142 11.25 14.75 11.5858 14.75 12L14.75 15C14.75 15.4142 14.4142 15.75 14 15.75Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.04326 7.76729C3.79579 7.75041 3.4762 7.75 3 7.75V6.25L3.02525 6.25C3.0417 6.25 3.05805 6.25 3.0743 6.25H20.9257C20.9419 6.25 20.9583 6.25 20.9747 6.25L21 6.25V7.75C20.5238 7.75 20.2042 7.75041 19.9567 7.76729C19.716 7.78372 19.5988 7.81319 19.5216 7.84515C19.2154 7.97202 18.972 8.21536 18.8452 8.52165C18.8132 8.5988 18.7837 8.71602 18.7673 8.95674C18.7504 9.20421 18.75 9.5238 18.75 10L18.75 16.052C18.75 16.9505 18.7501 17.6997 18.6701 18.2945C18.5857 18.9223 18.4 19.4891 17.9445 19.9445C17.4891 20.4 16.9223 20.5857 16.2945 20.6701C15.6997 20.7501 14.9505 20.75 14.052 20.75H9.94801C9.04952 20.75 8.3003 20.7501 7.70552 20.6701C7.07773 20.5857 6.51093 20.4 6.05546 19.9445C5.59999 19.4891 5.41432 18.9223 5.32991 18.2945C5.24994 17.6997 5.24997 16.9505 5.25 16.052L5.25 10C5.25 9.5238 5.24959 9.20421 5.23271 8.95674C5.21628 8.71602 5.18681 8.5988 5.15485 8.52165C5.02798 8.21536 4.78464 7.97202 4.47835 7.84515C4.4012 7.81319 4.28398 7.78372 4.04326 7.76729ZM17.5505 7.75H6.4495C6.48236 7.81441 6.51279 7.88033 6.54067 7.94762C6.66095 8.23801 6.70759 8.53754 6.72923 8.85464C6.75001 9.15925 6.75001 9.53028 6.75 9.97474L6.75 16C6.75 16.964 6.75159 17.6116 6.81654 18.0946C6.87858 18.5561 6.9858 18.7536 7.11612 18.8839C7.24643 19.0142 7.44393 19.1214 7.90539 19.1835C8.38843 19.2484 9.03599 19.25 10 19.25H14C14.964 19.25 15.6116 19.2484 16.0946 19.1835C16.5561 19.1214 16.7536 19.0142 16.8839 18.8839C17.0142 18.7536 17.1214 18.5561 17.1835 18.0946C17.2484 17.6116 17.25 16.964 17.25 16V9.97474C17.25 9.53028 17.25 9.15925 17.2708 8.85464C17.2924 8.53754 17.339 8.23801 17.4593 7.94762C17.4872 7.88033 17.5176 7.81441 17.5505 7.75Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3589 2.36675C12.9536 2.28901 12.477 2.25 12 2.25C11.523 2.25 11.0465 2.28901 10.6412 2.36675C10.4386 2.40562 10.2454 2.45573 10.0743 2.51977C9.91604 2.57898 9.71856 2.67104 9.55653 2.82221C9.25367 3.10479 9.23723 3.57937 9.5198 3.88224C9.79313 4.17518 10.2461 4.20016 10.5495 3.94575C10.5593 3.94104 10.5756 3.93375 10.5999 3.92466C10.6701 3.8984 10.7771 3.86803 10.9238 3.83989C11.2171 3.78363 11.5965 3.75 12 3.75C12.4036 3.75 12.783 3.78363 13.0763 3.83989C13.2229 3.86803 13.33 3.8984 13.4001 3.92466C13.4244 3.93375 13.4408 3.94104 13.4505 3.94575C13.754 4.20016 14.2069 4.17518 14.4803 3.88223C14.7628 3.57937 14.7464 3.10478 14.4435 2.82221C14.2815 2.67104 14.084 2.57898 13.9258 2.51977C13.7546 2.45573 13.5615 2.40562 13.3589 2.36675ZM13.4601 3.95068C13.4608 3.95115 13.4612 3.95137 13.4612 3.95136C13.4613 3.95136 13.4609 3.95113 13.4601 3.95068Z" fill={fill} />
  </svg>
);

TrashBinIcon.defaultProps = {
  fill: surface,
};

export default TrashBinIcon;
