import styled, { css } from 'styled-components';
import CustomizedBullet from '../../welcome-screens-subtitle-bullets-molecule/utils/types';

interface SubtitleBulletContainerProps {
  customized: CustomizedBullet
}

export const SubtitleBulletContainer = styled.li<SubtitleBulletContainerProps>`
  ${({ customized }) => customized === 'checkmark' && (
    css`
      display: inline-flex;

      &::before {
        margin-right: 10px;
        content: '\u2705';
      }
    `
  )};
`;

export const BulletTextContainer = styled.div`
  margin-left: -5px;
`;
