import React from 'react';
import Scrollbar from '../../../../../shared/components/scrollbar';
import { DropdownOption } from '../../utils/types';
import MultiSelectDropdownOption from '../multi-select-dropdown-option-atom';
import MultiSelectDropdownContainer from './utils/styles';

interface MultiSelectDropdownProp {
  dropdownOptions: Array<DropdownOption>,
  selectedOptions: Array<string>,
  isDropdownOpen: boolean,
  /* eslint-disable-next-line */
  onDropdownOptionClick: (option: string) => void,
}

const MultiSelectDropdown = ({
  dropdownOptions,
  selectedOptions,
  isDropdownOpen,
  onDropdownOptionClick,
}: MultiSelectDropdownProp) => {
  if (!isDropdownOpen) return null;

  return (
    <MultiSelectDropdownContainer>
      <Scrollbar maxHeight="400px">
        {dropdownOptions.map((option) => (
          <MultiSelectDropdownOption
            key={option.id}
            option={option}
            selectedOptions={selectedOptions}
            onDropdownOptionClick={onDropdownOptionClick}
          />
        ))}
      </Scrollbar>
    </MultiSelectDropdownContainer>
  );
};

export default MultiSelectDropdown;
