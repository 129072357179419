import styled from 'styled-components';
import { gray1 } from '../../../../../../shared/colours';

const MultiSelectDropdownContainer = styled.ul`
  z-index: 1;
  background: ${gray1};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 48px;

  width: 100%;
  /* max-height: 200px; */
  overflow-y: scroll;
  overflow: hidden;

  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  list-style: none;
  padding: 0;
  margin: 0;
`;

export default MultiSelectDropdownContainer;
