import React from 'react';
import styled from 'styled-components';
import { darkBlue1 } from '../../../../shared/colours';
import ButtonSmall from '../../../../shared/components/button-small';
import Modal from '../../../../shared/components/Modal';
import NotionGif from '../../../../shared/gif/notion_integration.gif';
import { header500, uiTextS } from '../../../../shared/typography';

const Container = styled.div`
 padding: 12px 24px 0px 24px ;
`;

const InstructionGIF = styled.img`
  margin: 32px 20px 32px 20px;
`;

const MainText = styled.p`
  ${header500}
  text-align: center;
`;

const SubText = styled.p`
  ${uiTextS}
  text-align: center;
`;

const ModalLineLocal = styled.div`
  background: ${darkBlue1};
  height: 2px;
  width: 100%;
  margin-top: 24px;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

interface Props {
  isOpen: boolean,
  handleCloseClick: () => void,
  handleNotionRedirect: () => void,
}

export default function NotionNotesExportModal(
  { isOpen, handleCloseClick, handleNotionRedirect }:Props,
) {
  return (
    <Modal title="Notion integration" isOpen={isOpen} setModalClosed={handleCloseClick}>
      <Container>
        <InstructionGIF src={NotionGif} alt="" height={288} width={390} />
        <MainText>Where do my Shepherd notes export to?</MainText>
        <SubText>
          You can find exported meeting notes inside a page called
          “Shepherd Notes” in your Notion workspace.
        </SubText>
      </Container>
      <ModalLineLocal />
      <ButtonRow>
        <ButtonSmall onClick={() => handleCloseClick()} text="Back" isOutline />
        <ButtonSmall onClick={() => handleNotionRedirect()} text="Next" />
      </ButtonRow>
    </Modal>
  );
}
