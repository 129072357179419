import React from 'react';
import styled from 'styled-components';
import { surface } from '../../../colours';
import { uiTextS } from '../../../typography';

const Email = styled.span`
  ${uiTextS};
  color: ${surface};
  display: inline-block;
`;

interface Props {
  email: string,
}

const EmailAtom = ({ email }: Props) => (
  <Email>
    {email}
  </Email>
);

export default EmailAtom;
