import styled from 'styled-components';
import { red6 } from '../../../../../../shared/colours';

const ErrorMessage = styled.p`
  color: ${red6};
  font-size: 10px;
  margin: 0;
  padding-top: 4px;
  height: 10px;
`;

export default ErrorMessage;
