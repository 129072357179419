import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import PreviewIcon from '../../icons/PreviewIcon';
import { uiText } from '../../typography';
import { blue6, gray7 } from '../../colours';
import ReactTooltip from '../tooltip/ReactTooltip';
import Switch from '../switch';

interface Props {
  checked: boolean,
  setChecked: Dispatch<SetStateAction<boolean>>,
}

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 24px 0;
`;

export const ToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0px 0px 0px 16px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

export const SubHeader = styled.h2`
  ${uiText};
  margin: 0;
  color: ${gray7};
  cursor: pointer;
    :hover {
      color: ${blue6};
    }
`;

export default function PrivateTaskToggle({ checked, setChecked }: Props) {
  return (
    <Container>
      <PreviewIcon fill="#200E32" />
      <ToggleContainer>
        <ReactTooltip place="right" tip="So only you can see it">
          <SubHeader onClick={() => { setChecked(!checked); }}>Private</SubHeader>
        </ReactTooltip>
      </ToggleContainer>
      <SwitchContainer>
        <Switch setChecked={setChecked} checked={checked} />
      </SwitchContainer>
    </Container>
  );
}
