import React from 'react';
import styled from 'styled-components';
import { ShareModalMode } from '../utils/types';
import { SharePlatforms } from '../../../../../shared/types/types';
import PlatformIcon from '../Atom/PlatformIcon';
import PlatformText from '../Atom/PlatformText';
import PlatformAction from '../Atom/PlatformAction';
import { layoutGrey } from '../../../../../shared/colours';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: ${layoutGrey};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  platform: SharePlatforms,
  onClick: () => void,
  mode: ShareModalMode | 'coming_soon'
  customText?: string | null
  iconSize?: string,
}

const Platform = ({
  platform, onClick, mode, customText = null, iconSize = '',
}: Props) => (
  <Container onClick={onClick}>
    <LogoContainer>
      <PlatformIcon platform={platform} size={iconSize} />
      <PlatformText platform={platform} customText={customText} />
    </LogoContainer>
    <PlatformAction platform={platform} mode={mode} onClick={() => {}} />
  </Container>
);

export default Platform;

Platform.defaultProps = {
  customText: null,
  iconSize: '40',
};
