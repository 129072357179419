/* eslint-disable no-unused-vars */
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { Scrollbar as ScrollbarType, ScrollStatus } from 'smooth-scrollbar/interfaces';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import AllNotesTemplate from '.';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { GapiMeetingData, UserCenterPage } from '../../shared/types/types';
import { PENDING, USER_CENTER_ALL_NOTES } from '../../utils/enums';
import useMeetingsAndUsersStore from '../../zustand/useAllMeetingsStore';
import { getOrMakeMeetingByEventAndCalendarId } from '../googleCalendar';
import { AuthContext } from '../../App';
import { logAllNotesClickMeeting, logPageEvent } from '../../utils/analytics/eventLogger';
import { SidebarStateContext } from '../../ListenToSidebarMessageContainer';
import { ALL_NOTES_PAGE } from '../../utils/analytics/enums';
import { shouldWeLog } from '../../utils/analytics/functions';

interface Props {
  section: UserCenterPage;
  setSection: Dispatch<SetStateAction<UserCenterPage>>;
}

const AllNotesPageCtrl = ({ section, setSection }: Props) => {
  const authState = useContext(AuthContext);
  const {
    meetings, resolveState,
    fetchMeetings, fetchMeetingsLastXDays,
    fetchMeetingsAgain,
  } = useMeetingsAndUsersStore();
  const [daysAgo, setDaysAgo] = useState<number>(6);
  const [error, setError] = useState<any>({});
  const [meetingsToShow, setMeetingsToShow] = useState<GapiMeetingData[]>([]);
  const [isLoadingGoToMeeting, setIsLoadingGoToMeeting] = useState<boolean>(false);
  const history = useHistory();
  const isSidebarOpen = useContext(SidebarStateContext);
  const { trackEvent } = useIntercom();

  useEffect(() => {
    if (section !== USER_CENTER_ALL_NOTES) return;
    console.log('All Notes fetching data started at', new Date());
    fetchMeetings(daysAgo, authState.userId);
  }, [section]);

  useEffect(() => {
    // Analytics event logging
    if (section !== USER_CENTER_ALL_NOTES) return;
    if (shouldWeLog(authState, isSidebarOpen)) return;
    logPageEvent(authState.userId, trackEvent, ALL_NOTES_PAGE);
  }, [section, isSidebarOpen]);

  useEffect(() => {
    // For some reason we can't pass meetings directly into AllNotesTemplate, so
    // we make this hack by passing meetingsToShow, which is a copy of meetings
    setMeetingsToShow(meetings);
  }, [meetings]);

  const handleGoToMeeting = async (eventId: string, calendarId: string, startDateTime: string) => {
    ConsoleImproved.log('handleToGoMeeting', { eventId, calendarId, startDateTime });
    setIsLoadingGoToMeeting(true);
    logAllNotesClickMeeting(authState.userId, startDateTime);
    await getOrMakeMeetingByEventAndCalendarId(eventId, calendarId, authState, history, setError);
    setTimeout(() => {
      setSection('currentMeeting');
      setIsLoadingGoToMeeting(false);
    }, 100); // Wait 100 to smoothly navigate the user. If not it will flicker
  };

  const onScroll = (status: ScrollStatus, scrollbar: ScrollbarType | null) => {
    if (status.limit.y === status.offset.y) {
      console.log('bottom');
      handleFetchMorePreviousMeetings();
    }
  };

  const handleFetchMeetingsAgain = () => {
    fetchMeetingsAgain(daysAgo, authState.userId);
  };

  const handleFetchMorePreviousMeetings = () => {
    if (isLoadingGoToMeeting) {
      console.log('Not fetching more meetings since we are loading');
      return;
    }
    setDaysAgo((prev) => {
      fetchMeetingsLastXDays(prev + 7, authState.userId);
      return prev + 7;
    });
  };

  const isLoading = resolveState === PENDING || isLoadingGoToMeeting;

  return (
    <AllNotesTemplate
      section={section}
      notes={meetingsToShow}
      isLoading={isLoading}
      onAction={handleGoToMeeting}
      onScroll={onScroll}
      fetchMeetingsAgain={handleFetchMeetingsAgain}
      onClickFetchMoreMeetings={handleFetchMorePreviousMeetings}
    />
  );
};

export default AllNotesPageCtrl;
