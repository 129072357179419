/*
 * This is a temporary file, used for development.
 * Once the database structure is deemed suitable and
 * non-invasive, this will be removed in favour of
 * the shepherd firebase configuration.
 */
// Google Cloud Address
const baseYjsBackendDomain = 'crdt-backend-gcp-pub-sub-6nvpwxyiva-ez.a.run.app';
// const baseYjsBackendDomain = 'localhost:3002';

export const yjs = {
  websocketProvider: `wss://${baseYjsBackendDomain}`,
  // websocketProvider: `wss://${baseYjsBackendDomain}`,
};

export const endpoints = {
  /**
   * In order to generate previews for link's, a new endpoint have been made in
   * cloud run.
   */
  // opengraphData: `http://${baseYjsBackendDomain}/opengraph`,
  opengraphData: `https://${baseYjsBackendDomain}/opengraph`,
};
