import React from 'react';
import styled from 'styled-components';
import { blue6, darkBlue4 } from '../../../../../shared/colours';

const Button = styled.div<ButtonProps>`
  color: ${({ isDismiss }) => (isDismiss ? darkBlue4 : blue6)};
  cursor: pointer;
`;

interface ButtonProps {
  isDismiss: boolean,
}

interface Props {
  onClick: () => void,
  isDismiss?: boolean,
  text: string,
}

const ActionButton = ({ onClick, isDismiss = false, text }: Props) => (
  <Button onClick={onClick} isDismiss={isDismiss}>
    {text}
  </Button>
);

ActionButton.defaultProps = {
  isDismiss: false,
};

export default ActionButton;
