import styled, { css } from 'styled-components';
import { gray8 } from '../../../../../../shared/colours';
import { uiTextM } from '../../../../../../shared/typography';
import CustomizedBullet from './types';

interface SubtitleBulletsContainerProps {
  customized: CustomizedBullet
}

const SubtitleBulletsContainer = styled.ul<SubtitleBulletsContainerProps>`
  ${uiTextM};
  color: ${gray8};

  margin: 25px 0 0 0;
  ${({ customized }) => (customized.length > 0 ? css`list-style: none; padding: 0px;` : css`padding: 0 0 0 20px;`)};};

  li:first-of-type {
    margin-top: 0px;
  }

  @media (max-height: 915px) {
    font-size: 14px;
    line-height: 20px;
    margin: 20px 0 0 0;
  };

  @media (max-height: 860px) {
    font-size: 13px;
    line-height: 19px;
    margin: 15px 0 0 0;
  };
`;

export default SubtitleBulletsContainer;
