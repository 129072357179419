import React from 'react';
import { surface } from '../colours';

interface Props {
  fill?: string,
}

const BlackCheckedBox = ({ fill }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z" fill={fill} />
    <path d="M6 10.2778L8.43478 13L14 6" stroke="white" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

BlackCheckedBox.defaultProps = {
  fill: surface,
};

export default BlackCheckedBox;
