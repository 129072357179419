import React from 'react';
import styled from 'styled-components';
import ProfileIcon from '../../../../shared/components/profile-icon';
import PlusIcon from '../../../../shared/icons/plusIcon';
import { AttendeeV2 } from '../../../../shared/types/types';

const Container = styled.div`
  display: flex;

  & > div:not(:nth-child(1)) {
    margin-left: -5px;
  }
  & > div:nth-child(1) {
    z-index: 10;
  }
  & > div:nth-child(2) {
    z-index: 11;
  }
  & > div:nth-child(3) {
    z-index: 12;
    margin-left: 1px;
  }

  & > div {
    &:hover {
      z-index: 40;
    }
  }
`;

const IconConatiner = styled.div``;

interface Props {
  attendees: AttendeeV2[]
}

const MeetingAttendeesMolecule = ({ attendees }: Props) => {
  if (attendees.length === 0) return null;
  const shepherdAttendees = attendees.filter((attendee) => attendee.userId.length);
  const nonShepherdAttendees = attendees.filter((attendee) => !attendee.userId.length);
  const filteredUsers = shepherdAttendees.concat(nonShepherdAttendees);
  const attendeesToShow = filteredUsers.slice(0, 2);
  const plusIconAttendees = filteredUsers.slice(2, filteredUsers.length);

  return (
    <Container>
      {
        attendeesToShow.map((attendee) => (
          <IconConatiner>
            <ProfileIcon user={attendee} />
          </IconConatiner>
        ))
      }
      {
        plusIconAttendees.length > 0 && (
          <IconConatiner>
            <PlusIcon attendees={plusIconAttendees} />
          </IconConatiner>
        )
      }
    </Container>
  );
};

export default MeetingAttendeesMolecule;
