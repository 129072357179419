import React from 'react';
import styled from 'styled-components';
import { header600, small } from '../../../shared/typography';
import { gray1 } from '../../../shared/colours';

const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-left: 16px;
  color: ${gray1};
`;

const PrimaryText = styled.div`
  ${header600}
  margin-bottom: 2px;
`;

const SecondaryText = styled.div`
  ${small}
`;

interface Props {
  userName: string,
}

const WelcomeMessage = ({ userName }: Props) => (
  <Container>
    <PrimaryText>
      All Tasks
    </PrimaryText>
    <SecondaryText>
      Hey
      {' '}
      {userName}
      !
      {' '}
      Time to supercharge your meetings!
    </SecondaryText>
  </Container>
);

export default WelcomeMessage;
