import React, {
  useContext,
} from 'react';
import * as Sentry from '@sentry/browser';
import { useIntercom } from 'react-use-intercom';
import { AuthContext, UserDataContext } from '../../../../../App';
import { cfSendTaskUpdateNotificationEmail } from '../../../../../database/cloudFunctionEmailAPI';
import { dbUpdateTask } from '../../../../../database/firebaseTasksAPI';
import { EDIT_EVENT, TASKS_MODAL_LOCATION, TASKS_MULTIPLE_FIELD } from '../../../../../utils/analytics/enums';
import { logTasksUserAction } from '../../../../../utils/analytics/eventLogger';
import { toastDanger, toastInfo } from '../../../../../utils/notifications';
import { slackCoreAPISendNotificationForTaskUpdate } from '../../../../../utils/slack/SlackCoreAPI';
import { updateTrelloCard } from '../../../../../utils/trello/trelloUtils';
import { TaskItem, TrelloData } from '../../../../types/types';
import TaskDetailsModal from '../../../task-modal/TaskDetailsModal';
import TaskDeletionConfirmModal from '../../../task-card/TaskDeletionConfirmModal';

interface Props {
  taskItem: TaskItem | null,
  enable?: boolean,
  isTaskDetailsOpen: boolean,
  setTaskDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  isDeleteModalOpen: boolean,
  setDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const TaskModal = ({
  taskItem,
  enable,
  isTaskDetailsOpen,
  setTaskDetailsOpen,
  isDeleteModalOpen,
  setDeleteModalOpen,
}: Props) => {
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const userData = useContext(UserDataContext);
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  if (!taskItem) return <></>;

  const handleUpdateTaskItem = (
    newTaskItem: TaskItem,
    trelloData: TrelloData = userData.integrations.trello,
    isTrelloSyncChecked: boolean,
    callback: any,
    updatedSection: string,
  ) => {
    // TODO: Rather use the new TaskAPI.
    const newTaskItemToUpdate: TaskItem = newTaskItem;
    newTaskItemToUpdate.integrations.trello.isTrelloSyncEnabled = isTrelloSyncChecked;
    dbUpdateTask(taskItem.taskId, newTaskItemToUpdate, callback).then(() => {
      updateTrelloCard(trelloData, newTaskItemToUpdate as TaskItem, 'many', 'all');
      toastInfo('Updated', 'Task updated');

      cfSendTaskUpdateNotificationEmail(newTaskItem, userData)
        .catch((error) => toastDanger('Failed to send notification', error));
      slackCoreAPISendNotificationForTaskUpdate(
        newTaskItemToUpdate,
        authState,
        updatedSection,
      );
      logTasksUserAction(
        authState.userId, EDIT_EVENT,
        TASKS_MODAL_LOCATION, TASKS_MULTIPLE_FIELD, trackEvent,
      );
    }).catch((error) => {
      console.log(taskItem.taskId);
      console.log(taskItem);
      console.error(error);
      Sentry.captureException(error);
      toastDanger('Task Could Not Be Updated', error.message);
    });
  };

  if (!enable) return <></>;

  return (
    <>
      <TaskDetailsModal
        taskItem={taskItem}
        updateTaskItem={handleUpdateTaskItem}
        isOpen={isTaskDetailsOpen}
        setOpen={setTaskDetailsOpen}
        disableCalendarSelect={false}
        disableAssigneeSelect={false}
        openDeleteModal={openDeleteModal}
      />
      <TaskDeletionConfirmModal
        isOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        taskItem={taskItem}
        analyticsFrom={TASKS_MODAL_LOCATION}
      />
    </>
  );
};

TaskModal.defaultProps = {
  enable: true,
};

export default TaskModal;
