import React from 'react';
import styled from 'styled-components';
import {
  gray4, red1, red6, surface,
} from '../../../../shared/colours';
import { uiTextMedium } from '../../../../shared/typography';

const Container = styled.div<TextProps>`
  padding: 6px 0px 6px 8px;
  background-color: ${(props: TextProps) => (props.isDelete ? red1 : 'inherit')};
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: ${gray4};
  }
`;

const Text = styled.h2<TextProps>`
  ${uiTextMedium}
  color: ${(props: TextProps) => (props.isDelete ? red6 : surface)};
  margin: 0px;
`;

interface TextProps {
  isDelete?: boolean,
}

interface Props {
  text: string,
  onClick: () => void,
  isDelete?: boolean,
}

const DropdownItemAtom = ({ text, onClick, isDelete }: Props) => (
  <Container onClick={onClick} isDelete={isDelete}>
    <Text>
      {text}
    </Text>
  </Container>
);

DropdownItemAtom.defaultProps = {
  isDelete: false,
};

export default DropdownItemAtom;
