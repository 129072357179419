import React from 'react';
import styled from 'styled-components';
import { layoutGrey, surface, gray1 } from '../../../../shared/colours';

const Container = styled.div`
  display: flex;
  background-color: ${gray1};
  color: ${surface};
  flex-direction: row;
  align-items: center;
  padding: 10px 16px 10px 14px;
  border-radius: 6px;
  width: 100%;
  :hover {
    background-color: ${layoutGrey};
    cursor: pointer;
  }
`;

const Text = styled.span`
  margin-left: 8px;
`;

interface Props {
  icon: any,
  text: string,
  onClick?: () => void,
}

const DropdownOption = ({ icon, text, onClick }: Props) => (
  <Container onClick={onClick}>
    {icon}
    <Text>
      {text}
    </Text>
  </Container>
);

DropdownOption.defaultProps = {
  onClick: () => { },
};

export default DropdownOption;
