import React from 'react';
import styled from 'styled-components';
import SpotSeparator from '../../icons/SpotSeparator';

const VerticalLineContainer = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  display: boolean;
}

const ProfileIconRowSeperator = ({ display }: Props) => {
  if (!display) return null;
  return (
    <VerticalLineContainer>
      <SpotSeparator />
    </VerticalLineContainer>
  );
};

export default ProfileIconRowSeperator;
