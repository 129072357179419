/* eslint-disable no-unused-vars */
import { findChildrenByAttr } from '@meetshepherd/prosemirror-utils';
import { setDate } from 'date-fns';
import { Moment } from 'moment';
import { createParagraphNear, joinDown, Keymap } from 'prosemirror-commands';
import { Schema } from 'prosemirror-model';
import {
  EditorState, NodeSelection, Selection, TextSelection,
} from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import TaskAPI from '../../../../../external/TaskAPI/TaskAPI';
import { dateToSDateObject } from '../../../../../utils/dateUtils/date';
import lazyTransactionAdapter from '../adapters/lazy-transaction-adapter';
import taskAdapter from '../nodes/task-adapter';

export function createTaskWithNoDueDate(
  state: EditorState<any>,
  dispatch: CallableFunction,
): boolean {
  const sel = state.selection;
  const taskNodeType = (state.schema as Schema).nodes.task;

  if (!sel.empty) return false;
  if (sel.$head.parent.type.name === taskNodeType.name) {
    const taskName = sel.$head.parent.textContent;
    const parentNode = sel.$head.parent;
    const taskIdentifier = parentNode.attrs.id;
    if (parentNode.attrs.taskid) return false;
    if (parentNode.attrs.locked === 'yes') return false;
    if (!taskName) return false;
    const pos = sel.$head.before();
    if (taskAdapter.getCalendarMenuShow()) {
      taskAdapter.setDate();
      return true;
    }
    TaskAPI.createTaskVariant3(
      taskName,
      taskAdapter.getCurrentUser()!.email,
      {
        ...taskAdapter.getCurrentMeetingData()!,
        startDate: dateToSDateObject(new Date()),
        tags: [],
      },
      JSON.parse(parentNode.attrs.userjson).data.email,
    ).then((taskId) => {
      // If the task creation succeeded, update the node
      // with the latest due date, but also mark it as
      // a 'resolved' task by putting in the task id.

      // The lazy transaction attribute for predicate matching
      const trAttr = {
        id: 'task_creation',
      };
      // append the new lazy transaction
      lazyTransactionAdapter.append({
        tr: (v: EditorView) => {
          // find the task (wherever it is)
          const children = findChildrenByAttr(
            v.state.doc,
            (_node) => {
              // this is a problem with the package, the input is NOT a prosemirror NODE
              // the input is the .attr field of a prosemirror node
              const attrs = _node as unknown as Record<string, any>;
              if (!attrs) return false;
              return attrs.id === taskIdentifier;
            },
            true,
          ).filter((nodeWithPos) => nodeWithPos.node.type.name === taskNodeType.name);
          // if it is found apply the transaction
          if (children && children.length >= 1) {
            const targetNode = children[0];

            const newAttrs = taskId ? {
              ...parentNode.attrs,
              taskid: taskId,
              name: taskName,
              locked: 'no',
            } : {
              ...parentNode.attrs,
              locked: 'no',
            };

            v.dispatch(
              v.state.tr.setNodeMarkup(
                targetNode.pos,
                parentNode.type,
                newAttrs,
              ),
            );
            // mark success
            return true;
          }
          // don't forget to correctly mark wether or not it was successful
          return false;
        },
        // Query predicate
        attr: trAttr,
      });
      // Perform the update only on this specific lazy transaction
      lazyTransactionAdapter.clear((att) => att.id === trAttr.id);
    });
    //* this will move the cursor to the next available paragraph
    const para = state.doc.resolve(pos + sel.$head.parent.nodeSize);
    const afterPara = state.doc.resolve(para.after(para.depth));
    const newSel = TextSelection.near(afterPara);
    dispatch!(
      state.tr
        .setSelection(newSel)
        .setNodeMarkup(
          pos,
          parentNode.type,
          {
            ...parentNode.attrs,
            locked: 'yes',
          },
        ),
    );
    return true;
  }
  return false;
}

export default {
  Enter: (state, dispatch) => createTaskWithNoDueDate(state, dispatch!),
  Tab: (state, dispatch, view): boolean => {
    if (!view) return false;
    const sel = state.selection;
    const taskNodeType = (state.schema as Schema).nodes.task;

    if (!sel.empty) return false;
    if (sel.$head.parent.type.name === taskNodeType.name) {
      const taskName = sel.$head.parent.textContent;
      const parentNode = sel.$head.parent;
      if (parentNode.attrs.taskid) return false;
      if (!taskName) return false;
      const taskIdentifier = parentNode.attrs.id;
      const pos = sel.$head.before();
      taskAdapter.calendarMenuShow(
        true,
        (due: Date | Moment | null) => {
          let parsedDue: string;
          if (due instanceof Date) {
            parsedDue = `${+due}`;
          } else if (!due) {
            parsedDue = '';
          } else {
            parsedDue = `${+due}`;
          }
          // If this is not a task already, and has a name.
          // TODO: If you want to allow empty name tasks, here
          // is the place to edit the code.
          let shouldLock = false;
          if (!parentNode.attrs.taskid && parentNode.textContent) {
            const tn = parentNode.textContent;
            if (!JSON.parse(parentNode?.attrs?.userjson || '{}')?.data?.email) return;
            TaskAPI.createTaskVariant3(
              tn,
              taskAdapter.getCurrentUser()!.email,
              {
                ...taskAdapter.getCurrentMeetingData()!,
                startDate: dateToSDateObject(new Date()),
                tags: [],
              },
              JSON.parse(parentNode?.attrs?.userjson)?.data?.email,
              due ? dateToSDateObject(new Date(parseInt(parsedDue, 10))) : undefined,
            ).then((taskId) => {
              const trAttr = {
                id: 'task_calendar_update',
              };
              // append the new lazy transaction
              lazyTransactionAdapter.append({
                tr: (v: EditorView) => {
                  // TODO duplicate code. Refactor
                  //  make helper functions for dom related node identifications by attributes
                  //  with regard to this context usage

                  // find the task (wherever it is)
                  const children = findChildrenByAttr(
                    v.state.doc,
                    (_node) => {
                      // this is a problem with the package, the input is NOT a prosemirror NODE
                      // the input is the .attr field of a prosemirror node
                      const attrs = _node as unknown as Record<string, any>;
                      if (!attrs) return false;
                      return attrs.id === taskIdentifier;
                    },
                    true,
                  ).filter((nodeWithPos) => nodeWithPos.node.type.name === taskNodeType.name);
                  // if it is found apply the transaction
                  if (children && children.length >= 1) {
                    const targetNode = children[0];

                    // If task creation failed, keep modifications.
                    // Maybe the user will succeed on the second attempt,
                    // and they expect updated data.
                    // The lazy transaction attribute for predicate matching
                    const newAttrs = !taskId ? {
                      ...parentNode.attrs,
                      due: parsedDue,
                      locked: 'no',
                    } : {
                      ...parentNode.attrs,
                      taskid: taskId,
                      due: parsedDue,
                      name: tn,
                      locked: 'no',
                    };
                    // If the task creation succeeded, update the node
                    // with the latest due date, but also mark it as
                    // a 'resolved' task by putting in the task id.

                    v.dispatch(
                      v.state.tr.setNodeMarkup(
                        targetNode.pos,
                        parentNode.type,
                        newAttrs,
                      ),
                    );
                    // mark success
                    return true;
                  }
                  // don't forget to correctly mark wether or not it was successful
                  return false;
                },
                // Query predicate
                attr: trAttr,
              });
              // Perform the update only on this specific lazy transaction
              lazyTransactionAdapter.clear((att) => att.id === trAttr.id);
            });
            shouldLock = true;
          } else if (parentNode.attrs.taskid) {
            TaskAPI.updateDueDate(
              parentNode.attrs.taskid,
              new Date(parseInt(parsedDue, 10)),
            ).then((result) => {
              if (result === 'resolved') {
                const trAttr = {
                  id: 'task_calendar_update',
                };
                // append the new lazy transaction
                lazyTransactionAdapter.append({
                  tr: (v: EditorView) => {
                    // TODO duplicate code. Refactor
                    //  make helper functions for dom related node identifications by attributes
                    //  with regard to this context usage

                    // find the task (wherever it is)
                    const children = findChildrenByAttr(
                      v.state.doc,
                      (_node) => {
                        // this is a problem with the package, the input is NOT a prosemirror NODE
                        // the input is the .attr field of a prosemirror node
                        const attrs = _node as unknown as Record<string, any>;
                        if (!attrs) return false;
                        return attrs.id === taskIdentifier;
                      },
                      true,
                    ).filter((nodeWithPos) => nodeWithPos.node.type.name === taskNodeType.name);
                    // if it is found apply the transaction
                    if (children && children.length >= 1) {
                      const targetNode = children[0];

                      const newAttrs = {
                        ...parentNode.attrs,
                        due: parsedDue,
                        locked: 'no',
                      };

                      v.dispatch(
                        v.state.tr.setNodeMarkup(
                          targetNode.pos,
                          parentNode.type,
                          newAttrs,
                        ),
                      );
                      // mark success
                      return true;
                    }
                    // don't forget to correctly mark wether or not it was successful
                    return false;
                  },
                  // Query predicate
                  attr: trAttr,
                });
                // Perform the update only on this specific lazy transaction
                lazyTransactionAdapter.clear((att) => att.id === trAttr.id);
              }
            });
            shouldLock = true;
          }
          if (shouldLock) {
            const trAttr = {
              id: 'task_calendar_lock_update',
            };
            // append the new lazy transaction
            lazyTransactionAdapter.append({
              tr: (v: EditorView) => {
                // TODO duplicate code. Refactor
                //  make helper functions for dom related node identifications by attributes
                //  with regard to this context usage

                // find the task (wherever it is)
                const children = findChildrenByAttr(
                  v.state.doc,
                  (_node) => {
                    // this is a problem with the package, the input is NOT a prosemirror NODE
                    // the input is the .attr field of a prosemirror node
                    const attrs = _node as unknown as Record<string, any>;
                    if (!attrs) return false;
                    return attrs.id === taskIdentifier;
                  },
                  true,
                ).filter((nodeWithPos) => nodeWithPos.node.type.name === taskNodeType.name);
                // if it is found apply the transaction
                if (children && children.length >= 1) {
                  const targetNode = children[0];
                  const newAttrs = {
                    ...parentNode.attrs,
                    locked: 'yes',
                  };

                  const para = state.doc.resolve(targetNode.pos + targetNode.node.nodeSize);
                  const afterPara = state.doc.resolve(para.after(para.depth));
                  const newSel = TextSelection.near(afterPara);

                  v.dispatch(
                    v.state.tr
                      .setSelection(newSel)
                      .setNodeMarkup(
                        targetNode.pos,
                        parentNode.type,
                        newAttrs,
                      ),
                  );
                  // Element does not expose .focus(), however, we know it is a DOM element
                  // @ts-ignore
                  v.dom.focus();
                  // mark success
                  return true;
                }
                // don't forget to correctly mark wether or not it was successful
                return false;
              },
              // Query predicate
              attr: trAttr,
            });
            // Perform the update only on this specific lazy transaction
            lazyTransactionAdapter.clear((att) => att.id === trAttr.id);
          }
        },
        parentNode.attrs.due ? new Date(parseInt(parentNode.attrs.due, 10)) : null,
        view.coordsAtPos(pos).bottom,
        view.coordsAtPos(pos).left,
      );
      return true;
    }
    return false;
  },
} as Keymap;
