import React, { useState } from 'react';
import ActiveTickIcon from '../../../../shared/icons/integrations/ActiveTickIcon';
import ThreeDotsIcon from '../../../../shared/icons/integrations/ThreeDotsIcon';
import {
  ActiveText, AppContainer, IntegratedContainer, MenuActiveContainer, MenuContainer, NameText,
} from '../integrations/utils/commonStyles';
import OptionsDropdown, { OptionsDropdownItem } from '../integrations/OptionsDropdown';
import NotionWorkspaceModal from './NotionWorkspaceModal';
import { red6, surface } from '../../../../shared/colours';
import { notionAuthUrl } from '../../../../utils/notion/notionUtils';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import { notionAPIRemoveNotionIntegration } from '../../../../database/firebaseNotionAPI';
import { NotionIcon } from '../../../../shared/icons/integration-icons';

interface Props {
  enable: boolean
  dropDownRef: React.MutableRefObject<null>,
  userId: string,
}

export default function NotionIntegrated({
  enable, dropDownRef, userId,
}: Props) {
  const [isNotionDropdownActive,
    setIsNotionDropdownActive] = useDetectOutsideClick(dropDownRef, false);
  const [notionWorkspaceModal, setNotionWorkspaceModal] = useState(false);

  const handleDisableNotion = () => {
    notionAPIRemoveNotionIntegration(userId);
    setIsNotionDropdownActive(false);
  };

  const closeNotionWorkspaceModal = () => {
    setNotionWorkspaceModal(false);
  };

  const handleTreeDotsClick = () => {
    setIsNotionDropdownActive(!isNotionDropdownActive);
  };

  const notionDropdownOptions: OptionsDropdownItem[] = [
    {
      id: 1, value: 'Switch Notion workspace', color: surface, onClick: () => window.open(notionAuthUrl),
    },
    {
      id: 2, value: 'Disable', color: red6, onClick: () => handleDisableNotion(),
    },
  ];
  if (!enable) return <></>;
  return (
    <>
      <IntegratedContainer>
        <AppContainer>
          <NotionIcon width="28" />
          <NameText>Notion (Beta)</NameText>
        </AppContainer>
        <MenuActiveContainer>
          <ActiveTickIcon />
          <ActiveText>
            Active
          </ActiveText>
          <MenuContainer>
            <ThreeDotsIcon onClick={() => handleTreeDotsClick()} />
            <OptionsDropdown
              optionsRef={dropDownRef}
              isOpen={isNotionDropdownActive}
              options={notionDropdownOptions}
            />
          </MenuContainer>
        </MenuActiveContainer>
      </IntegratedContainer>
      <NotionWorkspaceModal
        isOpen={notionWorkspaceModal}
        setModalClosed={closeNotionWorkspaceModal}
      />
    </>
  );
}
