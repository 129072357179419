import React, { useContext } from 'react';
import { useIntercom } from 'react-use-intercom';
import ButtonSmall from '../button-small';
import Modal from '../Modal';
import { dbDeleteTask } from '../../../database/firebaseTasksAPI';
import ModalFooterButtonsRightAlignedWrapper from '../Modal/ModalFooterButtonsRightAlignedWrapper';
import ModalBodyWrapper from '../Modal/ModalBodyWrapper';
import { toastDanger } from '../../../utils/notifications';
import { logTasksUserAction } from '../../../utils/analytics/eventLogger';
import { AuthContext, UserDataContext } from '../../../App';
import { cfSendTaskDeleteNotificationEmail } from '../../../database/cloudFunctionEmailAPI';
import { TaskItem } from '../../types/types';
import { deleteTrelloTask } from '../../../utils/trello/trelloAPIs';
import { isTrelloSyncAvailable } from '../../../utils/trello/trelloUtils';
import { DELETE_EVENT } from '../../../utils/analytics/enums';
import { slackCoreAPISendNotificationForTaskDelete } from '../../../utils/slack/SlackCoreAPI';

interface Props {
  isOpen: boolean,
  closeModal: () => void,
  taskItem: TaskItem,
  analyticsFrom: string,
}

const TaskDeletionConfirmModal = ({
  isOpen, closeModal, taskItem, analyticsFrom,
}: Props) => {
  const { trackEvent } = useIntercom();
  const userData = useContext(UserDataContext);
  const authState = useContext(AuthContext);

  const handleDeleteTask = () => {
    dbDeleteTask(taskItem.taskId).then(() => {
      logTasksUserAction(authState.userId, DELETE_EVENT, analyticsFrom, '', trackEvent);
      toastDanger('Deleted', 'Task deleted');
      if (isTrelloSyncAvailable(taskItem)) {
        deleteTrelloTask(taskItem.integrations.trello.trelloTaskId, taskItem.data.assignee.email);
      }
      slackCoreAPISendNotificationForTaskDelete(
        taskItem,
        authState,
      );
      cfSendTaskDeleteNotificationEmail(taskItem, userData)
        .catch((error) => toastDanger('Failed to send notification', error));
    })
      .finally(() => {
        closeModal();
      });
  };

  return (
    <Modal isOpen={isOpen} setModalClosed={closeModal} title="Confirm deletion">
      <ModalBodyWrapper>
        <p>
          Are you sure you want to delete
          {' '}
          {'\''}
          <b>{taskItem.data.title}</b>
          {'\''}
          ?
        </p>
      </ModalBodyWrapper>
      <ModalFooterButtonsRightAlignedWrapper>
        <ButtonSmall onClick={closeModal} text="Cancel" isOutline />
        <ButtonSmall
          type="button"
          onClick={handleDeleteTask}
          text="Delete"
        />
      </ModalFooterButtonsRightAlignedWrapper>
    </Modal>
  );
};

export default TaskDeletionConfirmModal;
