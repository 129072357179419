import React from 'react';
import { gray1 } from '../colours';

const GoogleIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M25.9861 13.2434C25.9861 12.1781 25.8976 11.4008 25.7062 10.5947H13.2588V15.4025H20.5652C20.4179 16.5974 19.6224 18.3967 17.8547 19.6058L17.83 19.7668L21.7656 22.7462L22.0383 22.7727C24.5424 20.5127 25.9861 17.1875 25.9861 13.2434Z" fill="#4285F4" />
      <path d="M13.2586 25.9107C16.8381 25.9107 19.8431 24.759 22.0381 22.7726L17.8545 19.6057C16.735 20.3686 15.2325 20.9012 13.2586 20.9012C9.75269 20.9012 6.7771 18.6413 5.71639 15.5176L5.56091 15.5305L1.46856 18.6254L1.41504 18.7708C3.59516 23.0028 8.07332 25.9107 13.2586 25.9107Z" fill="#34A853" />
      <path d="M5.7174 15.518C5.43752 14.7119 5.27555 13.8482 5.27555 12.9557C5.27555 12.0632 5.43752 11.1995 5.70268 10.3934L5.69526 10.2218L1.55164 7.07715L1.41606 7.14016C0.517533 8.89635 0.00195312 10.8685 0.00195312 12.9557C0.00195312 15.043 0.517533 17.015 1.41606 18.7712L5.7174 15.518Z" fill="#FBBC05" />
      <path d="M13.2585 5.00936C15.748 5.00936 17.4273 6.06017 18.3848 6.93832L22.1264 3.36838C19.8285 1.28114 16.838 0 13.2585 0C8.0733 0 3.59516 2.90773 1.41504 7.13977L5.70165 10.393C6.77709 7.26937 9.75266 5.00936 13.2585 5.00936Z" fill="#EB4335" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="26" height="26" fill={gray1} />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleIcon;
