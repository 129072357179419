import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { gray9 } from '../../shared/colours';
import { header200 } from '../../shared/typography';

const Container = styled.div`
  ${header200};
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${gray9};
  cursor: default;
`;

interface Props {
  date: string,
}

const getDay = (date: string) => {
  const ref = moment(new Date());
  const today = ref.clone().startOf('day');
  const yesterday = ref.clone().subtract(1, 'days').startOf('day');
  const tomorrow = ref.clone().add(1, 'days').startOf('day');
  const dateMoment = moment(date);
  if (dateMoment.isSame(today, 'day')) {
    return 'Today';
  }
  if (dateMoment.isSame(yesterday, 'day')) {
    return 'Yesterday';
  }
  if (dateMoment.isSame(tomorrow, 'day')) {
    return 'Tomorrow';
  }
  return dateMoment.format('dddd DD MMMM');
};

const DayLabelAtom = ({ date }: Props) => (

  <Container>
    {getDay(date)}
  </Container>
);

export default DayLabelAtom;
