/* eslint-disable no-unused-vars */
import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase';
import {
  AuthState, User, MeetingRole, PublicUserData,
  SimpleUserData,
  SlackNotificationsSettings,
  MeetingData,
  ResolveState,
  SlackData,
  ResolvedState,
} from '../shared/types/types';
import { dateISOObject, dateObject } from '../utils/dateUtils/date';
import { firestore, functions } from '../utils/firebase';
import {
  mapAuthStateToDatabaseUser, mapDatabaseDataToUser, rejectedUser,
} from '../utils/user/UserDataUtils';
import { handleOnSnapshotError, handleUpdateError } from './firebaseHandleError';
import { logDatabaseEvent } from '../utils/analytics/eventLogger';
import { toastDanger } from '../utils/notifications';
import { defaultTrelloData } from '../utils/trello/trelloUtils';
import {
  CREATE_EVENT, DATABASE_PHOTO_URL_FIELD, DATABASE_USER_DOCUMENT, UPDATE_EVENT,
} from '../utils/analytics/enums';
import dbUpdateFriendListCore from './firebaseUsersAPICore';
import dbGetUserWithFriendList from '../external/FriendListV2/dbFriendListV2Api';
import { cfSendWelcomeEmailAPI } from './cloudFunctionEmailAPI';
import { mapOnboardingFormValuesToDatabaseUserOnboardingFormat } from '../pages/onboarding/personal/utils/functions';
import { PersonalOnboardingData } from '../pages/onboarding/personal/utils/types';
import { DEFAULT_USER_SETTINGS, mapDatabaseUserSettingsToUserSettings } from '../utils/user/UserSettingsUtils';
import { isKnownUser } from '../utils/user/UserIds';

type SetUserType = Dispatch<SetStateAction<User>>

export const dbUserListenToMyUserData = (
  authState: AuthState, setUserData: SetUserType,
) => {
  if (authState.userState !== 'loggedIn') return () => {};
  if (authState.userId.length === 0) return () => {};
  return firestore()
    .collection('users')
    .doc(authState.userId)
    .onSnapshot(async (snapshot: any) => {
      if (!snapshot.exists) {
        dbCreateUserData(authState);
        return;
      }
      const user = mapDatabaseDataToUser(snapshot.data(), authState.userId);

      dbUpdatePhotoUrlIfNotUpdated(user, authState);
      dbUpdateNameIfNotUpdated(user, authState);

      setUserData(user);

      const userWithFriendListv2 = await dbGetUserWithFriendList(user, snapshot.data());
      setUserData(userWithFriendListv2);
    }, (error) => {
      Sentry.captureException(error);
      handleOnSnapshotError('Something went wrong while listening to user data');
    });
};

const dbUpdatePhotoUrlIfNotUpdated = (user: User, authState: AuthState) => {
  const { photoUrl } = user.data;
  if (photoUrl.length === 0) {
    console.log('photoUrl does not exist for an existing profile');
    dbUpdateUserPhotoUrlField(authState, authState.photoUrl);
  }
};

const dbUpdateNameIfNotUpdated = (user: User, authState: AuthState) => {
  const { firstName, lastName } = user.data;
  if (firstName.length === 0 && lastName.length === 0) {
    console.log('Name is not updated');
    dbPopulateUserNamesWithAuthData(authState);
  }
};
export const dbUpdateUserPhotoUrlField = (authState: AuthState, photoUrl: string) => {
  const newPictureUrlField = { 'data.photoUrl': photoUrl };

  firestore()
    .collection('users')
    .doc(authState.userId)
    .update(newPictureUrlField)
    .then(() => {
      console.log('photoUrl field successfully updated for user');
      logDatabaseEvent(authState.userId, UPDATE_EVENT, DATABASE_PHOTO_URL_FIELD);
    })
    .catch((err) => {
      console.log('error updating photoUrl field for user');
      console.log(err);
      Sentry.captureException(err);
    });
};

/**
 * We've had issues with the User data being set even though the user has already
 * data. So we've made this to a transaction where we first check if the document exist.
 */
export const dbCreateUserData = async (authState: AuthState) => {
  const databaseUser = mapAuthStateToDatabaseUser(authState);

  const userDocRef = firestore().collection('users').doc(authState.userId);

  return firestore()
    .runTransaction((transaction) => transaction.get(userDocRef).then((userDoc) => {
      if (userDoc.exists) return;
      transaction.set(userDocRef, databaseUser);
    }))
    .then(() => {
      console.log('Set user data first first time');
      logDatabaseEvent(authState.userId, CREATE_EVENT, DATABASE_USER_DOCUMENT);
    })
    .catch((err) => {
      console.log('error setting profile url');
      console.log(err);
      Sentry.captureException(err);
      toastDanger('Error', 'Failed to create user data. Please try again');
    });
};

export const dbUserUpdateInfo = (
  userId: string, updates: any,
) : Promise<ResolveState> => firestore()
  .collection('users')
  .doc(userId)
  .update(updates)
  .then(() => 'resolved' as ResolveState)
  .catch((error) => {
    Sentry.captureException(error);
    handleUpdateError('Something went wrong while updating user data', { userId, updates });
    return 'rejected' as ResolveState;
  });

/**
 * @userId the userId of the user
 * @isWelcomeEmailSent the value we update the database with
 */
export const dbUserSetWelcomeEmailSentStatus = async (
  userId: string,
  isWelcomeEmailSent: boolean,
) => {
  const updates = { 'data.receivedWelcomeEmail': isWelcomeEmailSent };
  return dbUserUpdateInfo(userId, updates);
};

export const dbUserUpdateHasSeenNewFeatures = (userId: string, newFeatureId: string) => {
  const ref = firestore()
    .collection('users')
    .doc(userId);

  return ref.update({
    'data.newFeaturesViewed': firebase.firestore.FieldValue.arrayUnion(newFeatureId),
  });
};

export const dbGetSimpleUserDataByUserId = async (userId: string, role: MeetingRole) => {
  const publicUserData = await dbGetPublicUserDataByUserId(userId);
  const simpleUserData: SimpleUserData = {
    access: true,
    userId,
    role,
    name: publicUserData.name,
    email: publicUserData.email,
    date: {
      added: dateObject(),
      created: dateObject(),
      lastViewed: dateObject(),
    },
  };
  return simpleUserData;
};

// TODO: Remove?
export const dbGetPublicUserDataByUserId = async (userId: string) => {
  // TODO HARALD: Refactor
  const publicUserData: PublicUserData = await firestore()
    .collection('users')
    .doc(userId)
    .collection('public_user_data')
    .doc(userId)
    .get()
    .then((docRef) => ({
      name: docRef.data()?.name ?? '',
      email: docRef.data()?.email ?? '',
      userId,
      photoUrl: docRef.data()?.photoUrl ?? '',
      integrations: {
        slack: [{
          version: docRef.data()?.integrations?.slack[0]?.version ?? 1,
          userAccessToken: docRef.data()?.integrations?.slack[0]?.userAccessToken ?? '',
          botAccessToken: docRef.data()?.integrations?.slack[0]?.botAccessToken ?? '',
          userId: docRef.data()?.integrations?.slack[0]?.userId ?? '',
          defaultChannels: docRef.data()?.integrations?.slack[0]?.defaultChannels ?? [],
          notifications: {
            mentionedInNotes:
              docRef.data()?.integrations?.slack?.notifications?.mentionedInNotes ?? false,
            meetingStartsSoon:
              docRef.data()?.integrations?.slack?.notifications?.meetingStartsSoon ?? false,
            taskOverdue: docRef.data()?.integrations?.slack?.notifications?.taskOverdue ?? false,
            taskCreated: docRef.data()?.integrations?.slack?.notifications?.taskCreated ?? false,
            taskUpdated: docRef.data()?.integrations?.slack?.notifications?.taskUpdated ?? false,
            taskDeleted: docRef.data()?.integrations?.slack?.notifications?.taskDeleted ?? false,
          },
          date: {
            created: docRef.data()?.integrations?.slack[0]?.date?.created ?? dateISOObject(),
            updated: docRef.data()?.integrations?.slack[0]?.date?.created ?? dateISOObject(),
          },
        } as SlackData],
        notion: docRef.data()?.integrations?.notion ?? [],
        trello: docRef.data()?.integrations?.trello ?? defaultTrelloData,
        jira: {},
      },
    }))
    .catch((error) => {
      console.log('Something went wrong while fetching public user data');
      console.log(error);
      Sentry.captureException(error);
      return {
        version: 1,
        name: '',
        email: '',
        userId,
        photoUrl: '',
        integrations: {
          slack: [{
            version: 1,
            userAccessToken: '',
            botAccessToken: '',
            userId: '',
            defaultChannels: [],
            notifications: {
              meetingStartsSoon: false,
              mentionedInNotes: false,
              taskOverdue: false,
              taskCreated: false,
              taskUpdated: false,
              taskDeleted: false,
            },
            date: {
              created: dateISOObject(),
              updated: dateISOObject(),
            },
          } as SlackData],
          notion: [],
          trello: defaultTrelloData,
          jira: {},
        },
      };
    });
  return publicUserData;
};

export const dbPopulateUserNamesWithAuthData = (authState: AuthState) => {
  dbUserUpdateInfo(authState.userId, {
    'data.firstName': authState.firstName,
    'data.lastName': authState.lastName,
    'data.name': `${authState.firstName} ${authState.lastName}`,
  });
};

type DatabaseSimpleUser = {
  userId: string;
  email: string;
  name: string;
}

export const cfSearchUserIdAndNameByEmails = async (emails: string[]) => functions()
  .httpsCallable('searchUserIDAndNameByEmail')({ emails })
  .then((userData) => userData.data.map((user: DatabaseSimpleUser) => user.email) as string[])
  .catch((error) => {
    console.log('Error searching userId by email', error);
    Sentry.captureException(error);
    return [] as string[];
  });

export const cfSearchUserSettingByEmail = async (email: string) => functions()
  .httpsCallable('searchUserSettingByEmail')({ email })
  .then((response) => mapDatabaseUserSettingsToUserSettings(response.data))
  .catch(() =>
    // console.log('Error searching user setting by email', { ...error, email });
    // Sentry.captureException({ ...error, email });
    // eslint-disable-next-line implicit-arrow-linebreak
    DEFAULT_USER_SETTINGS);

// TODO: Remove
export const cfUpdateUserFriendList = async (
  meetingData: MeetingData, userId: string,
) => {
  if (meetingData.resolvedState !== 'resolved') return;
  if (meetingData.meetingId.length === 0) return;
  if (meetingData.attendees.attendees.length === 0) return;
  console.log('updating friend list v1');
  console.log(meetingData.attendees);
  dbUpdateFriendListCore(meetingData.data.attendees, userId);
};

export const dbGetUserByUserId = async (userId: string) => {
  const databaseUser: User = await firestore()
    .collection('users')
    .doc(userId)
    .get()
    .then((docRef) => mapDatabaseDataToUser(docRef.data(), userId))
    .catch((error) => {
      console.log('Something went wrong while fetching database user data');
      console.log(error);
      Sentry.captureException(error);
      return rejectedUser;
    });
  return databaseUser;
};

export const dbUpdateUserSlackNotifications = (
  slackNotifications: SlackNotificationsSettings,
  userId: string,
) => {
  const updateData = {
    'integrations.slack.notifications': slackNotifications,
  };
  firestore()
    .collection('users')
    .doc(userId)
    .update(updateData)
    .then(() => {
      console.log('slack notifications updated successfully');
    })
    .catch((error) => {
      console.log(error);
    });
};

export const dbUserRemoveWhatsNewId = (userId: string, whatsNewId: string) => {
  const ref = firestore()
    .collection('users')
    .doc(userId);

  return ref.update({
    'data.newFeaturesViewed': firebase.firestore.FieldValue.arrayRemove(whatsNewId),
  })
    .catch((error) => {
      console.log(error);
      Sentry.captureException(error);
    });
};

// TODO Move to useState for userData, so we can check if the value is false
export const sendWelcomeEmailIfNotSent = async (authState: AuthState) => {
  const docRef = firestore().collection('users').doc(authState.userId);

  return firestore().runTransaction((transaction) => transaction.get(docRef)
    .then(async (doc) => {
      if (!doc.exists) throw new Error('User document does not exist');

      const userData = mapDatabaseDataToUser(doc.data(), doc.id);
      const { hasOnboarded, receivedWelcomeEmail } = userData.data;

      if (!isInitialUserDataSet(userData)) {
        console.log('User has not initial user data set');
        throw new Error('Transaction failed, user has not set initial data');
      }

      if (!hasOnboarded) {
        console.log('User has not onboarded');
        throw new Error('Transaction failed, user has not onboarded');
      }

      if (receivedWelcomeEmail) {
        console.log('User has already received the welcome email');
        throw new Error('Transaction failed, user has already received the welcome email');
      }

      transaction.update(docRef, { 'data.receivedWelcomeEmail': true });
    }))
    .then(async () => {
      const sendWelcomeEmailResolvedState = await cfSendWelcomeEmailAPI(authState.firstName);
      if (sendWelcomeEmailResolvedState === 'resolved') {
        console.log('Successfully sent welcome email and updated status in database');
      } else {
        console.log('Failed to send welcome email');
        dbUserSetWelcomeEmailSentStatus(authState.userId, false);
        return 'rejected';
      }
      return 'resolved' as ResolvedState;
    }).catch((error: Error) => {
      if (error.message === 'Transaction failed, user has already received the welcome email') return 'rejected';
      console.log('Error sending welcome email and updating status in database');
      Sentry.captureException(error);
      return 'rejected' as ResolvedState;
    });
};

/**
 * The first time the user logs in, we set the initial use data.
 * i.e. we set the hasOnboarded to false
 * @return if the user has set the initial user data
 */
const isInitialUserDataSet = (userData: User): boolean => userData.data.name.length > 0
  && userData.data.email.length > 0;

export const dbUpdateUserOnboardingData = async (
  onboardingValues: PersonalOnboardingData,
  authState: AuthState,
  logAnalytics: () => void,
  /* eslint-disable-next-line */
  setUserOnboardingPropertiesInMixpanel: (values: PersonalOnboardingData) => void,
) => {
  const dbOnboardingObj = mapOnboardingFormValuesToDatabaseUserOnboardingFormat(onboardingValues);
  const docRef = firestore().collection('users').doc(authState.userId);

  return firestore().runTransaction((transaction) => transaction.get(docRef)
    .then(async (doc) => {
      if (!doc.exists) throw new Error('User document does not exist');

      const userData = mapDatabaseDataToUser(doc.data(), doc.id);
      const { hasOnboarded } = userData.data;

      if (!isInitialUserDataSet(userData)) {
        console.log('User has not initial user data set');
        throw new Error('Transaction failed, user has not set initial data');
      }

      // Known users can go through the onboarding multiple times
      if (hasOnboarded && !isKnownUser(authState.userId)) {
        console.log('User has already onboarded');
        throw new Error('Transaction failed, user has already onboarded');
      }
      transaction.update(docRef, dbOnboardingObj);
    }))
    .then(() => {
      console.log('Successfully saved onboarding data');
      logAnalytics();
      setUserOnboardingPropertiesInMixpanel(onboardingValues);
      return 'resolved' as ResolvedState;
    }).catch((error) => {
      console.log('Onboarding transactions failed: ', error);
      console.log('Error saving onboarding data');
      Sentry.captureException(error);
      return 'rejected' as ResolvedState;
    });
};
