import React from 'react';
import styled from 'styled-components';
import {
  gray1, gray4, red6, red1, gray9,
} from '../../../../shared/colours';
import { uiTextMedium } from '../../../../shared/typography';

const Container = styled.div`
  position: absolute;
  margin-top: 30px;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  list-style: none;
  padding: 6px;
  margin: 0px;

  font-size: ${uiTextMedium};

  background-color: ${gray1};
  border-radius: 8px;
  border: none;
  box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.25);
`;

const Option = styled.div<{isEdit:boolean}>`
  padding: 6px 8px;
  border-radius: 8px;
  color: ${({ isEdit }) => (isEdit ? gray9 : red6)};

  &:hover {
    cursor: pointer;
    background-color: ${({ isEdit }) => (isEdit ? gray4 : red1)};
  }
`;

interface Props {
  isDropDownOpen: boolean,
  dropDownRef: React.MutableRefObject<null>
  handleEditClick: () => void
  handleDeleteClick: () => void
}

const DropDownOptions = ({
  isDropDownOpen, dropDownRef, handleEditClick, handleDeleteClick,
}: Props) => {
  if (isDropDownOpen) {
    return (
      <Container ref={dropDownRef}>
        <OptionsContainer>
          <Option isEdit onClick={handleEditClick}>
            Edit
          </Option>
          <Option isEdit={false} onClick={handleDeleteClick}>
            Delete
          </Option>
        </OptionsContainer>
      </Container>
    );
  }

  return null;
};

export default DropDownOptions;
