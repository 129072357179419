// TODO: Make this strongly typed
const OnboardingWelcomeScreensModel = {
  formId: 'WelcomeModel',
  value: {
    prepareNotes: {
      id: '1',
      title: 'Prepare for meetings from ',
      highlightedTitle: 'Google Calendar',
      subtitle: ['Use your calendar to move between meeting events', 'Quickly create and add custom meeting agendas', 'Prepare notes before the meeting begins'],
    },
    takeNotes: {
      id: '2',
      title: 'Use Shepherd from within ',
      highlightedTitle: 'Google Meet',
      subtitle: ['Open the sidebar from the video', 'Take collaborative and private notes, assign tasks to your team, and more'],
    },
    createTasks: {
      id: '3',
      title: 'Create and ',
      highlightedTitle: 'assign new tasks',
      subtitle: ['Assign tasks to you and your team', 'See a full list of your own tasks from every meeting'],
    },
  },
  integration: {
    id: '4',
    title: 'Connect your tools',
    subtitle: 'Integrate Shepherd with your team’s favourite tools.',
    apps: [
      { id: '1', name: 'Slack', integratable: true },
      { id: '2', name: 'Trello', integratable: true },
      // { id: '3', name: 'Notion', integratable: true },
      { id: '4', name: 'Jira', integratable: false },
      { id: '5', name: 'Zoom', integratable: false },
    ],
  },
  video: {
    id: '5',
    title: 'Video Tutorial',
    subtitle: 'Take a look at a step-by-step tutorial below.',
    videoSrc: 'https://www.youtube.com/embed/2T6rxgUzZxw', // Fancy video
  },
};

export default OnboardingWelcomeScreensModel;
