import React, { useState } from 'react';
import Header from '../../../shared/components/header';
import WelcomeScreensSwitcher from './components/welcome-screens-switcher';
import WelcomeScreensNavigation from './components/welcome-screens-navigation-molecule';
import { WelcomeContainer } from './utils/styles';
import { CALENDAR_SCREEN, TOTAL_NUM_WELCOME_SCREENS, VIDEO_SCREEN } from './utils/constants';
import { WelcomeScreens } from './utils/types';
import { switchToNextWelcomeScreen, switchToPreviousWelcomeScreen } from './utils/functions';
import { HEADER_ONBOARDING_VIEW } from '../../../shared/components/header/utils/constants';

const OnboardingWelcomeScreens = () => {
  const [activeScreen, setActiveScreen] = useState<WelcomeScreens>(CALENDAR_SCREEN);

  const navigateToPreviousScreen = () => {
    if (activeScreen === CALENDAR_SCREEN) return;

    setActiveScreen((prev) => switchToPreviousWelcomeScreen(prev));
  };

  const navigateToNextScreen = () => {
    if (activeScreen === VIDEO_SCREEN) return;

    setActiveScreen((prev) => switchToNextWelcomeScreen(prev));
  };

  const navigateToLastScreen = () => {
    setActiveScreen(VIDEO_SCREEN);
  };

  return (
    <WelcomeContainer>
      <Header view={HEADER_ONBOARDING_VIEW} />
      <WelcomeScreensSwitcher activeScreen={activeScreen} />
      <WelcomeScreensNavigation
        data={{ activeScreen, totalScreens: TOTAL_NUM_WELCOME_SCREENS }}
        functions={{ navigateToPreviousScreen, navigateToNextScreen, navigateToLastScreen }}
      />
    </WelcomeContainer>
  );
};

export default OnboardingWelcomeScreens;
