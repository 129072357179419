import React from 'react';
import styled from 'styled-components';
import { gray2 } from '../../../../shared/colours';

const InputForPlaceholderOnly = styled.input`
  background-color: ${gray2};
  outline: none;
  border: none;
  margin-left: 4px;
  width: 100%;
  cursor: pointer;
`;

interface Props {
  shouldShow: boolean,
  placeholder: string,
}

const InputPlaceholder = ({ shouldShow, placeholder }: Props) => {
  if (!shouldShow) return null;
  return (
    <InputForPlaceholderOnly readOnly placeholder={placeholder} />
  );
};

export default InputPlaceholder;
