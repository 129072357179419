import React from 'react';
import styled from 'styled-components';
import { surface } from '../../../../../colours';
import TrashBinIcon from '../../../../../icons/TrashBinIcon';
import { uiTextMedium } from '../../../../../typography';

const IconAndTextContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  column-gap: 8px;
`;

const ConfirmationTextContainer = styled.div`
  ${uiTextMedium};
  color: ${surface};
`;

const DeleteConfirmationIconAndText = () => (
  <IconAndTextContainer>
    <TrashBinIcon />
    <ConfirmationTextContainer>Are you sure?</ConfirmationTextContainer>
  </IconAndTextContainer>

);

export default DeleteConfirmationIconAndText;
