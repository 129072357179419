import React from 'react';
import styled from 'styled-components';
import { header400 } from '../../../../../shared/typography';
import { surface } from '../../../../../shared/colours';

const Text = styled.span`
  ${header400};
  color: ${surface};
  margin: 0px;
`;

const NotifyAtom = () => (
  <Text>
    Notify me on Slack when...
  </Text>
);

export default NotifyAtom;
