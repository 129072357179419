import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../App';
import { darkBlue1 } from '../../../../shared/colours';
import ButtonSmall from '../../../../shared/components/button-small';
import Modal from '../../../../shared/components/Modal';
import { ResolveState, TrelloData } from '../../../../shared/types/types';
import { dbSaveTrelloData } from '../../../../database/firebaseTrelloAPI';
import { toastSuccess } from '../../../../utils/notifications';
import TrelloSettings from './TrelloSettings';
import Scrollbar from '../../../../shared/components/scrollbar';

const Container = styled.div`
 padding: 16px 24px 4px 24px ;
`;

const ModalLineLocal = styled.div`
  background: ${darkBlue1};
  height: 2px;
  width: 100%;
`;

const ButtonRow = styled.div`
  display: flex;
  padding: 10px 24px;
  justify-content: flex-end;

  & > button {
    margin: 0;
  }

  button:first-of-type {
    margin-right: 8px;
  }
`;

interface Props {
  isOpen: boolean,
  setModalClosed: ()=> void,
}

export default function TrelloSettingModalWrapper({ isOpen, setModalClosed }:Props) {
  const { userId } = useContext(AuthContext);

  const [isTrelloDataUpdated, setIsTrelloDataUpdated] = useState<ResolveState>('pending');
  const [loading, setLoading] = useState<boolean>(false);
  const trelloData = useContext(UserDataContext).integrations.trello;
  const [userTrelloData, setTrelloData] = useState<TrelloData>(trelloData);

  const handleUpdate = () => {
    setLoading(true);
    dbSaveTrelloData(userId, userTrelloData, setIsTrelloDataUpdated);
  };

  const closeModal = () => {
    setTrelloData(trelloData);
    setLoading(false);
    setModalClosed();
  };

  useEffect(() => {
    if (isTrelloDataUpdated === 'resolved') {
      setLoading(false);
      toastSuccess('Trello settings updated', 'Your changes has been saved');
      setIsTrelloDataUpdated('pending');
      setModalClosed();
    }
  }, [isTrelloDataUpdated]);

  useEffect(() => {
    setTrelloData(trelloData);
  }, [trelloData]);

  return (
    <>
      <Modal isOpen={isOpen} setModalClosed={closeModal} title="Trello Settings">
        <Scrollbar maxHeight="65vh">
          <Container>
            <TrelloSettings
              setTrelloData={setTrelloData}
              trelloData={userTrelloData}
            />
          </Container>
        </Scrollbar>
        <ModalLineLocal />
        <ButtonRow>
          <ButtonSmall onClick={() => closeModal()} text="Back" isOutline />
          <ButtonSmall onClick={() => handleUpdate()} text="Update" loading={loading} />
        </ButtonRow>
      </Modal>
    </>
  );
}
