import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import { uiText } from '../../../shared/typography';
import useWindowSize from '../../../utils/hook/useWindowSize';

interface StyleProps {
  isScreenSizeSM: boolean
}

const Container = styled.span<StyleProps>`
  ${uiText}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: ${({ isScreenSizeSM }) => (isScreenSizeSM ? '44%' : '88%')};
`;

interface Props {
  text: string;
}

const NoteItemTitleAtom = ({ text }: Props) => {
  const textRef = useRef<HTMLSpanElement>(document.createElement('span'));
  const [isTextOverflow, setTextOverflow] = useState<boolean>(false);
  const { isScreenSizeSM } = useWindowSize();
  useEffect(() => {
    setTextOverflow(textRef.current.scrollWidth > textRef.current.offsetWidth);
  }, [textRef, text]);

  return isTextOverflow ? (
    <ReactTooltip tip={text}>
      <Container ref={textRef} isScreenSizeSM={isScreenSizeSM}>
        {text}
      </Container>
    </ReactTooltip>
  ) : (
    <Container ref={textRef} isScreenSizeSM={isScreenSizeSM}>
      {text}
    </Container>
  );
};

export default NoteItemTitleAtom;
