import React from 'react';
import styled from 'styled-components';
import { small } from '../../../../shared/typography';
import { gray7 } from '../../../../shared/colours';
import { getDayAndMonth } from '../../../../utils/dateUtils/date';

const Date = styled.h2`
  ${small};
  color: ${gray7};
`;

interface Props {
  date: string,
}

const MeetingDateAtom = ({ date }: Props) => {
  const properDateFormat = getDayAndMonth(date);
  return (
    <Date>
      {properDateFormat}
    </Date>
  );
};

export default MeetingDateAtom;
