import TurndownService from 'turndown';
import { markdownToBlocks } from '@meetshepherd/martian';
import { Block } from '@meetshepherd/martian/build/src/notion';
import { isArray, isEmpty } from 'lodash';
import * as Sentry from '@sentry/browser';
import {
  NotionCreatePageData, NotionData, PrivateNoteHtml, TaskItem,
} from '../../shared/types/types';
import { DateToTemplateDateFormat } from '../dateUtils/date';
import { EmailHTMLSection } from '../email/emailUtils';
import { isEmptyHTML } from '../strings';
import { NOTION_INTEGRATION } from '../analytics/enums';
import { toastDanger } from '../notifications';

const turndownService = new TurndownService();

export const notionAuthUrl = 'https://api.notion.com/v1/oauth/authorize?client_id=50584a37-d0bf-45d3-b481-e5849db4fe53&redirect_uri=https://app.meetshepherd.com/notion&response_type=code&owner=user';

type assembleSlackMessageParamType = {
  title: string,
  startDate: string,
  agendaChecked: boolean,
  sharedNotesChecked: boolean,
  personalNotesChecked: boolean,
  taskChecked: boolean,
  emailHeadHtml: string,
  agendaHtml: string,
  sharedNotesHtml: string,
  personalNotesHtml: string,
  taskItems: TaskItem[],
  checkedPrivateNotesHtml: PrivateNoteHtml[],
  privateSpaceNotesChecked: string[],
}

export const assembleNotionBlocks = ({
  title, startDate, agendaChecked, sharedNotesChecked, personalNotesChecked,
  taskChecked, emailHeadHtml, agendaHtml, sharedNotesHtml,
  checkedPrivateNotesHtml, privateSpaceNotesChecked,
  personalNotesHtml, taskItems,
}: assembleSlackMessageParamType, notionUserData: NotionData[]) => {
  const sections: EmailHTMLSection[] = [];
  const notionBlocks = [];

  if (agendaChecked && !isEmptyHTML(agendaHtml)) {
    sections.push({ title: 'Agenda', content: agendaHtml });
  }
  if (sharedNotesChecked && !isEmptyHTML(sharedNotesHtml)) {
    sections.push({ title: 'Shared notes', content: sharedNotesHtml });
  }
  if (personalNotesChecked && !isEmptyHTML(personalNotesHtml)) {
    sections.push({ title: 'Sender\'s notes', content: personalNotesHtml });
  }

  if (privateSpaceNotesChecked.length !== 0) {
    checkedPrivateNotesHtml.forEach((privateNoteHtml) => {
      if (privateSpaceNotesChecked.includes(privateNoteHtml.noteId)) {
        sections.push({ title: 'Private note', content: privateNoteHtml.contents });
      }
    });
  }

  let html = '<div style="padding-left: 20px;" >';
  sections.forEach((section: EmailHTMLSection) => {
    html += `<br><u><h3><b>${section.title}:</b></h3></u><p>${section.content}</p><br>`;
  });
  html += '</div>';
  html = `<div>${emailHeadHtml.split('\n').join('<br>')}</div>${html}`;
  try {
    const markdown = turndownService.turndown(html);
    const blocks = markdownToBlocks(markdown);
    blocks.forEach((block: any) => {
      if (block.type === 'heading_3') {
        // eslint-disable-next-line no-param-reassign
        block.heading_3!.text[0].annotations = {
          bold: true,
          strikethrough: false,
          underline: true,
          italic: false,
          code: false,
          color: 'default',
        };
      }
    });

    const validatedNotionBlocks = removeAttrDeep(blocks, 'children');

    notionBlocks.push(...validatedNotionBlocks);

    if (taskChecked && taskItems.length !== 0) {
      const taskBlock = taskItems.map((task: TaskItem) => generateTaskToNotionObject(task));
      notionBlocks.push(taskSectionBlock, ...taskBlock);
    }

    const generatedNotionBlock: Block[] = notionBlocks as Block[];
    console.log(generatedNotionBlock);
    const createPageObj: NotionCreatePageData[] = notionUserData.map(
      (workspaceData: NotionData) => ({
        workspaceToken: workspaceData.accessToken,
        parentPageId: workspaceData.parentPageId,
        pageTitle: `${title} | ${startDate}`,
        block: generatedNotionBlock,
      }),
    );
    return createPageObj;
  } catch (error) {
    Sentry.captureException({
      integration: NOTION_INTEGRATION, message: 'formatting error', error, htmlContent: html,
    });
    toastDanger('Something went wrong while parsing the notes to Notion');
    return [] as NotionCreatePageData[];
  }
};

const isObject = (obj: any) => obj != null && obj.constructor.name === 'Object';
const removeAttrDeep = (obj: any, key: string) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    // eslint-disable-next-line no-param-reassign
    if (prop === key && obj[key].length === 0) delete obj[prop];
    else if (isArray(obj[prop])) {
      // eslint-disable-next-line no-loop-func, no-param-reassign
      obj[prop] = obj[prop].filter((k: any) => !isEmpty(removeAttrDeep(k, key)));
    } else if (isObject(obj[prop])) removeAttrDeep(obj[prop], key);
  }
  return obj;
};

const taskSectionBlock = {
  object: 'block',
  type: 'heading_3',
  heading_3: {
    text: [
      {
        type: 'text',
        annotations: {
          bold: true,
          strikethrough: false,
          underline: true,
          italic: false,
          code: false,
          color: 'default',
        },
        text: { content: 'Task:' },
        plain_text: 'Task:',
      },
    ],
  },
};

const generateTaskToNotionObject = ((taskObj: TaskItem) => ({
  object: 'block',
  type: 'to_do',
  to_do: {
    text: [
      {
        type: 'text',
        text: {
          content: `${taskObj.data.title}`
            + `${taskObj.data.assignee.name ? ` | ${taskObj.data.assignee.name}` : ''}`
            + `${taskObj.date.dueDate.date.date ? ` | Due ${DateToTemplateDateFormat(taskObj.date.dueDate.date.date)}` : ''}`,
        },
      },
    ],
    checked: taskObj.data.status === 'completed',
  },
}));

/*
Can't assign types to 'taskSectionBlock','generateTaskToNotionObject',
'notionBlocks'
Open issues @NotionSDK https://github.com/makenotion/notion-sdk-js/issues/189
*/
