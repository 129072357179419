/* eslint-disable no-unused-vars */
import { dbGetSimpleUserDataByUserId } from '../../../database/firebaseUsersAPI';
import { mapGoogleMeetingToDatabaseMeetingData } from '../../../database/utils/templateMeetingData';
import { REJECTED, RESOLVED } from '../../../utils/enums';
import { functions } from '../../../utils/firebase';
import { gapiGetRecurringEventMeetings } from '../../../utils/google/GoogleCalendarAPI';
import { GapiMeetingData, SimpleUserData } from '../../types/types';

const updateMeetings = async (userId: string, calendarId: string, eventId: string) => {
  const GAPIMeetings: GapiMeetingData[] = await gapiGetRecurringEventMeetings(calendarId, eventId);
  const user: SimpleUserData = await dbGetSimpleUserDataByUserId(userId, 'admin');
  const newMeetings = GAPIMeetings.map((googleMeeting) => mapGoogleMeetingToDatabaseMeetingData(
    googleMeeting,
    calendarId,
    user,
  ));
  return functions().httpsCallable('updateMeetingsFromGAPItoDB')({
    meetings: newMeetings,
  }).then(() => RESOLVED).catch(() => REJECTED);
};

export default updateMeetings;
