import styled from 'styled-components';
import { gray9, red6 } from '../../../../../../shared/colours';
import { header200 } from '../../../../../../shared/typography';

export const OnboardingMultiSelectInputContainer = styled.div`
  width: 100%;
`;

export const HiddenLabel = styled.label``;

export const VisibleLabel = styled.div`
  ${header200};
  color: ${gray9};
  margin-bottom: 8px;
`;

export const MultiSelectInputAndDropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ErrorMessage = styled.p`
  color: ${red6};
  font-size: 10px;
  margin: 0;
  padding-top: 4px;
  height: 10px;
`;
