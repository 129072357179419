import styled from 'styled-components';
import { PRIVATE_NOTES } from '../../../../../../../utils/enums';
import {
  darkBlue1, darkBlue2, gray8, gray9,
} from '../../../../../../colours';
import { MeetingSection } from '../../../../../../types/types';

interface ArrowContainerProps {
  meetingTab: MeetingSection
  isDropdownOpen: boolean
}

const DropdownArrowContainer = styled.div<ArrowContainerProps>`
  width: 30px;
  height: 100%;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ meetingTab, isDropdownOpen }) => {
    if (!isDropdownOpen) return '';
    if (meetingTab === PRIVATE_NOTES) return gray8;
    return gray9;
  }};

  &:before {
    content: '';
    height: 18px;
    width: 1px;
    position: relative;
    right: 3.5px;
    background-color: ${({ meetingTab, isDropdownOpen }) => {
    if (!isDropdownOpen) return darkBlue1;
    if (meetingTab === PRIVATE_NOTES) return gray8;
    return 'transparent';
  }};
    border-radius: 8px;
  }

  &:hover {
    background-color: ${({ meetingTab }) => {
    if (meetingTab === PRIVATE_NOTES) return gray8;
    return darkBlue2;
  }};
    &::before {
      background-color: transparent;
    }
  };

  
`;

export default DropdownArrowContainer;
