/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import MeetingDataContext from '../../../../pages/meeting/context/MeetingDataContext';
import TabDivButton from '../../../../pages/meeting/tab/TabDivButton';
import { PRIVATE_NOTES } from '../../../../utils/enums';
import { MeetingSection } from '../../../types/types';
import AddPrivateNotesButton from '../add-private-notes-button-atom';

interface Props {
  meetingTab: MeetingSection,
  onClickOpenPrivateNotesModal: () => void,
}

const MeetingTabAddPrivateNotes = ({
  meetingTab,
  onClickOpenPrivateNotesModal,
}: Props) => {
  const [isPrivateNotesOpen, setIsPrivateNotesOpen] = useState(false);

  useEffect(() => {
    setIsPrivateNotesOpen(meetingTab === PRIVATE_NOTES);
  }, [meetingTab]);


  const addPrivateNotesOnClick = () => onClickOpenPrivateNotesModal;

  const addPrivateNotesTooltipText = () => 'Create new private note and add selected attendees';

  return (
    <TabDivButton
      active={isPrivateNotesOpen}
      disabled={isPrivateNotesOpen}
      onClick={addPrivateNotesOnClick()}
      tooltipText={addPrivateNotesTooltipText()}
      meetingTab={PRIVATE_NOTES}
      padding
    >
      <AddPrivateNotesButton />
    </TabDivButton>
  );
};

export default MeetingTabAddPrivateNotes;
