import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { AuthContext, UserDataContext } from '../../../../../App';
import { dbUserUpdateInfo } from '../../../../../database/firebaseUsersAPI';
import { logSettingsClicks } from '../../../../../utils/analytics/eventLogger';
import { header400 } from '../../../../typography';
import Switch from '../../../switch';

export const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const HeadText = styled.div`
  ${header400}
`;

const SwitchGroup = styled.div`
  padding-top: 20px;
  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const TaskEmailNotificationSettings = () => {
  const [isTaskEmailEnable, setTaskEmailEnable] = useState<boolean>(false);
  const [isPrepareRemindEnable, setPrepareRemindEnable] = useState<boolean>(false);
  const [isMeetingRemindEnable, setMeetingRemindEnable] = useState<boolean>(false);
  const userData = useContext(UserDataContext);
  const authData = useContext(AuthContext);
  const { trackEvent } = useIntercom();

  useEffect(() => {
    setTaskEmailEnable(userData.settings.receiveTaskEmail);
  }, [userData.settings.receiveTaskEmail]);
  const handleSetTaskEmailNotificationEnable = (value: boolean) => {
    dbUserUpdateInfo(authData.userId, { 'settings.receiveTaskEmail': value });
    logSettingsClicks(authData.userId, 'task_email_notification', 'notify_when_task_assigned_me', trackEvent);
  };
  return (
    <Container>
      <HeadText>
        Email notifications
      </HeadText>
      <SwitchGroup>
        <Switch
          label="Remind me to prepare for my meeting"
          checked={isPrepareRemindEnable}
          setChecked={setPrepareRemindEnable}
          disabled
          disabledTooltipText="Coming soon"
        />
        <Switch
          label="Remind me about my meeting beforehand"
          checked={isMeetingRemindEnable}
          setChecked={setMeetingRemindEnable}
          disabled
          disabledTooltipText="Coming soon"
        />
        <Switch
          label="Notify me when a task is assigned to me"
          checked={isTaskEmailEnable}
          setChecked={handleSetTaskEmailNotificationEnable as Dispatch<SetStateAction<boolean>>}
        />
      </SwitchGroup>
    </Container>
  );
};

export default TaskEmailNotificationSettings;
