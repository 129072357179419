import React, { useEffect, useRef, useState } from 'react';
import { surface } from '../../../../../shared/colours';
import CrossIcon from '../../../../../shared/icons/cross';
import { SelectedOptionCloseButton, SelectedOption, SelectedOptionsContainer } from './utils/styles';

interface SelectedDropdownOptionsProps {
  selectedOptions: Array<string>,
  placeholder: string,
  isDropdownOpen: boolean,
  /* eslint-disable-next-line */
  onRemoveSelectedOption: (event: any, valueToRemove: string) => void,
}

const SelectedDropdownOptions = ({
  selectedOptions,
  placeholder,
  isDropdownOpen,
  onRemoveSelectedOption,
}: SelectedDropdownOptionsProps) => {
  const selectedDropdownOptionsRef = useRef<any>(null);

  const [currentWidth, setCurrentWidth] = useState(0);

  useEffect(() => {
    if (!isDropdownOpen) selectedDropdownOptionsRef.current.scrollLeft = 0;
  }, [isDropdownOpen]);

  useEffect(() => {
    const scrollWidth = selectedDropdownOptionsRef.current?.scrollWidth;

    if (currentWidth >= scrollWidth) {
      setCurrentWidth(scrollWidth);
      return;
    }

    selectedDropdownOptionsRef.current.scrollLeft = scrollWidth ?? 0;
    setCurrentWidth(scrollWidth);
  }, [selectedDropdownOptionsRef.current?.scrollWidth]);

  const renderContents = () => {
    if (selectedOptions.length === 0) return placeholder;

    return (
      selectedOptions.map((option) => (
        <SelectedOption key={option}>
          {option}
          <SelectedOptionCloseButton onClick={(e) => onRemoveSelectedOption(e, option)}>
            <CrossIcon fill={surface} width={16} height={16} />
          </SelectedOptionCloseButton>
        </SelectedOption>
      ))
    );
  };

  return (
    <SelectedOptionsContainer
      ref={selectedDropdownOptionsRef}
      hasInput={selectedOptions.length > 0}
    >
      {renderContents()}
    </SelectedOptionsContainer>
  );
};

export default SelectedDropdownOptions;
