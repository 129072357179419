import React from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../shared/colours';
import ConnectionLostTextAtom from './ConnectionLostTextAtom';
import DangerIconAtom from './DangerIconAtom';
import ExplanationAtom from './ExplanationAtom';
import LearnMoreAtom from './LearnMoreAtom';

const Container = styled.div`
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${gray1};
  padding: 16px;
  box-shadow: 0px 14px 24px rgba(27, 33, 36, 0.16), 0px 8px 8px -4px rgba(27, 33, 36, 0.04);
  border-radius: 16px;
  z-index: 50;
  top: 300px;
  left: 56px;
`;

const TextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 20px 0px 20px;

  div:first-child {
    margin-bottom: 4px;
  }
  div:nth-child(2) {
    margin-bottom: 10px;
  }
`;

interface Props {
  isOpen: boolean,
  onLearnMoreClick: () => void,
}

const ConnectionLostPopup = ({ isOpen, onLearnMoreClick }: Props) => {
  if (!isOpen) return null;

  return (
    <Container>
      <DangerIconAtom />
      <TextContainer>
        <ConnectionLostTextAtom text="Internet connection lost" />
        <ExplanationAtom>
          Additional changes will not be saved. Check
          <br />
          your connection.
        </ExplanationAtom>
        <LearnMoreAtom text="Learn more" onClick={onLearnMoreClick} />
      </TextContainer>
    </Container>
  );
};

export default ConnectionLostPopup;
