import styled, { css } from 'styled-components';
import {
  blue6, darkBlue1, gray1, gray2, gray3, gray7, gray9, red6, surface,
} from '../../../../../../shared/colours';
import { header200, link, uiTextS } from '../../../../../../shared/typography';

export const Container = styled.div`
  width: 100%;
`;

export const DropdownContainer = styled.div`
  position: relative;

  width: 100%;
`;

export const HiddenLabel = styled.label``;

export const VisibleLabel = styled.div`
  ${header200};
  color: ${gray9};
  margin-bottom: 8px;
`;

interface DropdownInputContainerProps {
  input: string
  dropdownActive: boolean
}

export const DropdownInputContainer = styled.div<DropdownInputContainerProps>`
  align-items: center; 
  background: ${({ input }) => (input.length > 0 ? gray1 : gray3)};
  border-radius: 8px;
  border: ${({ dropdownActive }) => (dropdownActive ? css`2px solid ${blue6}` : css`2px solid ${gray3}`)};
  display: flex;
  height: 40px;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 12px;
  text-align: left;
  transition: box-shadow 0.4s ease;
  width: 100%; 

  &:hover {
    cursor: default;
    border: 2px solid ${darkBlue1};
  };
`;

export const DropdownInput = styled.input`
  all: unset;

  ${uiTextS};
  color: ${surface};
  
  width: 100%; 

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${gray7};
  }
`;

export const DropdownMenu = styled.ul`
  z-index: 1;
  background: ${gray1};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 48px;
  left: 0;

  width: 100%;
  overflow-y: scroll;
  overflow: hidden;

  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0;

    button {
      ${link};
      border: none;
      background: none;
      padding: 11px 16px;
      color: ${surface};
      cursor: pointer;
      width: 100%;
      text-align: left;

      &:hover {
        background: ${gray2};
      }
    }

    &:last-of-type {
      margin: 0;
    }
  }
`;

interface InputStyleProps {
  input: string
}

export const InputStyle = styled.input<InputStyleProps>`
  ${uiTextS};
  color: ${surface};
  
  background: ${({ input }) => (input.length > 0 ? gray1 : gray3)};
  
  box-sizing: border-box;
  border: 2px solid ${gray3};
  border-radius: 10px;
  
  width: 100%; 

  padding: 8px 10px;
  margin-top: 10px;

  :hover {
    cursor: default;
    border: 2px solid ${darkBlue1};
  }

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${gray7};
  }
`;

export const ErrorMessage = styled.p`
  color: ${red6};
  font-size: 10px;
  margin: 0;
  height: 10px;
`;
