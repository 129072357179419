import { Dispatch, SetStateAction } from 'react';
import { pendingGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import updateMeetingDataFromGapiMeetingData from '../../database/utils/updateMeetingDataFromCalendarData';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import {
  GapiMeetingData, GoogleAttendee, MeetingData, ResolvedStateItem, User,
} from '../../shared/types/types';
import { RESOLVED } from '../../utils/enums';
import { gapiGetMeeting, getMeetingsInPeriod } from '../../utils/google/GoogleCalendarAPI';
import GoogleAPICore from './GoogleAPICore';

/**
 * API to interact with the Google API.
 */
class GoogleAPI extends GoogleAPICore {
  /**
   * Gets Gapi data for a specific meeting using the meeting eventId and calendarId
   * and attendee information
   */
  static async getGapiDataForMeeting(
    meetingData: MeetingData,
    user: User,
    setGapiMeetingData: Dispatch<SetStateAction<GapiMeetingData>>,
  ): Promise<void> {
    console.log('Calling getGapiData');
    setGapiMeetingData(pendingGapiMeetingData);
    const gapiMeetingData = await gapiGetMeeting(
      meetingData.googleData.ids.eventId,
      meetingData.googleData.ids.calendarId,
      user.publicUserData.data.email,
      meetingData,
    );
    console.log(`Google Calendar API response: (eventId: ${meetingData.googleData.ids.eventId}), and (calendarId: ${meetingData.googleData.ids.calendarId})`);
    setGapiMeetingData(gapiMeetingData);

    updateMeetingDataFromGapiMeetingData(gapiMeetingData, meetingData, user);
  }

  static async getMeetingsLastSevenDays(daysAgo: number)
  : Promise<ResolvedStateItem<GapiMeetingData[]>> {
  // const meetings = await getTodaysMeetings();
    const meetings = await getMeetingsInPeriod(-daysAgo, 7);
    ConsoleImproved.log('Meetings in AllNotesPage', meetings);
    const filteredMeetingsByHavingAStartTime = meetings.filter((meeting) => meeting.start.dateTime);
    const nonDuplicatedMeetings = GoogleAPI.removeDuplicateMeetings(
      filteredMeetingsByHavingAStartTime,
    );
    const sortedMeetingsByStartTime = nonDuplicatedMeetings.sort(
      (a, b) => (
      // eslint-disable-next-line no-nested-ternary
        (a.start.dateTime > b.start.dateTime) ? -1 : (
          (a.start.dateTime < b.start.dateTime) ? 1 : 0)),
    );
    return {
      item: sortedMeetingsByStartTime,
      resolvedState: RESOLVED,
    };
  }

  static mapMeetingsToAttendees(meetings: GapiMeetingData[]): GoogleAttendee[] {
    const allAttendees = meetings.reduce((acc, meeting) => {
      const { attendees } = meeting;
      attendees.forEach((attendeeCandidate) => {
        if (!acc.find((attendee) => attendee.email === attendeeCandidate?.email)) {
          acc.push(attendeeCandidate);
        }
      });
      return acc;
    }, [] as GoogleAttendee[]);
    return allAttendees;
  }

  static removeDuplicateMeetings(meetings: GapiMeetingData[]): GapiMeetingData[] {
    const nonDuplicatedMeetings = meetings.reduce((acc, meeting) => {
      if (!acc.find((m) => m.id === meeting.id)) {
        acc.push(meeting);
      }
      return acc;
    }, [] as GapiMeetingData[]);
    return nonDuplicatedMeetings;
  }
}

export default GoogleAPI;
