import React from 'react';
import styled from 'styled-components';
import AddUser from '../../icons/AddUser';
import ReactTooltip from '../tooltip/ReactTooltip';

const Container = styled.div``;

interface Props {
  onClick: () => void,
}

const AddUserAtom = ({ onClick }: Props) => (
  <Container onClick={onClick}>
    <ReactTooltip tip="Invite others to Shepherd" place="bottom">
      <AddUser />
    </ReactTooltip>
  </Container>
);

export default AddUserAtom;
