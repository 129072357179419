import React, { HTMLProps } from 'react';
import styled from 'styled-components';
import ButtonSmall from '../button-small';

const Container = styled.div`
  > button {
    height: 40px;
    padding: 8px 14px;
  }
`;

interface Props {
  isDisabled: boolean;
  onClick: HTMLProps<HTMLElement>['onClick'];
}

const InviteButtonAtom = ({ isDisabled, onClick }: Props) => (
  <Container>
    <ButtonSmall text="Invite" isOutline isDisabled={isDisabled} onClick={onClick} />
  </Container>
);

export default InviteButtonAtom;
