import React from 'react';
import styled from 'styled-components';
import Notification from '../../../classes/Notification/Notification';
import { blue6 } from '../../../colours';
import { uiTextMedium } from '../../../typography';

interface Props {
  notification: Notification
}

const MeetingText = styled.span`
  color: ${blue6};
  ${uiTextMedium}
`;

function NotificationSubHeading({ notification }: Props) {
  return (
    <div>
      Mentioned you in
      <MeetingText>
    &nbsp;
        {notification.source.identifier2}
    &nbsp;
      </MeetingText>
      notes
    </div>
  );
}

export default NotificationSubHeading;
