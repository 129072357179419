import React, { useContext } from 'react';
import styled from 'styled-components';
import { ShortcutsContext } from '..';
import { gray8 } from '../../../colours';
import { paragraph } from '../../../typography';
import MacOrWindowsCtrlIcon from './MacOrWindowsCtrlIcon';
import SelectOSButton from './SelectOSButton';
import ShortcutCommandButton from './ShortcutCommandButton';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 10px;
`;

const ShortcutModalShortcut = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div:first-child {
    margin-right: 8px;
  }
`;

const TextContainer = styled.span`
  ${paragraph};
  color: ${gray8};

  padding-left: 16px;
`;

const ShortcutsModalHeader = () => {
  const ShortcutsCtx = useContext(ShortcutsContext);

  return (
    <Container>
      <ShortcutModalShortcut>
        <MacOrWindowsCtrlIcon operatingSystem={ShortcutsCtx.operatingSystem} />
        <ShortcutCommandButton command="/" />
        <TextContainer>
          to open this panel
        </TextContainer>
      </ShortcutModalShortcut>
      <SelectOSButton />
    </Container>
  );
};

export default ShortcutsModalHeader;
