import React from 'react';
import {
  ShepherdTasksView, FirstTask, SecondTask, ThirdTask, FourthTask, FifthTask,
} from '../images';
import { TASKS_SCREEN_HIGHLIGHT } from '../utils/colours';
import OnboardingWelcomeScreensModel from '../utils/onboarding-welcome-screens-model';
import {
  Container, TasksImageContainer, ImageContainer, ImagesContainer,
} from '../utils/styles';
import WelcomeScreenTitleAndSubtitle from '../components/welcome-screens-title-and-subtitle-organism';

const TasksScreen = () => {
  const { title, highlightedTitle, subtitle } = OnboardingWelcomeScreensModel.value.createTasks;

  return (
    <Container>
      <ImagesContainer>
        <TasksImageContainer>
          <ShepherdTasksView />
        </TasksImageContainer>
        <ImageContainer left="0px" top="275px" animation="fadeInRightTask" order={3} task>
          <FirstTask />
        </ImageContainer>
        <ImageContainer left="0px" top="325px" animation="fadeInLeftTask" delay="0.1s" order={3} task>
          <SecondTask />
        </ImageContainer>
        <ImageContainer left="0px" top="375px" animation="fadeInRightTask" delay="0.2s" order={3} task>
          <ThirdTask />
        </ImageContainer>
        <ImageContainer left="0px" top="425px" animation="fadeInLeftTask" delay="0.3s" order={3} task>
          <FourthTask />
        </ImageContainer>
        <ImageContainer left="0px" top="475px" animation="fadeInRightTask" delay="0.4s" order={3} task>
          <FifthTask />
        </ImageContainer>
      </ImagesContainer>
      <WelcomeScreenTitleAndSubtitle
        title={title}
        highlightedSection={highlightedTitle}
        highlightColor={TASKS_SCREEN_HIGHLIGHT}
        subtitle={subtitle}
        customized="checkmark"
      />
    </Container>
  );
};

export default TasksScreen;
