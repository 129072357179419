import React, { Dispatch, SetStateAction } from 'react';
import TextBox from '../../../../../shared/components/textbox';

interface TextBoxProps {
  setEmailHeadTemplate: Dispatch<SetStateAction<string>>,
  emailHeadTemplate: string,
}

export default function CustomMessage({ setEmailHeadTemplate, emailHeadTemplate }:TextBoxProps) {
  return (
    <TextBox
      placeholder="Write here to add additional notes"
      onChange={setEmailHeadTemplate}
      defaultValue={emailHeadTemplate}
    />
  );
}
