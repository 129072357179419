import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import CloseSquareIcon from '../../../icons/closeSquare';
import RecurringMeetingIcon from '../../../icons/RecurringMeetingIcon';
import { titleSemibold } from '../../../typography';
import {
  gray10, layoutWhite, gray9, /* layoutWhite, */
} from '../../../colours';
import StartAndEndTimeAtom from './DateAndTime';
import ReactTooltip from '../../tooltip/ReactTooltip';
import PreviousMeetingDataContext from '../context/PreviousMeetingDataContext';

const HeaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  width: 100%;
  padding: 20px 16px;
  background-color: ${layoutWhite};
  border-radius: 15px 15px 0 0;
`;

const PreviousMeetingInformationContainer = styled.span`
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
`;

const PreviousMeetingTitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${titleSemibold};
  color: ${gray9};
  width: 100%;
  margin-top: 10px;
`;

const CloseButtonContainer = styled.button`
  all: unset;
  align-self: flex-start;
  width: 24px;
  height: 24px;
  &:hover {
    cursor: pointer;
    filter: brightness(75%);
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
`;

interface Props {
  handleClick: () => void
}

const PreviousMeetingHeader = ({
  handleClick,
}:Props) => {
  const PreviousMeetingData = useContext(PreviousMeetingDataContext);

  const [meetingTitle, setMeetingTitle] = useState<string>('');

  useEffect(() => {
    const currentMeetingTitle = PreviousMeetingData.data?.title ?? '';

    setMeetingTitle(currentMeetingTitle);
  }, [PreviousMeetingData]);

  return (
    <HeaderContainer>
      <PreviousMeetingInformationContainer>
        <StartAndEndTimeAtom />
        <PreviousMeetingTitleContainer>
          {meetingTitle}
          <ReactTooltip place="top" tip="Recurring meeting">
            <IconContainer>
              <RecurringMeetingIcon />
            </IconContainer>
          </ReactTooltip>
        </PreviousMeetingTitleContainer>
      </PreviousMeetingInformationContainer>
      <CloseButtonContainer onClick={handleClick}>
        <CloseSquareIcon fill={gray10} width="24px" height="24px" />
      </CloseButtonContainer>
    </HeaderContainer>
  );
};

export default PreviousMeetingHeader;
