import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { AllNotesSection } from '../../../shared/types/types';
import NotesTabButtonAtom from './NotesTabButtonAtom';

const Container = styled.div`
  display: flex;
  margin-bottom: 16px;
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`;

interface Props {
  section: AllNotesSection,
  setSection: Dispatch<SetStateAction<AllNotesSection>>,
}

const SwitchNotesTabMolecule = ({ section, setSection }: Props) => (
  <Container>
    <NotesTabButtonAtom
      text="All notes"
      isSelected={section === 'allNotes'}
      onClick={() => setSection('allNotes')}
      tip="All the notes created in Shepherd"
    />
    <NotesTabButtonAtom
      text="Meeting notes"
      isSelected={section === 'meetingNotes'}
      onClick={() => setSection('meetingNotes')}
      tip="Meeting notes are directly linked to the events in your calendar"
    />
    <NotesTabButtonAtom
      text="Quick notes"
      isSelected={section === 'quickNotes'}
      onClick={() => setSection('quickNotes')}
      tip="Quick notes are not connected to events in your calendar"
    />
  </Container>
);

export default SwitchNotesTabMolecule;
