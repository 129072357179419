import styled, { css } from 'styled-components';
import { gray10 } from '../../../../../../shared/colours';
import { header900 } from '../../../../../../shared/typography';

export const TitleContainer = styled.div`
  ${header900}
  color: ${gray10};

  @media (max-height: 915px) {
    font-size: 34px;
    line-height: 39px;
  };

  @media (max-height: 860px) {
    font-size: 28px;
    line-height: 33px;
  };
`;

interface HighlightedSectionProps {
  color: string
}

export const HighlightedSection = styled.span<HighlightedSectionProps>`
  ${({ color }) => css`color: ${color} !important`};
`;
