import React from 'react';

const PreviousMeetingIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5 12.1525C3.5 17.0389 7.52944 21 12.5 21C17.4706 21 21.5 17.0389 21.5 12.1525C21.5 7.26622 17.5 3.30508 12.5 3.30508C6.5 3.30508 3.5 8.22034 3.5 8.22034M3.5 8.22034L3.5 3M3.5 8.22034H8.1551" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.5 8V12.7832C12.5 12.9076 12.5915 13.0131 12.7146 13.0307L16 13.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
  </svg>

);

export default PreviousMeetingIcon;
