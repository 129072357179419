import React from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../../shared/colours';
import BlackCheckedBox from '../../../../shared/icons/BlackCheckedBox';
import { MeetingData } from '../../../../shared/types/types';
import MeetingAttendeesMolecule from './MeetingAttendeesMolecule';
import MeetingDateAtom from './MeetingDateAtom';
import MeetingTitleAtom from './MeetingTitleAtom';
import ThreeDotsDropdownMolecule from './ThreeDotsDropdownMolecule';

const Container = styled.div`
  width: 488px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: ${gray1};
  padding: 12px 16px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    &:first-child{
      margin-right: 12px;
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemContainer = styled.div`
  margin-right: 12px;
`;

interface Props {
  meeting: MeetingData,
  isSelected: boolean,
}

const MeetingCard = ({ meeting, isSelected }: Props) => (
  <Container>
    <LeftContainer>
      {isSelected && (
        <BlackCheckedBox />
      )}
      <MeetingTitleAtom text={meeting.data.title} />
    </LeftContainer>
    <RightContainer>
      <ItemContainer>
        <MeetingAttendeesMolecule attendees={meeting.attendees.attendees} />
      </ItemContainer>
      <ItemContainer>
        <MeetingDateAtom date={meeting.date.created.date} />
      </ItemContainer>
      <ThreeDotsDropdownMolecule />
    </RightContainer>
  </Container>
);

export default MeetingCard;
