import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import QuickSettings from '../quick-settings';
import ShepherdLogo from '../../icons/shepherd/ShepherdLogo';
import CloseSquareIcon from '../../icons/closeSquare';
import SendCloseSidebarWindowMessage from '../../../utils/sendWindowMessages/closeSidebar';
import AddUserAtom from './AddUserAtom';
import {
  Container, LeftContainer, IconContainer, IconGroupContainer,
} from './utils/styles';
import { DEFAULT_HEADER_MEETING_VIEW, HEADER_DASHBORD_VIEW } from './utils/constants';
import { useUI } from '../../../pages/all-pages/ui-context/uiContext';
import { TopHeaderState } from '../../types/types';
import OpenLinkIcon from '../../icons/OpenLink';
import ReactTooltip from '../tooltip/ReactTooltip';
import NotificationInbox from '../notificationInbox';
import { AuthContext } from '../../../App';
import useNotificationStore from '../../../zustand/useNotificationStore';
import NotificationAPI from '../../classes/Notification/NotificationAPI';
import { logFullScreenButtonClick } from '../../../utils/analytics/eventLogger';

export interface Props {
  view?: TopHeaderState
}

const ShepherdLogoStyled = styled.div`
    align-items: center;
    display: flex;
    @media only screen and (min-width: 1280px){
      svg {
        display: none;
      }
    }
    @media only screen and (max-width: 1280px){
      svg {
        display: block;
      }
    }
  `;

const IconWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 10px;
    @media only screen and (min-width: 1280px){
      svg {
        display: none;
      }
    }
    @media only screen and (max-width: 1280px){
      svg {
        display: block;
      }
    }
  `;

const Header = ({ view = DEFAULT_HEADER_MEETING_VIEW }: Props) => {
  const { userId } = useContext(AuthContext);
  const { updateStore } = useNotificationStore();
  const { openInviteMemberModal } = useUI();
  const { trackEvent } = useIntercom();

  useEffect(() => {
    if (userId.length === 0) return () => {};
    return NotificationAPI.listenToUserNotifications(userId, updateStore);
  }, [userId]);

  const handleCloseSidebarClick = () => {
    SendCloseSidebarWindowMessage();
  };
  const handleOpenInFullScreenClick = () => {
    logFullScreenButtonClick(userId, trackEvent);
    window.open(window.location.href);
  };

  const renderSettingsAndLogo = () => {
    if (view === DEFAULT_HEADER_MEETING_VIEW || view === HEADER_DASHBORD_VIEW) {
      return (
        <>
          <LeftContainer>
            <QuickSettings />
            <NotificationInbox />
          </LeftContainer>
          <ShepherdLogoStyled>
            <ShepherdLogo />
          </ShepherdLogoStyled>
        </>
      );
    }

    return null;
  };

  return (
    <Container view={view}>
      {renderSettingsAndLogo()}

      <IconGroupContainer>
        <IconWrapper>
          <AddUserAtom onClick={openInviteMemberModal} />
          <ReactTooltip tip="Open Shepherd in Full Screen" place="bottom">
            <IconContainer id="open-in-full-screen-button" onClick={handleOpenInFullScreenClick}>
              <OpenLinkIcon width="24" height="24" />
            </IconContainer>
          </ReactTooltip>
          <IconContainer onClick={handleCloseSidebarClick}>
            <CloseSquareIcon width="24" height="24" />
          </IconContainer>
        </IconWrapper>
      </IconGroupContainer>
    </Container>
  );
};

Header.defaultProps = {
  view: DEFAULT_HEADER_MEETING_VIEW,
};

export default Header;
