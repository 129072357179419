import { MenuItemSpec } from 'prosemirror-menu';
import { MarkSpec, MarkType, Schema } from 'prosemirror-model';
import {
  blue3, blue6, blue7, cyan3, cyan6, cyan7,
  gray1, gray2, gray4, gray6, gray7, gray8,
  green3, green6, green7, purple3, purple6,
  purple7, red3, red6, red7, surface, yellow3,
  yellow6, yellow7,
} from '../../../../colours';

// eslint-disable-next-line no-unused-vars
function hexToRGB(h: string) {
  let r = 0;
  let g = 0;
  let b = 0;

  // 3 digits
  if (h.length === 4) {
    r = parseInt(`${h[1]}${h[1]}`, 16);
    g = parseInt(`${h[2]}${h[2]}`, 16);
    b = parseInt(`${h[3]}${h[3]}`, 16);

  // 6 digits
  } else if (h.length === 7) {
    r = parseInt(`${h[1]}${h[2]}`, 16);
    g = parseInt(`${h[3]}${h[4]}`, 16);
    b = parseInt(`${h[5]}${h[6]}`, 16);
  }

  return [r, g, b];
}

// eslint-disable-next-line no-unused-vars
function createHighlightMark(color: string, rawColor: string): MarkSpec {
  return {
    attrs: {
      style: {
        default: `background-color: ${color};`,
      },
      rawColor: {
        default: rawColor,
      },
    },
    group: 'shepherd-custom-highlights',
    excludes: 'shepherd-custom-highlights',
    parseDOM: [
      {
        style: `background-color=${color}`,
        attrs: { style: `background-color: ${color}` },
      },
    ],
    toDOM(node) {
      return ['span', { style: node.attrs.style }, 0];
    },
  };
}

export interface Color {
  value: string;
  valueHex: string;
  name?: string;
  transparent?: boolean;
}

export function buildHighlightMarks(...colors: Color[]): Record<string, MarkSpec> {
  const marks: Record<string, MarkSpec> = {};

  colors.forEach((c: Color) => {
    Object.defineProperty(marks, c.name || c.value, {
      value: createHighlightMark(c.valueHex, c.value),
      writable: false,
      enumerable: true,
    });
  });

  return marks;
}

export function
buildHighlightMenuItems(schema: Schema, ...colors: Color[]): [MarkType, Partial<MenuItemSpec>][] {
  return colors.map((c) => [
    schema.marks[c.name || c.value],
    { title: c.name || c.value, label: c.name || c.value },
  ]);
}

export const defaultHighlights = [
  {
    name: 'empty-highlight', value: gray1, transparent: true, valueHex: '#FEFEFE',
  },
  { name: 'surface-highlight', value: surface, valueHex: '#D1D2D3' },
  { name: 'gray8-highlight', value: gray8, valueHex: '#DEDEDE' },
  { name: 'gray7-highlight', value: gray7, valueHex: '#E8E8E8' },
  { name: 'gray6-highlight', value: gray6, valueHex: '#F2F2F2' },
  { name: 'gray4-highlight', value: gray4, valueHex: '#FBFBFB' },
  { name: 'gray2-highlight', value: gray2, valueHex: '#FEFEFE' },
  { name: 'blue6-highlight', value: blue6, valueHex: '#CDE9FC' },
  { name: 'yellow6-highlight', value: yellow6, valueHex: '#FFF5CC' },
  { name: 'cyan6-highlight', value: cyan6, valueHex: '#E0F9FF' },
  { name: 'purple6-highlight', value: purple6, valueHex: '#DDDEF5' },
  { name: 'green6-highlight', value: green6, valueHex: '#D5F8EC' },
  { name: 'red6-highlight', value: red6, valueHex: '#FFDEE1' },
  { name: 'blue3-highlight', value: blue3, valueHex: '#E6F4FD' },
  { name: 'yellow3-highlight', value: yellow3, valueHex: '#FFFAE6' },
  { name: 'cyan3-highlight', value: cyan3, valueHex: '#F0FCFF' },
  { name: 'purple3-highlight', value: purple3, valueHex: '#EEEFFA' },
  { name: 'green3-highlight', value: green3, valueHex: '#EAFBF6' },
  { name: 'red3-highlight', value: red3, valueHex: '#FFEEF0' },
  { name: 'blue7-highlight', value: blue7, valueHex: '#CDE3F2' },
  { name: 'yellow7-highlight', value: yellow7, valueHex: '#F5ECCC' },
  { name: 'cyan7-highlight', value: cyan7, valueHex: '#DCF0F5' },
  { name: 'purple7-highlight', value: purple7, valueHex: '#DADBED' },
  { name: 'green7-highlight', value: green7, valueHex: '#D4EFE6' },
  { name: 'red7-highlight', value: red7, valueHex: '#F5DADD' },
];
