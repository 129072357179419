import React from 'react';
import DownArrow from '../../../icons/downArrow';
import UpArrow from '../../../icons/upArrow';
import { ArrowContainer } from '../../integration-multi-input';

interface Props{
  isActive: boolean,
  // eslint-disable-next-line no-unused-vars
  handleArrowClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

function ArrowIconMolecule({ isActive, handleArrowClick }:Props) {
  return (
    <ArrowContainer onClick={handleArrowClick}>
      {isActive ? <UpArrow /> : <DownArrow />}
    </ArrowContainer>
  );
}

export default ArrowIconMolecule;
