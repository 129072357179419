import React from 'react';
import styled from 'styled-components';
import { gray1, surface } from '../../../../shared/colours';
import ButtonSmall from '../../../../shared/components/button-small';
import ExplanationAtom from './ExplanationAtom';
import ImageAtom from './ImageAtom';
import TitleAtom from './TitleAtom';
import CloseSquareIcon from '../../../../shared/icons/closeSquare';

const Container = styled.div`
  width: 456px;
  position: absolute;
  z-index: 100;
  background: ${gray1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  top: 300px;
  left: 32px;
  right: 32px;
  border-radius: 16px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  cursor: pointer;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div:first-child {
    margin-bottom: 8px;
  }

  div:nth-child(2) {
    margin-bottom: 16px;
  }
`;

interface Props {
  isOpen: boolean,
  onFinishClick: () => void,
}

const ConnectionLostMoreInfoModal = ({ isOpen, onFinishClick }: Props) => {
  if (!isOpen) return null;

  return (
    <Container>
      <ButtonContainer onClick={onFinishClick}>
        <CloseSquareIcon width="24" height="24" fill={surface} />
      </ButtonContainer>
      <ImageAtom />
      <TextContainer>
        <TitleAtom title="Lost connection" />
        <ExplanationAtom explanation="Uh oh! It seems your internet connection is weak
       or disconnected. Please check that you have a stable network
        to ensure any changes made are saved in Shepherd."
        />
      </TextContainer>
      <ButtonContainer>
        <ButtonSmall text="Okay" onClick={onFinishClick} />
      </ButtonContainer>
    </Container>
  );
};

export default ConnectionLostMoreInfoModal;
