import React, { useEffect, useState } from 'react';
import { PRIVATE_NOTES } from '../../../../../../utils/enums';
import { darkBlue4, gray1 } from '../../../../../colours';
import LockIcon from '../../../../../icons/LockIcon';
import { MeetingSection, PrivateNoteData } from '../../../../../types/types';
import ProfileBubbles from '../profile-bubble-atom';
import ProfileIconsContainer from './utils/styles';

interface Props {
  meetingTab: MeetingSection,
  /* eslint-disable-next-line */
  onClickSelectPrivateNote: (note: PrivateNoteData) => void,
  activePrivateNote: PrivateNoteData,
}

const ProfileIcons = ({
  meetingTab,
  onClickSelectPrivateNote,
  activePrivateNote,
}: Props) => {
  const [isPrivateNoteActive, setIsPrivateNoteActive] = useState(false);

  useEffect(() => {
    setIsPrivateNoteActive(meetingTab === PRIVATE_NOTES);
  }, [meetingTab]);
  return (
    <ProfileIconsContainer onClick={() => onClickSelectPrivateNote(activePrivateNote)}>
      <LockIcon fill={isPrivateNoteActive ? gray1 : darkBlue4} width="15" />
      <ProfileBubbles privateNote={activePrivateNote} />
    </ProfileIconsContainer>
  );
};

export default ProfileIcons;
