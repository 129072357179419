import styled, { css } from 'styled-components';
import { darkBlue2, surface } from '../../../../../../shared/colours';

export const Container = styled.div`
  width: 100%;
  padding: 0 0 40px 0;
`;

export const DotsContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  column-gap: 10px;
`;

interface DotProp {
  active: boolean
}

export const Dot = styled.div<DotProp>`
  width: 8px;
  height: 8px;

  background-color: ${darkBlue2};
  border-radius: 50%;

  ${({ active }) => active && css`
    width: 12px;
    height: 12px;

    background-color: ${surface};
  `};
`;
