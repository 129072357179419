import React from 'react';
import styled from 'styled-components';
import { gray8 } from '../../../../../shared/colours';
import { defaultSmall } from '../../../../../shared/typography';

const Explanation = styled.span`
  ${defaultSmall};
  color: ${gray8};
  margin: 0;
  display: inline-block;
`;

const ExplanationTextAtom = () => (
  <Explanation>
    By giving select attendees access to this new private note, you are able
    to collaborate with them privately. This is useful for interviews,
    presentations, external meetings, and more!
  </Explanation>
);

export default ExplanationTextAtom;
