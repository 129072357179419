import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../../../App';
import { dashboardPath } from '../../../routes';
import SpinnerLoadingPage from '../../../shared/components/loading/spinner-loading-page';
import ProseMirrorComponentV2 from '../../../shared/components/prosemirror';
import { ONBOARDING_FIRST_MEETING_PAGE, ERROR_EVENT, CREATE_MEETING_ERROR_EVENT } from '../../../utils/analytics/enums';
import { logOnboardingEvent, logPageEvent } from '../../../utils/analytics/eventLogger';
import { SHARED_NOTES } from '../../../utils/enums';
import { getFirepadPathV3 } from '../../../utils/firebase';
import { createEventInGoogleCalendarAndMeetingInDatabase, insertFirstMeetingNotes } from './utils/functions';

const FirstMeeting = () => {
  const authState = useContext(AuthContext);
  const history = useHistory();
  const { trackEvent } = useIntercom();

  const [firstMeetingId, setFirstMeetingId] = useState('');
  const [isNotesInserted, setIsNotesInserted] = useState(false);
  const [proseMirrorEditorView, setProseMirrorEditorView] = useState<any>({ page: '', editorView: '' });
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    createEventInGoogleCalendarAndMeetingInDatabase(authState, setFirstMeetingId, setIsError);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!proseMirrorEditorView.editorView) return;
      insertFirstMeetingNotes(proseMirrorEditorView.editorView, setIsNotesInserted, setIsError);
    }, 2000); // Timeout just in case it takes a bit of time to resolve the editor view
  }, [proseMirrorEditorView]);

  useEffect(() => {
    if (!isNotesInserted) return;

    logPageEvent(authState.userId, trackEvent, ONBOARDING_FIRST_MEETING_PAGE);
    history.replace(`/meeting/${firstMeetingId}`);
  }, [isNotesInserted]);

  useEffect(() => {
    if (isError) {
      logOnboardingEvent(
        authState.userId, trackEvent,
        ONBOARDING_FIRST_MEETING_PAGE, ERROR_EVENT,
        CREATE_MEETING_ERROR_EVENT,
      );
      history.replace(dashboardPath);
    }
  }, [isError]);

  if (firstMeetingId.length !== 0) {
    return (
      <ProseMirrorComponentV2
        path={getFirepadPathV3(SHARED_NOTES, firstMeetingId, 'prosemirror', authState.userId)}
        page={SHARED_NOTES}
        setProseMirrorEditorView={setProseMirrorEditorView}
        displayNotes={false}
      />
    );
  }

  return (
    <SpinnerLoadingPage />
  );
};

export default FirstMeeting;
