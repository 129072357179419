/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { gray8, gray9 } from '../../../../../../shared/colours';
import Switch from '../../../../../../shared/components/switch';
import ReactTooltip from '../../../../../../shared/components/tooltip/ReactTooltip';
import InfoQuestionIcon16 from '../../../../../../shared/icons/infoQuestionIcon16';
import { uiText } from '../../../../../../shared/typography';
import HelpIconAtom from '../../../../../all-notes/HelpIconAtom';
import { SettingContainer, SettingLabel, SettingSubText } from '../SettingTab';

const AutoSyncContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 4px 0 16px 0;
`;

const AutoSyncText = styled.p`
  ${uiText}
  color: ${gray9};
  padding: 0px;
`;

const InfoIconContainer = styled.div`
  display: flex;
  margin: 14px 0px 14px 4px;
  cursor: pointer;
`;

const SwitchContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: 16px;
`;

interface Props {
  settingLabel: string,
  settingSubText: string,
  toggleText: string,
  setChecked: React.Dispatch<React.SetStateAction<boolean>>,
  checked: boolean
}

export default function SyncSettingTab({
  settingLabel, settingSubText, toggleText, setChecked, checked,
}:Props) {
  return (
    <SettingContainer>
      <SettingLabel>{settingLabel}</SettingLabel>
      <AutoSyncContainer>
        <AutoSyncText>
          {toggleText}
        </AutoSyncText>
        <InfoIconContainer>
          <ReactTooltip tip={settingSubText} multiline>
            <HelpIconAtom />
          </ReactTooltip>
        </InfoIconContainer>
        <SwitchContainer>
          <Switch setChecked={setChecked} checked={checked} />
        </SwitchContainer>
      </AutoSyncContainer>
    </SettingContainer>
  );
}
