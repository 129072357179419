/* eslint-disable indent */
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { useHotkeys } from 'react-hotkeys-hook';
import { isValidMeetingSection, setMeetingSectionCarefully } from '../../utils/meetings/meetingsUtils';
import { AuthContext, UserDataContext } from '../../App';
import {
  GapiMeetingData, MeetingData, MeetingAnalyticsData,
  Shortcut, UserCenterPage, MeetingSection,
  TemplateData, User, AuthState,
  TaskTab, TaskItems, PrivateNoteData,
} from '../../shared/types/types';
import dbListenToMeetingData from '../../database/firebaseMeetingAPI';
import { logExternalSourceVisit, logPageEvent, mapReferenceTrackingToExternalSource } from '../../utils/analytics/eventLogger';
import { dbListenForMeetingAnalyticsData } from '../../database/firebaseAnalyticsAPI';
import INIT_MEETING_ANALYTICS_DATA from '../../utils/analytics/analyticsDataObjects';
import { dbListenToTasksForMeeting } from '../../database/firebaseTasksAPI';
import { getUnviewedTasksAssignedToMe } from '../../utils/tasks/tasksUtils';
import { leftKeyNavigation, rightKeyNavigation } from '../../shared/components/firepad/shortcutUtils';
import { pendingGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import { pendingMeetingData } from '../../database/utils/templateMeetingData';
import { dbListenToPrivateNotesData } from '../../database/firebasePrivateNotesAPI';
import { SidebarStateContext } from '../../ListenToSidebarMessageContainer';
import { MEETING_PAGE } from '../../utils/analytics/enums';
import { updateFriendListV2 } from '../../../src/external/FriendListV2/dbFriendListV2Api';
import MeetingPageTemplate from './MeetingPageTemplate';
import MeetingLoadingPage from '../../shared/components/loading/shepherd-logo-loading-page/MeetingLoadingPage';
import useGetQueryParam from '../../utils/hook/useGetParams';
import useFirstUserLoadEffect from '../../utils/user/useFirstUserLoadEffect';
import GoogleAPI from '../../external/GoogleAPI/GoogleAPI';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import DashboardWelcomePage from '../dashboard-welcome-page';

type Props = {
  match: any;
  section: UserCenterPage,
  setSection: Dispatch<SetStateAction<UserCenterPage>>,
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
  taskItems: TaskItems,
  setTaskItems: Dispatch<SetStateAction<TaskItems>>,
  meetingTab: MeetingSection,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  isTemplatesOpen: boolean,
  isEditTemplateEnabled: boolean,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
  selectedTemplateToEdit: TemplateData,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  isRecurringMeeting: boolean,
  meetingData: MeetingData,
  setMeetingData: Dispatch<SetStateAction<MeetingData>>,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>,
  setPrivateNoteOpen: Dispatch<SetStateAction<boolean>>,
  activePrivateNoteId: string,
  /* eslint-disable-next-line */
  onClickSelectPrivateNote: (chatId: string) => void,
  privateNotes: PrivateNoteData[],
  setPrivateNotes: Dispatch<SetStateAction<PrivateNoteData[]>>,
}

const Meeting = ({
  match,
  section,
  setSection,
  taskTab,
  setTaskTab,
  taskItems,
  setTaskItems,
  meetingTab,
  setMeetingTab,
  isTemplatesOpen,
  isEditTemplateEnabled,
  setIsTemplatesOpen,
  setSelectedTemplateToEdit,
  selectedTemplateToEdit,
  setCreateTaskModalOpen,
  isRecurringMeeting,
  meetingData,
  setMeetingData,
  setProseMirrorEditorView,
  setPrivateNoteOpen,
  privateNotes,
  setPrivateNotes,
  activePrivateNoteId,
  onClickSelectPrivateNote,
}: Props) => {
  const meetingId: string = match?.params?.meetingId ?? '';

  const referenceTracking: string = useGetQueryParam('ref');
  const authState = useContext(AuthContext);
  const userData = useContext(UserDataContext);
  const location = useLocation();
  const [meetingAnalyticsData,
    setMeetingAnalyticsData] = useState<MeetingAnalyticsData>(INIT_MEETING_ANALYTICS_DATA);
  const [gapiMeetingData, setGapiMeetingData] = useState<GapiMeetingData>(pendingGapiMeetingData);
  // indicates if data in notes/tasks is synchronized
  const [, setTabContentSynchronized] = useState<boolean>(true);
  const [, setNotesContent] = useState<string>('');
  const [membersInvited, setMembersInvited] = useState<boolean>(false);

  const isSidebarOpen = useContext(SidebarStateContext);
  const meetingDataVersion = meetingData.version;

  const { trackEvent } = useIntercom();

  useHotkeys('ctrl+right', () => rightKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('option+right', () => rightKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('ctrl+left', () => leftKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('option+left', () => leftKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion), {}, [meetingTab, meetingDataVersion]);
  useHotkeys('alt+t', () => setCreateTaskModalOpen(true), {}, [meetingTab, meetingDataVersion]);

  useEffect(() => {
    if (isValidMeetingId(meetingId) && authState.userState === 'loggedIn') {
      const unsub1 = dbListenToPrivateNotesData(
        meetingId, authState.userId, authState.email, setPrivateNotes,
);
      setGapiMeetingData(pendingGapiMeetingData);
      setMeetingData(pendingMeetingData);
      const unsub2 = dbListenToMeetingData(
        meetingId, authState.userId, setMeetingData, setGapiMeetingData,
      );
      return () => {
        unsub1();
        unsub2();
      };
    }
    return () => { };
  }, [meetingId, authState]);

  useEffect(() => {
    if (isValidMeetingId(meetingId) && isValidEmail(userData.data.email)) {
      return dbListenToTasksForMeeting(meetingId, userData.data.email, setTaskItems);
    }
    return () => { };
  }, [meetingId, userData.data.email]);

  useEffect(() => {
    if (!isValidEventAndCalendarIdAndUserData(meetingData, userData)) return;
    GoogleAPI.getGapiDataForMeeting(meetingData, userData, setGapiMeetingData);
  }, [meetingData.googleData.ids.eventId,
  meetingData.googleData.ids.calendarId,
  meetingData.attendees.resolvedState]);

  useEffect(() => {
    const meetingSectionQueryParam = useGetQueryParam('meetingSection');
    if (isValidMeetingSection(meetingSectionQueryParam)) {
      const newMeetingSection = meetingSectionQueryParam as MeetingSection;
      setMeetingSectionCarefully(newMeetingSection, meetingData.version, setMeetingTab);
    }
  }, [location]);

  useFirstUserLoadEffect(() => {
    const meetingSectionQueryParam = useGetQueryParam('meetingSection');
    if (isValidMeetingSection(meetingSectionQueryParam)) return;
    setMeetingSectionCarefully(
      userData.settings.defaultUserTab, meetingData.version, setMeetingTab,
    );
  }, [userData]);

  useEffect(() => {
    if (membersInvited) {
      setTimeout(() => {
        setMembersInvited(false);
      }, 5000);
    }
  }, [membersInvited]);

  useEffect(() => {
    updateFriendListV2(meetingData, userData);
  }, [meetingData, userData]);

  useEffect(() => {
    if (meetingId.length === 0) return () => { };
    return dbListenForMeetingAnalyticsData(meetingId, setMeetingAnalyticsData);
  }, [meetingId]);

  useEffect(() => {
    // Analytics
    if (isValidMeetingId(meetingId) && isLoggedIn(authState) && isSidebarOpen) {
      ConsoleImproved.log('Logging meeting page analytics');
      logAnalytics(); // TODO: Make sure we log in full page
    }
    return () => { };
  }, [meetingId, authState, isSidebarOpen]);

  useEffect(() => {
    if (!isLinkedFromExternalSource(referenceTracking)) return;
    logExternalSourceVisit(referenceTracking, trackEvent);
  }, [referenceTracking]);

  const isLinkedFromExternalSource = (reference: string) => {
    const externalSource = mapReferenceTrackingToExternalSource(reference);

    if (externalSource === 'unknown') return false;
    return true;
  };

  /* later to set user in groups

  useEffect(() => {
    if (sidebarState && meetingData.meetingId.length !== 0) {
                setMixpanelMeetingGroup(meetingData);
    }
  }, [sidebarState, meetingData.meetingId]); */

  const logAnalytics = () => {
    logPageEvent(authState.userId, trackEvent, MEETING_PAGE, meetingId);
  };

  const closeTemplatesView = () => {
    setIsTemplatesOpen(false);
  };

  const handleShortcutTrigger = (shortcut: Shortcut) => {
    if (shortcut === 'navigateLeft') {
      leftKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion);
    }
    if (shortcut === 'navigateRight') {
      rightKeyNavigation(meetingTab, setMeetingTab, meetingDataVersion);
    }
    if (shortcut === 'openCreateTask') {
      setCreateTaskModalOpen(true);
    }
  };

  const unviewedTasksAssignedToMe = getUnviewedTasksAssignedToMe(taskItems.tasks, authState.email);
  const calendarError = gapiMeetingData.resolvedState === 'rejected';
  const attendeesLoading = meetingData.attendees.resolvedState === 'pending';

  if (section !== 'currentMeeting') return null;

  if (meetingId.length === 0) return <DashboardWelcomePage />;

  if (meetingData.resolvedState === 'pending') {
    return <MeetingLoadingPage text="Fetching Meeting Data..." />;
  }

  return (
    <MeetingPageTemplate
      setSection={setSection}
      taskTab={taskTab}
      setTaskTab={setTaskTab}
      meetingData={meetingData}
      meetingAnalyticsData={meetingAnalyticsData}
      attendeesLoading={attendeesLoading}
      calendarError={calendarError}
      setMembersInvited={setMembersInvited}
      closeTemplatesView={closeTemplatesView}
      unviewedTasksAssignedToMe={unviewedTasksAssignedToMe}
      meetingDataVersion={meetingDataVersion}
      authState={authState}
      meetingId={meetingId}
      setNotesContent={setNotesContent}
      setTabContentSynchronized={setTabContentSynchronized}
      handleShortcutTrigger={handleShortcutTrigger}
      membersInvited={membersInvited}
      taskItems={taskItems}
      meetingTab={meetingTab}
      setMeetingTab={setMeetingTab}
      isTemplatesOpen={isTemplatesOpen}
      setIsTemplatesOpen={setIsTemplatesOpen}
      setCreateTaskModalOpen={setCreateTaskModalOpen}
      isRecurringMeeting={isRecurringMeeting}
      setProseMirrorEditorView={setProseMirrorEditorView}
      setPrivateNoteOpen={setPrivateNoteOpen}
      privateNotes={privateNotes}
      setPrivateNotes={setPrivateNotes}
      activePrivateNoteId={activePrivateNoteId}
      onClickSelectPrivateNote={onClickSelectPrivateNote}
      isEditTemplateEnabled={isEditTemplateEnabled}
      setSelectedTemplateToEdit={setSelectedTemplateToEdit}
      selectedTemplateToEdit={selectedTemplateToEdit}
    />
  );
};

export default Meeting;

function isValidMeetingId(meetingId: string) {
  return meetingId.length > 10;
}

function isValidEmail(email: string) {
  return email.length > 4;
}

const isValidEventAndCalendarIdAndUserData = (
  meetingData: MeetingData,
  userData: User,
) => {
  if (!isValidMeetingId(meetingData.meetingId)) return false;
  if (meetingData.resolvedState !== 'resolved') return false;
  if (meetingData.googleData.ids.eventId.length === 0
    || meetingData.googleData.ids.calendarId.length === 0) return false;
  if (userData.resolvedState !== 'resolved') return false;
  if (meetingData.attendees.resolvedState !== 'resolved') return false;
  return true;
};

const isLoggedIn = (authState: AuthState) => authState.userState === 'loggedIn';
