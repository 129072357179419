import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import CreateTask from '../../../shared/components/create-task';
import PreviousMeetingButtonMol from '../../../shared/components/previous-meeting-notes/components/PreviousMeetingButtonMol';
import BREAKPOINTS, { CSS_CONSTANTS } from '../../../shared/CSS/CSS_Constants';
import {
  MeetingData, MeetingSection, PrivateNoteData, TaskItems, TemplateData, UserCenterPage,
} from '../../../shared/types/types';
import { USER_CENTER_ALL_NOTES, USER_CENTER_CURRENT_MEETING } from '../../../utils/enums';
import ShareMeetingNotes from '../../meeting/share-meeting';
import TemplateEditBottomBar from '../../meeting/templates/components/TemplateEditBottomBar';
import ContainerAtom from './ContainerAtom';
import MoreOptions from './more-options/MoreOptions';

const TransparentBackgroundContainer = styled.div`
  background-color: transparent;
  width: 100%;
`;

const BottomButtonMarginToSide = '25px';

const PreviousBtnStyled = styled.div`
  @media only screen and (min-width: ${BREAKPOINTS.xl}){
    display: none;
  }
  @media only screen and (max-width: ${BREAKPOINTS.xl}){
    display: block;
  }
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

const ActionButtonStyled = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  right: ${BottomButtonMarginToSide};
  @media only screen and (min-width: ${CSS_CONSTANTS.MAX_WIDTH_FULL_PAGE}){
    right: calc((100vw - ${CSS_CONSTANTS.MAX_WIDTH_FULL_PAGE})*0.5 + ${BottomButtonMarginToSide} );
  }
`;

interface Props {
  isEditTemplateBottomBarEnabled: boolean
  meetingData: MeetingData,
  section: UserCenterPage,
  taskItems: TaskItems,
  meetingTab: MeetingSection,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  isCreateTaskModalOpen: boolean,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  setIsRecurringMeeting: Dispatch<SetStateAction<boolean>>,
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
  selectedTemplateToEdit: TemplateData,
  privateNotes: PrivateNoteData[],
}

const BottomBar = ({
  meetingData,
  section,
  taskItems,
  meetingTab,
  setIsTemplatesOpen,
  isCreateTaskModalOpen,
  setCreateTaskModalOpen,
  setIsRecurringMeeting,
  privateNotes,
  isEditTemplateBottomBarEnabled,
  setSelectedTemplateToEdit,
  selectedTemplateToEdit,
}: Props) => {
  if (isEditTemplateBottomBarEnabled) {
    return (
      <TransparentBackgroundContainer>
        <ContainerAtom>
          <></>
        </ContainerAtom>
        <TemplateEditBottomBar
          isEnabled={isEditTemplateBottomBarEnabled}
          meetingTab={meetingTab}
          setSelectedTemplateToEdit={setSelectedTemplateToEdit}
          selectedTemplateToEdit={selectedTemplateToEdit}
        />
      </TransparentBackgroundContainer>
    );
  }

  if (section === USER_CENTER_ALL_NOTES) return null;

  if (meetingData.meetingId.length === 0) return null;

  if (section === USER_CENTER_CURRENT_MEETING && meetingTab !== 'task') {
    return (
      <TransparentBackgroundContainer>
        <ContainerAtom>
          <></>
        </ContainerAtom>
        <PreviousBtnStyled>
          <PreviousMeetingButtonMol
            meetingData={meetingData}
            setIsRecurringMeeting={setIsRecurringMeeting}
          />
        </PreviousBtnStyled>
        <ActionButtonStyled>
          <ShareMeetingNotes
            taskItems={taskItems.tasks}
            privateNotes={privateNotes}
            closeDropdown={() => { }}
          />
          <MoreOptions
            meetingData={meetingData}
            meetingTab={meetingTab}
            isCreateTaskModalOpen={isCreateTaskModalOpen}
            setCreateTaskModalOpen={setCreateTaskModalOpen}
            setIsTemplatesOpen={setIsTemplatesOpen}
          />
        </ActionButtonStyled>
      </TransparentBackgroundContainer>
    );
  }
  return (
    <TransparentBackgroundContainer>
      <ContainerAtom>
        <></>
      </ContainerAtom>
      <ActionButtonStyled>
        <CreateTask
          meetingData={meetingData}
          isOpen={isCreateTaskModalOpen}
          setIsOpen={setCreateTaskModalOpen}
        />
      </ActionButtonStyled>
    </TransparentBackgroundContainer>
  );
};

export default BottomBar;
