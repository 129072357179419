import React from 'react';
import styled from 'styled-components';
import { blue6, gray1 } from '../../../shared/colours';
import useWindowSize from '../../../utils/hook/useWindowSize';
import OnboardingWelcomeScreensModel from '../../onboarding/welcome/utils/onboarding-welcome-screens-model';
import TextAtom from './TextAtom';
import VideoPlayerAtom from './VideoPlayerAtom';

interface StyleProps {
  isScreenSizeSM: boolean
}

const Container = styled.div<StyleProps>`
  background: ${gray1};
  padding: 16px 12px;
  width: ${({ isScreenSizeSM }) => (isScreenSizeSM ? '340px' : '440px')};
  box-shadow: 0px 14px 24px rgba(27, 33, 36, 0.16), 0px 8px 8px -4px rgba(27, 33, 36, 0.04);
  border-radius: 8px;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  img {
    margin-right: 20px;
  }

  span:first-of-type {
    margin-right: 8px;
  }
`;

const RefresherPopupMolecule = () => {
  const { isScreenSizeSM } = useWindowSize();
  const handleWatchTutorial = () => {
    const { videoSrc } = OnboardingWelcomeScreensModel.video;
    window.open(videoSrc);
  };

  return (
    <Container isScreenSizeSM={isScreenSizeSM}>
      <InnerContainer>
        <VideoPlayerAtom />
        <TextAtom text="Need a Shepherd refresher?" />
        <TextAtom text="Watch a tutorial" color={blue6} onClick={handleWatchTutorial} />
      </InnerContainer>
    </Container>
  );
};

export default RefresherPopupMolecule;
