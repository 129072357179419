import { dbUpdateMeetingAnalyticsData } from '../../database/firebaseAnalyticsAPI';
import { stripWhitespaceAndHtmlTag } from '../../utils/strings';
import { NOTES_EVENT_TRIGGER_THRESHOLD } from '../../utils/constants';
import {
  logEventMeetingWithShepherdUsed,
  logEventMeetingHasAgenda,
  logEventMeetingHasPrivateNotes,
  logEventMeetingHasSharedNotes,
} from '../../utils/analytics/eventLogger';
import {
  MeetingSection, MeetingAnalyticsData, TimestampLog,
} from '../../shared/types/types';
import { AGENDA, SHARED_NOTES, PERSONAL_NOTES } from '../../utils/enums';
import { NotesFor } from '../../utils/analytics/enums';

export const hasEnoughTextToTriggerLog = (notes: string) => stripWhitespaceAndHtmlTag(notes).length
  > NOTES_EVENT_TRIGGER_THRESHOLD;

export const triggerEventMeetingWithShepherdUsed = (
  analyticsData: MeetingAnalyticsData,
  notes: string, meetingId: string, userId: string,
  timestampObj: TimestampLog, notesFor: NotesFor, intercomTrack: any,
) => {
  if (hasEnoughTextToTriggerLog(notes)
    && (analyticsData?.hasUsedShepherd !== true
    || !userIdExistInUsersArray(analyticsData.users, userId))) {
    dbUpdateMeetingAnalyticsData(
      meetingId,
      () => {
        logEventMeetingWithShepherdUsed(meetingId, userId, timestampObj,
          notesFor, intercomTrack);
      },
      (doc) => doc.data().hasUsedShepherd !== true
        || !userIdExistInUsersArray(doc.data().users, userId),
      (doc) => ({
        hasUsedShepherd: true,
        users: doc.data().users ? [...(doc.data().users), { userId }] : [{ userId }],
      }),
    );
  }
};

export const triggerEventMeetingHasAgenda = (
  userId: string,
  analyticsData: MeetingAnalyticsData,
  notes: string, page: MeetingSection, meetingId: string,
  timestampObj: TimestampLog, notesFor: NotesFor, intercomTrack: any,
) => {
  if (page === AGENDA && hasEnoughTextToTriggerLog(notes)
    && !analyticsData?.hasAgenda) {
    dbUpdateMeetingAnalyticsData(
      meetingId,
      () => {
        logEventMeetingHasAgenda(userId, meetingId, timestampObj,
          notesFor, intercomTrack);
      },
      (doc) => doc.data().hasAgenda !== true,
      () => ({ hasAgenda: true }),
    );
  }
};

export const triggerEventMeetingHasSharedNotes = (
  userId: string,
  analyticsData: MeetingAnalyticsData,
  notes: string, page: MeetingSection,
  meetingId: string, timestampObj: TimestampLog, notesFor: NotesFor, intercomTrack: any,
) => {
  if (page === SHARED_NOTES
    && hasEnoughTextToTriggerLog(notes)
    && !analyticsData?.hasSharedNotes) {
    dbUpdateMeetingAnalyticsData(
      meetingId,
      () => {
        logEventMeetingHasSharedNotes(
          userId, meetingId, timestampObj,
          notesFor, intercomTrack,
        );
      },
      (doc) => doc.data().hasSharedNotes !== true,
      () => ({ hasSharedNotes: true }),
    );
  }
};

export const triggerEventMeetingHasPrivateNotes = (
  userId: string,
  analyticsData: MeetingAnalyticsData,
  notes: string, page: MeetingSection,
  meetingId: string, timestampObj: TimestampLog, notesFor: NotesFor, intercomTrack: any,
) => {
  if (page === PERSONAL_NOTES
    && hasEnoughTextToTriggerLog(notes)
    && !analyticsData?.hasPrivateNotes) {
    dbUpdateMeetingAnalyticsData(
      meetingId,
      () => {
        logEventMeetingHasPrivateNotes(
          userId, meetingId, timestampObj, notesFor, intercomTrack,
        );
      },
      (doc) => doc.get('hasPrivateNotes') !== true,
      () => ({ hasPrivateNotes: true }),
    );
  }
};

const userIdExistInUsersArray = (users: any, userId: string) => users?.some(
  (user: any) => user.userId === userId,
);
