import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface AddRowAfterProps {
  fill?: string;
  width?: number;
  height?: number;
}

const AddRowAfterIcon = ({
  fill,
  width,
  height,
}: AddRowAfterProps) => (
  <svg width={width} height={height} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 7.75,0 C 7.33579,0 7,0.33579 7,0.75 V 2 H 5.75 C 5.33579,2 5,2.33579 5,2.75 5,3.16421 5.33579,3.5 5.75,3.5 H 7 V 4.75 C 7,5.16421 7.33579,5.5 7.75,5.5 8.16421,5.5 8.5,5.16421 8.5,4.75 V 3.5 H 9.75 C 10.1642,3.5 10.5,3.16421 10.5,2.75 10.5,2.33579 10.1642,2 9.75,2 H 8.5 V 0.75 C 8.5,0.33579 8.16421,0 7.75,0 Z m -5,2.25 C 1.23122,2.25 0,3.48122 0,5 v 1 c 0,1.5188 1.23122,2.75 2.75,2.75 h 10 C 14.2688,8.75 15.5,7.5188 15.5,6 V 5 c 0,-1.51878 -1.2312,-2.75 -2.75,-2.75 h -0.5 c -0.4142,0 -0.75,0.33579 -0.75,0.75 0,0.41421 0.3358,0.75 0.75,0.75 h 0.5 C 13.4404,3.75 14,4.30964 14,5 v 1 c 0,0.6904 -0.5596,1.25 -1.25,1.25 h -10 C 2.05964,7.25 1.5,6.6904 1.5,6 V 5 C 1.5,4.30964 2.05964,3.75 2.75,3.75 h 0.4375 c 0.41421,0 0.75,-0.33579 0.75,-0.75 0,-0.41421 -0.33579,-0.75 -0.75,-0.75 z"
      fill={fill}
    />
  </svg>
);

AddRowAfterIcon.defaultProps = {
  fill: darkBlue4,
  width: 16,
  height: 9,
};

export default AddRowAfterIcon;
