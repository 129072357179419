import React from 'react';
import styled from 'styled-components';
import SlackNotificationsOrganism from '../../../../../pages/meeting/modal/SlackModalComponents/SlackNotifications/SlackNotificationsOrganism';
import { ModalLineLocal } from '../../../../../pages/meeting/modal/ShareNotesModal';
import { ResolveStateExtended, SlackNotificationsSettings } from '../../../../types/types';
import ButtonSmall from '../../../button-small';
import Modal from '../../../Modal';
import ModalFooterWrapper from '../../../Modal/ModalFooterWrapper';
import UpdatedAtom from './UpdatedAtom';

const UpdatedContainer = styled.div`
  margin-right: 99px;
`;

const BtnsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  button:last-child {
      margin-left: 12px;
    }
`;

const Container = styled.div`
  padding: 28px 24px 35px 24px;
`;

export interface SlackNotificationsModalProps {
  isOpen: boolean,
  setClosed: () => void,
  // eslint-disable-next-line no-unused-vars
  updateSlackNotifications: (val: SlackNotificationsSettings) => void,
  isResolved: ResolveStateExtended,
  notifications: SlackNotificationsSettings,
  isIntegratingSlack?: boolean,
}

const SlackNotificationsModal = ({
  isOpen,
  setClosed,
  updateSlackNotifications,
  isResolved,
  notifications,
  isIntegratingSlack,
}: SlackNotificationsModalProps) => {
  const handleBackToSettingsClick = () => {
    setClosed();
  };

  return (
    <Modal
      title="Slack notification settings"
      isOpen={isOpen}
      setModalClosed={setClosed}
    >
      <Container>
        <SlackNotificationsOrganism
          updateSlackNotifications={updateSlackNotifications}
          notifications={notifications}
          isResolved={isResolved}
        />
      </Container>
      <ModalLineLocal />
      <ModalFooterWrapper>
        <UpdatedContainer>
          <UpdatedAtom isUpdated={isResolved} />
        </UpdatedContainer>
        <BtnsContainer>
          <ButtonSmall text="Back" isOutline onClick={handleBackToSettingsClick} />
          {
            isIntegratingSlack && (
              <ButtonSmall text="Finish" onClick={handleBackToSettingsClick} />
            )
          }
        </BtnsContainer>
      </ModalFooterWrapper>
    </Modal>
  );
};

SlackNotificationsModal.defaultProps = {
  isIntegratingSlack: false,
};

export default SlackNotificationsModal;
