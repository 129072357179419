/* eslint-disable no-unused-vars */
import prependHttp from 'prepend-http';
import { DOMParser, Fragment, Slice } from 'prosemirror-model';
import {
  EditorState, Plugin, PluginKey, Selection,
} from 'prosemirror-state';
import { ProsemirrorNode } from 'prosemirror-suggest';
import { Decoration, DecorationSet, EditorView } from 'prosemirror-view';
import { consoleLogCouldntFindMeeting } from '../../../../../utils/google/GoogleCalendarAPI';
import { LinkPreviewInitState } from '../../components/widgets/link-preview';
import lazyTransactionAdapter from '../adapters/lazy-transaction-adapter';
import linkPreviewAdapter from '../adapters/link-preview-adapter';

export const linkPreviewPKName = 'link_preview';
export const linkPreviewPK = new PluginKey(linkPreviewPKName);

// Used to create the decoration set via DecorationSet.create()
// TODO more explicit type can be added. There are only 2 types of decorations used.
type DecorationArray = Decoration<{ [key: string]: any;}>[];

// Used to compose type for plugin transaction metadata and plugin state
export interface OptionalLinkPreview {
  widget?: LinkPreviewInitState;
}

// Used to compose plugin transaction metadata
interface LinkPreviewMetaControls {
  remove: boolean;
}

// Used to compose plugin state
interface LastPluginState {
  decorations: DecorationSet;
}

// Define the plugin state
export type LinkPreviewPluginState = LastPluginState & OptionalLinkPreview;

/**
 * Transaction metadata for the preview link plugin
 */
export interface LinkPreviewMeta {
  selection: Selection<any>;
  options: OptionalLinkPreview & LinkPreviewMetaControls;
}

/**
 * Default options for the plugin metadata
 */
const defaultOptions: LinkPreviewMeta['options'] = {
  remove: false,
  widget: undefined,
};

const createLinkReplacerNode = (currentSchema: EditorState['schema'], url: URL): ProsemirrorNode => {
  const linkMark = currentSchema.marks.link.create({
    title: url.origin,
    href: url.href,
  });

  const txt = currentSchema.text(url.origin, [linkMark]);
  return currentSchema.nodes.textInlineNode.create(
    { text: url.origin },
    [txt],
    [],
  );
};

type AnchorReplacementOptions = {
  rootOffset: number; // offset from fragment container
  offset: number; // offset from parent
  rootIndex: number; // child index from fragment container
  index: number; // child index from parent
}

type AnchorReplacement = {
  parent: ProsemirrorNode;
  target: ProsemirrorNode;
} & AnchorReplacementOptions;

const calculateNestedReplaceFor = (
  parent: ProsemirrorNode,
  { rootOffset, rootIndex }: AnchorReplacementOptions,
  toReplace: AnchorReplacement[],
) => {
  parent.forEach((target, offset, index) => {
    const hasLinkMarkup = target.marks.some((mark) => mark.type.name === 'link');
    const newOptions: AnchorReplacementOptions = {
      rootOffset, // offset from fragment container
      offset, // offset from parent
      rootIndex, // child index from fragment container
      index, // child index from parent
    };
    if (hasLinkMarkup) {
      toReplace.push({
        parent,
        target,
        ...newOptions,
      });
    } else {
      calculateNestedReplaceFor(target, newOptions, toReplace);
    }
  });
};

export default (currentSchema: EditorState['schema']) => new Plugin<LinkPreviewPluginState>({
  state: {
    init(config, instance) {
      return {
        decorations: DecorationSet.empty,
        widget: undefined,
      };
    },
    /**
       * Currently this works on the assumption that only one link preview is allowed at a time.
       * Thus no tr.docChanged is checked and nothing is repurposed from the old set of decorations.
       *
       * On every doc change this is run and expected to contain metadata fields
       *  if none are found => old decorations are kept
       *  otherwise it returns either an empty DecorationSet on invalid metadata
       *    or a link preview decoration applied on the selection slice.
       *    or a link widget preview.
       */
    apply(tr, state: LinkPreviewPluginState, oldState, newState) {
      // new decorations
      const decorations: DecorationArray = [];
      // metadata
      const linkMeta = tr.getMeta(linkPreviewPK) as LinkPreviewMeta;
      if (linkMeta) {
        const { selection } = linkMeta;
        const { from, to } = selection;
        const { remove, widget } = linkMeta.options || defaultOptions;

        if (remove) {
          // this does not remove anything
          // currently this has a side effect of returning an empty set equivalent with
          // DecorationSet.empty
          // read the comment above for more details
          // set.find(from, to).forEach((decoration: Decoration) => {
          //   const docMeta = decoration.spec;
          //   console.debug('docMeta: ', JSON.stringify(docMeta));
          // });
          linkPreviewAdapter.closePreviewDropdown();
          return {
            decorations: DecorationSet.empty,
            widget: undefined,
          };
        }

        if (widget) {
          linkPreviewAdapter.openPreviewDropdown(widget);
        } else {
          decorations.push(
            Decoration.inline(from, to, {
              class: 'link_preview',
            }),
          );
        }

        return {
          decorations: DecorationSet.create(tr.doc, decorations),
          widget,
        };
      }
      // if no new metadata is found return the old decoration list.
      return state;
    },
  },
  props: {
    decorations(state) {
      return this.getState(state).decorations;
    },

    createSelectionBetween(view, anchor, head) {
      // Remove the plugin when it is active and the cursor position changed
      const { state } = view;
      const pluginState = linkPreviewPK.getState(state);

      if (pluginState && pluginState.widget) {
        const { tr, selection } = state;

        view.dispatch(
          tr.setMeta(linkPreviewPK, {
            selection,
            options: { // optional
              remove: true,
            },
          } as LinkPreviewMeta),
        );
      }
      return undefined;
    },

    handleClickOn(this, view, pos, node, nodePos, event, direct) {
      // the only possible node a link could be content of
      if (node.type.name === 'textInlineNode') {
        const path = event.composedPath();
        const editorDOMIndex = path.findIndex((el) => el === view.dom);
        const relevantPath = path
          .splice(0, editorDOMIndex + 1)
          .map((el) => el as unknown as HTMLElement);
        const searchNode = relevantPath.find((el) => el.nodeName.toLowerCase() === 'a');
        if (searchNode) {
          const { state } = view;
          const { tr, selection } = state;
          const anchor = searchNode as HTMLElement;

          if (event.ctrlKey) {
            // open link in new tab
            const href = anchor.getAttribute('href');
            if (href) window.open(href, '_blank');
          } else {
            // open link preview
            view.dispatch(
              tr.setMeta(linkPreviewPK, {
                selection,
                options: { // optional
                  widget: {
                    anchor,
                    anchorNode: node,
                    href: anchor.getAttribute('href'),
                  },
                  remove: false,
                },
              } as LinkPreviewMeta),
            );
          }
        }
      }
      return false;
    },

    handleKeyDown(this, view, event) {
      // Remove the plugin when it is active and the user pressed ESC
      const pluginState = this.getState(view.state);
      const { state } = view;
      const { tr, selection } = state;
      if (event.key === 'Escape' && pluginState.widget) {
        // close only if escape while active widget
        view.dispatch(
          tr.setMeta(linkPreviewPK, {
            selection,
            options: { // optional
              remove: true,
            },
          } as LinkPreviewMeta),
        );
      }
      return false;
    },

    transformPasted(this, slice) {
      let {
        content: fragment,
      } = slice;

      const {
        openEnd,
        openStart,
      } = slice;

      const toReplace: Array<{childIndex: number, node: ProsemirrorNode}> = [];
      fragment.forEach((paragraph, offset, childIndex) => {
        if (paragraph.childCount === 1) {
          const node = paragraph.firstChild!;
          const notInlineNode = node.type.name !== 'textInlineNode';
          const linkMarkup = node.marks.find((mark) => mark.type.name === 'link');
          if (notInlineNode && linkMarkup) {
            toReplace.push({
              childIndex,
              node: currentSchema.nodes.paragraph.createAndFill({}, [
                createLinkReplacerNode(currentSchema, new URL(linkMarkup.attrs.href)),
              ]),
            });
          }
        }
      });

      if (!toReplace.length) return slice;

      toReplace.forEach(({ childIndex, node }) => {
        fragment = fragment.replaceChild(childIndex, node);
      });

      return new Slice(fragment, openStart, openEnd);
    },

    //* This is an example of another solution which can work on complex nested nodes
    //* It has no limitation and it can be used to path any anchor existent on the doc
    //* it's only limitation is that currently, the new nodes calculated cannot be
    //*  traced back to its original location, leaving the original fragment untouched.
    // transformPasted(this, slice) {
    //   const {
    //     content: fragment,
    //   } = slice;

    //   const toReplace: AnchorReplacement[] = [];
    //   fragment.forEach((paragraph, rootOffset, rootIndex) => {
    //     calculateNestedReplaceFor(
    //       paragraph,
    //       {
    //         rootOffset,
    //         rootIndex,
    //         index: 0, // to be calculated
    //         offset: 0, // to be calculated
    //       },
    //       toReplace,
    //     );
    //   });

    //   if (toReplace.length) {
    //     toReplace.forEach(
    //       ({
    //         parent, target, rootIndex, rootOffset, index, offset,
    //       }) => {
    //         const nodeLinkMark = target.marks.find((mark) => mark.type.name === 'link');
    //         if (!nodeLinkMark || parent.type.name === 'textInlineNode') return;

    //         const newNode = createLinkReplacerNode(
    //           currentSchema,
    //           new URL(nodeLinkMark.attrs.href),
    //         );

    //         const newParent = parent.replace(
    //           offset, offset + target.nodeSize,
    //           new Slice(Fragment.from(newNode), 0, 0),
    //         );
    //         // TODO update newParent inside fragment
    //       },
    //     );
    //   }

    //   return slice;
    // },
    //* a proposal is to add a ProsemirrorNode return value to calculateNestedReplaceFor
    //* and have the node be build recursively until node.nextChild() is null
  },
  key: linkPreviewPK,
});
