import firebase from 'firebase';
import * as Sentry from '@sentry/browser';
import { DummySetLoading, MeetingData, SetLoadingType } from '../shared/types/types';
import { DEFAULT_DB_ERROR_MESSAGE } from '../utils/constants';

// TODO: This function might not be useful anymore
export const handleDocDoesNotExist = (
  message: string,
  data: any,
  setLoading: SetLoadingType = DummySetLoading,
) => {
  console.log(message);
  printObject(data);
  setLoading(false);
};

export const handleOnSnapshotError = (
  message: string = DEFAULT_DB_ERROR_MESSAGE,
  objectToPrint: any = null,
  setLoading: SetLoadingType = DummySetLoading,
) => handleSingleDocError(message, objectToPrint, setLoading);

export const handleUpdateError = (
  message: string = DEFAULT_DB_ERROR_MESSAGE,
  objectToPrint: any = null,
) => handleSingleDocError(message, objectToPrint);

const handleSingleDocError = (
  message: string,
  objectToPrint: any,
  setLoading: SetLoadingType = DummySetLoading, data: MeetingData[] = [],
) => (
  error: firebase.firestore.FirestoreError,
) => {
  printDbError(message, error);
  printObject(objectToPrint);
  setLoading(false);
  return data;
};

const printObject = (objectToPrint: any) => {
  if (objectToPrint) {
    console.info(objectToPrint);
  }
};

const printDbError = (errorMessage: string, error: firebase.firestore.FirestoreError) => {
  Sentry.captureException(error);
  console.error(errorMessage);
  console.error(error.message);
};
