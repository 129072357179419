import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { UserDataContext } from '../../../../../../App';
import {
  blue1, blue7, darkBlue1, darkBlue2, gray1, gray9,
} from '../../../../../../shared/colours';
import {
  JiraIcon, ZoomIcon, TrelloIcon, SlackIcon, NotionIcon,
} from '../../../../../../shared/icons/integration-icons/index';
import { IntegrationsTabView } from '../../../../../../shared/types/types';
import { header400, uiTextMedium } from '../../../../../../shared/typography';
import { NOTION_PROCESSING_VIEW, SLACK_PROCESSING_VIEW, TRELLO_PROCESSING_VIEW } from '../../../../../../utils/constants';
import { notionAuthUrl } from '../../../../../../utils/notion/notionUtils';
import { slackAuthUrl } from '../../../../../../utils/slack/slackUtils';
import { trelloAuthUrl } from '../../../../../../utils/trello/trelloUtils';
import {
  ProcessingModalData, ProcessingModalFunctions, SingleIntegrationData, SingleIntegrationFunctions,
} from '../../../utils/types';
import IntegrationConnected from './IntegrationConnected';
import ProcessingModal from './ProcessingModal';

interface Props {
  data: SingleIntegrationData,
  // TODO Matt: Question from Harry: Can this be of type Dispatch<SetStateAction<boolean>>;?
  functions: SingleIntegrationFunctions,
}

const SingleIntegration = ({ data, functions }:Props) => {
  const { name, integratable } = data;

  const userData = useContext(UserDataContext);
  const slackAccessToken = userData?.integrations?.slack[0]?.userAccessToken ?? '';
  const isSlackEnabled = slackAccessToken.length > 10 ?? false;
  const isNotionEnabled = userData?.integrations.notion.length > 0 ?? false;
  const { isTrelloEnabled } = userData?.integrations.trello.settings ?? false;

  const [isConnecting, setIsConnecting] = useState(false);
  const [processingState, setProcessingState] = useState<IntegrationsTabView>('');

  const handleSlackConnect = () => {
    try {
      window.open(slackAuthUrl);
      setProcessingState(SLACK_PROCESSING_VIEW);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotionConnect = () => {
    try {
      window.open(notionAuthUrl);
      setProcessingState(NOTION_PROCESSING_VIEW);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTrelloConnect = () => {
    try {
      window.open(trelloAuthUrl);
      setProcessingState(TRELLO_PROCESSING_VIEW);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConnect = () => {
    if (name === 'Slack' || name === 'Notion' || name === 'Trello') {
      setIsConnecting(true);
    }
    if (name === 'Slack') {
      handleSlackConnect();
    }
    if (name === 'Notion') {
      handleNotionConnect();
    }
    if (name === 'Trello') {
      handleTrelloConnect();
    }
  };

  const handleButtonText = () => {
    switch (name) {
      case 'Slack':
        return isSlackEnabled ? 'Connected' : 'Connect';
      case 'Trello':
        return isTrelloEnabled ? 'Connected' : 'Connect';
      case 'Notion':
        return isNotionEnabled ? 'Connected' : 'Connect';
      default:
        return 'Coming Soon';
    }
  };

  const DisplayIcon = () => {
    switch (name) {
      case 'Slack':
        return <SlackIcon />;
      case 'Trello':
        return <TrelloIcon />;
      case 'Notion':
        return <NotionIcon />;
      case 'Jira':
        return <JiraIcon />;
      case 'Zoom':
        return <ZoomIcon />;
      default:
        return null;
    }
  };

  const disableButtonIfIntegrationNotReleased = () => {
    if (name === 'Jira' || name === 'Zoom') return true;

    return false;
  };

  const displayConnectedOrNot = () => {
    if (handleButtonText() === 'Connected') {
      return <IntegrationConnected />;
    }

    return (
      <ConnectButton
        integratable={integratable}
        onClick={handleConnect}
        disabled={disableButtonIfIntegrationNotReleased()}
      >
        { handleButtonText() }
      </ConnectButton>
    );
  };

  const setIsOpenToClosed = () => {
    setIsConnecting(false);
    setProcessingState('');
  };

  const processingModalData: ProcessingModalData = {
    isOpen: isConnecting,
    processingState,
  };

  const processingModalFunctions: ProcessingModalFunctions = {
    setIsOpenToClosed,
    setProcessingState,
    setActivateConfetti: functions.setActivateConfetti,
  };

  return (
    <Integration>
      <IntegrationIcon>{ DisplayIcon() }</IntegrationIcon>
      <IntegrationTitle>{name}</IntegrationTitle>
      { displayConnectedOrNot() }
      <ProcessingModal data={processingModalData} functions={processingModalFunctions} />
    </Integration>
  );
};

export default SingleIntegration;

const Integration = styled.div`
  width: 100%;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const IntegrationIcon = styled.div`
  height: 40px;
`;

const IntegrationTitle = styled.div`
  ${header400}
  color: ${gray9};

  width: 100%;
  
  padding: 0 0 0 24px;
`;

interface IntegrationProps {
  integratable: boolean
}

const ConnectButton = styled.button<IntegrationProps>`  
  all: unset;

  display: flex;
  align-items: center;
  justify-content: center;

  ${uiTextMedium}

  height: 16px;
  padding: 8px 24px;
  background-color: ${gray1};
  border: 2px solid ${darkBlue1};
  border-radius: 8px;
  white-space: nowrap;

  color: ${({ integratable }) => (integratable ? gray9 : darkBlue2)};

  &:hover {
    ${({ integratable }) => (integratable && css`
      background-color: ${blue1};
      color: ${blue7};
      border: 2px solid ${blue1};
      cursor: pointer;
    `)}
  }
`;
