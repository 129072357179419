import React from 'react';
import { darkBlue4 } from '../colours';

interface Props {
  fill?: string,
}

const NewFeatureMinusIcon = ({ fill }: Props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.75 9C2.75 8.44772 3.19772 8 3.75 8H14.25C14.8023 8 15.25 8.44772 15.25 9C15.25 9.55228 14.8023 10 14.25 10H3.75C3.19772 10 2.75 9.55228 2.75 9Z" fill={fill} />
  </svg>
);

NewFeatureMinusIcon.defaultProps = {
  fill: darkBlue4,
};

export default NewFeatureMinusIcon;
