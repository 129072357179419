import * as Sentry from '@sentry/react';
import mapGapiResultToGapiMeetingData, { rejectedGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import { GapiMeetingData, ResolveState } from '../../shared/types/types';
import { GAPI_MEETING_FIELD, GET_EVENT } from '../analytics/enums';
import { logGapiEvent } from '../analytics/eventLogger';
import { consoleLogCouldntFindMeeting } from './GoogleCalendarAPI';
import { mapGapiInsertMeetingResponseToGoogleMeetingIdsObj, RejectedGoogleMeetingIdsObj } from './GoogleCalendarUtils';

declare let gapi: any;
// gapi = Google API

export const gapiListUpcomingEvents = () => {
  gapi.client.calendar.events.list({
    calendarId: 'primary',
    timeMin: (new Date()).toISOString(),
    showDeleted: false,
    singleEvents: true,
    maxResults: 10,
    orderBy: 'startTime',
  }).then((response: any) => {
    console.log('Got list of upcoming events');
    console.log(response); // look in response.result.items for events
    return response.result.items;
  });
};

/**
 * @param filterOnlySelected if true, will only return those calendars you have enabled (toggled on)
 * in your google calendar
 */
export const gapiCoreGetMyCalendarIds = async (
  filterOnlySelected?: boolean,
): Promise<string[]> => gapi
  .client.calendar.calendarList.list({})
  .then((response: any) => {
    const data = response.result.items
      .filter((calendar: any) => (filterOnlySelected ? calendar?.selected : true))
      .map((calendar: any) => calendar.id as string);
    return data;
  })
  .catch((error: any) => {
    console.error('Execute error in gapiCoreGetMyCalenarIds', error);
    return [];
  });

export const gapiCoreGetMeetingsInPeriod = async (
  minTime: string, maxTime: string,
): Promise<GapiMeetingData[]> => {
  const calendarIds = await gapiCoreGetMyCalendarIds(true);
  const promises = await calendarIds
    .map(
      (calendarId) => gapiCoreGetMeetingsInCalendarInPeriod(
        calendarId, minTime, maxTime,
      ),
    );
  const tempResult = await Promise.all(promises);
  const result = tempResult.flat();
  return result;
};

const gapiCoreGetMeetingsInCalendarInPeriod = async (
  calendarId: string,
  minTime: string,
  maxTime: string,
): Promise<GapiMeetingData[]> => gapi.client.calendar.events.list({
  calendarId,
  timeMin: minTime,
  timeMax: maxTime,
  showDeleted: false,
  singleEvents: true,
  maxResults: 100,
  orderBy: 'startTime',
})
  .then((response: any) => response.result.items.map(
    (event: any) => mapGapiResultToGapiMeetingData(event),
  ))
  .catch((error: any) => {
    Sentry.captureException(error);
  });

type ResultType = {
  result: GapiMeetingData
}

/**
 * @eventId the id of the event
 * @calendarId the id of the calendar
 * @number the number of attempts to get the event.
 * Typically we don't know which calendar the user have access to the event by,
 * so we have to try with multiple. number indices how many attemps we have made to get the event.
 * @returns the resolved and mapped meeting data if found,
 * or the rejected meeting data if not found.
 */
export const gapiCoreGetMeeting = async (eventId: string, calendarId: string = 'primary', number: number = 0): Promise<GapiMeetingData> => gapi.client.calendar.events.get({
  calendarId,
  eventId,
})
  .then(({ result }: ResultType) => {
    console.log('Got GAPI meeting Data, number:', number);
    console.log(result);
    logGapiEvent(GET_EVENT, GAPI_MEETING_FIELD, true, number);
    const meetingData = mapGapiResultToGapiMeetingData(result);
    return meetingData;
  })
  .catch((error: any) => {
    logGapiEvent(GET_EVENT, GAPI_MEETING_FIELD, false, number);
    consoleLogCouldntFindMeeting(eventId, calendarId, number);
    const scope = new Sentry.Scope();
    scope.setTag('number', number);
    Sentry.captureException(error, scope);
    return rejectedGapiMeetingData;
  });

export const gapiCoreUpdateMeetingDescription = async (
  eventId: string, calendarId: string = 'primary', description: string,
): Promise<ResolveState> => gapi.client.calendar.events.patch({
  calendarId,
  eventId,
  description,
}).then((response: any) => {
  console.log('Updated meeting description');
  console.log(response);
  return 'resolved';
}).catch((error: any) => {
  console.error('Error updating meeting description');
  console.error(error);
  Sentry.captureException(error);
  return 'rejected';
});

/* using google instances api to get recurring events */
export const getRecurringEventData = (
  calendarId: string,
  eventId: string,
) => {
  const currentDateTime = (new Date()).toISOString();
  return gapi.client.calendar.events.instances({
    calendarId,
    eventId,
    timeMax: currentDateTime,
    showDeleted: true,
  })
    .then((result:any) => {
      const meetingsFromGAPI = result.result.items;
      const mappedRecurringMeetings = meetingsFromGAPI.map(mapGapiResultToGapiMeetingData);
      return mappedRecurringMeetings;
    })
    .catch((error:any) => {
      Sentry.captureException(error);
      console.log(12, error);
      throw error;
    });
};

export const gapiInsertMeetingCore = async (
  event: any,
) => gapi.client.calendar.events.insert({
  calendarId: 'primary',
  resource: event,
}).then((response: any) => mapGapiInsertMeetingResponseToGoogleMeetingIdsObj(response))
  .catch((error: any) => {
    console.log('Error in gapiInsertMeeting', error);
    return RejectedGoogleMeetingIdsObj;
  });
