import React from 'react';
import styled from 'styled-components';
import ReactTooltip from '../../shared/components/tooltip/ReactTooltip';
import ShepherdIcon from '../../shared/icons/shepherd-logo/components/shepherd-icon';
import HeaderTextAtom from './HeaderTextAtom';
import HelpIconAtom from './HelpIconAtom';
import RefreshArrowAtom from './RefreshArrow';

const Container = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    margin-right: 12px;
  }
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const TooltipText = () => (
  <>
    This page is home to your meeting notes linked to your
    <br />
    calendar events. For today we also show meetings without notes.
    {/* <br />
    , as well as your unattached notesmade in your Quick notes. */}
  </>
);

interface Props {
  isLoading: boolean,
  fetchMeetingsAgain: () => void;
}

const AllNotesHeaderMolecule = ({ isLoading, fetchMeetingsAgain }: Props) => (
  <Container>
    <HeaderTextAtom text="All notes" />
    <ReactTooltip tip={TooltipText()} place="right" multiline enableOnMobile>
      <HelpIconAtom />
    </ReactTooltip>
    <ReactTooltip tip="Refresh your meetings" multiline>
      {/* <HelpIconAtom onClick={fetchMeetingsAgain} /> */}
      <RefreshArrowAtom onClick={fetchMeetingsAgain} />
    </ReactTooltip>
    {isLoading && <ShepherdIcon width={20} spin />}
  </Container>
);

export default AllNotesHeaderMolecule;
