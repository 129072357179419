import { captureException } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { firestore } from '../utils/firebase';
import { ResolveState, SlackChannel, SlackData } from '../shared/types/types';
import { toastDanger, toastSuccess } from '../utils/notifications';
import { DISABLE_EVENT, SLACK_INTEGRATION } from '../utils/analytics/enums';
import { logIntegrationsEvent } from '../utils/analytics/eventLogger';

export const dbSlackUpdateDefaultChannels = (
  newDefaultSlackChannels: SlackChannel[],
  userId: string,
) => {
  const defaultChannelData = {
    'integrations.slack.defaultChannels': newDefaultSlackChannels,
  };
  firestore()
    .collection('users')
    .doc(userId)
    .update(defaultChannelData)
    .then(() => {
      console.log('successfully updated defaultChannelData');
      toastSuccess('Success', 'Default channel updated');
    })
    .catch((error) => {
      console.log(error);
      Sentry.captureException(error);
      return [];
    });
};

export const dbSaveUserAccessToken = (userId: string, accessToken: string) => {
  const dbData = {
    'integrations.slack.accessToken': accessToken,
  };
  firestore()
    .collection('users')
    .doc(userId)
    .update(dbData)
    .then(() => {
      console.log('token saved successfully');
    })
    .catch((error) => {
      console.log('token not saved');
      console.log(error);
      Sentry.captureException(error);
    });
};

export const dbUpdateSlackData = async (
  userId: string,
  slackData: SlackData[],
): Promise<ResolveState> => {
  const dbData = {
    'integrations.slack': slackData,
  };
  return firestore()
    .collection('users')
    .doc(userId)
    .update(dbData)
    .then(() => {
      console.log('Slack data updated successfuly');
      return 'resolved' as ResolveState;
    })
    .catch((error) => {
      console.log('something went wrong', error);
      toastDanger('Error', 'Something went wrong while trying to update Slack notifications settings');
      Sentry.captureException(error);
      return 'rejected' as ResolveState;
    });
};

export const dbSaveSlackUserId = (userId: string, slackUserId: string) => {
  const dbData = {
    'integrations.slack.userId': slackUserId,
  };
  firestore()
    .collection('users')
    .doc(userId)
    .update(dbData)
    .then(() => {
      console.log('slack userId saved successfully');
    })
    .catch((error) => {
      console.log('slack userId not saved');
      console.log(error);
      Sentry.captureException(error);
    });
};

export const slackAPIRemoveSlackIntegration = (userId: string) => {
  const defaultChannelData = {
    'integrations.slack': [],
  };
  firestore()
    .collection('users')
    .doc(userId)
    .update(defaultChannelData)
    .then(() => {
      console.log('Successfully removed Slack data from user data');
      logIntegrationsEvent(userId, DISABLE_EVENT, SLACK_INTEGRATION);
    })
    .catch((error) => {
      console.log(error);
      captureException(error);
    });
};
