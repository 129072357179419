import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface EnclosingBlockOutProps {
  fill?: string;
  width?: number;
  height?: number;
}

const EnclosingBlockOutIcon = ({
  fill,
  width,
  height,
}: EnclosingBlockOutProps) => (
  <svg width={width} height={height} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 0,5.25 1.73787,6.98787 C 1.92686,7.17686 2.25,7.04301 2.25,6.77574 V 3.72426 C 2.25,3.45699 1.92686,3.32314 1.73787,3.51213 Z m 0,4.5 c 0,0.4142 0.335787,0.75 0.75,0.75 h 12 c 0.4142,0 0.75,-0.3358 0.75,-0.75 C 13.5,9.3358 13.1642,9 12.75,9 h -12 C 0.335787,9 0,9.3358 0,9.75 Z m 0,-9 C 0,1.16421 0.335786,1.5 0.75,1.5 h 12 C 13.1642,1.5 13.5,1.16421 13.5,0.75 13.5,0.33579 13.1642,0 12.75,0 h -12 C 0.335787,0 0,0.33579 0,0.75 Z m 4.5,3 c 0,0.41421 0.33579,0.75 0.75,0.75 h 7.5 C 13.1642,4.5 13.5,4.16421 13.5,3.75 13.5,3.33579 13.1642,3 12.75,3 H 5.25 C 4.83579,3 4.5,3.33579 4.5,3.75 Z m 0,3 c 0,0.41421 0.33579,0.75 0.75,0.75 h 7.5 C 13.1642,7.5 13.5,7.16421 13.5,6.75 13.5,6.33579 13.1642,6 12.75,6 H 5.25 C 4.83579,6 4.5,6.33579 4.5,6.75 Z"
      fill={fill}
    />
  </svg>
);

EnclosingBlockOutIcon.defaultProps = {
  fill: darkBlue4,
  width: 14,
  height: 12,
};

export default EnclosingBlockOutIcon;
