import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface ThreeDotsProps {
  fill?: string;
  width?: number;
  height?: number;
}

const ThreeDotsIcon = ({
  fill,
  width,
  height,
}: ThreeDotsProps) => (
  <svg width={width} height={height} viewBox="0 0 3 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 1.5,0 C 0.672,0 0,0.6716 0,1.5 0,2.3284 0.672,3 1.5,3 2.329,3 3,2.3284 3,1.5 3,0.6716 2.329,0 1.5,0 Z m 0,5.25 C 0.672,5.25 0,5.9216 0,6.75 0,7.5784 0.672,8.25 1.5,8.25 2.329,8.25 3,7.5784 3,6.75 3,5.9216 2.329,5.25 1.5,5.25 Z m 0,5.25 C 0.672,10.5 0,11.1716 0,12 0,12.8284 0.672,13.5 1.5,13.5 2.329,13.5 3,12.8284 3,12 3,11.1716 2.329,10.5 1.5,10.5 Z"
      fill={fill}
    />
  </svg>
);

ThreeDotsIcon.defaultProps = {
  fill: darkBlue4,
  width: 3,
  height: 14,
};

export default ThreeDotsIcon;
