import React, {
  Dispatch, SetStateAction, useContext, useEffect, useRef, useState,
} from 'react';
import CrossIcon from '../../../../shared/icons/cross';
import DownArrow from '../../../../shared/icons/downArrow';
import UpArrow from '../../../../shared/icons/upArrow';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import {
  InputCrossContainer, CrossContainer, MultiInputContainer,
  InsideContainer, InputItemContainer, ArrowContainer, InputItem,
} from '../../../../shared/components/integration-multi-input';
import { TaskItem } from '../../../../shared/types/types';
import { TabDropdownItem } from '../../../../shared/components/simple-dropdown';
import SelectTaskModal from './SelectTaskModal';
import { UserDataContext } from '../../../../App';
import TrelloMultiInputMenuItems from './TrelloMultiInputMenuItems';
import { toastInfo } from '../../../../utils/notifications';
import InputPlaceholder from '../integrations/InputPlaceholder';

interface Props {
  isChecked: boolean,
  taskItems: TaskItem[]
  setTaskToSend: Dispatch<SetStateAction<TaskItem[]>>
  taskToSend: TaskItem[]
}

const TrelloMultiInput = ({
  isChecked, taskItems, setTaskToSend, taskToSend,
}: Props) => {
  const userData = useContext(UserDataContext);
  const defaultChannelDropdown = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(defaultChannelDropdown, false);
  const [selectTaskModelActive, setSelectTaskModelActive] = useState<boolean>(false);
  const [trelloNotSentTasks, setTrelloNotesSentTasks] = useState<TaskItem[]>([]);

  useEffect(() => {
    const filteredTaskItems = taskItems.filter(
      (taskItem) => !taskItem.integrations.trello.trelloTaskId,
    );
    setTrelloNotesSentTasks(filteredTaskItems);
  }, [taskItems]);

  const handleArrowClick = () => {
    setIsActive(!isActive);
  };

  const handleDropdownChannelClicked = (option: TabDropdownItem) => {
    if (option.id === '0') {
      if (trelloNotSentTasks.length === 0) return toastInfo('No additonal tasks to add', 'There are no tasks to add which is not already synched with Trello');
      setSelectTaskModelActive(true);
      handleArrowClick();
    }
    if (option.id === '1') {
      const filteredTaskItems = trelloNotSentTasks.filter(
        (taskItem) => taskItem.data.assignee.email === userData.data.email,
      );
      if (filteredTaskItems.length === 0) return toastInfo('No additonal tasks to add', 'There are no tasks to add which is not already synched with Trello');
      setTaskToSend(filteredTaskItems);
      handleArrowClick();
    }
    if (option.id === '2') {
      if (trelloNotSentTasks.length === 0) return toastInfo('No additonal tasks to add', 'There are no tasks to add which is not already synched with Trello');
      setTaskToSend(trelloNotSentTasks);
      handleArrowClick();
    }
    return null;
  };

  const handleRemoveChannel = (
    item: TaskItem,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    const tempTask = taskToSend.filter((task) => task.taskId !== item.taskId);
    setTaskToSend(tempTask);
  };

  const handleSelectTaskModal = () => {
    setSelectTaskModelActive(!selectTaskModelActive);
  };

  if (!isChecked) return null;

  const shouldDisplayPlaceholder = taskToSend.length === 0;

  return (
    <>
      <MultiInputContainer>
        <InsideContainer onClick={handleArrowClick}>
          <InputItemContainer>
            <InputPlaceholder shouldShow={shouldDisplayPlaceholder} placeholder="Select tasks to export" />
            {taskToSend.map((task) => (
              <InputCrossContainer key={task.taskId}>
                <InputItem>{task.data.title}</InputItem>
                <CrossContainer onClick={(event) => handleRemoveChannel(task, event)}>
                  <CrossIcon />
                </CrossContainer>
              </InputCrossContainer>
            ))}
          </InputItemContainer>
          <ArrowContainer onClick={handleArrowClick}>
            {isActive ? <UpArrow /> : <DownArrow />}
          </ArrowContainer>
        </InsideContainer>
      </MultiInputContainer>
      <TrelloMultiInputMenuItems
        isActive={isActive}
        ref={defaultChannelDropdown}
        onClick={handleDropdownChannelClicked}
      />
      <SelectTaskModal
        taskItems={trelloNotSentTasks}
        setTaskToSend={setTaskToSend}
        isOpen={selectTaskModelActive}
        setModalClosed={handleSelectTaskModal}
      />
    </>
  );
};

export default TrelloMultiInput;
