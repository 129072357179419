import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface AtProps {
  fill?: string;
  width?: number;
  height?: number;
}

const AtIcon = ({
  fill,
  width,
  height,
}: AtProps) => (
  <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 6,0 C 2.692,0 0,2.6916 0,6 0,9.3084 2.692,12 6,12 6.803,12 7.596,11.8391 8.33,11.5304 8.712,11.3698 8.833,10.9064 8.626,10.5478 8.418,10.1892 7.961,10.0731 7.572,10.2176 7.072,10.4035 6.539,10.5 6,10.5 3.519,10.5 1.5,8.4814 1.5,6 1.5,3.5186 3.519,1.5 6,1.5 8.482,1.5 10.5,3.5186 10.5,6 V 6.6533 C 10.5,7.144 10.185,7.5 9.75,7.5 9.337,7.5 9,7.1634 9,6.75 v -3 H 7.963 C 7.436,3.2891 6.754,3 6,3 4.346,3 3,4.3458 3,6 3,7.6542 4.346,9 6,9 6.788,9 7.5,8.6885 8.036,8.1901 8.449,8.681 9.06,9 9.75,9 11.012,9 12,7.9691 12,6.6533 V 6 C 12,2.6916 9.309,0 6,0 Z M 6,7.5 C 5.173,7.5 4.5,6.8273 4.5,6 4.5,5.1727 5.173,4.5 6,4.5 6.827,4.5 7.5,5.1727 7.5,6 7.5,6.8273 6.827,7.5 6,7.5 Z"
      fill={fill}
    />
  </svg>
);

AtIcon.defaultProps = {
  fill: darkBlue4,
  width: 12,
  height: 12,
};

export default AtIcon;
