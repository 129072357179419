import styled from 'styled-components';
import { uiTextS } from '../../../typography';
import { darkBlue3 } from '../../../colours';

const DropdownInputPlaceholder = styled.div`
  ${uiTextS};
  color: ${darkBlue3};
`;

export default DropdownInputPlaceholder;
