import React from 'react';
import styled from 'styled-components';
import { gray1, gray4 } from '../../../colours';
import DeleteConfirmationIconAndText from './components/delete-confirmation-icon-and-text-molecule';
import DeleteConfirmationButtons from './components/delete-confirmation-buttons-molecule';
import { SIDEBAR_WIDTH } from '../../../../utils/globalStyle';

const DeletePrivateNoteConfirmationContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(${SIDEBAR_WIDTH} - 100px);
  padding: 16px;
  box-shadow: 0px 14px 24px rgba(27, 33, 36, 0.16), 0px 8px 8px -4px rgba(27, 33, 36, 0.04);
  border-radius: 16px;
  background: ${gray1};
  z-index: 100;
  left: 50px;
  bottom: 580px;
  border: 2px solid ${gray4};

  @media (max-height: 880px) {
    bottom: 365px;
  }
`;

interface Props {
  isOpen: boolean,
  onClickDeletePrivateNote: () => void,
  refOutsideClick: React.MutableRefObject<null>,
  onClickCancelDelete: () => void,
}

const DeletePrivateNoteConfirmationModal = ({
  isOpen, onClickDeletePrivateNote, onClickCancelDelete, refOutsideClick,
}: Props) => {
  if (!isOpen) return null;

  return (
    <DeletePrivateNoteConfirmationContainer ref={refOutsideClick}>
      <DeleteConfirmationIconAndText />
      <DeleteConfirmationButtons
        onClickCancelDelete={onClickCancelDelete}
        onClickDelete={onClickDeletePrivateNote}
      />
    </DeletePrivateNoteConfirmationContainer>
  );
};

export default DeletePrivateNoteConfirmationModal;
