import _ from 'lodash';
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import { dbUpdateSlackData } from '../../../../database/firebaseSlackAPI';
import defaultSlackNotifications from '../../../../database/utils/templateSlackNotifications';
import { logSlackNotificationSettings, mapDatabaseNotificationsToSlackNotifications, mapNotificationsToSlackData } from '../../../../utils/slack/slackUtils';
import useFirstUserLoadEffect from '../../../../utils/user/useFirstUserLoadEffect';
import { gray7, gray9 } from '../../../colours';
import { ResolveStateExtended, SlackNotificationsSettings } from '../../../types/types';
import { defaultSmall, header200 } from '../../../typography';
import Modal from '../../Modal';
import ModalLine from '../../Modal/ModalLine';
import TaskEmailNotificationSettings from '../notification/task-email/TaskEmailNotificationSettings';
// import DesktopNotificationSettings from '../notification/desktop-notification-settings/index';
import SlackNotificationSettings from '../notification/slack-notifications/SlackNotificationSettings';
import GeneralSettings from '../general-settings';
import CloseAccount from '../close-account';

const Section = styled.div`
  padding: 0 24px 24px 24px;

  h2 {
    ${header200};
    color: ${gray9};
    margin: 24px 0 4px 0;
  }

  p {
    ${defaultSmall};
    color: ${gray7};

    a {
      color: ${gray7};
    }
    margin: 0 0 12px 0;
  }
`;

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isSlackEnabled: boolean;
}

const Settings = ({
  isOpen, setIsOpen, isSlackEnabled,
}: Props) => {
  const userData = useContext(UserDataContext);
  const [isResolved, setIsResolved] = useState<ResolveStateExtended>('null');
  const [updatedNotifications, setUpdatedNotifications] = useState(defaultSlackNotifications);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  // the desktop notification modal state is defined here because we want to close it
  // when the parent modal is closed
  // const [isDesktopNotifyModalOpen, setDesktopNotifyModalOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsOpen(false);
    // setDesktopNotifyModalOpen(false);
  };

  const notifications = mapDatabaseNotificationsToSlackNotifications(
    userData.integrations.slack,
  );

  useEffect(() => {
    // Logic to handle the update text in the bottom bar
    // whenver a new update is initiated, we clear the state
    // of the previous setTimout, and starts a new one
    if (isResolved === 'null') return () => { };
    const timeoutId = setTimeout(() => {
      setIsResolved('null');
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [isResolved]);

  useFirstUserLoadEffect(() => {
    if (userData.integrations.slack.length === 0) return;
    setUpdatedNotifications(userData.integrations.slack[0].notifications);
  }, [userData]);

  useEffect(() => {
    // We want to make sure the notifications we just updated, is the same as the notifications
    // we get from the user data before we let the user interact with the UI
    if (isResolved !== 'resolved') return;
    if (_.isEqual(updatedNotifications, userData.integrations.slack[0]?.notifications)) {
      setIsLocked(false);
    } else {
      setIsLocked(true);
    }
  }, [userData.integrations.slack, updatedNotifications, isResolved]);

  const onUpdateSlackNotifications = async (newNotifications: SlackNotificationsSettings) => {
    setIsResolved('pending');
    setUpdatedNotifications(newNotifications);
    setIsLocked(true);
    const mappedSlackData = mapNotificationsToSlackData(
      newNotifications,
      userData.integrations.slack,
    );
    const status = await dbUpdateSlackData(userData.userId, mappedSlackData);
    logSlackNotificationSettings(userData.userId, newNotifications);
    setIsResolved(status);
  };

  return (
    <>
      <Modal isOpen={isOpen} setModalClosed={closeModal} title="Settings">
        <Section>
          <GeneralSettings />
          <ModalLine />
          <TaskEmailNotificationSettings />
          <ModalLine />
          {/* <DesktopNotificationSettings
            isDesktopNotifyModalOpen={isDesktopNotifyModalOpen}
            setDesktopNotifyModalOpen={setDesktopNotifyModalOpen}
          /> */}
          {isSlackEnabled && (
            <>
              <ModalLine />
              <SlackNotificationSettings
                notifications={notifications}
                updateSlackNotifications={onUpdateSlackNotifications}
                isResolved={isLocked ? 'pending' : isResolved}
              />
            </>
          )}
          <ModalLine />
          <CloseAccount />
        </Section>
      </Modal>
    </>
  );
};

export default Settings;
