import React from 'react';
import styled from 'styled-components';
import { header600, uiText } from '../../../../shared/typography';
import ConnectedSuccess from '../../../../shared/img/open_doodles_roller_skating.png';
import { darkBlue4 } from '../../../../shared/colours';

const OuterSuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuccessContainer = styled.div`
  width: 100%;
  height: 336px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const HeadText = styled.h1`
  ${header600};
  margin: 16px 68.5px 8px 68.5px;
  text-align: center;
`;

const BottomText = styled.p`
  ${uiText};
  margin-bottom: 16px;
  color: ${darkBlue4};
  text-align: center;
`;
const Image = styled.img`
  width: 135px;
  height: 120px;
`;

const SuccessfullyIntegratedTab = () => (
  <OuterSuccessContainer>
    <SuccessContainer>
      <Image src={ConnectedSuccess} />
      <HeadText>Shepherd is successfully integrated into your Slack workspace!</HeadText>
      <BottomText>
        This integration allows you to post Shepherd notes to Slack channels.
      </BottomText>
    </SuccessContainer>
  </OuterSuccessContainer>
);

export default SuccessfullyIntegratedTab;
