import styled from 'styled-components';
import {
  gray1, gray2, gray4, surface, darkBlue1, darkBlue2,
} from '../../colours';
import { link, messageText } from '../../typography';

export const CrossContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
`;

export const MultiInputContainer = styled.div`
  width: 409px;
  height: auto;
  background-color: ${gray2};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;

  :hover {
    outline: 1px solid ${darkBlue2};
  }
`;

export const InsideContainer = styled.div`
  cursor: pointer;
  width: 409px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    border-radius: 4px;
    background: ${darkBlue1};
  }
`;

export const InputItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const InputItem = styled.div`
  ${messageText};
  border-radius: 4px;
  color: ${surface};
  margin: 2px;
  padding: 2px 6px;
  flex-shrink: 0;
  width: fit-content;
  box-sizing: border-box;
  line-break: anywhere;
  display: flex;
  align-items: center;
  letter-spacing: -0.08px;
  padding-right: 2px;
`;

export const Menu = styled.nav`
  background: ${gray1};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: ${(props: MenuProps) => (props['data-active'] ? 'relative' : 'absolute')};
  top: 0px;
  left: 0;
  width: 100%;
  transition: opacity 0.4s ease, transform 1s ease, visibility 0.4s;
  padding-top: 12px;
  padding-bottom: 12px;

  opacity: ${(props: MenuProps) => (props['data-active'] ? 1 : 0)};
  visibility: ${(props: MenuProps) => (props['data-active'] ? 'visible' : 'hidden')};
  transform: ${(props: MenuProps) => (props['data-active'] ? 'translateY(0)' : 'translateY(130px)')};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    button {
      ${link};
      border: none;
      background: none;
      padding: 6px 16px;
      color: ${surface};
      cursor: pointer;
      width: 100%;
      text-align: left;
      
      &:hover {
        background: ${gray2};
      }
    }
  }
`;

export const InputCrossContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${gray4};
  border-radius: 4px;
  margin: 2px;
  white-space: nowrap;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 12px 0px 8px 16px;
`;

interface MenuProps {
  'data-active': boolean
}
