/* eslint-disable no-empty-function */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import Fuse from 'fuse.js';
import moment, { Moment } from 'moment';
import { EditorView } from 'prosemirror-suggest';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import MeetingDataContext from '../../../../../pages/meeting/context/MeetingDataContext';
import {
  blue1,
  blue2,
  blue5,
  blue7,
  darkBlue1,
  darkBlue4,
  gray1, gray2, gray3, gray4, gray6, surface,
} from '../../../../colours';
import { AssigneeV2, AttendeeV2 } from '../../../../types/types';
import { small, uiText, uiTextMedium } from '../../../../typography';
import useDetectClickOutside from '../../hooks/fixed-click-outside';
import MagnifyingGlassIcon from '../icons/magnifying-glass';
import TaskCalendarFloatingPanel from './task-calendar-floating-panel';

interface MenuProps {
  show: boolean;
  top: number;
  left: number;
}

const Menu = styled.div<MenuProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  max-height: 368px;
  position: absolute;
  width: 242px;
  background-color: ${gray1};
  border-radius: 8px;
  top: min(${({ top }) => `${top}px`}, calc(100vh - 368px - 60px));
  left: min(${({ left }) => `${left}px`}, 258px);
  z-index: 1000;
  box-shadow: 0px 10px 18px rgba(0,0,0,0.14),0px 0px 1px rgba(0,0,0,0.25);
  flex-flow: column;
  user-select: none;
  gap: 4px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface MenuItemProps {
  selected?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  padding: 10px 14px;
  display: flex;
  flex-flow: row;
  align-items: center;

  ${uiText}
  ${({ selected }) => (selected ? `background-color: ${blue1};` : '')}

  &:hover {
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? blue2 : gray3)};
  }
`;

MenuItem.defaultProps = {
  selected: false,
};

interface MenuItemTextProps {
  selected?: boolean;
}

const MenuItemText = styled.div<MenuItemTextProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 8px;
  color: ${({ selected }) => (selected ? blue7 : surface)};
`;

MenuItemText.defaultProps = {
  selected: false,
};

interface MenuItemPhotoProps {
  url?: string;
}

const MenuItemPhoto = styled.div<MenuItemPhotoProps>`
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: ${gray4};
  ${({ url }) => `background-image: url(${url});`}
  background-position: center;
  background-size: cover;
  margin-right: 8px;
`;

MenuItemPhoto.defaultProps = {
  url: '',
};

interface MenuItemSubTextProps {
  selected?: boolean;
}

const MenuItemSubText = styled.div<MenuItemSubTextProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  ${uiText}
  color: ${({ selected }) => (selected ? blue5 : darkBlue4)};
`;

MenuItemSubText.defaultProps = {
  selected: false,
};

const Separator = styled.div`
  height: 1px;
  background-color: ${darkBlue1};
  width: 100%;
  margin: 0;
`;

const Input = styled.input`
  border: 0 !important;
  outline: none;
  width: 100%;
  padding: 0;
  flex-grow: 0.5;
  margin-left: 10px;
`;

const InputWrapper = styled.div`
  border: 2px solid #E7E9EF;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  margin: 16px;
  display: flex;
  flex-flow: row;
  align-items: center;

  & svg {
    min-width: 20px !important;
    margin-right: 10px;
  }
`;

interface AssigneeProps {
  show: boolean,
  left: number,
  top: number,
  closeContents: () => void,
  currentAssignee: any,
  updateAssignee: (newAssignee: AssigneeV2) => void,
  view: EditorView | null,
}

function isSelected(assignee: AssigneeV2 | null, a: AssigneeV2 | null): boolean {
  if (!assignee || !a) {
    return false;
  }
  return assignee.userId === a.userId;
}

const AssigneePicker = ({
  show,
  left,
  top,
  closeContents,
  currentAssignee,
  updateAssignee,
  view,
}: AssigneeProps) => {
  const meetingContext = useContext(MeetingDataContext);
  // meetingContext.googleData.ids.recurringEventId;

  const [showSpecific, setShowSpecific] = useState<boolean>(false);

  const [attendees, setAttendees] = useState<AttendeeV2[]>([]);

  useEffect(() => {
    const att = meetingContext.attendees.attendees;
    setAttendees(att);
  });

  function closeAndReset() {
    closeContents();
    setShowSpecific(false);
  }

  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (!showSpecific) {
        closeAndReset();
      }
    },
  });

  const selectedRef = useRef(null);

  useEffect(() => {
    if (selectedRef.current) {
      (selectedRef.current as unknown as HTMLDivElement).scrollIntoView({
        block: 'nearest',
      });
    }
  }, [attendees, show]);

  const [searchWord, setSearchWord] = useState<string>('');

  const [filteredParticipants, setFilteredParticipants] = useState<AttendeeV2[]>(attendees);

  useEffect(() => {
    if (!searchWord) {
      setFilteredParticipants(attendees);
      return;
    }
    const fuse = new Fuse(attendees, {
      keys: ['data.name', 'data.email'],
    });
    setFilteredParticipants(fuse.search(searchWord).map((value) => value.item));
  }, [searchWord]);

  useEffect(() => {
    if (filteredParticipants.length === 0) {
      setFilteredParticipants(attendees);
    }
  }, [attendees]);

  const inputField = useRef<HTMLInputElement>(null);
  const [inputFieldFocused, setInputFieldFocused] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      // setTimeout(() => (inputField?.current as HTMLInputElement)?.focus(), 1);
      setInputFieldFocused(true);
    } else {
      setInputFieldFocused(false);
    }
  }, [show]);

  return (
    <Menu
      show={show && top !== 0 && left !== 0 && !showSpecific}
      left={left}
      top={top}
      ref={ref}
    >
      <InputWrapper>
        <Input
          value={searchWord}
          onChange={(e) => setSearchWord(e.target.value)}
          ref={(e) => {
            if (inputFieldFocused) {
              e?.focus();
            }
          }}
          autoFocus
        />
        <MagnifyingGlassIcon width={20} height={20} fill="#200E32" />
      </InputWrapper>
      <Separator />
      {
        filteredParticipants.map((value, index) => (
          <MenuItem
            selected={isSelected(currentAssignee, value)}
            key={value?.userId}
            onClick={() => {
              updateAssignee(value);
              closeAndReset();
            }}
          >
            <MenuItemPhoto
              url={value?.data?.photoUrl}
            />
            <MenuItemText
              selected={isSelected(currentAssignee, value)}
            >
              { `${value?.data?.name || value?.data?.email}`}
            </MenuItemText>
            {/* <MenuItemSubText
              selected={isSelected(currentAssignee, value[0])}
            >
            </MenuItemSubText> */}
          </MenuItem>
        ))
      }
    </Menu>
  );
};

export default AssigneePicker;
