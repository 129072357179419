import React from 'react';
import { TitleContainer, HighlightedSection } from './utils/styles';

interface TitleWithHighlightedSectionProps {
  title: string,
  highlightedSection: string,
  highlightColor: string
}

const TitleWithHighlightedSection = ({
  title, highlightedSection, highlightColor,
}: TitleWithHighlightedSectionProps) => (
  <TitleContainer>
    {title}
    <HighlightedSection color={highlightColor}>{highlightedSection}</HighlightedSection>
  </TitleContainer>
);

export default TitleWithHighlightedSection;
