import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import { firestore } from '../utils/firebase';
import { DatabaseTemplateData, TemplateData } from '../shared/types/types';
import { mapDatabaseTemplateDataToTemplateData } from '../shared/components/templates-modal/utils';
import { toastDanger, toastInfo } from '../utils/notifications';

export const dbListenForShepherdTemplates = (
  setState: Dispatch<SetStateAction<TemplateData[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  setLoading(true);

  firestore().collection('library').doc('templates').collection('note_templates')
    .onSnapshot((snapshot) => {
      if (snapshot.empty) {
        setState([]);
        setLoading(false);
        return;
      }

      const newTemplates : TemplateData[] = snapshot.docs.map(
        (doc) => mapDatabaseTemplateDataToTemplateData(doc.id, doc.data()),
      );

      setState(newTemplates);
      setLoading(false);
    }, (error) => {
      Sentry.captureException(error);
    });
};

export const dbListenForUserTemplates = (
  userId: string,
  setState: Dispatch<SetStateAction<TemplateData[]>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
) => {
  setLoading(true);

  firestore().collection('users').doc(userId).collection('note_templates')
    .onSnapshot((snapshot) => {
      if (snapshot.empty) {
        setState([]);
        setLoading(false);
        return;
      }

      const newTemplates : TemplateData[] = snapshot.docs.map(
        (doc) => mapDatabaseTemplateDataToTemplateData(doc.id, doc.data()),
      );
      setState(newTemplates);
      setLoading(false);
    }, (error) => {
      Sentry.captureException(error);
    });
};

export const dbCreateUserTemplate = (
  userId: string,
  templateData: DatabaseTemplateData,
  setIsSaved: Dispatch<SetStateAction<boolean>>,
  setIsSubmitLoading: Dispatch<SetStateAction<boolean>>,
) => {
  setIsSubmitLoading(true);

  firestore().collection('users').doc(userId).collection('note_templates')
    .add(templateData)
    .then(() => {
      setIsSaved(true);
      console.log('added new template');
    })
    .catch((error) => {
      toastDanger('Error', 'Problem adding new template. Please refresh the page and try again!');
      console.error('error adding new template');
      console.info(templateData);
      console.error(error);
      Sentry.captureException(error);
    })
    .finally(() => {
      setIsSubmitLoading(false);
    });
};

export const dbDeleteUserTemplate = (
  userId: string,
  templateId: string,
) => firestore().collection('users').doc(userId).collection('note_templates')
  .doc(templateId)
  .delete()
  .then(() => {
    console.log('template deleted successfully');
    toastDanger('Deleted', 'Template deleted');
  })
  .catch((error) => {
    toastDanger('Error', 'Problem deleting template. Please refresh the page and try again!');
    console.error('error deleting template', error);
    Sentry.captureException(error);
  });

export const dbUpdateUserTemplate = (
  userId: string,
  templateId: string,
  templateData: DatabaseTemplateData,
  handleReset: ()=> void,
  setIsSubmitLoading: Dispatch<SetStateAction<boolean>>,
) => {
  setIsSubmitLoading(true);
  firestore().collection('users').doc(userId).collection('note_templates')
    .doc(templateId)
    .update(templateData)
    .then(() => {
      console.log('template updated successfully');
      toastInfo('Updated', 'Template updated');
    })
    .catch((error) => {
      toastDanger('Error', 'Problem updating template. Please refresh the page and try again!');
      console.error('error updating template', error);
      Sentry.captureException(error);
    })
    .finally(() => {
      handleReset();
      setIsSubmitLoading(false);
    });
};
