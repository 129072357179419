import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import CssSpinner from '../../../../shared/components/css-spinner';
import { ResolveState } from '../../../../shared/types/types';
import { emptySlackData } from '../../../../utils/slack/slackUtils';
import SuccessfullyIntegratedTab from './SuccessfullyIntegratedTab';

const WaitingOnIntegrationSetupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SlackIntegrationProcessStatus = () => {
  const [status, setStatus] = useState<ResolveState>('pending');
  const userData = useContext(UserDataContext);
  const slack = userData.integrations.slack[0] ?? emptySlackData;

  useEffect(() => {
    if (slack?.userAccessToken?.length) setStatus('resolved');
  }, [slack]);

  useEffect(() => {
    const milliSecondsBeforeTimeout = 60 * 1000;
    setTimeout(() => {
      setStatus((prev) => (prev === 'resolved' ? 'resolved' : 'rejected'));
    }, milliSecondsBeforeTimeout);
  }, [slack]);

  if (status === 'pending') {
    return (
      <WaitingOnIntegrationSetupContainer>
        <CssSpinner color="black" />
      </WaitingOnIntegrationSetupContainer>
    );
  }

  if (status === 'resolved') {
    return (
      <SuccessfullyIntegratedTab />
    );
  }
  if (status === 'rejected') {
    return (
      <div>
        <p>
          There was an error connecting to Slack. Please try again.
        </p>
      </div>
    );
  }
  return null;
};

export default SlackIntegrationProcessStatus;
