import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../../external/publicUserData/PublicUserDataAPI';
import {
  dashboardPath, onboardingPersonalPath, onboardingWorkspacePath,
} from '../../routes';
import { trackUserInvites } from '../../shared/components/quick-settings/invite-members/utils/functions';
import {
  AuthState, IntercomTrackEvent, LoginOrSignup, MeetingSection,
  MeetingVersion,
  OnboardingType, ResolvedState, TasksPage, TemplateType,
  TimestampLog, TimeTrackingEvents, User, UserCenterPage,
} from '../../shared/types/types';
import { analytics, functions } from '../firebase';
import { updateIntercomRoute } from '../intercom';
import { defaultAuthContext } from '../user/UserDataUtils';
import {
  APP_ERROR_EVENT,
  AnalyticsDataPayload,
  APP_EVENT,
  AUTHORIZATION_EVENT,
  CREATE_EVENT,
  DATABASE_EVENT,
  DesktopNotificationActionOn,
  DESKTOP_NOTIFICATION,
  GAPI_EVENT,
  INTEGRATION_EVENT,
  InviteSource,
  INVITE_EMAIL,
  INVITE_EVENT,
  LOGIN_EVENT,
  MixPanelUserPayload,
  ROOT_DOMAIN,
  MAIN_TAB_MENU_EVENT,
  QUICK_SETTINGS_CLICK_EVENT,
  NOTES_TAB_EVENT,
  NOTION_INTEGRATION,
  ONBOARDING_EVENT,
  ORGANIC_SIGN_UP,
  PAGE_EVENT,
  SECRET_CHAT_EVENT,
  SETTING_ITEM_CLICK_EVENT,
  SHARE_NOTES_EMAIL,
  SHARE_NOTES_EVENT,
  SIDEBAR_EVENT,
  SLACK_INTEGRATION,
  TASKS_EVENT,
  TEMPLATES_EVENT,
  VIEW_EVENT,
  SLACK,
  UNKNOWN,
  TEXT_EDITOR_EVENT,
  NotesFor,
  ENABLE_EVENT,
  DISABLE_EVENT,
  UPDATE_HUB_EVENT,
  ALL_NOTES_EVENT,
  CLICK_EVENT,
  MEETING_CARD_FIELD,
  SEND_FEEDBACK,
} from './enums';
import SDateClass from '../../shared/classes/SDate';

// ============ Main Analytics Function ==============================================

export const logEvent = (
  userId: string, eventType: string, eventData: Object,
  intercomTrackEvent?: IntercomTrackEvent,
) => {
  if (window.location.host.includes('6006')) return;
  if (analytics) analytics().logEvent(eventType, eventData);
  if (intercomTrackEvent) intercomTrackEvent(eventType, eventData);

  if (userId.length !== 0) {
    sendDataToMixPanelCF(userId, eventType, eventData);
  } else {
    mixpanel.track(eventType, eventData);
  }
};

export const sendDataToMixPanelCF = (
  userId:string, eventType: string, eventData: Object,
) => {
  const payload: AnalyticsDataPayload = {
    userId,
    eventType,
    eventData,
  };
  return functions().httpsCallable('sendDataToMixPanel')(payload).then((data) => {
    console.log(data);
  }).catch((error) => {
    Sentry.captureException(error);
    console.error('Something went wrong sending analytics', error);
  });
};

export const setupPeopleInMixPanel = (userData:AuthState) => {
  const payload: MixPanelUserPayload = {
    userId: userData.userId,
    userEmail: userData.email,
    firstName: userData.firstName,
    lastName: userData.lastName,
  };
  return functions().httpsCallable('setupPeopleInMixPanel')(payload).then((data) => {
    console.log(data);
  }).catch((error) => {
    Sentry.captureException(error);
    console.error('Something went wrong setting user', error);
  });
};

export const sendPeoplePropertiesInMixPanel = (
  userId: string, eventType: string, eventData:Object = {},
) => {
  const payload: AnalyticsDataPayload = {
    userId,
    eventType,
    eventData,
  };
  return functions().httpsCallable('setupPeoplePropertiesInMixPanelv2')(payload).then((data) => {
    console.log(data);
  }).catch((error) => {
    Sentry.captureException(error);
    console.error('Something went wrong sending analytics', error);
  });
};

// ============ Log Event Page Analytics Functions ===================================

export const logEventPageViewDashboard = (authState: AuthState, update: any) => {
  updateIntercomRoute(authState, update);
  logEvent(authState.userId, 'pageview', { page_title: 'dashboard', page_path: dashboardPath, page_location: `${ROOT_DOMAIN}${dashboardPath}` });
};

export const logEventPageViewPersonalOnboarding = (authState: AuthState, update: any) => {
  updateIntercomRoute(authState, update);
  logEvent(authState.userId, 'pageview', { page_title: 'personal_onboarding', page_path: onboardingPersonalPath, page_location: `${ROOT_DOMAIN}${onboardingPersonalPath}` });
};

export const logEventPageViewWorkspaceOnboarding = (authState: AuthState, update: any) => {
  updateIntercomRoute(authState, update);
  logEvent(authState.userId, 'pageview', { page_title: 'workspace_onboarding', page_path: onboardingWorkspacePath, page_location: `${ROOT_DOMAIN}${onboardingWorkspacePath}` });
};

export const logEventPageViewGoogleMeet = (authState: AuthState, update: any) => {
  updateIntercomRoute(authState, update);
  logEvent(authState.userId, 'pageview', { page_title: 'google_meet', page_path: '/google-meet', page_location: `${ROOT_DOMAIN}/google-meet` });
};

export const logEventPageViewGoogleCalendar = (authState: AuthState, update: any) => {
  updateIntercomRoute(authState, update);
  logEvent(authState.userId, 'pageview', { page_title: 'google_calendar', page_path: '/google-calendar', page_location: `${ROOT_DOMAIN}/google-calendar` });
};

// ============ Log Event ButtonClicks Analytics Functions ===========================

export const logEventButtonClickSendEmail = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-send-email');
  logEvent(userId, 'click', { button: 'send_email' });
};

export const logEventButtonClickSendEmailSubmit = (
  userId: string, intercomTrackEvent: any, numberOfEmails: number,
) => {
  intercomTrackEvent('click-email-submit', { numberOfEmails });
  logEvent(userId, 'click', { button: 'send_email_submit', numberOfEmails });
};

export const logEventButtonClickSendEmailCancel = (
  userId: string, intercomTrackEvent: any,
) => {
  intercomTrackEvent('click-email-cancel');
  logEvent(userId, 'click', { button: 'send_email_cancel' });
};

export const logEventButtonClickOpenIntercom = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-help-icon');
  logEvent(userId, 'click', { button: 'open_intercom' });
};

export const logEventButtonClickWelcomeBannerHelp = (
  userId: string, intercomTrackEvent: any,
) => {
  intercomTrackEvent('click-welcome-banner-help');
  logEvent(userId, 'click', { button: 'welcome_banner_help' });
};

export const logEventButtonClickWelcomeBannerVideo = (
  userId: string, intercomTrackEvent: any,
) => {
  intercomTrackEvent('click-welcome-banner-video');
  logEvent(userId, 'click', { button: 'welcome_banner_video' });
};

export const logEventButtonClickCopyText = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-copy-text');
  logEvent(userId, 'click', { button: 'copy_text' });
};

export const logEventButtonClickQuickSettings = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-quick-settings-open');
  logEvent(userId, 'click', { button: 'quick_settings_open' });
};

export const logEventButtonClickInviteMembers = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-invite-members-open');
  logEvent(userId, 'click', { button: 'invite_members_open' });
};

export const logEventButtonClickSettings = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-settings-open');
  logEvent(userId, 'click', { button: 'settings_open' });
};

export const logEventButtonClickNoteTab = (
  userId: string, intercomTrackEvent: any, noteTab: MeetingSection,
) => {
  intercomTrackEvent(noteTab);
  logEvent(userId, 'click', { button: noteTab });
};

export const logEventButtonClickNewTask = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-new-task-button');
  logEvent(userId, 'click', { button: 'new_task_button' });
};

export const logEventButtonClickEmptyTaskTab = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-empty-task-tab');
  logEvent(userId, 'click', { button: 'empty_task_tab' });
};

export const logEventButtonClickPrevMeeting = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-prev-meeting-button');
  logEvent(userId, 'click', { button: 'prev_meeting_button' });
};

export const logEventButtonClickCEButton = (
  userId: string, intercomTrackEvent: any, buttonName: string,
) => {
  intercomTrackEvent(`click-ce-${buttonName.replaceAll('_', '-')}`);
  logEvent(userId, 'click', { button: `ce_${buttonName}` });
};

export const logFullScreenButtonClick = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-full-screen-button');
  logEvent(userId, 'click', { button: 'full_screen_button' });
};

// ============ Log Sidebar Toggle Analytics Functions ===============================

export const logEventToggleSidebar = (
  userId: string, intercomTrackEvent: any, isOpen: boolean,
) => {
  intercomTrackEvent('toggle-sidebar');
  logEvent(userId, 'toggle_sidebar', { sidebarState: isOpen });
};

export const logEventToggleSidebar2 = (
  userId: string, intercomTrackEvent: any, isOpen: boolean,
) => {
  intercomTrackEvent('click-toggle-sidebar');
  logEvent(userId, 'click', { button: 'toggle_sidebar', isOpen });
};

// ============ Log Meeting & Notes Related Analytics Functions ======================

export const logPreviewButtonClicked = (userId: string, intercomTrackEvent: any) => {
  intercomTrackEvent('click-preview');
  logEvent(userId, 'click', { button: 'preview' });
};

export const logQuickSettingsClick = (
  userId:string, action: string, field: string, intercomTrackEvent: any,
) => {
  logEvent(userId, QUICK_SETTINGS_CLICK_EVENT, { action, field }, intercomTrackEvent);
};

export const logSettingsClicks = (
  userId: string, section: string, field: string, intercomTrackEvent: any,
) => {
  logEvent(userId, SETTING_ITEM_CLICK_EVENT, { section, field }, intercomTrackEvent);
};

export const logExternalSourceVisit = (source: string, intercomTrackEvent: any) => {
  analytics().logEvent('external_source_visit', { source });
  intercomTrackEvent('external_source_visit', { source });
  mixpanel.track('external_source_visit', { source });
};

// fired whenever meeting sidebar  is opened
// export const logEventOpenShepherd = (
//   meetingData: MeetingData, userId: string, intercomTrackEvent: any,
// ) => {
//   const { meetingId } = meetingData;
//   const startTime = meetingData.date.start.timestamp;
//   const endTime = meetingData.date.end.timestamp;
//   const nowTime = Date.now();

//   let timing: RelativeTiming;
//   if (nowTime < startTime) {
//     timing = 'before';
//   } else if (nowTime >= startTime && nowTime < endTime) {
//     timing = 'during';
//   } else if (nowTime >= endTime) {
//     timing = 'after';
//   }

//   analytics().logEvent('open_shepherd', { meetingId, userId, timing });
//   intercomTrackEvent('open-shepherd', { meetingId, userId, timing });
//   mixpanel.track('open_shepherd', { meetingId, userId, timing });
// };

// only triggered when a user first time open Shepherd sidebar for a meeting
// criteria: 1. sidebar is open
//           2. more than 10 characters in any tab
// triggered only once for any [user, meeting] pair

/**
 * @description only triggered when a user first time open Shepherd sidebar for a meeting
 * criteria:
 * 1. sidebar is open
 * 2. more than 10 characters in any tab
 * triggered only once for any [user, meeting] pair
 */
export const logEventMeetingWithShepherdUsed = (
  meetingId: string,
  userId: string,
  timestamp: TimestampLog,
  notesFor: NotesFor,
  intercomTrackEvent: any,
) => {
  logEvent(userId, 'meeting_with_shepherd_used', {
    meetingId, userId, notesFor, ...timestamp,
  }, intercomTrackEvent);
};

// should be fired only during the meeting time,
// and there are more than 10 characters in any notes tab
// triggered only once for any [user, meeting] pair
// TODO not used for now
// export const logEventAttendMeeting = (
//   meetingId: string, userId: string, intercomTrackEvent: any,
// ) => {
//   analytics().logEvent('attend_meeting', { meetingId, userId });
//   intercomTrackEvent('attend-meeting', { meetingId, userId });
//   mixpanel.track('attend_meeting', { meetingId, userId });
// };

/**
 * @description triggered when the agenda tab has more than 10 characters,
 * triggered only once for a meeting.
 */
export const logEventMeetingHasAgenda = (
  userId: string, meetingId: string, timestampObj: TimestampLog,
  notesFor: NotesFor, intercomTrackEvent: any,
) => {
  logEvent(userId, 'meeting_has_agenda', { meetingId, ...timestampObj, notesFor }, intercomTrackEvent);
};

/**
 * @description triggered when the sharedNotes tab has more than 10 characters,
 * triggered only once for a meeting.
 */
export const logEventMeetingHasSharedNotes = (
  userId: string, meetingId: string, timestampObj: TimestampLog,
  notesFor: NotesFor, intercomTrackEvent: any,
) => {
  logEvent(userId, 'meeting_has_shared_notes', { meetingId, ...timestampObj, notesFor }, intercomTrackEvent);
};

/**
 * @description triggered when the privateNotes tab has more than 10 characters,
 * triggered only once for a meeting.
 */
export const logEventMeetingHasPrivateNotes = (
  userId: string, meetingId: string, timestampObj: TimestampLog,
  notesFor: NotesFor, intercomTrackEvent: any,
) => {
  logEvent(userId, 'meeting_has_private_notes', { meetingId, ...timestampObj, notesFor }, intercomTrackEvent);
};

// ============ Tasks Analytics ===========

export const logEventDragAndDropTasks = (
  userId: string, page: TasksPage, intercomTrackEvent: any,
) => {
  logEvent(userId, 'drag_and_drop_tasks', { page }, intercomTrackEvent);
};

export const logTasksUserAction = (
  userId: string,
  action: string, actionFrom: string,
  field: string = '', intercomTrackEvent?: IntercomTrackEvent,
  isTaskPrivate: boolean = false, isTaskSyncedToTrello: boolean = false,
) => {
  if (action === CREATE_EVENT) {
    sendPeoplePropertiesInMixPanel(userId, `${TASKS_EVENT}_${CREATE_EVENT}`);
    logEvent(userId, TASKS_EVENT, {
      action, actionFrom, isTaskPrivate, isTaskSyncedToTrello,
    }, intercomTrackEvent);
  } else {
    logEvent(userId, TASKS_EVENT, { action, actionFrom, field }, intercomTrackEvent);
  }
};

// ============ Notion Analytics ===========

/**
 * For logging user actions performed related to the Notion integration
 * @action the action the user is performing
 * @field the object the action is done upon
 * @numberOfItems number of items fetched or created
 */
export const logNotionUserAction = (
  userId: string, action: string, field: string, numberOfItems: number = -1,
) => {
  if (numberOfItems !== -1) {
    return logEvent(userId, NOTION_INTEGRATION, { action, field, numberOfItems });
  }
  return logEvent(userId, NOTION_INTEGRATION, { action, field });
};

// ============ Slack Analytics ===========

/**
 * For logging user actions performed related to the Slack integration
 * @action the action the user is performing
 * @field the object the action is done upon
 * @numberOfItems number of items fetched or created
 */
export const logSlackUserAction = (
  userId: string, action: string, field: string, numberOfItems: number = -1,
) => {
  if (numberOfItems !== -1) {
    return logEvent(userId, SLACK_INTEGRATION, { action, field, numberOfItems });
  }
  return logEvent(userId, SLACK_INTEGRATION, { action, field });
};

/**
 * For logging notifications delivered through slack
 * @action the action the user is performing
 * @field the object the action is done upon
 * @updatedField field updated in the task
 */
export const logSlackNotification = (userId: string, action: string, field: string, updatedField: string = '') => {
  if (updatedField !== '') {
    return logEvent(userId, SLACK_INTEGRATION, { action, field, updatedField });
  }
  return logEvent(userId, SLACK_INTEGRATION, { action, field });
};

// ============ Templates Analytics ===========

export const logTemplateUserAction = (
  userId: string,
  intercomTrackEvent: any,
  action: string,
  field: string,
  meetingVersion: MeetingVersion,
  meetingId: string,
  templateType: TemplateType = 'user',
) => {
  logEvent(userId, TEMPLATES_EVENT, {
    action, field, meetingVersion, meetingId, templateType,
  }, intercomTrackEvent);
};

// ============ Update Database Analytics ===========

export const logDatabaseEvent = (
  userId: string, action: string, field: string,
) => {
  logEvent(userId, DATABASE_EVENT, { action, field });
};

// ============ App Event Analytics (UPDATED) ===========

export const logAppEvent = (
  userId: string, field: string, action: string,
) => {
  logEvent(userId, APP_EVENT, { field, action });
};

// ============ Sidebar Event Analytics (UPDATED) ===========

export const logSidebarEvent = (
  userId: string, intercomTrackEvent: IntercomTrackEvent, field: string, action: string,
) => {
  logEvent(userId, SIDEBAR_EVENT, { field, action }, intercomTrackEvent);
};

// ============ Page Event Analytics (UPDATED) ===========

export const logPageEvent = (
  userId: string, intercomTrackEvent: IntercomTrackEvent, page: string, meetingId: string = '',
) => {
  if (meetingId.length === 0) {
    logEvent(userId, PAGE_EVENT, { page, action: VIEW_EVENT }, intercomTrackEvent);
    return;
  }

  logEvent(
    userId, PAGE_EVENT,
    { page, meetingId, action: VIEW_EVENT }, intercomTrackEvent,
  );
};

// ============ Signup Event Analytics ===========

export const logAuthenticationEvent = (
  userId: string,
  intercomTrackEvent: IntercomTrackEvent,
  loginOrSignup: LoginOrSignup,
  type: OnboardingType = ORGANIC_SIGN_UP,
  source: InviteSource = INVITE_EMAIL,
) => {
  if (loginOrSignup === LOGIN_EVENT) {
    logEvent(userId, AUTHORIZATION_EVENT, { action: loginOrSignup }, intercomTrackEvent);
    return;
  }

  logEvent(
    userId, AUTHORIZATION_EVENT,
    { action: loginOrSignup, type, source }, intercomTrackEvent,
  );
};

// ============ Onboarding Event Analytics ===========

export const logOnboardingEvent = (
  userId: string,
  intercomTrackEvent: IntercomTrackEvent,
  page: string,
  action: string,
  error: string = '',
) => {
  if (error.length === 0) {
    logEvent(userId, ONBOARDING_EVENT, { page, action }, intercomTrackEvent);
    return;
  }

  logEvent(userId, ONBOARDING_EVENT, { page, action, error }, intercomTrackEvent);
};

// ============ Invite Non Shepherd Event Analytics ===========

export const logInviteEvent = (
  userId: string,
  intercomTrackEvent: IntercomTrackEvent,
  page: string,
  action: string,
  numberOfInvites: number,
) => {
  logEvent(userId, INVITE_EVENT, { page, action, numberOfInvites }, intercomTrackEvent);
};

// ============ Loading Analytics ====================================================

export const startTrackingEvent = (event: TimeTrackingEvents) => {
  console.log('log:: start', event);
  mixpanel.time_event(event);
};

export const stopTrackingEvent = (event: TimeTrackingEvents) => {
  console.log('log:: stop', event);
  mixpanel.track(event);
};

// ============ GAPI Analytics =======================================================

export const logGapiEvent = (
  action: string, field: string, isSuccess: boolean, numberOfAttempts: number,
) => {
  logEvent(defaultAuthContext.userId, GAPI_EVENT, {
    action, field, isSuccess, numberOfAttempts,
  });
};

// ============ Desktop Notification Analytics ===========
/**
 * @action the action the user is performing
 * @section the action happens in which section
 */
export const logDesktopNotificationEvent = (
  userId: string, action: string, section: DesktopNotificationActionOn,
) => {
  if (action === ENABLE_EVENT || action === DISABLE_EVENT) {
    sendPeoplePropertiesInMixPanel(userId, DESKTOP_NOTIFICATION,
      { isDesktopNotificationEnabled: action === ENABLE_EVENT });
  }
  logEvent(userId, DESKTOP_NOTIFICATION, { action, section });
};

// ============ Tab Analytics ============
/**
 * @action the action the user is performing
 * @section the action happens in which section
 */
export const logMainTabMenuEvent = (
  userId: string, action: string, section: UserCenterPage,
) => {
  logEvent(userId, MAIN_TAB_MENU_EVENT, { action, section });
};

export const logNotesTabEvent = (
  userId: string, action: string, section: MeetingSection,
) => {
  logEvent(userId, NOTES_TAB_EVENT, { action, section });
};

// ============ Integrations Analytics ============
/**
 * @action the action the user is performing
 * @integration which integration the action is done upon
 * @location where  the action originates from,
 * example SHARE_MODAL_LOCATION | SETTINGS_MODAL_LOCATION | REDIRECT_LOCATION
 */
export const logIntegrationsEvent = (
  userId: string, action: string, integration: string, location: string = '',
) => {
  logEvent(userId, INTEGRATION_EVENT, { action, integration, location });
};

// ============ Secret Chat Analytics ============
/**
 * @action the action the user is performing
 */
export const logSecretChatEvents = (
  userId: string, action: string,
) => {
  logEvent(userId, SECRET_CHAT_EVENT, { action });
};

// ============ Share Notes Analytics ============

/**
 * @action: SEND_EVENT | OPEN_EVENT | CLOSE_EVENT | VIEW_EVENT
 * @meetingSection: 'shared' | 'private' | 'secret' | 'task'
 * @channelsShared: 'emails' | 'trello' | 'slack' | 'notion'
 * @time:{
      @when: 'before' | 'during' | 'after'
      @timeDiff: <time difference if it ws before/after the meeting>
      @timestamp: <timestamp of notes shared>
    }
 */
export const logShareNotesEvents = (
  userId: string, action: string,
  meetingSection: MeetingSection[],
  channelsShared: string[], time: TimestampLog,
  status: ResolvedState,
) => {
  logEvent(userId, SHARE_NOTES_EVENT, {
    action, meetingSection, channelsShared, ...time, status,
  });
};

export const logMeetingShared = (userId: string) => {
  sendPeoplePropertiesInMixPanel(userId, 'meeting_shared');
};

// ============ Error Events Analytics ============

export const logAppErrorEvents = (
  userId:string, action: string, component: string,
  errorMessage: string, time: number,
) => {
  logEvent(userId, APP_ERROR_EVENT, {
    action, component, errorMessage, time,
  });
};

export const logAllNotesClickMeeting = (
  userId: string,
  startDateTime: string,
) => {
  // Interesting data
  // That you clicked a meeting in the all notes dashboard
  // If you clicked a meeting today or yesterday
  logEvent(userId, ALL_NOTES_EVENT, {
    action: CLICK_EVENT,
    field: MEETING_CARD_FIELD,
    days_since_meeting: SDateClass.calculateNumberOfDaysSinceDate(startDateTime),
  });
};

/**
 * Log non Shepherd users to whom emails are sent to
 */
export const logNonShepherdUsers = async (userData: User, emails:{email: string}[]) => {
  const emailList = emails.map((email) => email.email);

  const users = await cfSearchPublicUserDataV2ByEmailsV2(emailList);

  const nonShepherdUsers = users
    .filter((data) => !data.isShepherdUser)
    .map((data) => data.data.email);

  trackUserInvites(nonShepherdUsers, SHARE_NOTES_EMAIL, userData);
};

export const mapReferenceTrackingToExternalSource = (referenceTracking: string): InviteSource => {
  if (referenceTracking === SLACK) return SLACK;
  if (referenceTracking === INVITE_EMAIL) return INVITE_EMAIL;
  if (referenceTracking === SHARE_NOTES_EMAIL) return SHARE_NOTES_EMAIL;
  return UNKNOWN;
};
// =============== Log TextEditor Usage -------------------------------------

export const handleTextEditorLog = (
  userId: string,
  data: Object,
) => {
  logEvent(userId, TEXT_EDITOR_EVENT, data);
};

// =============== Log UpdateHub Usage -------------------------------------

export const handleUpdateHubLog = (
  userId: string,
  action: string,
) => {
  logEvent(userId, UPDATE_HUB_EVENT, { action });
};

// =============== Log Send feedback Usage -------------------------------------

export const handleSendFeedbackLog = (
  userId: string,
  email: string,
  feedback: string,
  emailSent: boolean,
) => {
  logEvent(userId, SEND_FEEDBACK, {
    userId,
    email,
    feedbackLength: feedback.length,
    emailSent,
  });
};
