import { captureException } from '@sentry/react';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { ResolveState } from '../../shared/types/types';
import { TASKS_COLLECTION } from '../../utils/constants';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { firestore } from '../../utils/firebase';

/**
 * Core functions to update data in the `/task` collection. Should only be used by TaskAPI class.
 *
 * Responsible for:
 * - Adding a new task in DB
 * - Map return data from DB to proper type
 * - Log actions to Mixpanel
 * - Log errors to Sentry
 */
class TaskCoreAPI {
  protected static async updateTask(taskId: string, updateObject: object): Promise<ResolveState> {
    ConsoleImproved.log('TaskCoreAPI: updateTask');
    if (!TaskCoreAPI.isValidUpdateObject(updateObject)) {
      ConsoleImproved.log('Failed to validate the updateObject when updating task', updateObject);
      return REJECTED;
    }

    const result = TaskCoreAPI.updateTaskCore(taskId, updateObject)
      .then(() => {
        ConsoleImproved.log('updated task successfully');
        // TODO: Log mixpanel
        return RESOLVED as ResolveState;
      })
      .catch((error) => {
        ConsoleImproved.log(error);
        // TODO: Log mixpanel
        captureException(error);
        return REJECTED as ResolveState;
      });

    return result;
  }

  /**
   * Should only be called by TaskCoreAPI class.
   * Therefore it's private. Will update the task in the DB.
   */
  private static async updateTaskCore(taskId: string, updateObject: object) {
    const result = await firestore()
      .collection(TASKS_COLLECTION)
      .doc(taskId)
      .update(updateObject);
    return result;
  }

  static isValidUpdateObject(updateObject: object): boolean {
    const validKeys = [
      'data.title',
      'date.dueDate.date',
      'date.dueDate.type',
      'data.assignee',
      'data.status',
      'assignee',
    ];
    const invalidKeys = Object.keys(updateObject).filter((key) => !validKeys.includes(key));
    if (invalidKeys.length > 0) return false;
    return true;
  }
}

export default TaskCoreAPI;
