import React from 'react';
import TitleWithHighlightedSection from '../welcome-screens-highlighted-title-atom';
import SubtitleBullets from '../welcome-screens-subtitle-bullets-molecule';
import CustomizedBullet from '../welcome-screens-subtitle-bullets-molecule/utils/types';
import TitleContainer from './utils/styles';

interface WelcomeScreenTitleAndSubtitleProps {
  title: string,
  highlightedSection: string,
  highlightColor: string,
  subtitle: string[],
  customized?: CustomizedBullet,
}

const WelcomeScreenTitleAndSubtitle = ({
  title,
  highlightedSection,
  highlightColor,
  subtitle,
  customized = '',
}: WelcomeScreenTitleAndSubtitleProps) => (
  <TitleContainer>
    <TitleWithHighlightedSection
      title={title}
      highlightedSection={highlightedSection}
      highlightColor={highlightColor}
    />
    <SubtitleBullets subtitle={subtitle} customized={customized} />
  </TitleContainer>
);

WelcomeScreenTitleAndSubtitle.defaultProps = {
  customized: '',
};

export default WelcomeScreenTitleAndSubtitle;
