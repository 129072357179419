import React from 'react';
import styled from 'styled-components';
import { ResolveStateExtended, SlackNotificationsSettings } from '../../../../../shared/types/types';
import NotifyAtom from './NotifyAtom';
import SlackNotificationSwitchsGroupMolecule from './SlackNotificationSwitchsGroupMolecule';

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 22px;
`;

interface Props {
  notifications: SlackNotificationsSettings,
  // eslint-disable-next-line no-unused-vars
  updateSlackNotifications: (val: SlackNotificationsSettings) => void,
  isResolved: ResolveStateExtended,
}

const SlackNotificationToggleMolecule = ({
  notifications,
  updateSlackNotifications,
  isResolved,
}: Props) => (
  <>
    <TextContainer>
      <NotifyAtom />
    </TextContainer>
    <SlackNotificationSwitchsGroupMolecule
      notifications={notifications}
      updateSlackNotifications={updateSlackNotifications}
      isResolved={isResolved}
    />
  </>
);

export default SlackNotificationToggleMolecule;
