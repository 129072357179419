import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ConsoleImproved from './shared/classes/ConsoleImproved';
import { listenToWindowRouteMessages } from './utils/chromeExtension/chromeExtension';
import { cleanUrl } from './utils/urlUtils/getCleanUrls';

interface Props {
  children: React.ReactNode;
}

// TODO use new hook
const ListenToRouteMessageContainer = ({ children }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [newUrl, setNewUrl] = useState('');

  useEffect(() => {
    listenToWindowRouteMessages(history, setNewUrl);
  }, []);

  useEffect(() => {
    ConsoleImproved.log('New URL');
    ConsoleImproved.log(newUrl);
    ConsoleImproved.log('Current location');
    ConsoleImproved.log(location.pathname);
    const cleanedUrl = cleanUrl(newUrl);
    if (!location.pathname.includes(cleanedUrl)) {
      ConsoleImproved.log('Navigating to new URL');
      history.push(cleanedUrl);
    } else {
      ConsoleImproved.log('NOT Navigating to new URL');
      ConsoleImproved.log('New URL');
      ConsoleImproved.log(cleanedUrl);
      ConsoleImproved.log('Current location');
      ConsoleImproved.log(location.pathname);
      ConsoleImproved.log(cleanedUrl.includes(location.pathname));
    }
  }, [newUrl]);

  return (
    <>
      {children}
    </>
  );
};

export default ListenToRouteMessageContainer;
