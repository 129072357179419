import React from 'react';
import styled from 'styled-components';
import { header900, header800 } from '../../../shared/typography';
import useWindowSize from '../../../utils/hook/useWindowSize';

interface StyleProps {
  isScreenSizeSM: boolean
}

const Title = styled.span<StyleProps>`
  ${({ isScreenSizeSM }) => (isScreenSizeSM ? header800 : header900)}
`;

interface Props {
  firstName: string,
}

const TitleAtom = ({ firstName }: Props) => {
  const { isScreenSizeSM } = useWindowSize();
  return (
    <Title isScreenSizeSM={isScreenSizeSM}>
      {`Welcome back, ${firstName}!`}
    </Title>
  );
};

export default TitleAtom;
