import React from 'react';
import styled from 'styled-components';
import { MeetingData } from '../../../types/types';
import { uiText } from '../../../typography';
import {
  gray1, gray7, surface, blue6,
} from '../../../colours';
import Arrow from '../../../icons/ArrowIcon';
import { getDayAndMonth } from '../../../../utils/dateUtils/date';

interface ButtonContainerProps {
  isDropDownOpen?: boolean
}

const ButtonContainer = styled.div<ButtonContainerProps>`
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  background-color: ${gray1};

  width: 170px;
  height: 40px;

  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.25);

  ${(props) => (props.isDropDownOpen ? `border: 2px ${blue6} solid` : '')};
`;

const ButtonTextContainer = styled.span`
  ${uiText};
  color: ${gray7};

  display: flex;
  justify-content: center;

  width: 100%;

  &:hover {
    cursor: default;
  }
`;

interface ArrowProps {
  disable?: boolean
}

const LeftArrowContainer = styled.button<ArrowProps>`
  all: unset;

  padding-left: 16px;

  &:hover {
    cursor: ${(props) => (props.disable ? 'default' : 'pointer')};
  }
`;

const RightArrowContainer = styled.button<ArrowProps>`
  all: unset;

  padding-right: 16px;

  &:hover {
    cursor: ${(props) => (props.disable ? 'default' : 'pointer')} ;
  }
`;

interface Props {
  previousMeetingList: Array<MeetingData>,
  isDropDownOpen: boolean,
  currentMeetingIndex: number,
  /* eslint-disable-next-line no-unused-vars */
  handleLeftArrowClick: (event:any) => void,
  /* eslint-disable-next-line no-unused-vars */
  handleRightArrowClick: (event:any) => void,
  toggleDropDownView: () => void,
}

const PreviousMeetingDropDownButton = ({
  previousMeetingList,
  isDropDownOpen,
  toggleDropDownView,
  currentMeetingIndex,
  handleLeftArrowClick,
  handleRightArrowClick,
}:Props) => {
  if (previousMeetingList.length > 1) {
    return (
      <ButtonContainer onClick={toggleDropDownView} isDropDownOpen={isDropDownOpen}>
        <LeftArrowContainer
          onClick={(event) => { handleLeftArrowClick(event); }}
          disabled={currentMeetingIndex === previousMeetingList.length - 1}
          disable={currentMeetingIndex === previousMeetingList.length - 1}
        >
          <Arrow
            fill={currentMeetingIndex === previousMeetingList.length - 1 ? gray7 : surface}
            direction="left"
          />
        </LeftArrowContainer>
        <ButtonTextContainer>
          {getDayAndMonth(previousMeetingList[currentMeetingIndex].date.start.date)}
        </ButtonTextContainer>
        <RightArrowContainer
          onClick={(event) => { handleRightArrowClick(event); }}
          disabled={currentMeetingIndex === 0}
          disable={currentMeetingIndex === 0}
        >
          <Arrow
            fill={currentMeetingIndex === 0 ? gray7 : surface}
            direction="right"
          />
        </RightArrowContainer>
      </ButtonContainer>
    );
  }

  return null;
};

export default PreviousMeetingDropDownButton;
