import { AuthState, IntercomTrackEvent } from '../../../../../../shared/types/types';
import { logPageEvent } from '../../../../../../utils/analytics/eventLogger';

const logAnalytics = (
  authState: AuthState, trackEvent: IntercomTrackEvent, onboardingPage: string,
) => {
  logPageEvent(authState.userId, trackEvent, onboardingPage);
};

export default logAnalytics;
