import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import NameInputAtom from '../../../../../../pages/meeting/private-notes/add-members-modal/NameInputAtom';
import IconEmailMolecules from '../../../../../../pages/meeting/private-notes/create-private-notes-modal/components/IconEmailMolecules';
import { mapPrivateNoteAttendeeV2ToAttendeeV2 } from '../../../../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import { gray8, gray9 } from '../../../../../colours';
import { AssigneeV2, AttendeeV2, PrivateNoteAttendeeV2 } from '../../../../../types/types';
import { defaultSmall, header200 } from '../../../../../typography';
import SearchableAssign from '../../../../searchable-assign';

const EditPrivateNoteModalBodyContainer = styled.div`
  padding: 24px 24px 8px 24px;

  span:first-child {
    margin-bottom: 12px;
  }
  span:nth-child(1) {
    margin-bottom: 16px;
  }
  span:nth-child(3) {
    margin-bottom: 8px;
  }
`;

const EditPrivateNoteDescription = styled.div`
  ${defaultSmall};
  color: ${gray8};
  margin: 0 0 18px 0;
  display: inline-block;
`;

const PrivateNoteNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  :first-child {
    margin-bottom: 8px;
  }

  input {
    margin-bottom: 20px;
  }
`;

const PrivateNoteNameInputFieldLabel = styled.span`
  width: 100%;
  ${header200};
  color: ${gray9};
  margin: 0;
  display: inline-block;
`;

interface Props {
  privateNoteTitle: string,
  setPrivateNoteTitle: Dispatch<SetStateAction<string>>,
  privateNoteMembers: PrivateNoteAttendeeV2[],
  remainingMeetingAttendees: AttendeeV2[],
  /* eslint-disable-next-line */
  onClickAddAttendeeToPrivateNote: (assignee: AssigneeV2) => void,
  /* eslint-disable-next-line */
  onClickRemoveAttendeeFromPrivateNote: (email: string) => void,
}

const EditPrivateNoteModalBody = ({
  privateNoteTitle,
  setPrivateNoteTitle,
  privateNoteMembers,
  remainingMeetingAttendees,
  onClickAddAttendeeToPrivateNote,
  onClickRemoveAttendeeFromPrivateNote,
}:Props) => (
  <EditPrivateNoteModalBodyContainer>
    <EditPrivateNoteDescription>
      By giving select attendees access to this new private note, you are able
      to collaborate with them privately. This is useful for interviews,
      presentations, external meetings, and more!
    </EditPrivateNoteDescription>
    <PrivateNoteNameContainer>
      <PrivateNoteNameInputFieldLabel>Name your Private note</PrivateNoteNameInputFieldLabel>
      <NameInputAtom prevTitle={privateNoteTitle} setTitle={setPrivateNoteTitle} />
    </PrivateNoteNameContainer>
    <PrivateNoteNameInputFieldLabel>Give access to the following</PrivateNoteNameInputFieldLabel>
    <SearchableAssign
      candidates={remainingMeetingAttendees}
      selectedEmail=""
      onAssigneeChange={onClickAddAttendeeToPrivateNote}
      clearInputAfterSelect
    />
    <IconEmailMolecules
      handleRemove={onClickRemoveAttendeeFromPrivateNote}
      assignees={privateNoteMembers.map(mapPrivateNoteAttendeeV2ToAttendeeV2)}
    />
  </EditPrivateNoteModalBodyContainer>
);

export default EditPrivateNoteModalBody;
