import React from 'react';
import styled from 'styled-components';
import { blue6 } from '../../colours';
import ManyUserIcon from '../../icons/ManyUserIcon';
import { uiText } from '../../typography';
import ReactTooltip from '../tooltip/ReactTooltip';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const SubContainer = styled.div`
  margin: 0px 0px 0px 16px;
`;

const H2 = styled.h2`
  ${uiText};
  margin: 0px 0px 8px 0px;
`;

const TitleContainer = styled.div`
  align-items: center;
`;

const Title = styled.div`
  ${uiText};
  width: fit-content;
  padding: 0px 4px 0px 0px;
  color: ${blue6};
  border-radius: 4px;
  cursor: pointer;
`;

interface Props {
  title: string,
}

const TaskDetailsMeetingComponent = ({
  title,
}: Props) => (
  <Container>
    <ManyUserIcon />
    <SubContainer>
      <H2>Meeting</H2>
      <TitleContainer>
        <ReactTooltip place="top" tip="The task was created in this meeting">
          <Title>{title}</Title>
        </ReactTooltip>
      </TitleContainer>
    </SubContainer>
  </Container>
);

export default TaskDetailsMeetingComponent;
