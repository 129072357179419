import React, { useState } from 'react';
import styled from 'styled-components';
import { gray7 } from '../../../../../shared/colours';
import { ResolveStateExtended, SlackNotificationsSettings } from '../../../../../shared/types/types';
import { defaultSmall } from '../../../../../shared/typography';
import HelpIconAtom from '../../../../all-notes/HelpIconAtom';
import HelpIconInfoModal from '../../HelpIconInfoModal';
import slackNotificationInfoImg from '../../../../../shared/img/slackNotificationInfo.png';
import ToggleAtom from './ToggleAtom';

const SwitchGroup = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 30px;
  }
  width: 100%;
  margin-bottom: 0px;
`;

const SubText = styled.div`
  display:flex;
  ${defaultSmall}
  color:${gray7};
`;

interface Props {
  notifications: SlackNotificationsSettings,
  // eslint-disable-next-line no-unused-vars
  updateSlackNotifications: (val: SlackNotificationsSettings) => void,
  isResolved: ResolveStateExtended,
}

const SlackNotificationSwitchsGroupMolecule = ({
  notifications,
  updateSlackNotifications,
  isResolved,
}: Props) => {
  const {
    taskCreated, taskDeleted, taskOverdue, taskUpdated,
  } = notifications;

  const [isSlackNotificationInfoOpen, setIsSlackNotificationInfoOpen] = useState(false);

  const setTaskOverdue = () => {
    updateSlackNotifications({
      ...notifications,
      taskOverdue: !taskOverdue,
    });
  };

  const setTaskCreated = () => {
    updateSlackNotifications({
      ...notifications,
      taskCreated: !taskCreated,
    });
  };

  const setTaskUpdated = () => {
    updateSlackNotifications({
      ...notifications,
      taskUpdated: !taskUpdated,
    });
  };

  const setTaskDeleted = () => {
    updateSlackNotifications({
      ...notifications,
      taskDeleted: !taskDeleted,
    });
  };

  return (
    <>
      <SwitchGroup>
        <ToggleAtom label="I have overdue tasks" isToggled={taskOverdue} onToggle={setTaskOverdue} isResolved={isResolved} />
        <ToggleAtom label="A task is assigned to me" isToggled={taskCreated} onToggle={setTaskCreated} isResolved={isResolved} />
        <ToggleAtom label="Any of my tasks are updated" isToggled={taskUpdated} onToggle={setTaskUpdated} isResolved={isResolved} />
        <ToggleAtom label="When a task assigned to me is deleted" isToggled={taskDeleted} onToggle={setTaskDeleted} isResolved={isResolved} />
        <SubText>
          Notifications will appear in the Slack Shepherd app &nbsp;
          <HelpIconAtom onClick={() => { setIsSlackNotificationInfoOpen(true); }} />
        </SubText>
      </SwitchGroup>
      <HelpIconInfoModal
        isOpen={isSlackNotificationInfoOpen}
        setModalClosed={() => { setIsSlackNotificationInfoOpen(false); }}
        headText="Look for the Shepherd app inside Slack"
        subText="You will receive notifications from Shepherd here."
        imgSrc={slackNotificationInfoImg}
      />
    </>
  );
};

export default SlackNotificationSwitchsGroupMolecule;
