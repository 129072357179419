import React from 'react';
import styled from 'styled-components';
import { gray8 } from '../../../../../shared/colours';
import Dropdown from '../../../../../shared/components/dropdown';
import { DropdownItem } from '../../../../../shared/components/dropdown/utils';
import { header300, uiText } from '../../../../../shared/typography';

export const SettingLabel = styled.p`
  ${header300}
  padding: 0px;
  margin: 0px 0px 8px 6px;
`;

export const SettingSubText = styled.p`
  ${uiText}
  color: ${gray8};
  padding: 0px;
  margin: 0px 0px 0px 6px;
`;

export const SettingContainer = styled.div`
  margin: 0px 0px 24px 0px;
`;

export const DropdownContainer = styled.div`
  margin-top: 0px 0px 6px 0px;
`;

interface Props {
  label: string,
  placeholder: string,
  options: DropdownItem[],
  loading: boolean
  // eslint-disable-next-line no-unused-vars
  onChange: (tab: DropdownItem) => void,
  onClick: ()=>void
}

export default function SettingTab({
  label, placeholder, options, loading, onChange, onClick,
}:Props) {
  return (
    <SettingContainer>
      <SettingLabel>{label}</SettingLabel>
      <DropdownContainer>
        <Dropdown
          onDropdownClick={onClick}
          dropdownItems={options}
          isMultiSelect={false}
          placeholderText={placeholder}
          onOptionClick={onChange}
          isLoading={loading}
        />
      </DropdownContainer>
    </SettingContainer>
  );
}
