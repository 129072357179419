import React from 'react';
import { slackAPIRemoveSlackIntegration } from '../../../../database/firebaseSlackAPI';
import { red6, surface } from '../../../../shared/colours';
import { SlackIcon } from '../../../../shared/icons/integration-icons';
import ActiveTickIcon from '../../../../shared/icons/integrations/ActiveTickIcon';
import ThreeDotsIcon from '../../../../shared/icons/integrations/ThreeDotsIcon';
import { IntegrationsTabView } from '../../../../shared/types/types';
import { SLACK_SELECT_DEFAULT_CHANNEL_VIEW, SLACK_NOTIFICATIONS_VIEW } from '../../../../utils/constants';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import { slackAuthUrl } from '../../../../utils/slack/slackUtils';
import {
  ActiveText, AppContainer, IntegratedContainer, MenuActiveContainer, MenuContainer, NameText,
} from '../integrations/utils/commonStyles';
import OptionsDropdown, { OptionsDropdownItem } from '../integrations/OptionsDropdown';

interface Props {
  enable: boolean
  dropDownRef: React.MutableRefObject<null>,
  userId: string,
  // eslint-disable-next-line no-unused-vars
  setTabView: (value: React.SetStateAction<IntegrationsTabView>) => void
}

const SlackIntegrated = ({
  enable, dropDownRef, userId, setTabView,
}:Props) => {
  const [isSlackDropdownActive,
    setIsSlackDropdownActive] = useDetectOutsideClick(dropDownRef, false);

  const slackDropdownOptions: OptionsDropdownItem[] = [
    {
      id: 1, value: 'Switch Slack workspace', color: surface, onClick: () => window.open(slackAuthUrl),
    },
    {
      id: 2, value: 'Change default channel', color: surface, onClick: () => setTabView(SLACK_SELECT_DEFAULT_CHANNEL_VIEW),
    },
    {
      id: 3,
      value: 'Manage notifications',
      color: surface,
      onClick: () => handleManageNotifications(),
    },
    {
      id: 4, value: 'Disable', color: red6, onClick: () => handleDisableSlack(),
    },
  ];

  const handleDisableSlack = () => {
    slackAPIRemoveSlackIntegration(userId);
    setIsSlackDropdownActive(false);
  };

  const handleManageNotifications = () => {
    setTabView(SLACK_NOTIFICATIONS_VIEW);
    setIsSlackDropdownActive(false);
  };

  const handleThreeDotsClick = () => {
    setIsSlackDropdownActive(!isSlackDropdownActive);
  };

  if (!enable) return <></>;

  return (
    <IntegratedContainer>
      <AppContainer>
        <SlackIcon width="28" />
        <NameText>Slack (Beta)</NameText>
      </AppContainer>
      <MenuActiveContainer>
        <ActiveTickIcon />
        <ActiveText>Active</ActiveText>
        <MenuContainer>
          <ThreeDotsIcon onClick={handleThreeDotsClick} />
          <OptionsDropdown
            optionsRef={dropDownRef}
            isOpen={isSlackDropdownActive}
            options={slackDropdownOptions}
          />
        </MenuContainer>
      </MenuActiveContainer>
    </IntegratedContainer>
  );
};

export default SlackIntegrated;
