import React from 'react';
import styled from 'styled-components';
import Header from '../header';
import { uiTextMedium, defaultText } from '../../typography';
import Scrollbar from '../scrollbar';
import { DEFAULT_HEADER_MEETING_VIEW } from '../header/utils/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  // In Google Meet, the sidebar is slightly shorter 
  // becase of the bottom bar in Google Meet
  height: calc(100vh - 48px); 
  padding: 0 40px;
`;

const ExplanationText = styled.p`
  ${uiTextMedium};
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 64px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextArrowContainer = styled.div`
  display: flex;
  align-items: baseline;
  width: 250px;
`;

export const Title = styled.span`
  ${defaultText}
  text-align: center;
  margin-bottom: 10px;
`;

export interface Props {
  children: React.ReactNode;
}

const ErrorPage = ({ children }: Props) => (
  // If there doesn't exist any meeting with the googleMeetId,
  // this page will be shown
  <>
    <Header view={DEFAULT_HEADER_MEETING_VIEW} />
    <Scrollbar maxHeight="100%">
      <Container>
        <TopContainer>
          {children}
        </TopContainer>
        <BottomContainer>
          <TextArrowContainer>
            <ExplanationText>
              Please feel free to speak to us directly
              if you need further support
            </ExplanationText>
          </TextArrowContainer>
        </BottomContainer>
      </Container>
    </Scrollbar>
  </>
);

export default ErrorPage;
