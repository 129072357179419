import * as Sentry from '@sentry/browser';
import { Dispatch, SetStateAction } from 'react';
import { functions } from '../firebase';
import {
  AuthState,
  NotionCreatePageData, NotionPageData, ResolvedState, ResolveState,
} from '../../shared/types/types';
import { logNotionUserAction } from '../analytics/eventLogger';
import {
  CREATE_EVENT, GET_EVENT, NOTION_INTEGRATION,
  NOTION_PAGE_FIELD, NOTION_SUB_PAGE_FIELD, NOTION_WORKSPACE_PAGE_FIELD,
} from '../analytics/enums';
import { toastDanger } from '../notifications';
import { REJECTED } from '../enums';

export const createAndSendNotionPage = (
  authState: AuthState, createPageObj: NotionCreatePageData[],
) => functions()
  .httpsCallable('createNotionPage')(createPageObj).then((res) => {
    logNotionUserAction(authState.userId, CREATE_EVENT, NOTION_PAGE_FIELD, createPageObj.length);
    return res;
  });

export const getNotionWorkspacePages = (
  authState: AuthState, searchText: string, token: string,
  setPages: Dispatch<SetStateAction<NotionPageData[]>>,
  setResolveState: Dispatch<SetStateAction<ResolveState>>,
) => {
  functions().httpsCallable('getNotionWorkspacePagesV3')({ accessToken: token, query: searchText }).then((data) => {
    logNotionUserAction(authState.userId, GET_EVENT, NOTION_WORKSPACE_PAGE_FIELD, data.data.length);
    if (data.data.length !== 0) {
      // no need to map cf response to NotionPageData[] as mapping is done in cf
      const workspacePages: NotionPageData[] = data.data;
      setPages(workspacePages);
      setResolveState('resolved');
    } else {
      setPages([]);
      setResolveState(REJECTED);
    }
  }).catch(() => {
    setResolveState(REJECTED);
  });
};

export const getNotionSubPages = (
  authState: AuthState,
  parentPageId: string, token: string,
  setPages: Dispatch<SetStateAction<NotionPageData[]>>,
  setResolveState: Dispatch<SetStateAction<ResolveState>>,
) => {
  functions().httpsCallable('getNotionSubPages')({ accessToken: token, parentPageId }).then((data) => {
    logNotionUserAction(authState.userId, GET_EVENT, NOTION_SUB_PAGE_FIELD, data.data.length);
    if (data.data.length !== 0) {
      const workspacePages: NotionPageData[] = data.data.map((page: any) => (
        {
          pageId: page.id,
          parentPageId: page.parent?.page_id || '',
          title: page.child_page.title,
          workspaceToken: token,
        }
      ));
      setPages(workspacePages);
      setResolveState('resolved');
    } else {
      setPages([]);
      setResolveState(REJECTED);
    }
  }).catch(() => {
    setResolveState(REJECTED);
  });
};

export const saveNotionData = (tempAuthCode: string, userId: string) => functions()
  .httpsCallable('saveNotionDataToUser')({ tempAuthCode, userId })
  .then((res) => {
    if (res.data.state === REJECTED || res.data.topLevelPageId.length === 0) {
      toastDanger('Integration failed', 'You selected a database-page, which we unfortunately cannot integrate with. Please select a normal page in Notion. A database page is a "special" format page, i.e. table page / list page.', 120000);
      throw new Error('Notion integration failed, no top level page found');
    }
    return { state: res.data.state as ResolvedState, data: res.data };
  })
  .catch((error) => {
    Sentry.captureException({
      integration: NOTION_INTEGRATION,
      error,
    });
    return { state: REJECTED as ResolvedState, data: error };
  });
