import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import useWindowSize from '../../../utils/hook/useWindowSize';
import {
  darkBlue1,
  gray1, gray8, gray9, overlaySurface,
} from '../../colours';
import ArrowRight from '../../icons/arrowRight';
import CloseSquareIcon from '../../icons/closeSquare';
import { header500 } from '../../typography';
import Scrollbar from '../scrollbar';
import ShortcutsModalHeader from '../shortcuts/components/ShortcutsModalHeader';

interface StyleProps {
  isTitleCenter?: boolean;
}

const Title = styled.span`
  ${header500};
  color: ${gray9};
`;

const TitleIconContainer = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isTitleCenter }) => (isTitleCenter ? '-webkit-fill-available' : '')};

  svg {
    margin-left: 8px;
  }
`;

const IconWrapper = styled.button`
  display: flex;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;

  :hover {
    path {
      fill: ${gray8};
    }
  }
`;

const ModalHeader = styled.div<{ isHeadless: boolean }>`
  ${({ isHeadless }) => !isHeadless && `border-bottom: 2px solid ${darkBlue1};`};
  padding: 18px 24px;
`;

const ModalHeaderRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface ModalProps {
  isOpen: boolean;
  setModalClosed: () => void
  title?: string;
  children: ReactNode;
  isShortcutsModal?: boolean;
  isScrollbarEnabled?: boolean;
  icon?: any,
  isHeadless?: boolean;
  isTitleCenter?: boolean;
  isOverflowHidden?: boolean;
  enableBackButton?: boolean,
  onBackButtonClick?: () => void,
}

const Modal = ({
  isOpen, setModalClosed, title, isTitleCenter,
  children, isShortcutsModal, isScrollbarEnabled, icon, isHeadless,
  isOverflowHidden, enableBackButton, onBackButtonClick,
}: ModalProps) => {
  const { isScreenSizeSM } = useWindowSize();
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={setModalClosed}
      style={{
        content: {
          background: gray1,
          borderRadius: 10,
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
          height: 'fit-content',
          inset: isScreenSizeSM ? '10vh calc((122vw - 457px)/2)' : '10vh calc((100vw - 457px)/2)',
          width: isScreenSizeSM ? '370px' : '457px',
          padding: 0,
          border: 'none',
          overflow: isOverflowHidden ? 'hidden' : '',
        },
        overlay: {
          zIndex: 20,
          background: overlaySurface,
        },
      }}
    >
      <ModalHeader isHeadless={isHeadless as boolean}>
        <ModalHeaderRowContainer>
          {enableBackButton
          && (
          <IconWrapper onClick={onBackButtonClick}>
            <ArrowRight fill={gray9} rotateBy={180} />
          </IconWrapper>
          )}
          <TitleIconContainer isTitleCenter={isTitleCenter}>
            <Title>
              {!isHeadless && title}
            </Title>
            {icon}
          </TitleIconContainer>
          <IconWrapper onClick={setModalClosed}>
            <CloseSquareIcon width="24" height="24" fill={gray9} />
          </IconWrapper>
        </ModalHeaderRowContainer>
        {isShortcutsModal && <ShortcutsModalHeader />}
      </ModalHeader>
      {isScrollbarEnabled
        ? (
          <Scrollbar maxHeight="77vh">
            {children}
          </Scrollbar>
        ) : children}
    </ReactModal>
  );
};

Modal.defaultProps = {
  icon: null,
  title: '',
  isShortcutsModal: false,
  isScrollbarEnabled: true,
  isHeadless: false,
  isTitleCenter: false,
  isOverflowHidden: false,
  enableBackButton: false,
  onBackButtonClick: () => {},
};

export default Modal;
