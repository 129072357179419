import React from 'react';
import styled from 'styled-components';
import StrokeIcon from '../../icons/stroke';
import { darkBlue2, surface } from '../../colours';

const Container = styled.span`
  display: flex;
  align-items: center;
`;

interface Props {
  isOpen: boolean,
}

const TaskOperationButton = ({ isOpen }: Props) => (
  <Container>
    <StrokeIcon fill={isOpen ? surface : darkBlue2} />
  </Container>
);

export default TaskOperationButton;
