/* eslint-disable no-unused-vars */
import { uuid4 } from '@sentry/utils';
import firebase from 'firebase';

interface UploadParams {
  file: File,
  getStorageRef: (path: string) => firebase.storage.Reference;
  onCompleted?: (ref: firebase.storage.Reference) => any;
  onError?: (error: Error) => any;
  onProgress?: (progress: number, total?: number, transferred?: number) => any;
  path: string;
}

export default function startFirebaseUpload({
  file,
  getStorageRef,
  onCompleted,
  onError,
  onProgress,
  path,
}: UploadParams) {
  const fileCode = uuid4();
  const uploadTask = getStorageRef(path)
    .child(`/${fileCode}-${file.name}`)
    .put(file);

  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot) => {
      if (typeof onProgress === 'function') {
        onProgress(
          snapshot.bytesTransferred / snapshot.totalBytes,
          snapshot.totalBytes,
          snapshot.bytesTransferred,
        );
      }
    },
    (error) => {
      if (typeof onError === 'function') {
        onError(error);
      }
    },
    async () => {
      if (typeof onCompleted === 'function') {
        await onCompleted(uploadTask.snapshot.ref);
      }
    });
}
