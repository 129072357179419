import React from 'react';
import styled from 'styled-components';
import { header600, header700 } from '../../typography';
import { red3 } from '../../colours';

interface TextStyleProps {
  isSmall: boolean
}

const DefaultTextProfilePicture = styled.div<TextStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => (props.isSmall ? header600 : header700)};
  font-size: ${(props) => (props.isSmall ? '16px' : 'auto')};
  line-height: ${(props) => (props.isSmall ? '16px' : 'auto')};

  background: ${red3};
  height: ${(props) => (props.isSmall ? '24px' : '40px')};
  width: ${(props) => (props.isSmall ? '24px' : '40px')};
  border-radius: ${(props) => (props.isSmall ? '6px' : '25px')};
  border: none;
  cursor: ${(props) => (props.isSmall ? 'pointer' : 'normal')};

  margin-right: ${(props) => (props.isSmall ? 'auto' : '12px')};
`;

const ImageProfilePicture = styled.img<TextStyleProps>`
  border-radius: ${(props) => (props.isSmall ? '6px' : '25px')};
  border: none;

  height: ${(props) => (props.isSmall ? '100%' : '40px')};
  width: ${(props) => (props.isSmall ? '100%' : '40px')};

  margin-right: ${(props) => (props.isSmall ? 'auto' : '12px')};
`;

interface Props {
  photoUrl: string,
  initial: string,
  isSmall?: boolean
}

const ProfilePicture = ({ photoUrl, initial, isSmall = false }: Props) => {
  if (photoUrl.length > 0) {
    return (
      <ImageProfilePicture src={photoUrl} isSmall={isSmall} />
    );
  }

  return (
    <DefaultTextProfilePicture isSmall={isSmall}>
      {initial}
    </DefaultTextProfilePicture>
  );
};

ProfilePicture.defaultProps = {
  isSmall: false,
};

export default ProfilePicture;
