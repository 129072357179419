import React, { HTMLProps, ReactNode } from 'react';
import styled from 'styled-components';
import {
  darkBlue4, gray1, gray8, surface,
} from '../../../shared/colours';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import { header300 } from '../../../shared/typography';

interface ButtonProps {
  isSelected: boolean;
}

const ButtonElement = styled.span<ButtonProps>`
  ${header300}
  cursor: pointer;
  border-radius: 8px;
  padding: 6px 12px;
  color: ${({ isSelected }) => (isSelected ? gray1 : darkBlue4)};
  background-color: ${({ isSelected }) => (isSelected ? surface : gray1)};
  
  &:hover {
    background: ${gray8};
    color: ${gray1};
  }
`;

interface Props {
  text: string;
  isSelected: boolean;
  tip: string | ReactNode | undefined;
  onClick: HTMLProps<HTMLElement>['onClick'];
}

const NotesTabButtonAtom = ({
  text, isSelected, tip, onClick,
}: Props) => (
  <ReactTooltip tip={tip}>
    <ButtonElement isSelected={isSelected} onClick={onClick}>
      {text}
    </ButtonElement>
  </ReactTooltip>
);

export default NotesTabButtonAtom;
