import React from 'react';
import styled from 'styled-components';
import { SIDEBAR_WIDTH } from '../../../../utils/globalStyle';
import { darkBlue4, gray1, surface } from '../../../colours';
import ShepherdLogo from '../../../icons/shepherd-logo';
import { uiTextMedium } from '../../../typography';

interface Props {
  text: string
}

/**
 * Page loader with Shepherd logo showed while the meetingData is fetched
*/
const MeetingLoadingPage = ({ text }: Props) => (
  <TopContainer>
    <Container>
      <ShepherdLogo width={267} spin fadeIn={false} />
      <Text>{text}</Text>
    </Container>
  </TopContainer>
);

export default MeetingLoadingPage;

const TopContainer = styled.div`
  background: ${surface};

  & > div {
    border-radius: 24px 24px 0 0;
  }
`;

const Container = styled.div`
  background: ${gray1};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1280px){
    width: 100%;
  }
  @media only screen and (max-width: 1280px){
    width: ${SIDEBAR_WIDTH};
  }
  height: 86vh;
`;

const Text = styled.p`
  ${uiTextMedium};
  color: ${darkBlue4};
  margin-top: 16px;
`;
