/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ConsoleImproved from '../../../shared/classes/ConsoleImproved';
import SDateClass from '../../../shared/classes/SDate';
import {
  blue1, blue2, layoutGrey, layoutWhite,
} from '../../../shared/colours';
import ProfileIconsRow from '../../../shared/components/profile-icon/ProfileIconsRow';
import { GapiMeetingData, GoogleAttendee, PublicUserDataV2 } from '../../../shared/types/types';
import useWindowSize from '../../../utils/hook/useWindowSize';
import useMeetingsAndUsersStore from '../../../zustand/useAllMeetingsStore';
import NoteItemDateAtom from './NoteItemDateAtom';
import NoteItemTitleAtom from './NoteItemTitleAtom';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

interface StyleProps {
  isScreenSizeSM: boolean
}

const LeftContainer = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  cursor: default;
  margin-right: 20px;

  &:first-child{
    margin-left: ${({ isScreenSizeSM }) => (isScreenSizeSM ? '0px' : '8px')};
  }
`;

interface MeetingCardProp {
  isCurrentlyOngoing: boolean;
  isScreenSizeSM: boolean;
}

const RightContainer = styled.div<MeetingCardProp>`
  display: flex;
  align-items: center;
  justify-content: ${({ isScreenSizeSM }) => (isScreenSizeSM ? 'space-around' : 'space-between')};;
  position: relative;
  padding: ${({ isScreenSizeSM }) => (isScreenSizeSM ? '0px' : '0 20px 0 20px')};
  background-color: ${layoutWhite};
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  margin-right: ${({ isScreenSizeSM }) => (isScreenSizeSM ? '0px' : '15px')};
  height: 44px;
  /* @media only screen and (min-width: 1280px){
    width: 90%;
  }
  @media only screen and (max-width: 1280px){
    width: 85%;
  } */

  & > *:not(:first-child) {
    margin-left: ${({ isScreenSizeSM }) => (isScreenSizeSM ? '0px' : '8px')};
  }
/* last child */
  & > *:last-child {
    margin-left: 30px;
  }

  ${(props) => props.isCurrentlyOngoing && css`
    background-color: ${blue1};
  `}

  &:hover {
    background-color: ${layoutGrey};
    ${(props) => props.isCurrentlyOngoing && css`
      background-color: ${blue2};
    `}
  }
`;

const AttendeesContainer = styled.div`
  width: auto;
`;

interface Props {
  note: GapiMeetingData
  onAction: (eventId: string, calendarId: string, startDateTime: string) => void,
  // title: string;
  // // attendees: AttendeeV2[];
  // attendees: any[];
  // // replace date type
  // date: any;
}

// eslint-disable-next-line max-len

const NoteItemMolecule = ({ note, onAction }: Props) => {
  const numberOfDisplayAttendees = 2;
  const [meetingUsers, setMeetingUsers] = useState<PublicUserDataV2[]>([]);
  const [extraMeetingUsers, setExtraMeetingUsers] = useState<PublicUserDataV2[]>([]);
  // ToDo: Enhance Extra Attendees, to show all attendees
  // const [extraMeetingUsers, setExtraMeetingUsers] = useState<PublicUserDataV2[]>([]);
  const { fetchUsers } = useMeetingsAndUsersStore();
  const { isScreenSizeSM } = useWindowSize();

  const getDisplayUsers = async (attendees: GoogleAttendee[]) => {
    const users = await fetchUsers(attendees);
    const displayAttendees = users.slice(0, numberOfDisplayAttendees);
    const extraDisplayAttendees = users.slice(numberOfDisplayAttendees);
    setMeetingUsers(displayAttendees);
    setExtraMeetingUsers(extraDisplayAttendees);

    // cfGetAttendeesFromGoogleAttendees(attendees).then((result: PublicUserDataV2[]) => {
    //   const displayAttendees = result.slice(0, numberOfDisplayAttendees);
    //   setMeetingUsers(displayAttendees);
    // });
  };

  useEffect(() => {
    getDisplayUsers(note.attendees);
  }, []);

  const handleGoToMeeting = () => {
    console.log({ note });
    ConsoleImproved.log('Clicked go to meeting', note);
    onAction(note.id, note.iCalUID, note.start.dateTime);
    // window.open(`${window.location.origin}/meeting/${note.id}`);
  };

  const isCurrentlyOngoing = SDateClass.isMeetingCurrentlyOngoing(note);

  return (
    <Container>
      <LeftContainer isScreenSizeSM={isScreenSizeSM}>
        <NoteItemDateAtom date={note.start.dateTime} />
      </LeftContainer>
      <RightContainer
        onClick={handleGoToMeeting}
        isCurrentlyOngoing={isCurrentlyOngoing}
        isScreenSizeSM={isScreenSizeSM}
      >
        <NoteItemTitleAtom text={note.summary} />
        <AttendeesContainer>
          <ProfileIconsRow
            profileIconAttendees={meetingUsers}
            extraAttendees={extraMeetingUsers}
            size={24}
          />
        </AttendeesContainer>
        {/* <NoteItemDropdownMolecule note={null} /> */}
        {/* <GoToMeetingAtom onClick={handleGoToMeeting} /> */}
      </RightContainer>
    </Container>
  );
};

export default NoteItemMolecule;
