import styled from 'styled-components';
import { darkBlue1, darkBlue6, darkBlue4 } from '../../../../colours';
import { messageText } from '../../../../typography';

export const AddPrivateNotesButtonContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  color: ${darkBlue4};

  & > svg {
    margin-right: 0px !important;
    padding: 0 4px 0 0;
  };

  &:hover {
    cursor: pointer;
    color: ${darkBlue6};
    background: ${darkBlue1};      
  }
`;

export const AddPrivateNotesTextContainer = styled.div`
  ${messageText};
`;
