import React from 'react';
import styled, { css } from 'styled-components';
import { NewFeature } from '../../types/types';
import ParagraphAtom from './ExplanationAtom';
import MediaAtom from './MediaAtom';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  div:nth-child(2) {
    align-self: center;
  }
`;

const ExplanationContainer = styled.div`
  margin-bottom: 16px;
`;

interface MediaContainerProps {
  isLast: boolean,
}

const MediaContainer = styled.div<MediaContainerProps>`
  ${(props) => !props.isLast && css`
    margin-bottom: 24px;
  `}
`;

interface Props {
  feature: NewFeature,
  isExpanded?: boolean,
  isLast: boolean,
}

const FeatureBodyMolecule = ({
  feature, isExpanded, isLast,
}: Props) => {
  if (!isExpanded) return null;
  const { Explanation, media } = feature;
  return (
    <Container>
      <ExplanationContainer>
        <ParagraphAtom>
          <Explanation />
        </ParagraphAtom>
      </ExplanationContainer>
      <MediaContainer isLast={isLast}>
        <MediaAtom media={media} />
      </MediaContainer>
    </Container>
  );
};

FeatureBodyMolecule.defaultProps = {
  isExpanded: true,
};

export default FeatureBodyMolecule;
