import React from 'react';
import { darkBlue4 } from '../../colours';

interface Props {
  fill?: string,
}

const SmallArrowDown = ({ fill }: Props) => (
  <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.52788 6.86204C1.40334 6.98797 1.3335 7.15793 1.3335 7.33504C1.3335 7.51215 1.40334 7.68211 1.52788 7.80804L3.48722 9.78471C3.63255 9.92804 3.82055 9.99937 4.00655 9.99937C4.19255 9.99937 4.37722 9.92804 4.51922 9.78471L6.47255 7.81471C6.59693 7.6887 6.66667 7.51876 6.66667 7.34171C6.66667 7.16465 6.59693 6.99472 6.47255 6.86871C6.41133 6.80659 6.33837 6.75725 6.25791 6.72358C6.17745 6.68991 6.0911 6.67257 6.00388 6.67257C5.91666 6.67257 5.83031 6.68991 5.74986 6.72358C5.6694 6.75725 5.59644 6.80659 5.53522 6.86871L4.00322 8.41337L2.46522 6.86204C2.40386 6.80016 2.33085 6.75104 2.25041 6.71752C2.16997 6.68401 2.08369 6.66675 1.99655 6.66675C1.90941 6.66675 1.82312 6.68401 1.74269 6.71752C1.66225 6.75104 1.58924 6.80016 1.52788 6.86204Z" fill={fill} />
  </svg>
);

SmallArrowDown.defaultProps = {
  fill: darkBlue4,
};

export default SmallArrowDown;
