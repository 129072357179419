/* eslint-disable no-unused-vars */
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../../../../App';
import { MARK_AS_READ_ICON_CLICK_EVENT, MARK_AS_UNREAD_ICON_CLICK_EVENT } from '../../../../utils/analytics/enums';
import { handleUpdateHubLog } from '../../../../utils/analytics/eventLogger';
import { makeMeetingUrl } from '../../../../utils/meetings/meetingsUtils';
import Notification from '../../../classes/Notification/Notification';
import {
  darkBlue4, gray7, gray9, blue6, layoutGrey, gray1,
} from '../../../colours';
import Icon from '../../../icons/attendeeIcon';
import BlueDotIcon from '../../../icons/BlueDotIcon';
import NotificationReadAnimation from '../../../icons/NotificationReadAnimation/index';
import { uiTextS, uiTextMedium, defaultSmall } from '../../../typography';
import ModalLineAtom from '../../quick-settings/notification/desktop-notification-settings/modal/ModalLineAtom';
import Scrollbar from '../../scrollbar';
import TaskDropdown from '../../task-card/TaskDropdown';
import TaskOperationButton from '../../task-card/TaskOperationButton';
import ReactTooltip from '../../tooltip/ReactTooltip';
import EmptyNotificationContent from '../EmptyNotificationContent';
import NotificationsList from './NotificationsList';

interface StyleProps {
  isHover: boolean
}

interface NotificationsListProps{
notifications: Notification[]
onNotificationClick: (notification: Notification)=> void
}

function Notifications({ notifications, onNotificationClick }: NotificationsListProps) {
  const { userId } = useContext(AuthContext);

  const onClick = (event:any, notification: Notification) => {
    if (isNotificationStatusIconClicked(event)) {
      updateNotificationReadStatus(notification);
      return;
    }
    onNotificationClick(notification);
  };

  const updateNotificationReadStatus = (notification: Notification) => {
    if (notification.read.isRead) {
      handleUpdateHubLog(userId, MARK_AS_UNREAD_ICON_CLICK_EVENT);
      notification.updateIsRead(false);
      return;
    }
    handleUpdateHubLog(userId, MARK_AS_READ_ICON_CLICK_EVENT);
    notification.updateIsRead(true);
  };

  const isNotificationStatusIconClicked = (event:any) => {
    if (
      event.target.parentElement.nodeName === 'svg'
      || event.target.parentElement.nodeName === 'path'
      || event.target.parentElement.nodeName === 'circle'
      || event.target.parentElement.classList.contains('unreadIcon')
      || event.target.parentElement.classList.contains('readIcon')
    ) { return true; }
    return false;
  };

  if (notifications.length === 0) {
    return <EmptyNotificationContent />;
  }

  const isUnreadNotificaitons = notifications.filter((not) => !not.read.isRead).length > 0;
  const MaxHeight = isUnreadNotificaitons ? '456px' : '476px';

  return (
    <Scrollbar maxHeight={MaxHeight}>
      <NotificationsList notifications={notifications} onClick={onClick} />
    </Scrollbar>
  );
}

export default Notifications;
