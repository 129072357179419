/* eslint-disable no-empty-function */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-unused-vars */
import moment, { Moment } from 'moment';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import MeetingDataContext from '../../../../../pages/meeting/context/MeetingDataContext';
import {
  blue1,
  blue2,
  blue5,
  blue7,
  darkBlue1,
  darkBlue4,
  gray1, gray2, gray3, gray4, gray6, surface,
} from '../../../../colours';
import { small, uiText, uiTextMedium } from '../../../../typography';
import useDetectClickOutside from '../../hooks/fixed-click-outside';
import taskAdapter from '../../logic/nodes/task-adapter';
import TaskCalendarFloatingPanel from './task-calendar-floating-panel';

interface MenuProps {
  show: boolean;
  top: number;
  left: number;
}

const Menu = styled.div<MenuProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  max-height: 368px;
  position: absolute;
  width: 242px;
  background-color: ${gray1};
  border-radius: 8px;
  top: min(${({ top }) => `${top}px`}, calc(100vh - 368px));
  left: min(${({ left }) => `${left}px`}, 258px);
  z-index: 1000;
  box-shadow: 0px 10px 18px rgba(0,0,0,0.14),0px 0px 1px rgba(0,0,0,0.25);
  flex-flow: column;
  user-select: none;
  gap: 4px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface MenuItemProps {
  selected?: boolean;
  isHovered?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  padding: 10px 14px;
  display: flex;
  flex-flow: row;
  align-items: baseline;

  ${uiText}
  ${({ selected }) => (selected ? `background-color: ${blue1};` : '')}

  ${({ isHovered, selected }) => (isHovered ? `background-color: ${(selected ? blue2 : gray3)};` : '')}

  &:hover {
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? blue2 : gray3)};
  }
`;

MenuItem.defaultProps = {
  selected: false,
  isHovered: false,
};

interface MenuItemTextProps {
  selected?: boolean;
}

const MenuItemText = styled.div<MenuItemTextProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 8px;
  color: ${({ selected }) => (selected ? blue7 : surface)};
`;

MenuItemText.defaultProps = {
  selected: false,
};

interface MenuItemSubTextProps {
  selected?: boolean;
}

const MenuItemSubText = styled.div<MenuItemSubTextProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  ${uiText}
  color: ${({ selected }) => (selected ? blue5 : darkBlue4)};
`;

MenuItemSubText.defaultProps = {
  selected: false,
};

const Separator = styled.div`
  height: 1px;
  background-color: ${darkBlue1};
  width: 100%;
  margin: 0;
`;

interface DateSelectProps {
  show: boolean,
  left: number,
  top: number,
  closeContents: () => void,
  selectedIndex: number,
  dueDate: Date | Moment | null,
  updateDate: (newDate: Moment | Date | null) => void,
}

// m.format('D MMM YYYY')
const contents: (hasRecurring?: boolean) => [Moment, string][] = (hasRecurring = false) => [
  [moment(), 'Today'],
  [moment().add(1, 'day'), 'Tomorrow'],
  [moment().add(2, 'days')].flatMap((m: Moment) => [m, m.format('dddd')]) as [Moment, string],
  [moment().day(8)].flatMap((m: Moment) => [m, m.format('[Next] dddd')]) as [Moment, string],
  [moment().day(9)].flatMap((m: Moment) => [m, m.format('[Next] dddd')]) as [Moment, string],
  [moment().day(10)].flatMap((m: Moment) => [m, m.format('[Next] dddd')]) as [Moment, string],
  ...(hasRecurring ? [/* Add fetching here */] : []),
];

function isSelected(dueDate: Moment | Date | null, m: Moment | null): boolean {
  if (dueDate === null && m === null) {
    return true;
  }
  if (!dueDate && !m) {
    return true;
  }
  if (!dueDate) {
    return false;
  }
  if (!m) {
    return false;
  }
  if (dueDate instanceof Date) {
    return dueDate.getDate() === m.date()
    && dueDate.getMonth() === m.month()
    && dueDate.getFullYear() === m.year();
  }
  return dueDate.date() === m.date()
  && dueDate.month() === m.month()
  && dueDate.year() === m.year();
}

const DateSelect = ({
  show,
  left,
  top,
  closeContents,
  dueDate,
  selectedIndex,
  updateDate,
}: DateSelectProps) => {
  const meetingContext = useContext(MeetingDataContext);
  // meetingContext.googleData.ids.recurringEventId;

  const [showSpecific, setShowSpecific] = useState<boolean>(false);

  function closeAndReset() {
    closeContents();
    setShowSpecific(false);
  }

  const ref = useDetectClickOutside({
    onTriggered: () => {
      if (!showSpecific) {
        closeAndReset();
      }
    },
  });

  const selectedRef = useRef(null);

  useEffect(() => {
    if (show) {
      if (selectedRef.current) {
        (selectedRef.current as unknown as HTMLDivElement).scrollIntoView({
          block: 'nearest',
        });
      }
    }
  }, [selectedIndex, contents, show]);

  useEffect(() => {
    if (!show) {
      taskAdapter.setCalendarMenuIndex(0);
    }
  }, [show]);

  return (
    <>
      <Menu
        show={show && top !== 0 && left !== 0 && !showSpecific}
        left={left}
        top={top}
        ref={ref}
      >
        <MenuItem
          selected={isSelected(dueDate, null)}
          isHovered={selectedIndex === 0}
          key={0}
          ref={selectedIndex === 0 ? selectedRef : null}
          onClick={() => {
            updateDate(null);
            closeAndReset();
          }}
        >
          <MenuItemText
            selected={isSelected(dueDate, null)}
          >
            No due date
          </MenuItemText>
        </MenuItem>
        <Separator />
        {
        contents().map((value, index) => (
          <MenuItem
            isHovered={index + 1 === selectedIndex}
            selected={isSelected(dueDate, value[0])}
            key={value[1] ?? index}
            ref={index + 1 === selectedIndex ? selectedRef : null}
            onClick={() => {
              updateDate(value[0]);
              closeAndReset();
            }}
          >
            <MenuItemText
              selected={isSelected(dueDate, value[0])}
            >
              { `${(value as any)[1]}`}
            </MenuItemText>
            <MenuItemSubText
              selected={isSelected(dueDate, value[0])}
            >
              { `${value[0].format('D MMM YYYY') || ''}` }
            </MenuItemSubText>
          </MenuItem>
        ))
      }
        <MenuItem
          isHovered={selectedIndex === contents().length + 1}
          selected={false}
          key={contents().length + 1}
          ref={selectedIndex === 0 ? selectedRef : null}
          onClick={() => {
            setShowSpecific(true);
          }}
        >
          <MenuItemText
            selected={false}
          >
            Specific date
          </MenuItemText>
        </MenuItem>
      </Menu>
      <TaskCalendarFloatingPanel
        show={show && top !== 0 && left !== 0 && showSpecific}
        left={left}
        top={top}
        closeContents={() => { closeAndReset(); }}
        updateDate={(date) => { updateDate(date); }}
      />
    </>
  );
};

export default DateSelect;
