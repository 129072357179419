import { Form, Formik } from 'formik';
import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { UserDataContext } from '../../../../App';
import { darkBlue4 } from '../../../../shared/colours';
import Dropdown from '../../../../shared/components/dropdown';
import { DropdownItem } from '../../../../shared/components/dropdown/utils';
import SlackIconLarge from '../../../../shared/icons/integrations/SlackIconLarge';
import { header600, uiText } from '../../../../shared/typography';

const View3Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectChannelHeader = styled.h1`
  ${header600};
`;

const ChangeChannelText = styled.h3`
  ${uiText}
  color: ${darkBlue4};
  text-align: center;
`;

interface Props {
  isLoading: boolean
  isError: boolean
  slackChannels: DropdownItem[],
  setNewDefaultChannels: Dispatch<SetStateAction<DropdownItem[]>>,
}

const SlackSelectDefaultChannel = ({
  isLoading,
  isError,
  slackChannels,
  setNewDefaultChannels,
}:Props) => {
  const { integrations } = useContext(UserDataContext);
  const [placeholder, setPlaceholder] = useState<string>('Choose default channel');

  useEffect(() => {
    if (integrations.slack[0].defaultChannels.length === 0) {
      setPlaceholder('Choose default channel');
    } else {
      setPlaceholder(integrations.slack[0].defaultChannels[0].name);
    }
  }, [integrations.slack[0].defaultChannels]);

  const onChannelSelect = (item: DropdownItem) => {
    setNewDefaultChannels([item]);
    setPlaceholder(item.name);
  };

  return (
    <View3Container>
      <SlackIconLarge />
      <SelectChannelHeader>Select your default Slack channel</SelectChannelHeader>
      <ChangeChannelText>
        You can change your default channel before you share notes, anytime.
      </ChangeChannelText>
      <Formik
        initialValues={{ defaultChannel: '' }}
        onSubmit={() => { }}
      >
        {() => (
          <Form>
            <Dropdown
              isLoading={isLoading}
              isError={isError}
              dropdownItems={slackChannels}
              isMultiSelect={false}
              placeholderText={placeholder}
              onOptionClick={onChannelSelect}
            />
          </Form>
        )}
      </Formik>
    </View3Container>
  );
};

export default SlackSelectDefaultChannel;
