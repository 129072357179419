import React from 'react';
import styled from 'styled-components';
import { layoutWhite } from '../../../shared/colours';
import LineAtom from '../../../shared/components/new-feaures-modal/LineAtom';
import { Quote } from '../../../shared/types/types';
import QuoteMolecule from '../quote/QuoteMolecule';
import RefresherPopupMolecule from '../refresherPopup/RefresherPopupMolecule';
import RockHandAtom from './RockHandAtom';
import WelcomeBackMolecule from './WelcomeBackMolecule';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  background: ${layoutWhite};

  padding-bottom: 75px;
`;

const HandContainer = styled.div`
  height: 40%;
  padding-bottom: 40px;
  
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  transform-origin: bottom center;
  transform: scale(0.8);

  @media (max-height: 880px) {
    height: 100%;
  }
`;

const WelcomeBackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  height: 100%;
  @media(max - height: 880px) {
    height: 100 %;
  }
`;

const LineContainer = styled.div`
width: 440px;

@media(max - height: 880px) {
  display: none;
}
`;
interface Props {
  quote: Quote,
  firstName: string,
}

const WelcomeTemplate = ({ quote, firstName }: Props) => (
  <Container>
    {/* <Header view={HEADER_DASHBORD_VIEW} /> */}
    <HandContainer>
      <RockHandAtom />
    </HandContainer>
    <WelcomeBackContainer>
      <WelcomeBackMolecule firstName={firstName} />
      <LineContainer><LineAtom /></LineContainer>
      <QuoteMolecule quote={quote} />
      <RefresherPopupMolecule />
    </WelcomeBackContainer>
  </Container>
);

export default WelcomeTemplate;
