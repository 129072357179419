import React from 'react';
import { ShepherdScreen, GoogleMeetIcon, GoogleMeetScreen } from '../images';
import { MEET_SCREEN_HIGHLIGHT } from '../utils/colours';
import OnboardingWelcomeScreensModel from '../utils/onboarding-welcome-screens-model';
import { Container, ImageContainer } from '../utils/styles';
import WelcomeScreenTitleAndSubtitle from '../components/welcome-screens-title-and-subtitle-organism';

const MeetScreen = () => {
  const { title, highlightedTitle, subtitle } = OnboardingWelcomeScreensModel.value.takeNotes;

  return (
    <Container>
      <ImageContainer left="-0px" top="160px" animation="fadeInRight" order={1}>
        <GoogleMeetScreen />
      </ImageContainer>
      <ImageContainer left="50px" top="280px" animation="fadeIn" order={3}>
        <GoogleMeetIcon />
      </ImageContainer>
      <ImageContainer left="179px" top="60px" animation="fadeInLeft" order={2}>
        <ShepherdScreen />
      </ImageContainer>
      <WelcomeScreenTitleAndSubtitle
        title={title}
        highlightedSection={highlightedTitle}
        highlightColor={MEET_SCREEN_HIGHLIGHT}
        subtitle={subtitle}
      />
    </Container>
  );
};

export default MeetScreen;
