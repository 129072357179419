import React from 'react';
import Exclamatory from '../../icons/exclamatory';
import SuccessCircle from '../../icons/SuccessCircle';
import InfoIcon from '../../icons/infoCircleFilled';
import { ToastNotificationType } from '../../types/types';
import { yellow6 } from '../../colours';

interface Props {
  type: ToastNotificationType,
}

export default function ToastNotificationIcon({ type }: Props) {
  if (type === 'success') {
    return <SuccessCircle />;
  }
  if (type === 'info') {
    return <InfoIcon />;
  }
  if (type === 'warning') {
    return <InfoIcon fill={yellow6} />;
  }
  return <Exclamatory />;
}
