import { captureException } from '@sentry/react';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { PublicUserDataV2, ResolveState, SlackNotificationData } from '../../shared/types/types';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { functions } from '../../utils/firebase';
import { isValidBlock } from '../../utils/slack/SlackAPIUtils';

class SlackCoreAPI {
  protected static async sendNotificationCore(
    title: string,
    message: string,
    recipientData: PublicUserDataV2,
  ) : Promise<ResolveState> {
    const blocks = [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `${message}`,
        },
      },
    ];

    const data: SlackNotificationData = {
      title,
      recipientEmail: recipientData.data.email,
      blocks,
    };

    if (!isValidBlock(blocks)) {
      ConsoleImproved.log('Invalid block', blocks);
      return REJECTED;
    }
    return functions().httpsCallable('sendSlackNotificationV2')(data)
      .then(() => {
        ConsoleImproved.log('Sent slack notification', data);
        return RESOLVED as ResolveState;
      })
      .catch((error) => {
        console.log(error);
        captureException(error);
        return REJECTED as ResolveState;
      });
  }
}

export default SlackCoreAPI;
