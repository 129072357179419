import React, { Dispatch, SetStateAction } from 'react';
import { TaskItem } from '../../../../../shared/types/types';
import { TRELLO } from '../../../../../utils/enums';
import TrelloMultiInput from '../../TrelloModalComponents/TrelloMultiInput';
import { APPS_VIEW, ShareModalMode, TRELLO_SHARE_VIEW } from '../utils/types';
import Platform from './Platform';

interface Props{
  taskItems: TaskItem[],
  taskToSend:TaskItem[],
  setTaskToSend: Dispatch<SetStateAction<TaskItem[]>>,
  setMode: React.Dispatch<React.SetStateAction<ShareModalMode>>
}

export default function TrelloView({
  taskItems, taskToSend, setTaskToSend, setMode,
}: Props) {
  return (
    <>
      <Platform platform={TRELLO} mode={TRELLO_SHARE_VIEW} iconSize="26" onClick={() => { setMode(APPS_VIEW); }} />
      <TrelloMultiInput
        isChecked
        taskItems={taskItems}
        taskToSend={taskToSend}
        setTaskToSend={setTaskToSend}
      />
    </>
  );
}
