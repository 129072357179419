import React from 'react';
import styled from 'styled-components';
import Notification from '../../../classes/Notification/Notification';
import BlueDotIcon from '../../../icons/BlueDotIcon';
import TickCircle from '../../../icons/TickCircle';
import ReactTooltip from '../../tooltip/ReactTooltip';

interface Props{
  notification: Notification,
}

const IconContainer = styled.div`
  border: solid;
  border-color: transparent;
  border-width: 5px;
`;

function MarkReadUnreadStatusIcon({ notification }: Props) {
  return (
    <ReactTooltip
      place="left"
      tip={notification.read.isRead ? 'Mark as unread' : 'Mark as read'}
    >
      <IconContainer className="unreadIcon">
        <BlueDotIcon />
      </IconContainer>
      <IconContainer className="readIcon">
        <TickCircle />
      </IconContainer>
    </ReactTooltip>
  );
}

export default MarkReadUnreadStatusIcon;
