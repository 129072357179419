import React from 'react';
import { NewFeature } from '../../types/types';
import JamieNewTextEditor from '../../gif/jamie-text-editor-new-features.gif';
import SlackGif from '../../gif/slack.gif';
import NotionGif from '../../gif/notion.gif';
import PreviousMeeting from '../../gif/previousMeeting.gif';
import FullPageGif from '../../gif/FullPageWebAppGif.gif';
import allTasks from '../../gif/allTasks.gif';

export const NewFeaturesV1: NewFeature[] = [
  {
    id: 0, title: 'Slack Integration ⚡', media: SlackGif, Explanation: 'Quickly share a meeting summary with your team via #channels or DMs',
  },
  {
    id: 1, title: 'Notion Integration 🚀', media: NotionGif, Explanation: 'You can now instantly export your meeting notes to Notion from Shepherd',
  },
  {
    id: 2, title: 'Preview Previous Meetings 🔙', media: PreviousMeeting, Explanation: 'Take a peek at what happened during the previous meeting in the recurring series from your current meeting.',
  },
  {
    id: 3, title: 'New Text Editor ✍', media: JamieNewTextEditor, Explanation: 'Now you can @mention, insert links, GIFs, emojis, tables, and so much more!',
  },
  {
    id: 4, title: 'All your tasks in one place ✅', media: allTasks, Explanation: "You can now see a complete list of all your tasks from every meeting you've use Shepherd with.",
  },
];

const ExplanationComponent = () => (
  <>
    <span>
      Step 1: 💻 Expand the sidebar into full screen
      <br />
      Step 2: 😻 Enjoy the larger note taking experience with your team
    </span>
  </>
);

export const NewFeaturesV3Content: NewFeature[] = [
  {
    id: 0,
    title: 'Our Full-Screen Web App is Live ⚡',
    media: FullPageGif,
    Explanation: ExplanationComponent,
  },
  // {
  //   id: 1,
  //   title: 'Share notes to Google Event Description',
  //   media: NotionGif,
  //   Explanation: () => (
  //     <span>
  //       You can now instantly export your meeting notes to Google Calendar from Shepherd
  //     </span>
  //   ),
  // },
];

// export const NewFeaturesV2 = {
//   id: '26102023',
//   features: NewFeaturesV1
//  };

export const NewFeaturesV3 = {
  id: '12052022',
  features: NewFeaturesV3Content,
};
