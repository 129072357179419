import { cfSendTaskAssignNotificationEmail } from '../../database/cloudFunctionEmailAPI';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { ResolveState, TaskItem } from '../../shared/types/types';
import { toastDanger } from '../../utils/notifications';
import EmailCore from './EmailCoreAPI';

/**
 * API to send email. Emails are typically sent with SendGrid
 */
class EmailAPI extends EmailCore {
  /**
   * @param taskItem The task that was just created
   * @param reporterName Name of the user which created the task
   * @param reporterEmail Name of the user which created the task
   */
  static async sendNotificationForTaskCreation(
    taskItem: TaskItem,
    reporterName: string,
    reporterEmail: string,
  ): Promise<any> {
    cfSendTaskAssignNotificationEmail(taskItem, reporterName, reporterEmail)
      .catch((error) => toastDanger('Failed to send notification', error));
  }

  /**
   * Very simple interface to send an email
   */
  static async sendBasicEmail(
    emailRecipients: string[],
    subject: string,
    mainContentHTML: string,
  ): Promise<ResolveState> {
    ConsoleImproved.log('Send basic email', { emailRecipients, subject, mainContentHTML });
    return EmailCore.sendBasicEmailCore(emailRecipients, subject, mainContentHTML);
  }
}

export default EmailAPI;
