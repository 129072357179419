import React from 'react';
import styled from 'styled-components';
import { uiTextMedium } from '../../../shared/typography';
import { darkBlue5 } from '../../../shared/colours';

const Text = styled.div`
  width: 100%;
  ${uiTextMedium};
  color: ${darkBlue5};
  cursor: pointer;
`;

interface Props {
  text: string,
  onClick: () => void,
}

const LearnMoreAtom = ({ text, onClick }: Props) => (
  <Text onClick={onClick}>
    {text}
  </Text>
);

export default LearnMoreAtom;
