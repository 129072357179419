import React from 'react';
import styled from 'styled-components';
import { darkBlue4 } from '../../../colours';
import { uiTextS } from '../../../typography';

const Text = styled.span`
  ${uiTextS};
  color: ${darkBlue4};
`;

interface Props {
  text: string,
}

const SentTextAtom = ({ text }: Props) => (
  <Text>
    {text}
  </Text>
);

export default SentTextAtom;
