import {
  triggerEventMeetingHasAgenda,
  triggerEventMeetingHasPrivateNotes,
  triggerEventMeetingHasSharedNotes,
  triggerEventMeetingWithShepherdUsed,
} from '../../../pages/meeting/events';
import { NotesFor } from '../../../utils/analytics/enums';
import { mapTimestampForLog } from '../../../utils/meetings/meetingsUtils';
import {
  MeetingAnalyticsData, MeetingSection, SDate,
} from '../../types/types';

const triggerLogEvent = (
  isSidebarOpen: boolean,
  page: MeetingSection,
  meetingAnalyticsData: MeetingAnalyticsData,
  html: string,
  meetingId: string,
  intercomTrackEvent: any,
  userId: string, start: SDate, end: SDate, notesFor: NotesFor,
) => {
  if (!isSidebarOpen || meetingId.length === 0) return;
  const timestampLog = mapTimestampForLog(start, end);
  triggerEventMeetingWithShepherdUsed(meetingAnalyticsData,
    html, meetingId, userId, timestampLog, notesFor, intercomTrackEvent);
  triggerEventMeetingHasAgenda(userId, meetingAnalyticsData,
    html, page, meetingId, timestampLog, notesFor, intercomTrackEvent);
  triggerEventMeetingHasSharedNotes(userId, meetingAnalyticsData,
    html, page, meetingId, timestampLog, notesFor, intercomTrackEvent);
  triggerEventMeetingHasPrivateNotes(userId, meetingAnalyticsData,
    html, page, meetingId, timestampLog, notesFor, intercomTrackEvent);
};

export default triggerLogEvent;
