import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import ErrorPage from '../../shared/components/error-page';
import ErrorImage from '../../shared/icons/ErrorImage';
import ButtonSmall from '../../shared/components/button-small';
import { header800, uiTextMedium } from '../../shared/typography';
import { logPageEvent } from '../../utils/analytics/eventLogger';
import { GOOGLE_CALENDAR_ERROR_PAGE } from '../../utils/analytics/enums';
import Scrollbar from '../../shared/components/scrollbar';
import { AuthContext } from '../../App';

const ImageContainer = styled.div`
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 135px;
`;

const Text = styled.h2`
  margin-top: 0;
  ${header800};
`;

const TextExplanationBottom = styled.h2`
  ${uiTextMedium};
  text-align: center;
  margin: 0;
`;

const TextExplanationTop = styled.div`
  ${uiTextMedium};
  margin-bottom: 24px;
`;

const OopsContainer = styled.div`
  margin-bottom: 16px;
`;

export interface Props {
  email: string;
  retry: () => void;
}

const GoogleCalendarErrorPage = ({ email, retry }: Props) => {
  // If you don't have access to the meeting in Google Calendar,
  // and the meeting doesn't exist in Shepherd, this page will show
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  useEffect(() => {
    logPageEvent(authState.userId, trackEvent, GOOGLE_CALENDAR_ERROR_PAGE);
  }, []);

  return (
    <>
      <Scrollbar maxHeight="100vh">
        <ErrorPage>
          <ImageContainer>
            <ErrorImage />
          </ImageContainer>
          <OopsContainer>
            <Text>Oops!</Text>
          </OopsContainer>
          <TextExplanationTop>
            We could not get the meeting data from Google Calendar.
          </TextExplanationTop>
          {email && (
            <TextExplanationBottom>
              You are currently logged into Shepherd with
              {' '}
              <br />
              <strong>{email}</strong>
              <br />
              so make sure this account has access to the event in Google Calendar.
            </TextExplanationBottom>
          )}
          <ButtonContainer>
            <ButtonSmall text="Retry" onClick={retry} />
          </ButtonContainer>

        </ErrorPage>
      </Scrollbar>
    </>
  );
};

export default GoogleCalendarErrorPage;
