import React, {
  useState, Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import ProfileIcon from '../../components/profile-icon';
import EmptyProfileIcon from '../../icons/EmptyProfileIcon';
import AttendeeDropdown from '../attendeeDropdown';
import ReactTooltip from '../tooltip/ReactTooltip';
import { green6 } from '../../colours';
import { PublicUserDataV2 } from '../../types/types';
import { emptyPublicUserDataV2 } from '../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';

const ProfileIconWrapper = styled.span`
  margin-right: 16px;

  & button {
    cursor: pointer;
  }
`;

const AttendeeDropdownContainer = styled.div`
  z-index: 30;
  position: relative;
  left: -276px;
  top: 16px;
`;

interface Props {
  assignee: PublicUserDataV2,
  // eslint-disable-next-line no-unused-vars
  updateAssignee: (assignee: PublicUserDataV2) => void,
  assigneeCandidates: PublicUserDataV2[],
  disableAssigneeSelect?: boolean,
}

const TaskCardAssigneeIcon = ({
  assignee, assigneeCandidates, updateAssignee, disableAssigneeSelect,
}: Props) => {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const setAssignee = (newAssignee: PublicUserDataV2) => {
    if (newAssignee.data.email === assignee.data.email) {
      updateAssignee(emptyPublicUserDataV2);
    } else {
      updateAssignee(newAssignee);
    }
  };

  const handleAssigneeIconClick = () => {
    if (disableAssigneeSelect) return;
    setDropdownOpen(true);
  };

  return (
    <>
      <ProfileIconWrapper onClick={handleAssigneeIconClick}>
        {
          assignee.data.email.length === 0
            ? (
              <ReactTooltip tip="Assign the task to an attendee" place="bottom">
                <EmptyProfileIcon character="+" />
              </ReactTooltip>
            )
            : (
              <ProfileIcon tooltipPosition="bottom" user={assignee} color={green6} />
            )
        }
      </ProfileIconWrapper>
      <AttendeeDropdownContainer>
        <AttendeeDropdown
          isOpen={isDropdownOpen}
          setIsOpen={setDropdownOpen}
          assigneeCandidates={assigneeCandidates}
          setAssignee={setAssignee as Dispatch<SetStateAction<PublicUserDataV2>>}
        />
      </AttendeeDropdownContainer>
    </>
  );
};

TaskCardAssigneeIcon.defaultProps = {
  disableAssigneeSelect: false,
};

export default TaskCardAssigneeIcon;
