import React from 'react';
import styled from 'styled-components';
import { gray9 } from '../../colours';
import { header600 } from '../../typography';

const Title = styled.span`
  ${header600};
  margin: 0px;
  color: ${gray9};
`;

interface Props {
  children: React.ReactNode
}

const TitleAtom = ({ children }: Props) => (
  <Title>
    {children}
  </Title>
);

export default TitleAtom;
