import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../../App';
import { dbListenToTasksForUser } from '../../database/firebaseTasksAPI';
import { rejectedMeetingData } from '../../database/utils/templateMeetingData';
import { SidebarStateContext } from '../../ListenToSidebarMessageContainer';
import CreateNewTaskModal from '../../shared/components/task-modal/CreateNewTaskModal';
import Tasks from '../../shared/components/tasks';
import { TaskItems, TaskTab, UserCenterPage } from '../../shared/types/types';
import { logPageEvent } from '../../utils/analytics/eventLogger';
import { shouldWeLog } from '../../utils/analytics/functions';
import { ALL_TASKS_PAGE, USER_CENTER_ALL_TASKS } from '../../utils/enums';
import { pendingTaskItems } from '../../utils/tasks/tasksUtils';
import WelcomeBanner from './welcomeBanner';

// const TaskTabMaxHeight = 'calc(100vh - 48px - 49px - 110px - 127px)';
// const TaskTabMaxHeight = 'calc(100vh - 48px - 110px - 110px)';

interface Props {
  section: UserCenterPage,
  setSection: Dispatch<SetStateAction<UserCenterPage>>,
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
}

const AllTasksOrganism = ({
  section, setSection, taskTab, setTaskTab,
}: Props) => {
  const authState = useContext(AuthContext);
  const isSidebarOpen = useContext(SidebarStateContext);
  const { trackEvent } = useIntercom();
  const [taskItems, setTaskItems] = useState<TaskItems>(pendingTaskItems);
  const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState<boolean>(false);
  const [isWelcomeBannerOpened, setWelcomeBannerClosed] = useState<boolean>(true);

  useEffect(() => {
    dbListenToTasksForUser(authState.email, setTaskItems);
  }, [authState]);

  useEffect(() => {
    // Analytics event logging
    if (section !== USER_CENTER_ALL_TASKS) return;
    if (shouldWeLog(authState, isSidebarOpen)) return;
    logPageEvent(authState.userId, trackEvent, ALL_TASKS_PAGE);
  }, [section, isSidebarOpen]);

  const handleWelcomeBannerClose = () => {
    setWelcomeBannerClosed(false);
  };

  const handleCloseCreateTaskModal = () => setCreateTaskModalOpen(false);

  if (section !== USER_CENTER_ALL_TASKS) return null;
  /** Height of the task content when the welcome banner is opened and closed  */
  const TaskTabMaxHeight = isWelcomeBannerOpened ? 'calc(100vh - 110px - 110px - 17px)' : 'calc(100vh - 48px - 110px + 29px)';

  return (
    <>
      <WelcomeBanner
        handleWelcomeBannerClose={handleWelcomeBannerClose}
        section={section}
        isClosed={!isWelcomeBannerOpened}
      />
      <Tasks
        taskTab={taskTab}
        setTaskTab={setTaskTab}
        taskItems={taskItems}
        setSection={setSection}
        setCreateTaskModalOpen={setCreateTaskModalOpen}
        height={TaskTabMaxHeight}
        page={ALL_TASKS_PAGE}
        disableAssigneeSelect
      />
      <CreateNewTaskModal
        meetingData={rejectedMeetingData}
        isModalOpen={isCreateTaskModalOpen}
        setModalClosed={handleCloseCreateTaskModal}
      />
    </>
  );
};

export default AllTasksOrganism;
