/* eslint-disable no-unused-vars */
import { EditorView } from 'prosemirror-view';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../../App';
import { CLICK_EVENT, TEXT_EDITOR_SLASH_MENU } from '../../../../utils/analytics/enums';
import { handleTextEditorLog } from '../../../../utils/analytics/eventLogger';
import {
  gray1, gray2, gray4, gray6, darkBlue4,
} from '../../../colours';
import { AssigneeV2 } from '../../../types/types';
import { small, uiTextMedium } from '../../../typography';
import { mapOptionMenuNamesToTextEditorOptions } from '../analytics';
import useDetectClickOutside from '../hooks/fixed-click-outside';
import { offsetBoundByproduct } from '../logic/computation/offsets';
import commandAdapter from '../logic/suggestions/command-adapter';

interface MenuProps {
  show: boolean;
  top: number;
  left: number;
}

const Menu = styled.div<MenuProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  /* opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity 50ms ease-in-out; */

  max-height: 337px;
  position: absolute;
  width: 296px;
  background-color: ${gray1};
  border-radius: 8px;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  z-index: 1000;
  box-shadow: 0px 10px 18px rgba(0,0,0,0.14),0px 0px 1px rgba(0,0,0,0.25);
  padding: 8px;
  flex-flow: column;
  user-select: none;
  gap: 4px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface MenuItemProps {
  selected?: boolean;
}

const MenuItem = styled.div<MenuItemProps>`
  border-radius: 6px;
  display: flex;
  scroll-margin: 18px;
  flex-flow: column;
  align-items: flex-start;

  ${uiTextMedium}
  ${({ selected }) => (selected ? `background-color: ${gray4};` : '')}

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
`;

MenuItem.defaultProps = {
  selected: false,
};

interface WrapperProps {
  selected: boolean;
}

const WrapperPhotoItem = styled.div<WrapperProps>`
  &:hover {
    cursor: pointer;
    background-color: ${({ selected }) => (selected ? gray6 : gray2)};
  }
  display: flex;
  flex-flow: row;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  ${({ selected }) => (selected ? `background-color: ${gray4};` : '')}
`;

WrapperPhotoItem.defaultProps = {
  selected: false,
};

const MenuItemPhoto = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: ${gray4};
  background-position: center;
  background-size: cover;
  margin-right: 8px;
`;

const MenuItemText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  ${uiTextMedium}
`;

const MenuItemSubText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  ${small}
  color: ${darkBlue4};
  letter-spacing: -0.08px;
`;

const TitleDiv = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  /* identical to box height, or 133% */
  letter-spacing: -0.08px;

  /* Dark Blue/darkBlue4 */
  color: #6F7C9F;
`;

interface CommandsFloatingPanelProps {
  show: boolean,
  left: number,
  top: number,
  contents: unknown[],
  selectedIndex: number,
  view?: EditorView | null,
}

const CommandsFloatingPanel = ({
  show,
  left,
  top,
  contents,
  selectedIndex,
  view,
}: CommandsFloatingPanelProps) => {
  const selectedRef = useRef(null);
  const { userId } = useContext(AuthContext);

  useEffect(() => {
    if (selectedRef.current) {
      (selectedRef.current as unknown as HTMLDivElement).scrollIntoView({
        block: 'nearest',
      });
    }
  }, [selectedIndex, contents, show]);

  const handleClick = ({ item }: any) => {
    handleTextEditorLog(
      userId,
      {
        source: TEXT_EDITOR_SLASH_MENU,
        option: mapOptionMenuNamesToTextEditorOptions(item.name),
        action: CLICK_EVENT,
      },
    );
    if (!view) return;
    const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
    const c = item;
    if (!c || !currentPos) return;
    const { tr } = view.state;
    c.callback();
    if (!c.useTransactionLogic) {
      tr.deleteRange(currentPos, view.state.selection.$anchor.pos);
      try {
        view.dispatch!(tr);
      } catch (e) {
        commandAdapter.setShow(false);
      }
    }
    commandAdapter.setShow(false);
  };

  const ref = useDetectClickOutside({
    onTriggered: () => {
      commandAdapter.setShow(false);
    },
  });

  const menuContentContainerRef = useRef<HTMLDivElement | null>(null);
  let menuBoundingBox: DOMRect | null = null;
  try {
    if (menuContentContainerRef.current?.parentElement && view && view.dom.parentElement) {
      const menuDOM = menuContentContainerRef.current.parentElement;
      const menuRect = menuDOM.getBoundingClientRect();
      const positionedMenuRect = new DOMRect(
        left,
        top,
        menuRect.width,
        menuRect.height,
      );
      const editorRect = view.dom.parentElement.getBoundingClientRect();
      const offset = offsetBoundByproduct(positionedMenuRect, editorRect);
      menuBoundingBox = new DOMRect(
        positionedMenuRect.x + offset.left,
        positionedMenuRect.y + offset.top,
        menuRect.width,
        menuRect.height,
      );
    }
  } catch (error) {
    console.error(error);
  }

  return (
    <Menu
      show={show && top !== 0 && left !== 0 && menuBoundingBox !== null}
      left={menuBoundingBox ? menuBoundingBox.x : left}
      top={menuBoundingBox ? menuBoundingBox.y : top}
      ref={ref}
    >
      <div ref={menuContentContainerRef}>
        {
          contents.map((value, index) => (
            <>
              {
                (value as any).item?.title
                  ? (
                    <TitleDiv>
                      { (value as any).name }
                    </TitleDiv>
                  )
                  : (
                    <WrapperPhotoItem
                      selected={index === selectedIndex}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleClick(value as any);
                      }}
                    >
                      <MenuItemPhoto
                        className={(value as any).item.className}
                      />
                      <MenuItem
                        selected={false}
                        key={(value as any).item?.name}
                        ref={index === selectedIndex ? selectedRef : null}
                      >
                        <MenuItemText>
                          { `${(value as any).item?.name}`}
                        </MenuItemText>
                        <MenuItemSubText>
                          { `${(value as any).item?.description || ''}` }
                        </MenuItemSubText>
                      </MenuItem>
                    </WrapperPhotoItem>
                  )
              }
            </>
          ))
        }
      </div>
    </Menu>
  );
};

CommandsFloatingPanel.defaultProps = {
  view: null,
};

export default CommandsFloatingPanel;
