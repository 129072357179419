import * as Sentry from '@sentry/browser';
import { REJECTED, RESOLVED } from '../../../utils/enums';
import { firestore } from '../../../utils/firebase';
import { NotificationDatabase, ResolvedState } from '../../types/types';
import Notification from './Notification';
import { NOTIFICATION_COLLECTION } from './utils';

/**
 * Responsible for
 * - Interaction with firebase
 * - Handle error logging to sentry
 * - Converting response to proper datatype
 * @access: protected
 * @method: async dbListen(userId: string, updateStore: zustandStore)
 * @method: async dbAdd(notificationData: NotificationDatabase) => Promise<ResolvedState>
 * @method: async dbUpdate(notificationId: string, updateField: Object) => Promise<ResolvedState>
 * @method: async dbDelete(notificationId: string) => Promise<ResolvedState>
 */
class NotificationAPICore {
  /** Adds a listener to users notification and updates the notificationStore */
  protected static dbListenNotification = (
    // eslint-disable-next-line no-unused-vars
    userId: string, updateStore: (newNotifications: Notification[]) => void,
  ) => firestore()
    .collection(NOTIFICATION_COLLECTION)
    .where('recipient.userId', '==', userId)
    .orderBy('created.timestamp', 'desc')
    .limit(50)
    .onSnapshot((snapshot) => {
      const docs = snapshot.docs.map((doc) => new Notification(doc.id, doc.data()));
      updateStore(docs);
    })

  /** Inserts a new notification to notification collection */
  protected static dbAddNotification = async (
    notificationData: NotificationDatabase,
  ) => firestore()
    .collection(NOTIFICATION_COLLECTION)
    .add(notificationData)
    .then((response) => {
      console.log(response.id);
      return RESOLVED as ResolvedState;
    })
    .catch((error) => {
      console.error(error);
      Sentry.captureException(error);
      return REJECTED as ResolvedState;
    })

  /** Updates a notification with the field provided */
  protected static dbUpdateNotification = async (
    notificationId: string, updateField: Object,
  ) => firestore()
    .collection(NOTIFICATION_COLLECTION)
    .doc(notificationId)
    .update(updateField)
    .then((response) => {
      console.log(response);
      return RESOLVED as ResolvedState;
    })
    .catch((error) => {
      console.error(error);
      Sentry.captureException(error);
      return REJECTED as ResolvedState;
    })

  /** Deletes a notification with the id provided */
  protected static dbDeleteNotification = async (
    notificationId: string,
  ) => firestore()
    .collection(NOTIFICATION_COLLECTION)
    .doc(notificationId).delete()
    .then((response) => {
      console.log(response);
      return RESOLVED as ResolvedState;
    })
    .catch((error) => {
      console.error(error);
      Sentry.captureException(error);
      return REJECTED as ResolvedState;
    })
}

export default NotificationAPICore;
