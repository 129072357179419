import React, { useContext } from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import DefaultMeetingTabDropdown from '../../default-tab-dropdown';
import { header200, header400 } from '../../../typography';
import { gray9 } from '../../../colours';
import { AuthContext, UserDataContext } from '../../../../App';
import { dbUserUpdateInfo } from '../../../../database/firebaseUsersAPI';
import { toastDanger, toastSuccess } from '../../../../utils/notifications';
import { mapMeetingSectionToText } from '../../../../utils/meetings/meetingsUtils';
import { MeetingSection } from '../../../types/types';
import { logSettingsClicks } from '../../../../utils/analytics/eventLogger';
import { GENERAL_SETTINGS } from '../../../../utils/analytics/enums';

const HeadText = styled.div`
  ${header400};
  color: ${gray9};
  margin-top: 32px;
  margin-bottom: 20px;
`;

const SubHeadText = styled.div`
  ${header200};
  margin-bottom: 4px;
`;

const GeneralSettings = () => {
  const { settings } = useContext(UserDataContext);
  const { userId } = useContext(AuthContext);
  const { trackEvent } = useIntercom();
  const meetingSectionPlaceholder = mapMeetingSectionToText(settings.defaultUserTab);

  const onChange = (newTab: MeetingSection) => {
    const newSettings = {
      ...settings,
      defaultUserTab: newTab,
    };

    dbUserUpdateInfo(
      userId,
      { settings: newSettings },
    ).then(
      () => {
        toastSuccess('Updated', `Default tab set to ${mapMeetingSectionToText(newTab)}.`);
        logSettingsClicks(userId, GENERAL_SETTINGS, 'change_default_tab', trackEvent);
      },
    ).catch(() => {
      toastDanger('Failed', 'Failed to update default tab.');
    });
  };

  return (
    <>
      <HeadText>General</HeadText>
      <SubHeadText>When meeting begins</SubHeadText>
      <DefaultMeetingTabDropdown
        placeholder={meetingSectionPlaceholder}
        label="This tab will be open by default when you use Shepherd"
        name="defaultTab"
        onChange={onChange}
      />
    </>
  );
};

export default GeneralSettings;
