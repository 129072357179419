import React from 'react';
import { Formik, FormikValues } from 'formik';
import OnboardingSubmitButton from '../components/onboarding-submit-button';
import { PersonalOnboardingData } from '../utils/types';
import PersonalOnboardingFormModel from '../utils/personal-onboarding-form-model';
import {
  Roles, CompanySizes, ProductivityTools, RemotePolicies, CompanyFormValidationSchema,
} from '../utils/constants';
import OnboardingSingleSelectDropdown from '../components/onboarding-single-select-dropdown';
import { SortedArrayAlphabetically } from '../utils/functions';
import {
  FormContainer, Title, Subtitle, CompanyForm, NavigationContainer, BackButton,
} from '../utils/styles';
import OnboardingMultiSelectDropdown from '../components/onboarding-multi-select-dropdown';
import Scrollbar from '../../../../shared/components/scrollbar';

interface Props {
  // eslint-disable-next-line no-unused-vars
  handlePreviousStep: (newData: PersonalOnboardingData) => void
  // eslint-disable-next-line no-unused-vars
  handleOnSubmit: (newData: PersonalOnboardingData) => void,
  formValues: PersonalOnboardingData
  isLoading: boolean
}

const CompanyInfoForm = ({
  handlePreviousStep, handleOnSubmit, formValues, isLoading,
}:Props) => {
  const {
    title, subtitle, jobType, companySize, productivityTool, remotePolicy,
  } = PersonalOnboardingFormModel.formP2;

  const handleBackArrowClick = (values: PersonalOnboardingData) => {
    handlePreviousStep(values);
  };

  const handleOnClick = (values: PersonalOnboardingData) => {
    handleOnSubmit(values);
  };

  return (
    <Scrollbar maxHeight="100vh">
      <Formik
        validateOnMount
        initialValues={formValues}
        onSubmit={handleOnClick}
        validationSchema={CompanyFormValidationSchema}
      >
        {({
          values, isValid, handleSubmit, handleChange, handleBlur,
        }: FormikValues) => (
          <FormContainer>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <CompanyForm onSubmit={(event) => { event.preventDefault(); handleSubmit(); }}>
              <OnboardingSingleSelectDropdown
                data={{ data: jobType, options: SortedArrayAlphabetically(Roles) }}
                functions={{ handleBlur, handleChange }}
              />
              <OnboardingSingleSelectDropdown
                data={{ data: companySize, options: CompanySizes }}
                functions={{ handleBlur, handleChange }}
              />
              <OnboardingMultiSelectDropdown
                data={{
                  data: productivityTool, options: SortedArrayAlphabetically(ProductivityTools),
                }}
              />
              <OnboardingSingleSelectDropdown
                data={{ data: remotePolicy, options: RemotePolicies }}
                functions={{ handleBlur, handleChange }}
              />
              <NavigationContainer>
                <BackButton onClick={() => { handleBackArrowClick(values); }}>
                  Back
                </BackButton>
                <OnboardingSubmitButton type="submit" text="Continue" loading={isLoading} isDisabled={!isValid} />
              </NavigationContainer>
            </CompanyForm>
          </FormContainer>
        )}
      </Formik>
    </Scrollbar>
  );
};

export default CompanyInfoForm;
