import React from 'react';
import styled from 'styled-components';
import { blue6, darkBlue1, gray1 } from '../../colours';

const Input = styled.input<InputProps>`
  -webkit-appearance: none;
  width: 100%;
  height: ${({ isLinkInput }) => (isLinkInput ? '44px' : '40px')};
  padding: 10px 0px 10px 15px;
  background-color: ${gray1};
  border: 2px solid ${darkBlue1};
  box-sizing: border-box;
  border-radius: 8px;

  :focus-visible {
    outline: inherit;
  }

  :focus {
    border: 2px solid ${blue6};
  }
`;

interface InputProps {
  isLinkInput: boolean,
}

interface Props {
  isLinkInput?: boolean;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  onKeyPress: (event: any) => void;
  inputRef: any;
}

const TextInputAtom = ({
  isLinkInput = false, placeholder, onKeyPress, inputRef,
}: Props) => (
  <Input
    isLinkInput={isLinkInput}
    placeholder={placeholder}
    onKeyPress={onKeyPress}
    ref={inputRef}
    autoFocus
  />
);

TextInputAtom.defaultProps = {
  isLinkInput: false,
};

export default TextInputAtom;
