import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../../shared/components/Modal';
import Scrollbar from '../../../shared/components/scrollbar';
import ButtonSmall from '../../../shared/components/button-small';
import ModalBodyWrapper from '../../../shared/components/Modal/ModalBodyWrapper';
import ModalFooterButtonsRightAlignedWrapper from '../../../shared/components/Modal/ModalFooterButtonsRightAlignedWrapper';
import Signature from './EmailSignature';
import { uiText } from '../../../shared/typography';
import MeetingDataContext from '../context/MeetingDataContext';
import { assembleEmailHTML } from '../../../utils/email/emailUtils';
import { TaskItem } from '../../../shared/types/types';
import { setNoteContent } from '../../../utils/meetings/meetingsUtils';
import { AGENDA, PERSONAL_NOTES, SHARED_NOTES } from '../../../utils/enums';
import { AuthContext } from '../../../App';
import { makeSubstringsBold } from '../../../utils/strings';
import { getUTCString } from '../../../utils/dateUtils/date';
import { NotesData, NotesSwitchData } from './ShareModal/utils/types';

export interface PreviewEmailModalProps {
  isModalOpen: boolean,
  loading: boolean,
  onBackClick: () => void,
  onSendClick: () => void,
  notesData: NotesData,
  notesSwitchData: NotesSwitchData,
  emailHeadTemplate: string,
  taskItems: TaskItem[],
}

const PreviewEmailModal = ({
  isModalOpen,
  loading,
  onBackClick,
  onSendClick,
  notesData,
  notesSwitchData,
  emailHeadTemplate,
  taskItems,
}: PreviewEmailModalProps) => {
  const {
    agendaHtml, sharedNotesHtml, personalNotesHtml, privateNotesHtml,
    setAgendaHtml, setSharedNotesHtml, setPersonalNotesHtml,
  } = notesData;
  const {
    isAgendaNotesChecked,
    isSharedNotesChecked,
    isPersonalNotesChecked,
    isTasksChecked,
    isPrivateSpaceNotesChecked,
  } = notesSwitchData;
  const [emailHtml, setEmailHtml] = useState<string>('');

  const {
    meetingId, data: { title }, date: { start }, version,
  } = useContext(MeetingDataContext);
  const { userId, firstName, lastName } = useContext(AuthContext);

  const wrapStyle = (html: string) => `<div style="${uiText}">${html}</div>`;

  useEffect(() => {
    if (!meetingId) return;

    const emailHeadHtml = makeSubstringsBold(emailHeadTemplate, [title, getUTCString(start.date)]);

    if (version === 2) {
      setNoteContent(setAgendaHtml, AGENDA, meetingId, userId);
      setNoteContent(setSharedNotesHtml, SHARED_NOTES, meetingId, userId);
      setNoteContent(setPersonalNotesHtml, PERSONAL_NOTES, meetingId, userId);
    }

    if (version >= 3) {
      // Filler comment
    }

    const notesContentHtml = assembleEmailHTML(
      isAgendaNotesChecked, isSharedNotesChecked, isPersonalNotesChecked, isTasksChecked,
      emailHeadHtml, agendaHtml, sharedNotesHtml, personalNotesHtml, taskItems,
      isPrivateSpaceNotesChecked, privateNotesHtml,
    );

    setEmailHtml(wrapStyle(notesContentHtml + Signature(firstName, lastName)));
  }, [isModalOpen, emailHeadTemplate, isAgendaNotesChecked, isSharedNotesChecked,
    isPersonalNotesChecked, meetingId]);

  return (
    <>
      <Modal isOpen={isModalOpen} setModalClosed={onBackClick} title="Preview before sharing">
        <Scrollbar maxHeight="60vh">
          <ModalBodyWrapper>
            <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
          </ModalBodyWrapper>
        </Scrollbar>
        <ModalFooterButtonsRightAlignedWrapper>
          <ButtonSmall onClick={onBackClick} text="Back" isOutline />
          <ButtonSmall onClick={onSendClick} text="Send" loading={loading} />
        </ModalFooterButtonsRightAlignedWrapper>
      </Modal>
    </>
  );
};

export default PreviewEmailModal;
