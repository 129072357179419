import { captureException } from '@sentry/browser';
import {
  AuthState,
  NotionData,
  PrivateNoteHtml, SlackChannel, TaskItem, TrelloData,
  MeetingSection,
  SharePlatforms, SDate, User, ResolvedState,
} from '../../shared/types/types';
import { SEND_EVENT } from '../analytics/enums';
import {
  logEventButtonClickSendEmailSubmit, logMeetingShared, logNonShepherdUsers, logShareNotesEvents,
} from '../analytics/eventLogger';
import { addCallForAction } from '../email/emailUtils';
import { cfSendMeetingNotes } from '../firebase';
import { createAndSendNotionPage } from '../notion/notionAPIs';
import { assembleNotionBlocks } from '../notion/notionUtils';
import { slackCoreSendMessages } from '../slack/SlackCoreAPI';
import { assembleSlackMessage } from '../slack/slackUtils';
import { sendTaskToTrelloBoard } from '../trello/trelloAPIs';
import { mapTimestampForLog } from './meetingsUtils';

export type ShareNotesDataType = {
  authState: AuthState,
  sendHeadlessNotesTemplateId: string
  emails: { email: string }[]
  html: string;
  title: string;
  startTime: string;
  startDate: string;
  agendaChecked: boolean;
  sharedNotesChecked: boolean;
  personalNotesChecked: boolean;
  taskChecked: boolean;
  emailHeadHtml: string;
  emailHeadTemplate: string;
  agendaHtml: string;
  sharedNotesHtml: string;
  personalNotesHtml: string;
  taskItems: TaskItem[];
  checkedPrivateNotesHtml: PrivateNoteHtml[],
  privateSpaceNotesChecked: string[],
}

export const generateSlackMessage = (
  shareNotesData: ShareNotesDataType,
  channelsToReceive: SlackChannel[],
  slackAccessToken: string,
  promiseArr: Promise<any>[],
) => {
  const slackMessage = assembleSlackMessage(shareNotesData);
  const messageWithCallForAction = addCallForAction(slackMessage);
  promiseArr.push(slackCoreSendMessages(
    shareNotesData.authState,
    channelsToReceive,
    slackAccessToken,
    messageWithCallForAction,
  ));
};

export const generateNotionPage = (
  shareNotesData: ShareNotesDataType,
  notionUserData: NotionData[],
  promiseArr: Promise<any>[],
) => {
  const notionPage = assembleNotionBlocks(shareNotesData, notionUserData);
  promiseArr.push(createAndSendNotionPage(shareNotesData.authState, notionPage));
};

export const sendEmails = (userData: User, {
  authState, emails, title, html, sendHeadlessNotesTemplateId,
  // eslint-disable-next-line no-unused-vars
}: ShareNotesDataType, trackEvent: (event: string, metaData?: object | undefined) => void,
promiseArr: Promise<any>[]) => {
  logEventButtonClickSendEmailSubmit(authState.userId, trackEvent, emails.length);
  logNonShepherdUsers(userData, emails);
  promiseArr.push(cfSendMeetingNotes(
    emails, title, html, sendHeadlessNotesTemplateId,
  ));
};

export const sendToTrello = (
  trelloChecked: boolean,
  taskToSend: TaskItem[],
  trelloUserData: TrelloData,
  promiseArr: Promise<any>[],
) => {
  try {
    const promise = sendTaskToTrelloBoard(trelloChecked, taskToSend, trelloUserData);
    promiseArr.push(promise);
  } catch (error) {
    captureException(error);
    console.log('Error sending task to trello board');
    console.log(error);
  }
};

export const getBooleanArray = (secretChatsLength: number) => {
  const booleanArray: boolean[] = [];
  for (let index = 0; index < secretChatsLength; index += 1) {
    booleanArray.push(false);
  }
  return booleanArray;
};

export const generateLog = (
  userId: string,
  channelsToShare: SharePlatforms[],
  meetingSectionToShare: MeetingSection[],
  meetingStartTime: SDate,
  meetingEndTime: SDate,
  status: ResolvedState,
) => {
  const logTimeStamp = mapTimestampForLog(meetingStartTime, meetingEndTime);
  logShareNotesEvents(
    userId, SEND_EVENT, meetingSectionToShare,
    channelsToShare, logTimeStamp, status,
  );
  logMeetingShared(userId);
};

export const getMeetingSectionToShare = (
  isAgendaNotesChecked: boolean,
  isSharedNotesChecked: boolean,
  isPersonalNotesChecked:boolean,
  isTasksChecked:boolean,
) => {
  const meetingSections: MeetingSection[] = [];
  if (isAgendaNotesChecked) meetingSections.push('agenda');
  if (isSharedNotesChecked) meetingSections.push('shared');
  if (isPersonalNotesChecked) meetingSections.push('private');
  if (isTasksChecked) meetingSections.push('task');
  return meetingSections;
};
