/* eslint-disable no-unused-vars */
import { captureException } from '@sentry/react';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { ResolveState } from '../../shared/types/types';
import { TASKS_COLLECTION } from '../../utils/constants';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { firestore } from '../../utils/firebase';

/**
 * Core functions to interact with the Google API. Should only be used by GoogleAPI class.
 */
class GoogleAPICore {
  // protected static async updateTask(taskId: string, updateObject: object): Promise<ResolveState>{
  // }

}

export default GoogleAPICore;
