import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import { firestore } from '../utils/firebase';
import { NotionData } from '../shared/types/types';
import { logIntegrationsEvent } from '../utils/analytics/eventLogger';
import { DISABLE_EVENT, NOTION_INTEGRATION } from '../utils/analytics/enums';

export const dbSaveUserWorkspace = (userId: string, workSpace: NotionData[]) => {
  const dbData = {
    'integrations.notion': workSpace,
  };
  firestore()
    .collection('users')
    .doc(userId)
    .update(dbData)
    .then(() => {
      console.log('token saved successfully');
    })
    .catch((error) => {
      console.log('token not saved');
      console.log(error);
      Sentry.captureException(error);
    });
};

export const dbGetUserWorkspace = (
  userId: string,
  setWorkspace: Dispatch<SetStateAction<NotionData[]>>,
) => firestore()
  .collection('users')
  .doc(userId)
  .get()
  .then((snapshot) => {
    console.log('workspace in firebase', snapshot.data()?.integrations?.notion || []);
    setWorkspace(snapshot.data()?.integrations?.notion || [] as NotionData[]);
    console.log(snapshot.data());
  })
  .catch((error) => {
    Sentry.captureException(error);
    console.log(error);
  });

export const notionAPIRemoveNotionIntegration = (userId: string) => {
  const defaultChannelData = {
    'integrations.notion': [],
  };
  firestore()
    .collection('users')
    .doc(userId)
    .update(defaultChannelData)
    .then(() => {
      console.log('Successfully removed notion data from user data');
      logIntegrationsEvent(userId, DISABLE_EVENT, NOTION_INTEGRATION);
    })
    .catch((error) => {
      console.log(error);
    });
};
