import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { TASK_LIST_OPTIONS_DUE_DATE_PREFIX } from '../../../utils/constants';
import { red7 } from '../../colours';
import ReactTooltip from '../tooltip/ReactTooltip';

const StyleModifier = styled.span`
  ${(props: any) => (props['data-is-overdue']
    ? `
    color: ${red7};
    `
    : '')}
`;

interface Props {
  id?: string,
  children: ReactNode,
  isOverdue: boolean,
}

const TaskDueDateOverdueModifier = ({ id, children, isOverdue }: Props) => (
  <ReactTooltip tip="Overdue" disabled={!isOverdue} offset={{ top: 0, left: 9 }}>
    <StyleModifier id={TASK_LIST_OPTIONS_DUE_DATE_PREFIX + id} data-is-overdue={isOverdue}>
      {children}
    </StyleModifier>
  </ReactTooltip>
);
TaskDueDateOverdueModifier.defaultProps = {
  id: '',
};
export default TaskDueDateOverdueModifier;
