import React from 'react';
import styled, { css } from 'styled-components';
import { surface } from '../../colours';
import { TasksPage } from '../../types/types';

const TopContainer = styled.div<Props>`
  background: ${surface};
  // ${({ borderRadiusTop }) => css`
  //     height: ${borderRadiusTop};
  // `};
  height: 24px;
`;

const InnerContainer = styled.div<Props>`
  width: 100%;
  height: 100%;
  background: white;
  ${({ page, borderRadiusTop }) => page === 'allTasks' && css`
      border-radius: ${borderRadiusTop} ${borderRadiusTop} 0 0;
  `};
`;

interface Props {
  page: TasksPage,
  borderRadiusTop: string;
}

/**
 * We use this component since when we added the border radius inside the `TaskTabs`,
 *  the scrollbar was messed up
 */
const BorderRadiusComponent = ({ page, borderRadiusTop }: Props) => (
  <TopContainer page={page} borderRadiusTop={borderRadiusTop}>
    <InnerContainer page={page} borderRadiusTop={borderRadiusTop} />
  </TopContainer>
);

export default BorderRadiusComponent;
