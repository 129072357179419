import React from 'react';
import styled from 'styled-components';
import InviteButtonAtom from './InviteButtonAtom';
import LabelAtom from './LabelAtom';
import SubHeaderAtom from './SubHeaderAtom';
import TextInputAtom from './TextInputAtom';

const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;

  span {
    :first-child {
      margin-bottom: 20px;
    }
    :nth-child(2) {
      margin-bottom: 16px;
    }
  }
`;

const InputFormContainer = styled.div`
  display: flex;
  width: 100%;
  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

interface Props {
  // eslint-disable-next-line no-unused-vars
  onKeyPress: (event: KeyboardEvent) => void;
  onInviteButtonClick: () => void;
  isInviteButtonDisabled: boolean;
  inputRef: any;
}

const SendEmailMolecule = ({
  onKeyPress, onInviteButtonClick, isInviteButtonDisabled, inputRef,
}: Props) => (
  <Container>
    <LabelAtom text="Shepherd is better together!" />
    <SubHeaderAtom text="Send an email invite" />
    <InputFormContainer>
      <TextInputAtom
        placeholder="Add email and press Enter to send"
        onKeyPress={onKeyPress}
        inputRef={inputRef}
      />
      <InviteButtonAtom isDisabled={isInviteButtonDisabled} onClick={onInviteButtonClick} />
    </InputFormContainer>
  </Container>
);

export default SendEmailMolecule;
