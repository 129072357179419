import { captureException } from '@sentry/react';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { ResolveState } from '../../shared/types/types';
import { REJECTED, RESOLVED } from '../../utils/enums';
import { functions } from '../../utils/firebase';

/**
 * DON'T USE THIS CLASS DIRECTLY. This class should only be used by EmailAPI class
 */
class EmailCore {
  protected static async sendBasicEmailCore(
    emailRecipients: string[],
    subject: string,
    mainContentHTML: string,
  ): Promise<ResolveState> {
    return functions()
      .httpsCallable('sendBasicEmail2')({
        emailRecipients, subject, mainContentHTML,
      })
      .then(() => RESOLVED as ResolveState)
      .catch((error) => {
        captureException(error);
        ConsoleImproved.log('Failed to send email', {
          error, emailRecipients, subject, mainContentHTML,
        });
        return REJECTED as ResolveState;
      });
  }
}

export default EmailCore;
